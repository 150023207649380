import { useState } from "react";
import { primaryColor } from "../../constants";
import { ContactFilters } from "./ContactFilters";

export const Sidebar = ({ className }) => {
  const [activeFilter, setActiveFilter] = useState(0);
  return (
    <div
      className={`-min-w-[calc(18vw-4.5vw+23px)] min-w-[calc(15%+7px)] --w-[17.2vw] -w-[17.85%]- h-full overflow-y--scroll bg-[var(--background)] border-[var(--layoutBorder)] px-[1vw] py-[1.5vw] border-r border-r-[rgb(229,231,235)] ${className}`}
    >
      <p
        className={`${
          activeFilter === 0
            ? `bg-[${primaryColor}] text-white font-[600]`
            : `text-black font-[500]`
        } cursor-pointer transition-colors duration-200 rounded-lg ps-[0.5vw] py-[.7vh] text-[0.85vw] font-[500]`}
        onClick={() => setActiveFilter(0)}
      >
        All contacts
      </p>
      {/* 
      <ContactFilters
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
      /> */}
    </div>
  );
};
