import ArrowWithCircle from '../../../assets/customSVG/ArrowWithCircle';
import Invoice from '../../../assets/customSVG/Invoice';
import Shop from '../../../assets/customSVG/Shop';
import User from '../../../assets/customSVG/User';

export const ACCOUNT_COUNTS = [
  {
    id: 1,
    label: 'No. of brands',
    value: '6',
    key: 'total_brands',
    icon: Shop,
    color: '#ED4F9D',
    bg: '#FDF2F8',
    route: '',
  },
  {
    id: 2,
    label: 'No. of people',
    value: '6',
    key: 'total_users',
    icon: User,
    color: '#4AACEA',
    bg: '#E7F4FC',
    route: '',
  },
  {
    id: 3,
    label: 'Active brands',
    value: '16',
    key: 'active_brands',
    icon: Shop,
    color: '#0ACD95',
    bg: '#F0FDF4',
    route: '',
  },
  {
    id: 4,
    label: 'In-active brands',
    value: '3',
    key: 'inactive_brands',
    icon: Shop,
    color: '#F96056',
    bg: '#FFEEED',
    route: '',
  },
  {
    id: 5,
    label: 'Pending Invoices',
    value: '6',
    key: 'pendinng_invoices',
    icon: Invoice,
    color: '#F6A723',
    bg: '#FFFBEB',
    route: '',
  },
  {
    id: 6,
    label: 'View All Brands',
    value: '',
    icon: ArrowWithCircle,
    color: '#0ACD95',
    bg: '',
    route: '',
  },
];
