import React, { useEffect, useState } from "react";

// assets
import PencilIcon from "assets/customSVG/Pencil";
import { ICONS } from "assets/icons";

// hooks
import useToggle from "hooks/useToggle";

// redux
import {
  useAspDispatch,
  useAspSelector,
} from "../../../../test/jest-redux-hooks";
import { update } from "../../../../reduxToolkit/templateSlice";
import { checkTemplateExits } from "../../api/Api";

import Loader from "components/commonComponents/Loader/Index";

export default function TemplateName({
  type,
  onChange,
  checking,
  setChecking,
  templateName,
  placeholder,
  error,
  onErrorChange,
  allow = true,
  title,
  content,
  rootClassName,
}) {
  const templateDetails = useAspSelector((state) => state?.Template);
  const { currentBrand } = useAspSelector((state) => state?.app);
  const dispatch = useAspDispatch();

  const [name, setName] = useState(null);
  const [isEdit, setIsEdit] = useToggle(false);

  useEffect(() => {
    setName(templateDetails?.name);
  }, [templateDetails]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      updateName(name);
    }, 500);

    return () => clearTimeout(timeout);
  }, [name]);

  const handlesChange = (val) => {
    // convert spaces into underscroe and uppercase values into lowercase
    // does not allow special characters and symbols
    const modifiedValue = val?.replace(/ /g, "_");
    const sanitizedValue = modifiedValue.replace(/[^\w\s]/gi, "").toLowerCase();
    setName(sanitizedValue);
    templateName;
    if (onChange) onChange(sanitizedValue);
  };

  const checkName = async (name) => {
    const res = await checkTemplateExits({
      brandId: currentBrand?.brand_id,
      name: name,
    });
    return res;
  };

  const updateName = async () => {
    if (setChecking) setChecking(true);
    try {
      const res = await checkName(name);

      if (res?.data?.exists === false) {
        dispatch(
          update({
            key: "name",
            value: name,
          })
        );
        if (type === "edit") setIsEdit(false);
        onErrorChange(false);
      }

      if (res?.data?.exists === true || res === null) {
        onErrorChange(true);
        handlesChange(name);
      }
    } catch (e) {
      console.warn(e);
    } finally {
      if (setChecking) setChecking(false);
    }
  };

  return (
    <div
      className={`w-full bg-[var(--appHeaderBG)] flex-column px-[1vw] ${
        ["create", "duplicate"].includes(type) ? "pt-[0.5vw] pb-[1vw]" : ""
      }  ${
        type === "edit" && error
          ? " border-[0.08vw] border-[var(--fontRed)]"
          : "border-[0.08vw] border-transparent"
      } ${rootClassName}`}
    >
      {type === "create" && !title && !content && (
        <>
          <div
            className={`font-normal weight-bold mb-[0.3vw] text-[var(--contentText)]`}
          >
            Template Name
          </div>
          <p
            className={`font-md weight-medium text-[var(--font-400)] pb-[0.7vw]`}
          >
            Enter a template name.
          </p>
        </>
      )}
      {type === "duplicate" && (
        <>
          <div
            className={`font-normal weight-bold mb-[0.3vw] text-[var(--contentText)]`}
          >
            {title}
          </div>
          <p
            className={`font-md weight-medium text-[var(--font-400)] pb-[0.7vw]`}
          >
            {content}
          </p>
        </>
      )}
      <div
        className={`w-full py-[0.5vw] rounded-[0.4vw] flex items-center text-[var(--contentText)] ${
          type === "edit"
            ? allow && isEdit
              ? "max-w-[30vw]"
              : "max-w-[25vw]"
            : "border-[0.08vw] border-[var(--border-100)] pl-[0.6vw] pr-[0.6vw] "
        }`}
      >
        {["create", "duplicate"].includes(type) ||
        (type === "edit" && allow && isEdit) ? (
          <input
            type="text"
            value={name}
            placeholder={"message template name..."}
            maxLength={512}
            onChange={(e) => {
              handlesChange(e.target.value);
            }}
            // onBlur={() => {
            //   updateName();
            // }}
            className={`w-full font-md weight-medium`}
          />
        ) : (
          <div
            className={`w-full font-md weight-medium block truncate ${
              ["create", "duplicate"].includes(type) ? "w-[92%]" : "w-full"
            } ${name?.length > 0 ? "" : "text-[var(--font-400)]"}`}
          >
            {name?.length > 0 ? name : placeholder}
          </div>
        )}
        {type === "edit" && (
          <div
            className={`w-[2.5vw] pl-[1vw] ${
              allow ? "cursor-pointer" : "cursor-not-allowed"
            }`}
            onClick={() => {
              if (allow) {
                if (!isEdit) setIsEdit(!isEdit);
                if (isEdit) updateName(name);
              }
            }}
          >
            {isEdit ? (
              <img
                src={ICONS?.greenTickVerticalSteps}
                alt="confirm"
                className="w-[1vw] h-[1vw]"
              />
            ) : (
              <PencilIcon
                width={"1.2vw"}
                height={"1.2vw"}
                color={"var(--primary)"}
              />
            )}
          </div>
        )}
        {["create", "duplicate"].includes(type) && (
          <div
            className={`w-[3.5vw] h-[auto] font-sm weight-medium text-[var(--font-400)] mt-[0.3vw] text-right flex items-center justify-end`}
          >
            {checking ? (
              <Loader Width={20} Height={20} />
            ) : (
              `${name?.length > 0 ? name?.length : 0} / 512`
            )}
          </div>
        )}
      </div>

      {!checking &&
        name?.length > 0 &&
        error &&
        ["create", "duplicate"].includes(type) && (
          <div className="p-[0.4vw] font-sm weight-medium text-[var(--fontRed)]">
            This name already exists, please enter a different one.
          </div>
        )}
    </div>
  );
}
