import React from 'react';
import './avatar.css';
import { Logout } from '../../../api/Api';
import { profileBoxList } from '../../../constant/app/appLayout';
import { useNavigate } from 'react-router-dom';
import Alert from '../modals/Alert';
import useToggle from '../../../hooks/useToggle';

function Avatar({ style, name, onClick, isModalVisible, setIsModalVisible }) {
  const [loader, setLoader] = useToggle(false);

  const navigate = useNavigate();

  const handleCancel = () => setIsModalVisible(false);

  const handleNo = () => setIsModalVisible(false);

  const handleYes = () => {
    setLoader(true);

    Logout().then((res) => {
      if (res?.status === 200) {
        navigate('/login');
        localStorage.clear();
      } else {
        console.warn('error', res);
      }
    });
  };

  const handleItemClick = (item) => {
    setIsModalVisible(true);
  };

  return (
    <>
      {name ? (
        <div
          className='nameAvatar flex-column align-center justify-center'
          style={style}
          onClick={onClick}
        >
          <div className='name'>{name?.slice(0, 2)}</div>
        </div>
      ) : (
        <div className='nameAvatar'>
          {profileBoxList.map((item) => (
            <div
              className='flex-row list nameAvatar'
              onClick={() => handleItemClick(item)}
              key={item.id}
            >
              <img src={item.icon} alt={item.name} className='images' />
            </div>
          ))}

          <Alert
            open={isModalVisible}
            setOpen={setIsModalVisible}
            handleCancel={handleCancel}
            handleNo={handleNo}
            handleYes={handleYes}
            content={<p>Are you sure you want to Logout ?</p>}
            yesContent='Yes'
            noContent='No'
            loading={loader}
            yesButtonClassName='py-[0.25vw]'
            noButtonClassName='py-[0.25vw]'
          />
        </div>
      )}
    </>
  );
}

export default React.memo(Avatar);
