import { ICONS } from "assets/icons";

export const contactSourceIcons = {
  // WhatsApp: ICONS?.crmWhatsApp,
  "Facebook Ads": ICONS?.crmFaceBook,
  "Facebook Reels": ICONS?.crmFaceBook,
  "Facebook Post": ICONS?.crmFaceBook,
  "Instagram Post": ICONS?.crmInstagram,
  "Twitter Ads": ICONS?.crmTwitter,
  "Linkedin Ads": ICONS?.crmLinkedIn,
  "TikTok Ads": ICONS?.crmTikTok,
  // "Google Ads": ICONS?.crmGoogleAds,
  Website: ICONS?.crmWebsite,
  "Store Visit": ICONS?.crmStore,
  Other: ICONS?.crmOthers,
};

export const gender = [
  {
    id: 1,
    label: "Male",
  },
  {
    id: 2,
    label: "Female",
  },
  {
    id: 0,
    label: "Other",
  },
];

export const ageRange = [
  {
    id: 1,
    label: "2 - 17",
  },
  {
    id: 2,
    label: "18 - 24",
  },
  {
    id: 3,
    label: "25 - 34",
  },
  {
    id: 4,
    label: "35 - 44",
  },
  {
    id: 5,
    label: "45 - 54",
  },
  {
    id: 6,
    label: "54 - 64",
  },
  {
    id: 7,
    label: "65+",
  },
];
