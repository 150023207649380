const COLORS = [
  "#EF4444",
  "#F97316",
  "#FACC15",
  "#4ADE80",
  "#2DD4BF",
  "#EC4899",
  "#3B82F6",
  "#6366F1",
  "#02D1C5",
  "#F43F5E",

  "#D946EF",
  "#8B5CF6",
  "#10B981",
  "#84CC16",
  "#C585FE",
  "#F49345",
  "#9354A5",
  "#9C112E",
  "#ECAA22",
  "#8D6951",

  "#973CA5",
  "#6D5E8F",
  "#0B7E58",
  "#64A008",
  "#6820A8",
  "#D9680D",
  "#AE34D0",
  "#E00230",
  "#E99E03",
  "#7B411B",

  "#72297D",
  "#38216B",
  "#1B503E",
  "#457300",
  "#4B246D",
  "#5E3514",
  "#43254B",
  "#672130",
  "#AA7509",
  "#431E05",

  "#FDA41E",
  "#15B14A",
  "#3CBF94",
  "#12929A",
  "#D20B6B",
  "#B10845",
  "#9A0D8C",
  "#E55573",
  "#46C9E5",
  "#8D5171",
];

const LEETERS_COLORS = {
  0: "#F97316",
  1: "#FACC15",
  2: "#4ADE80",
  3: "#2DD4BF",
  4: "#EC4899",
  5: "#3B82F6",
  6: "#6366F1",
  7: "#02D1C5",
  8: "#F43F5E",
  9: "#D946EF",
  a: "#D946EF",
  b: "#8B5CF6",
  c: "#10B981",
  d: "#84CC16",
  e: "#C585FE",
  f: "#8D6951",
  g: "#9354A5",
  h: "#F49345",
  i: "#ECAA22",
  j: "#973CA5",
  k: "#6D5E8F",
  l: "#0B7E58",
  m: "#64A008",
  n: "#6820A8",
  o: "#D9680D",
  p: "#AE34D0",
  q: "#E99E03",
  r: "#7B411B",
  s: "#72297D",
  t: "#38216B",
  u: "#1B503E",
  v: "#457300",
  w: "#4B246D",
  x: "#5E3514",
  y: "#43254B",
  z: "#AA7509",
};

export function hexToRgba(hex, alpha = 0.2) {
  hex = hex?.replace(/^#/, "");

  if (hex.length === 4) {
    hex = hex
      .split("")
      .map((char) => char + char)
      .join("");
  }

  // Convert to RGB
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export const getRandomColors = (COLORS) =>
  COLORS[Math.floor(Math.random() * COLORS.length)];

export const getRandomColorsBassedOnLetters = (str) => {
  const letter = str?.slice(0, 1);
  const color = LEETERS_COLORS[letter?.toString()?.toLowerCase()];
  return {
    color: color,
    backgroundColor: hexToRgba(color, 0.2),
  };
};
