import React from 'react';

export default function Invoice({
  width = '22',
  height = '26',
  color = '#F6A723',
  className = '',
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M5.97563 15.9444C5.72568 15.9444 5.48597 16.0437 5.30923 16.2205C5.13249 16.3972 5.0332 16.6369 5.0332 16.8869C5.0332 17.1368 5.13249 17.3765 5.30923 17.5533C5.48597 17.73 5.72568 17.8293 5.97563 17.8293H12.8868C13.1367 17.8293 13.3764 17.73 13.5532 17.5533C13.7299 17.3765 13.8292 17.1368 13.8292 16.8869C13.8292 16.6369 13.7299 16.3972 13.5532 16.2205C13.3764 16.0437 13.1367 15.9444 12.8868 15.9444H5.97563ZM5.0332 8.09087C5.0332 7.84092 5.13249 7.60121 5.30923 7.42447C5.48597 7.24773 5.72568 7.14844 5.97563 7.14844H16.0282C16.2781 7.14844 16.5179 7.24773 16.6946 7.42447C16.8713 7.60121 16.9706 7.84092 16.9706 8.09087C16.9706 8.34081 16.8713 8.58052 16.6946 8.75726C16.5179 8.934 16.2781 9.03329 16.0282 9.03329H5.97563C5.72568 9.03329 5.48597 8.934 5.30923 8.75726C5.13249 8.58052 5.0332 8.34081 5.0332 8.09087ZM5.97563 11.5464C5.72568 11.5464 5.48597 11.6457 5.30923 11.8225C5.13249 11.9992 5.0332 12.2389 5.0332 12.4889C5.0332 12.7388 5.13249 12.9785 5.30923 13.1553C5.48597 13.332 5.72568 13.4313 5.97563 13.4313H16.0282C16.2781 13.4313 16.5179 13.332 16.6946 13.1553C16.8713 12.9785 16.9706 12.7388 16.9706 12.4889C16.9706 12.2389 16.8713 11.9992 16.6946 11.8225C16.5179 11.6457 16.2781 11.5464 16.0282 11.5464H5.97563Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.04555 0.235352C7.32782 0.235352 5.94182 0.235352 4.85238 0.38237C3.72146 0.533159 2.76898 0.859867 2.01253 1.61507C1.25607 2.37152 0.931876 3.324 0.779831 4.45492C0.632813 5.54436 0.632812 6.93036 0.632812 8.64809V23.2683C0.632812 25.0401 2.65463 26.0503 4.07079 24.9873L4.3887 24.7485C5.00515 24.2862 5.76082 24.048 6.53095 24.0731C7.30107 24.0982 8.03961 24.3852 8.6246 24.8868C9.28599 25.4537 10.1284 25.7654 10.9995 25.7654C11.8707 25.7654 12.713 25.4537 13.3744 24.8868C13.9594 24.3852 14.698 24.0982 15.4681 24.0731C16.2382 24.048 16.9939 24.2862 17.6103 24.7485L17.9282 24.9873C19.3444 26.0503 21.3662 25.0388 21.3662 23.2683V8.64809C21.3662 6.93036 21.3662 5.54436 21.2205 4.45492C21.0672 3.324 20.743 2.37152 19.9865 1.61507C19.2301 0.858611 18.2776 0.534415 17.1467 0.38237C16.0572 0.235352 14.6712 0.235352 12.9535 0.235352H9.04555ZM3.347 2.94829C3.69507 2.60022 4.18262 2.37403 5.1062 2.24963C6.05366 2.12272 7.31274 2.12021 9.11592 2.12021H12.8856C14.6888 2.12021 15.9466 2.12272 16.8966 2.24963C17.8189 2.37403 18.3065 2.60147 18.6545 2.94829C19.0026 3.29636 19.2288 3.78391 19.3532 4.70749C19.4801 5.65494 19.4826 6.91402 19.4826 8.7172V23.2683C19.4826 23.3173 19.469 23.3653 19.4432 23.407C19.4175 23.4487 19.3806 23.4824 19.3368 23.5043C19.2929 23.5262 19.2439 23.5355 19.195 23.5311C19.1462 23.5267 19.0996 23.5088 19.0604 23.4794L18.7425 23.2406C17.7829 22.5207 16.6065 22.1497 15.4075 22.1888C14.2085 22.2278 13.0587 22.6747 12.148 23.4555C11.4883 24.021 10.5145 24.021 9.85353 23.4555C8.94258 22.6751 7.79275 22.2286 6.59383 22.1897C5.39491 22.1509 4.21859 22.5221 3.25904 23.2419L2.94113 23.4806C2.90184 23.5101 2.85511 23.528 2.80619 23.5324C2.75727 23.5367 2.70811 23.5274 2.66423 23.5053C2.62035 23.4832 2.58351 23.4493 2.55784 23.4075C2.53217 23.3656 2.51869 23.3174 2.51892 23.2683V8.7172C2.51892 6.91402 2.52144 5.65494 2.64835 4.70623C2.77275 3.78391 3.00019 3.29636 3.347 2.94829Z'
        fill={color}
      />
    </svg>
  );
}
