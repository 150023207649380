import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import LineLoader from "../../commonComponents/loader/LineLoader";
import { useSelector } from "react-redux";
import CrmTable from "./CrmTable";
import NoCrm from "./NoCrm";

const MainContactList = ({
  crmLists,
  isLoading,
  visibleColumns,
  loadNextPage,
  count,
  isFilter,
  no,
}) => {
  const [isTagsDropdownOpen, setIsTagsDropdownOpen] = useState(null);
  const [selectedItems, setSelectedItems] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const firstColumnRef = useRef(null);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    if (crmLists) {
      const selectedContactData = crmLists?.map((item) => {
        return { id: item.id, selected: false };
      });
      setSelectedItems(selectedContactData);
    }
  }, [crmLists]);

  // handle click on single column checkbox
  const handleSelect = (id) => {
    const updated = selectedItems?.map((item) => {
      if (item.id === id) {
        return { ...item, selected: !item.selected };
      }
      return item;
    });
    const isSelectAll = updated.filter((item) => !item.selected).length === 0;

    setSelectedItems(updated);
    setSelectAll(isSelectAll);
  };

  const { crmColumn } = useSelector((store) => store.crm);

  // handle visibility state of each columns Tags popover
  const toggleTagsDropdown = (id) => {
    setIsTagsDropdownOpen((prev) => {
      if (prev === id) {
        return null;
      }
      return id;
    });
  };

  // handle select all and unselect all scenario in columns
  const enableSelectAll = selectedItems?.filter((item) => item.selected).length;
  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedItems((prev) =>
      prev.map((item) => {
        return { ...item, selected: !selectAll };
      })
    );
  };

  // to hide popover on scroll
  useEffect(() => {
    const handleScroll = (e) => {
      {
        isTagsDropdownOpen && setIsTagsDropdownOpen(null);
        const { scrollLeft, scrollWidth, clientWidth } = e.target;
        // const indication = Math.ceil(
        //   (scrollTop / (scrollHeight - clientHeight)) * 100,
        // );

        // indication > 50 &&
        //   columns.length <= 70 &&
        //   (console.log({ indication, columnslen: columns.length }),
        //   FetchColumns());
        const position = Math.ceil(
          (scrollLeft / (scrollWidth - clientWidth)) * 100
        );
        const containers = firstColumnRef?.current?.children;

        position === 0 && handleScrollShadow("remove", containers);
        position > 0 && position <= 4 && handleScrollShadow("add", containers);
      }
    };
    // to show and hide single column scroll shadow
    function handleScrollShadow(
      method = "add",
      collection,
      classList = "scroll-shadow"
    ) {
      for (let i = 0; i < collection?.length; i++) {
        collection?.[i]?.children?.[0]?.classList?.[method]?.(classList);
      }
    }

    const refElement = scrollContainerRef.current;

    // refElement.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    // return () => {
    //   refElement.removeEventListener("scroll", handleScroll);
    // };
  }, [isTagsDropdownOpen]);

  // const fetchColumns = async () => {
  //   setIsColumnsLoading(true);
  //   try {
  //     await setTimeout(() => {
  //       setColumns((prev) => [...prev, ...crmLists]);
  //       setIsColumnsLoading(false);
  //     }, 500);
  //   } catch (error) {
  //     console.log(error.message);
  //   } finally {
  //     // console.log('fetch success');
  //   }
  // };

  const showSkeleton = isLoading;

  return (
    <div className="overflow-scroll sm:w-[99.4%] bg-[#F4F6F8] 2xl:w-full relative">
      {/* <LineLoader visible={isColumnsLoading} /> */}
      {isLoading && (
        <div
          className="w-full h-[2vh] flex items-center justify-center "
          // style={{ marginBottom: "40px" }}
        >
          <LineLoader
            visible={isLoading}
            Width="200vw"
            loaderBg="4px solid white"
            loaderColor="4px solid var(--primary)"
            primaryColor="var(--primary)"
          />
        </div>
      )}

      <div
        className="container"
        style={{
          height: "73vh",
        }}
      >
        <div
          className="innerContainer listScroll pr-[0.5vw] pl-10"
          id="scrollableDiv"
          // ref={templateListContainerRef}
        >
          {/* <Skeleton loading={isLoading} active paragraph={{ rows: 7 }}> */}
          <InfiniteScroll
            dataLength={crmLists?.length}
            next={loadNextPage}
            hasMore={crmLists?.length < count}
            scrollableTarget="scrollableDiv"
          >
            {crmLists?.length > 0 ? (
              <table className="">
                <thead className="!text-[var(--textBlack)]">
                  <tr>
                    {crmColumn
                      ?.filter((column) =>
                        visibleColumns?.includes(column?.label)
                      )
                      ?.map((item, i) => (
                        <th
                          key={i}
                          className="min-w-[15vw]"
                          style={{ fontSize: "0.95vw", fontWeight: "500" }}
                        >
                          {item?.label}
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {crmLists?.map((item, i) => {
                    return (
                      <CrmTable
                        key={`${item?.name}-${i}`}
                        isLoading={isLoading}
                        data={item}
                        visibleColumns={visibleColumns}
                        isFilter={isFilter}
                      />
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className="h-[40vh]">
                <NoCrm no={no} />
              </div>
            )}
          </InfiniteScroll>
          {/* </Skeleton> */}
        </div>
      </div>
    </div>
  );
};

export default MainContactList;
