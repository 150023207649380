import React, { useEffect, useState } from "react";
import "../../styles/Campaign.css";

import { useNavigate, useParams } from "react-router-dom";

import Table from "../../../../components/commonComponents/tables/Table";
import ListViewHeader from "../../../../components/commonComponents/ListViewHeader/ListViewHeader";
import SelectedCampaignCount from "./SelectedCampaignCount";

import {
  useAspDispatch,
  useAspSelector,
} from "../../../../test/jest-redux-hooks";
import {
  updateTabList,
  updateActiveTab,
} from "../../../../reduxToolkit/CampaignSlice";
import {
  campaignBroadcastListApi,
  campaignDelete,
  campaignDeleteBulk,
  campaignListApi,
} from "../../api/Api";

import { Skeleton } from "antd";
import { updateToggleToast } from "../../../../reduxToolkit/appSlice";
import useToggle from "../../../../hooks/useToggle";

let page = 1;
let count = 0;

function CampaignList({ statusExists }) {
  // let count = 0;
  const navigate = useNavigate();
  const { campaign_type } = useParams();

  const [tableList, setTableList] = useState([]);
  const [tableList1, setTableList1] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isFilter, setIsFilter] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [value, toggleValue] = useToggle(false);

  // const [count, setCount] = useState(0);
  // const [page, setPage] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const dispatch = useAspDispatch();
  const { tabList } = useAspSelector((state) => state.Campaign);
  const [continueLoading, setContinueLoading] = useState(true);

  const { currentBrand, toggleToast } = useAspSelector((state) => state.app);

  const tableRowClicked = (data) => {
    dispatch(
      updateTabList([
        { id: 1, tabName: "campaignList" },
        {
          id: data?.id,
          tabName: "campaignName",
          name: data?.name,
          allData: data,
        },
      ])
    );
    dispatch(updateActiveTab("campaignName"));
  };

  useEffect(() => {
    dispatch(updateActiveTab("campaignList"));
    tabList?.length === 1 &&
      dispatch(updateTabList([{ id: 1, tabName: "campaignList" }]));
  }, []);

  const getCampaignList = (brandId, payload) => {
    if (brandId) {
      setIsLoading(true);
      campaignListApi(brandId, payload)
        .then((res) => {
          if (res && res?.data?.campaigns?.length > 0) {
            const newData =
              payload?.page === 1
                ? res?.data?.campaigns
                : [...tableList, ...res?.data?.campaigns];
            setTableList(newData);
            count = res?.data?.campaigns_count;
            // setCount(res?.data?.campaigns_count);
            // setPage(page + 1);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setTableList([]);
    }
  };

  const getBroadcastCampaignList = (brandId, payload) => {
    if (brandId) {
      setIsLoading(true);
      campaignBroadcastListApi(brandId, payload)
        .then((res) => {
          if (res && res?.data?.campaigns?.length > 0) {
            const newData =
              payload?.page === 1
                ? res?.data?.campaigns
                : [...tableList, ...res?.data?.campaigns];
            setTableList(newData);
            count = res?.data?.campaigns_count;
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setTableList([]);
    }
  };

  const loadNextPage = () => {
    if (!isLoading) {
      page += 1;

      if (campaign_type === "all")
        getCampaignList(currentBrand?.brand_id, {
          page: page,
          limit: 10,
        });

      if (campaign_type === "time_specified")
        getBroadcastCampaignList(currentBrand?.brand_id, {
          page: page,
          limit: 10,
        });
    }
  };

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;

    if (
      tableList?.length < count &&
      scrollTop + clientHeight >= scrollHeight - 20
    ) {
      setIsLoading(true);
      loadNextPage();
    }
  };

  useEffect(() => {
    page = 1;
    setTableList([]);
    setIsLoading(true);
    if (campaign_type === "time_specified") {
      getBroadcastCampaignList(currentBrand?.brand_id, {
        page: page,
        limit: 10,
        name: isFilter,
        status:
          selectedStatus.length > 0 ? selectedStatus.join(",") : undefined,
      });
    }
    if (campaign_type === "all") {
      getCampaignList(currentBrand?.brand_id, {
        page: page,
        limit: 10,
        name: isFilter,
      });
    }
  }, [currentBrand, isFilter, campaign_type]);

  const handleDelete = (item) => {
    toggleValue(true);

    campaignDelete(item?.id).then((res) => {
      if (res.status === 200) {
        getCampaignList(currentBrand?.id);
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: "Campaign deleted successfully",
              status: "Success",
              duration: "",
            },
          ])
        );
        window.location.reload();
      } else {
      }
    });
  };

  const handleBulkDelete = () => {
    toggleValue(true);

    const selectedCampaignIds = tableList
      .filter((campaign) => campaign.selected === true)
      .map((campaign) => campaign.id);

    const unselectedCampaignIds = tableList
      .filter((campaign) => !selectedCampaignIds.includes(campaign.id))
      .map((campaign) => campaign.id);

    const payLoad = {
      except_campaign_ids: unselectedCampaignIds,
      brand_id: currentBrand?.brand_id,
      bulk_delete: 1,
    };

    campaignDeleteBulk(payLoad).then((res) => {
      if (res.status === 200) {
        getCampaignList(currentBrand?.brand_id);
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: "Campaign deleted successfully",
              status: "Success",
              duration: "",
            },
          ])
        );
        // window.location.reload();
      } else {
        toggleValue(false);
      }
    });
  };
  const templateSearch = (value) => {
    setIsFilter(value);
  };

  const onFilterChange = (status) => {
    let newSelectedStatus = [...selectedStatus];
    if (newSelectedStatus.includes(status)) {
      newSelectedStatus = newSelectedStatus.filter((s) => s !== status);
    } else {
      newSelectedStatus.push(status);
    }

    setSelectedStatus(newSelectedStatus);

    const payload = {
      page: 1,
      limit: 10,
      name: isFilter,
      status:
        newSelectedStatus.length > 0 ? newSelectedStatus.join(",") : undefined,
    };

    if (campaign_type === "time_specified") {
      getBroadcastCampaignList(currentBrand?.brand_id, payload);
    }
    if (campaign_type === "all") {
      getCampaignList(currentBrand?.brand_id, payload);
    }
  };

  const showSkeleton = isLoading && tableList?.length === 0 && continueLoading;

  const selectedRows = tableList?.filter((l) => l?.selected === true);

  const onSelectChange = (value) => {
    const res = tableList?.map((l) => {
      return { ...l, selected: value };
    });
    setTableList(res);
  };

  return (
    <>
      {selectedRows?.length > 0 ? (
        <SelectedCampaignCount
          count={selectedRows?.length}
          onSelectChange={onSelectChange}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          handleBulkDelete={handleBulkDelete}
          value={value}
        />
      ) : (
        <ListViewHeader
          isFilter={isFilter}
          setIsFilter={setIsFilter}
          selectedStatus={selectedStatus}
          // searchingText={(text) => templateSearch(text)}
          templateSearch={templateSearch}
          onFilterChange={onFilterChange}
          tableList={tableList}
          onChange={(value) => setIsFilter(value)}
          setTableList={setTableList}
        />
      )}
      {isModalVisible && <div className="modal-overlay"></div>}

      <div className="campaign-list">
        <Skeleton
          active
          style={{ width: "79vw", padding: "20px" }}
          loading={showSkeleton}
          showSkeleton={showSkeleton}
          paragraph={{ rows: 8 }}
          className=".ant-skeleton-paragraph >li +li"
        >
          <Table
            tableRowClicked={tableRowClicked}
            tableList={tableList}
            setTableList={setTableList}
            isLoading={isLoading}
            selectedStatus={selectedStatus}
            handleDelete={handleDelete}
            isFilter={isFilter}
            statusExists={statusExists}
            showSkeleton={showSkeleton}
            value={value}
            handleScroll={handleScroll}
            selectedRows={selectedRows}
            onSelectChange={onSelectChange}
            setTableList1={setTableList1}
            tableList1={tableList1}
          />
        </Skeleton>
      </div>
    </>
  );
}

export default CampaignList;
