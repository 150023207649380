export const INITIAL_STATE = {
  user_id: null,
  acc_id: null,
  acc_name: '',
  acc_image_url: null,
  acc_status: null,
  acc_settings: {},
  acc_full_description: null,
  acc_created_at: null,
  acc_updated_at: null,
  acc_type: null,
  role_name: null,
  account_metrics: {
    total_users: null,
    total_brands: null,
    active_brands: null,
    inactive_brands: null,
    pendinng_invoices: null,
  },
  invite_people: [
    {
      id: 1,
      type: '',
      value: '',
    },
  ],
  invited_members: [],
};

const new_people = {
  type: '',
  value: '',
};

export const accountDetailsReducer = (state, action) => {
  const { type, id, key, value, data } = action;

  switch (type) {
    case 'update':
      return { ...state, [key]: value };
    case 'add_people':
      return {
        ...state,
        invite_people: [
          ...state.invite_people,
          {
            id: state.invite_people.length + 1,
            ...new_people,
          },
        ],
      };

    case 'update_people':
      return {
        ...state,
        invite_people: state.invite_people.map((p) => {
          if (p.id === id) return { ...p, [key]: value };
          return p;
        }),
      };

    case 'remove_people':
      return {
        ...state,
        invite_people: state.invite_people.filter((p) => p.id !== id),
      };

    case 'update_data':
      return {
        user_id: data.user_id,
        acc_id: data.account_list?.[0]?.acc_id,
        acc_name: data.account_list?.[0]?.acc_name,
        acc_image_url: data.account_list?.[0]?.acc_image_url,
        acc_status: data.account_list?.[0]?.acc_status,
        acc_settings: data.account_list?.[0]?.acc_settings,
        acc_full_description: data.account_list?.[0]?.acc_full_description,
        acc_created_at: data.account_list?.[0]?.acc_created_at,
        acc_updated_at: data.account_list?.[0]?.acc_updated_at,
        acc_type: data.account_list?.[0]?.acc_type,
        role_name: data.account_list?.[0]?.role_name,
        account_metrics: data.account_list?.[0]?.account_metrics,
        invited_members: data.account_list?.[0]?.invited_members,
      };

    case 'reset':
      return INITIAL_STATE;

    default:
      break;
  }
};
