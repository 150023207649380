import React, { useEffect, useRef, useState } from 'react';

// assets
import Edit from '../../../assets/customSVG/Edit';
import Close from '../../../assets/customSVG/Close';
import Tick from '../../../assets/customSVG/Tick';

// helper
import { textOnly } from '../../../helper/validation';

// hooks
import useToggle from 'hooks/useToggle';

export default function InputContainer({
  name,
  label,
  editable = true,
  value = '',
  icon = null,
  count = null,
  iconStyle = '',
  onInputChange,
  validation,
}) {
  const [isEditOpen, setIsEditOpen] = useToggle(false);
  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useToggle(false);

  const inputRef = useRef();

  const editRef = useRef();

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    if (editable) {
      document.addEventListener('mousedown', handleClickOutside);

      return () =>
        document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [value, editable]);

  const onChange = (value) => {
    setInputValue(value);
    inputRef?.current?.focus();
    if (inputValue?.length > 0) onInputChange(value);
  };

  const handleClickOutside = (e) => {
    if (inputValue?.length > 0) {
      if (editRef.current && !editRef.current.contains(e.target)) {
        const isError = checkInputError(value);
        if (!isError) {
          setIsEditOpen(false);
          setInputValue(value);
          onInputChange(value);
        } else {
          inputRef?.current?.focus();
        }
      }
    } else {
      inputRef?.current?.focus();
    }
  };

  const checkInputError = (value) => {
    if (value?.length === 0) setInputError(true);
    else setInputError(false);

    return value?.length === 0;
  };

  return (
    <div
      className={`w-full h-auto flex flex-col justify-start tracking-[0.2px] ${
        editable ? 'group/card' : ''
      }`}
    >
      <label className='text-base font-semibold text-[var(--contentText)]'>
        {label}
      </label>
      <div
        className={`w-full h-full flex items-center justify-between gap-1.5 text-base font-semibold text-[var(--font-600)] mt-2 p-1 pl-0  rounded-lg ${
          inputError
            ? 'border border-[var(--fontRed)]'
            : editable
            ? 'cursor-pointer border border-[var(--border-50)] group-hover/card:border-[var(--border-100)]'
            : 'cursor-not-allowed'
        }`}
        ref={editRef}
        onClick={() => {
          if (editable) {
            setIsEditOpen(true);
            setInputValue(value);
            inputRef?.current?.focus();
          }
        }}
        data-testid='inputClick'
      >
        {typeof icon === 'string' ? (
          <img src={icon} alt='icon' className='size-5 rounded' />
        ) : (
          icon
        )}
        <div className={`w-full h-8`}>
          {isEditOpen ? (
            <input
              type='text'
              name={name}
              value={inputValue}
              ref={inputRef}
              className={`w-full h-full pl-[0.5vw] text-[var(--contentText)]`}
              onChange={async (e) => {
                if (validation === 'text') {
                  const res = await textOnly(e.target.value);
                  onChange(res);
                  if (res?.length === 0) setInputError(true);
                  else setInputError(false);
                } else {
                  onChange(e.target.value);
                  if (e.target.value?.length === 0) setInputError(true);
                  else setInputError(false);
                }
                inputRef?.current?.focus();
              }}
              maxLength={count}
              autoFocus={isEditOpen}
            />
          ) : (
            <div
              className={`w-full h-full flex items-center truncate text-ellipsis py-1 px-2.5 ${
                !editable ? 'border border-[#e8e8ea] rounded-lg' : ''
              }`}
            >
              {inputValue}
            </div>
          )}
        </div>
        {editable && isEditOpen && (
          <div className='w-8 h-full'>
            <div
              className='w-[1.5vw] h-[1.45vw] flex items-center justify-center rounded-[0.37vw] hover:bg-slate-100'
              onClick={() => {
                setInputValue('');
                checkInputError('');
                onInputChange('');
              }}
            >
              <Close
                stroke='#616874'
                circle={false}
                width='1.5vw'
                height='1.5vw'
              />
            </div>
          </div>
        )}
      </div>
      {/* <div
        className={`w-full h-full flex items-center justify-between gap-1.5 text-base font-semibold text-[var(--font-600)] mt-2 p-1 pl-0 border group-hover/card:border-[var(--border-50)] rounded-lg ${
          isEditOpen ? 'border-[var(--border-50)]' : 'border-transparent'
        }`}
        ref={editRef}
      >
        {typeof icon === 'string' ? (
          <img src={icon} alt='icon' className='size-5 rounded' />
        ) : (
          icon
        )}
        <div className={`w-full`}>
          {isEditOpen ? (
            <input
              type='text'
              name={name}
              value={inputValue ?? ''}
              className={`w-full h-full pl-[0.5vw] text-[var(--contentText)]`}
              onChange={(e) => {
                onChange(e.target.value);
              }}
              maxLength={count}
              autoFocus={isEditOpen}
            />
          ) : (
            <div
              className={`h-full flex items-center truncate text-ellipsis py-1 px-2.5 ${
                !editable ? 'border border-[#e8e8ea] rounded-lg' : ''
              }`}
            >
              {inputValue}
            </div>
          )}
        </div>
        {editable && isEditOpen && (
          <div className='w-[3.3vw] h-[2vw] flex items-center justify-between gap-[0.3vw]'>
            <button
              className='w-[1.5vw] h-[1.45vw] flex items-center justify-center rounded-[0.37vw] hover:bg-slate-100'
              onClick={() => {
                setIsEditOpen(false);
                setInputValue(value);
              }}
            >
              <Close
                stroke='#616874'
                circle={false}
                width='1.5vw'
                height='1.5vw'
              />
            </button>
            <button
              className='w-[1.5vw] h-[1.45vw] flex items-center justify-center rounded-[0.37vw] hover:bg-slate-100'
              onClick={() => {
                setIsEditOpen(false);
                if (onInputChange) onInputChange(key, inputValue);
              }}
            >
              <Tick width='0.8vw' height='0.8vw' strokeWidth='3.2' />
            </button>
          </div>
        )}

        {editable && !isEditOpen && (
          <button
            className='w-[1.5vw] h-[1.45vw] cursor-pointer hover:bg-slate-100 rounded-[0.37vw] flex items-center justify-center opacity-0 group-hover/card:opacity-100'
            onClick={() => {
              setIsEditOpen(true);
              setInputValue(inputValue);
            }}
          >
            <Edit
              width='0.85vw'
              height='0.85vw'
              color={''}
              strokeWidth={'1.9'}
              className='stroke-[#898E99] group-hover/eye:stroke-[var(--contentText)]'
            />
          </button>
        )}
      </div> */}
    </div>
  );
}
