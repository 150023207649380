import React from "react";

// assets
import Avatar from '../../../../assets/customSVG/Avatar';
import { ICONS } from '../../../../assets/icons';

// constants
import {
  FEATURE_FLAG_EDIT_CONTACT_ADDRESS_DETAILS,
  FEATURE_FLAG_EDIT_CONTACT_DETAILS,
  FEATURE_FLAG_VIEW_CONTACT_DETAILS,
  FEATURE_FLAG_VIEW_TAGS_IN_CONTACT_DETAILS,
} from '../../constants/FeatureFlag';

// components
import ProfileInfoCard from './ProfileInfoCard';
import AddTags from '../../../../components/commonComponents/tags/AddTags';

export default function Profile({ selectedPerson }) {
  const name =
    selectedPerson?.first_name && selectedPerson?.last_name
      ? `${selectedPerson?.first_name} ${selectedPerson?.last_name}`
      : selectedPerson?.first_name
      ? selectedPerson?.first_name
      : selectedPerson?.last_name
      ? selectedPerson?.last_name
      : selectedPerson?.profile_name && selectedPerson?.profile_name;

  const profileName = selectedPerson?.profile_name?.split(" ");

  const firstName = selectedPerson?.first_name ?? profileName?.[0] ?? "--";
  const lastName = selectedPerson?.last_name ?? profileName?.[1] ?? "--";

  return (
    <div className="w-full text-[var(--contentText)]">
      <div className="flex items-center gap-[0.8vw] p-[1vw] border-b-[0.08vw] border-[var(--border-50)]">
        <div className="size-[2.5vw] flex items-center justify-center rounded-full bg-gray-200">
          <Avatar color="#ffffff" />
        </div>
        <div className="">
          <p className="font-normal weight-semibold leading-[1vw]">{name}</p>
          {/* <p className='font-xs weight-semibold text-[var(--font-600)]'>
            Added on --
          </p> */}
        </div>
      </div>
      <div className="w-full h-[75vh] py-[1vw]  overflow-y-scroll listScroll">
        <div className="px-[1.5vw]">
          <h3 className="font-md weight-bold mb-[0.7vw]">Contact Property</h3>
          <ProfileInfoCard
            label={"First Name"}
            value={firstName}
            count={50}
            editable={FEATURE_FLAG_EDIT_CONTACT_DETAILS}
          />
          <ProfileInfoCard
            label={"Last Name"}
            value={lastName}
            count={50}
            editable={FEATURE_FLAG_EDIT_CONTACT_DETAILS}
          />
          <ProfileInfoCard
            label={"Phone Number"}
            value={selectedPerson?.to_number ?? "--"}
            icon={ICONS?.PhoneWithBg}
            editable={FEATURE_FLAG_EDIT_CONTACT_DETAILS}
          />
          {FEATURE_FLAG_VIEW_CONTACT_DETAILS && (
            <>
              {' '}
              <ProfileInfoCard
                label={'Email'}
                value={selectedPerson?.email ?? '--'}
                icon={ICONS?.Email}
                editable={FEATURE_FLAG_EDIT_CONTACT_DETAILS}
              />
              <ProfileInfoCard
                label={'Alternative mobile'}
                value={'--'}
                icon={ICONS?.Phone}
                editable={FEATURE_FLAG_EDIT_CONTACT_DETAILS}
              />
              <ProfileInfoCard
                label={'Work Email'}
                value={selectedPerson?.email ?? '--'}
                icon={ICONS?.Email}
                editable={FEATURE_FLAG_EDIT_CONTACT_DETAILS}
              />
              <ProfileInfoCard
                label={'Contact Source'}
                value={selectedPerson?.email ?? '--'}
                editable={FEATURE_FLAG_EDIT_CONTACT_DETAILS}
              />
              <ProfileInfoCard
                label={'Age'}
                value={selectedPerson?.email ?? '--'}
                editable={FEATURE_FLAG_EDIT_CONTACT_DETAILS}
              />
              <ProfileInfoCard
                label={'Gender'}
                value={selectedPerson?.email ?? '--'}
                editable={FEATURE_FLAG_EDIT_CONTACT_DETAILS}
              />
              <ProfileInfoCard
                label={'Profession'}
                value={selectedPerson?.email ?? '--'}
                editable={FEATURE_FLAG_EDIT_CONTACT_DETAILS}
              />
            </>
          )}
        </div>
        {FEATURE_FLAG_VIEW_TAGS_IN_CONTACT_DETAILS && (
          <div className='p-[1.5vw] border-t-[0.08vw] border-[var(--border-50)]'>
            <h3 className='font-md weight-bold mb-[0.7vw] mt-[0.5vw]'>Tags</h3>
            <div></div>
            <AddTags />
          </div>
        )}
        {FEATURE_FLAG_EDIT_CONTACT_ADDRESS_DETAILS && (
          <div className='p-[1.5vw] pb-0 border-t-[0.08vw] border-[var(--border-50)]'>
            <h3 className='font-md weight-bold mb-[0.7vw] mt-[0.5vw]'>
              Address Info
            </h3>
            <ProfileInfoCard
              label={'Street'}
              value={'--'}
              editable={FEATURE_FLAG_EDIT_CONTACT_DETAILS}
            />
            <ProfileInfoCard
              label={'City'}
              value={'--'}
              editable={FEATURE_FLAG_EDIT_CONTACT_DETAILS}
            />
            <ProfileInfoCard
              label={'State'}
              value={'--'}
              editable={FEATURE_FLAG_EDIT_CONTACT_DETAILS}
            />
            <ProfileInfoCard
              label={'Zip/Postal code'}
              value={'--'}
              editable={FEATURE_FLAG_EDIT_CONTACT_DETAILS}
            />
            <ProfileInfoCard
              label={'country'}
              value={'--'}
              editable={FEATURE_FLAG_EDIT_CONTACT_DETAILS}
            />
          </div>
        )}
      </div>
    </div>
  );
}
