import React from 'react';

// helper
import { hexToRgba } from '../../../helper/colors';

import Close from '../../../assets/customSVG/Close';
import {
  FEATURE_FLAG_ADD_TAGS_TO_CONTACT_DETAILS,
  FEATURE_FLAG_DELETE_TAGS_IN_CONTACT_DETAILS,
} from '../../../modules/inbox/constants/FeatureFlag';

export default function Tag({ id, name, color, addTag, deleteTag }) {
  const rgbaColor = hexToRgba(color, 0.2);

  return (
    <div
      className='w-fit py-0.5 rounded-full text-xs font-semibold flex items-center'
      style={{
        color: color,
        background: rgbaColor,
      }}
    >
      <div
        className='flex flex-1 pl-2.5'
        onClick={() => {
          if (FEATURE_FLAG_ADD_TAGS_TO_CONTACT_DETAILS)
            if (addTag) addTag(name, id);
        }}
      >
        {name}
      </div>
      <div
        onClick={() => {
          if (FEATURE_FLAG_DELETE_TAGS_IN_CONTACT_DETAILS)
            if (deleteTag) deleteTag(id);
        }}
        className={`${
          FEATURE_FLAG_DELETE_TAGS_IN_CONTACT_DETAILS
            ? 'cursor-pointer'
            : 'cursor-not-allowed'
        }`}
      >
        <Close circle={false} width={20} height={20} stroke={color} />
      </div>
    </div>
  );
}
