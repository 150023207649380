import React, { useEffect, useReducer, useRef, useState } from 'react';

// assets
import Settings from '../../../../../assets/customSVG/Settings';

// utils
import { Modal, Skeleton } from 'antd';
import { useOutletContext } from 'react-router-dom';

// constants
import { countryList } from '../../../../../constant/app/countryList';
import { TemplateLeftSideList } from '../../../constant/phoneNumbers';

// hooks
import useToggle from 'hooks/useToggle';
import {
  profileReducer,
  INITIAL_STATE,
} from '../../../reducers/profileReducer';

// API
import { whatsappBusinessNumbers } from '../../../api/Api';

// helper
import { cn } from 'helper/cn';

// redux
import { useAspSelector } from '../../../../../test/jest-redux-hooks';

// components
import NumberStatus from './components/NumberStatus';
import Header from '../../../../../components/commonComponents/modals/Header';

const daily_messaging_limit = {
  TIER_50: 50,
  TIER_100: 100,
  TIER_250: 250,
  TIER_1K: 1000,
  TIER_10K: 10000,
  TIER_100K: 100000,
  TIER_UNLIMITED: 100000000,
};

const quality_rating = {
  UNKNOWN: 'High',
  GREEN: 'High',
  RED: 'Low',
  YELLOW: 'Medium',
};

const quality_rating_styles = {
  High: 'var(--primary)',
  Medium: 'var(--secondary)',
  Low: '#f96056',
  Unknown: 'var(--primary)',
};

const PhoneNumbers = () => {
  const { currentBrand } = useAspSelector((state) => state.app);
  const [numberDetails, setNumberDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [syncing, setSyncing] = useOutletContext();
  const [openEditprofile, setOpenEditprofile] = useToggle(false);

  const descriptionRef = useRef();

  const [profileDetails, profileDispatch] = useReducer(
    profileReducer,
    INITIAL_STATE
  );

  const { name, category, description, address, email, website } =
    profileDetails;

  useEffect(() => {
    if (!syncing) fetchWhatsappBusinessNumbers(currentBrand?.brand_id);
  }, [currentBrand, syncing]);

  const fetchWhatsappBusinessNumbers = (brandId) => {
    if (brandId) {
      setIsLoading(true);

      whatsappBusinessNumbers(brandId)
        .then((res) => {
          if (res && res.data?.wapp_business_num?.length > 0) {
            setNumberDetails(res?.data?.wapp_business_num);
          } else {
            setNumberDetails([]);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setNumberDetails([]);
    }
  };

  const getCountry = (code) => {
    return countryList?.filter((c) => c.value === code)?.[0];
  };

  const closeEditProfile = () => {
    setOpenEditprofile(false);
  };

  // styles

  const title = 'text-sm weight-semibold text-[var(--contentText)]';
  const subTitle = 'text-sm weight-medium text-[#898E99]';
  const card = 'flex flex-col gap-2.5 mt-6';
  const inputWrapper =
    'w-full pl-2.5 pr-1 py-2 flex items-center gap-2.5 text-sm weight-small border border-[#E8E8EA] rounded-md';
  const input = 'w-full text-sm weight-small';

  return (
    <div className='w-full h-full flex-column'>
      <div className='TemplateList-container'>
        <div className=' TemplateList-wrapper p-[0.6vw]'>
          <Skeleton
            active
            loading={isLoading}
            style={{ padding: '10px 20px' }}
            paragraph={{ rows: 10 }}
          >
            <div className='w-full flex items-center justify-between px-[1vw] pt-[1vw] pb-[0.5vw] font-md weight-medium text-[var(--textBlack)] text-center'>
              <div className='TemplateList w-[35vw] flex-row !text-left'>
                Phone Number
              </div>
              <div className='w-[70vw] flex items-center justify-between'>
                {TemplateLeftSideList?.map((item) => (
                  <div
                    key={item?.id}
                    className='w-[12vw] flex items-center justify-center'
                  >
                    {item?.name}
                  </div>
                ))}
              </div>
            </div>

            <div className='Brand-Invite '>
              {numberDetails?.map((item) => {
                getCountry(item?.country_code);

                return (
                  <div
                    className='w-full my-[0.6vw] py-[0.2vw] rounded-[0.5vw] bg-[var(--background)] flex items-center justify-between font-md weight-medium text-[var(--channelToggleSwitch)]'
                    key={item?.id}
                  >
                    <div className='max-w-[35vw] w-full py-[0.6vw] pl-[1vw] flex items-center gap-2.5'>
                      <div>
                        {item?.country_code} {item?.send_from_phone_number}
                      </div>
                      {/* <div
                        className='size-6 flex items-center justify-center rounded-md hover:bg-[var(--BG-50)] cursor-pointer group'
                        onClick={() => {
                          setOpenEditprofile(true);
                        }}
                      >
                        <Settings
                          width={14}
                          height={14}
                          strokeWidth='2'
                          color={''}
                          className={
                            'group-hover:stroke-[var(--contentText)] stroke-[#898E99]'
                          }
                        />
                      </div> */}
                    </div>
                    <div className='w-[70vw] flex items-center justify-between'>
                      <p className='w-[12.5vw] flex items-center justify-center'>
                        {item?.send_from_phone_name}
                      </p>

                      <p className='w-[11vw] flex items-center justify-center'>
                        <NumberStatus id={item?.id} status={item.status} />
                      </p>

                      <p
                        className={`w-[13vw] flex items-center justify-center gap-[0.4vw] ${
                          quality_rating[item?.quality_rating]
                        }`}
                      >
                        <span
                          className={`size-[0.5vw] rounded-[0.15vw] contents-[""]`}
                          style={{
                            backgroundColor:
                              quality_rating_styles[
                                quality_rating[item?.quality_rating]
                              ],
                          }}
                        ></span>
                        <span>{quality_rating[item?.quality_rating]}</span>
                      </p>
                      <p className='TemplateList-quality w-[11vw] flex items-center justify-center'>
                        {item?.daily_messaging_limit !== 'TIER_UNLIMITED'
                          ? `${
                              daily_messaging_limit[item?.daily_messaging_limit]
                            }/day`
                          : 'Unlimited'}
                      </p>
                      <p className='w-[13.5vw] flex items-center justify-center capitalize'>
                        {getCountry(item?.country_code)?.label}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </Skeleton>
        </div>
      </div>
      <Modal
        footer={false}
        open={openEditprofile}
        afterOpenChange={(open) => {
          setOpenEditprofile(open);
        }}
        closeIcon={false}
        centered
        width={'780px'}
        maskClosable
        mask={true}
        wrapClassName={'bg-[#00000095]'}
        style={{
          width: '100%',
          borderRadius: 20,
          boxShadow: '0px 10px 24px 0px rgba(79, 92, 128, 0.15)',
        }}
      >
        <div>
          <Header
            title='Profile Information'
            subTitle='This will be visible on your business profile'
            handleCancel={closeEditProfile}
            titleStyle='text-base'
            subTitleStyle='text-sm text-[var(--font-400)]'
          />
        </div>
        <div className='px-5 pt-4 max-h-[600px] overflow-y-scroll listScroll'>
          <div className='w-full flex gap-5 py-2.5'>
            <div className='w-[170px] h-[150px] border border-red-500 rounded-md'>
              img
            </div>
            <div className='w-[calc(100%-190px)] flex flex-col gap-2.5'>
              <div className='flex flex-col gap-2.5'>
                <h3 className={`${title}`}>Display Name</h3>
                <p className='w-full h-9 weight-semibold text-sm text-[var(--font-600)] bg-[var(--BG-50)] rounded-md px-2.5 flex items-center'>
                  {name}
                </p>
              </div>
              <div className='flex flex-col gap-2.5'>
                <h3 className={`${title}`}>Category</h3>
                <div>Food</div>
              </div>
            </div>
          </div>

          <div className='flex flex-col gap-2.5 my-4'>
            <h3 className={`${title}`}>Description</h3>
            <div className='w-full h-32 border border-[#E8E8EA] rounded-md p-1.5 pr-0.5 text-xs weight-medium'>
              <textarea
                placeholder='Enter description'
                value={description}
                // ref={descriptionRef}
                onChange={(e) => {
                  const { value } = e.target;
                  profileDispatch({
                    type: 'update',
                    key: 'description',
                    value: value,
                  });
                }}
                className={`w-full h-[100px] resize-none outline-none border-none listScroll pr-1`}
              ></textarea>
              <div className='w-full h-3.5 flex justify-end items-center pr-1 text-xs'>
                0/512
              </div>
            </div>
          </div>

          <div className='mt-6'>
            <h3 className={`${title}`}>Contact Information</h3>
            <p className={`${subTitle} mt-1`}>
              List your contact info to connect with your business
            </p>
          </div>

          <div className={`${card}`}>
            <label className={`${title}`}>Address</label>
            <div className={cn(inputWrapper)}>
              <input
                name='address'
                type='text'
                placeholder='Enter business address'
                className={cn(input)}
                onChange={(e) => {
                  const { name, value } = e.target;
                  profileDispatch({
                    type: 'update',
                    key: name,
                    value: value,
                  });
                }}
              />
              <p className='text-xs'>0/256</p>
            </div>
          </div>
          <div className={`${card}`}>
            <label className={`${title}`}>Email</label>
            <div className={cn(inputWrapper)}>
              <input
                name='email'
                type='text'
                placeholder='Enter business email'
                className={cn(input)}
                onChange={(e) => {
                  const { name, value } = e.target;
                  profileDispatch({
                    type: 'update',
                    key: name,
                    value: value,
                  });
                }}
              />
              <p className='text-xs'>0/128</p>
            </div>
          </div>

          <div className={cn(card)}>
            <h3 className={`${title}`}>Website</h3>
            {website?.map((w) => {
              return (
                <div className={cn(inputWrapper)}>
                  <input
                    type='text'
                    value={w?.value}
                    placeholder='Ex:- https://yourwebsite.com'
                    className={cn(input)}
                    onChange={(e) => {
                      const { name, value } = e.target;
                      profileDispatch({
                        type: 'updateWebsite',
                        id: w.id,
                        value: value,
                      });
                    }}
                  />
                  <p className='text-xs'>0/256</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className='flex justify-end px-5 pt-4 pb-2'>
          <button className='px-4 py-1.5 bg-[var(--primary)] text-white text-sm rounded-md'>
            Submit
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default PhoneNumbers;
