import React, { useCallback, useEffect, useRef, useState } from 'react';

// api
import { singleAccountupdate } from '../api/Api';

// assets
import AddImageSvg from '../../../assets/customSVG/AddImageSvg';
import Delete from '../../../assets/customSVG/Delete';
import Edit from '../../../assets/customSVG/Edit';

// helper
import { useFileUploader } from 'hooks/useFileUploader';
import { cn } from '../../../helper/cn';

// redux
import { updateToggleToast } from '../../../reduxToolkit/appSlice';
import { useAspDispatch, useAspSelector } from '../../../test/jest-redux-hooks';

// components
import Loader from '../../../components/commonComponents/Loader/Index';
import InputContainer from '../../../components/commonComponents/inputContainer';
import Select from '../../../components/commonComponents/select';
import { accountType } from '../../auth/components/onboarding/AccountCreation';

const editable = true;

const category_type = {
  Business: 'Business Entity',
  Agency: 'Marketing Agency',
  Creator: 'Influencer',
};

export default function ProfileDetails({
  account_id,
  name,
  image,
  type,
  onChange,
}) {
  const { currentBrand, toggleToast } = useAspSelector((state) => state.app);
  const dispatch = useAspDispatch();

  const { uploading, selectedFile, setSelectedFile, uploadFiles, error } =
    useFileUploader();
  const inputRef = useRef();

  const [datas, setDatas] = useState({
    name: '',
    image: '',
    type: '',
  });

  const [errors, setErrors] = useState({
    name: false,
    image: false,
    type: false,
  });

  useEffect(() => {
    setDatas({ ...datas, name: name, image: image, type: type });
  }, [name, image, type]);

  const onInputChange = (key, value) => {
    setDatas({ ...datas, [key]: value });
  };

  const onChangeErrors = (key, value) => setErrors({ ...errors, [key]: value });

  const validate = useCallback(() => {
    return true;
  }, [name, image]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let payload = {
      acc_name: datas?.name,
      acc_img_url: datas?.image,
      acc_type: datas?.type,
    };

    try {
      const res = await singleAccountupdate({ acc_id: account_id }, payload);

      if (res.status === 200) {
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: 'Account details updated successfully',
              status: 'Success',
              duration: '',
            },
          ])
        );
      } else {
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: 'Account details updated failed!',
              status: 'Error',
              duration: '',
            },
          ])
        );
      }
    } catch (error) {
      console.warn(error);
    }
  };

  // styles
  const card = 'w-full flex flex-col gap-3 mt-4';
  const label = 'text-base weight-semibold leading-6 text-[#2D3036]';

  return (
    <div className='w-full h-full max-h-60 px-3.5 py-2.5 border border-[#F0F1F2] rounded-2xl flex gap-7 shadow-[0px_1.5px_2px_0px_#1018281A]'>
      <div className='w-2/6 h-full max-h-52 rounded-2xl flex items-center justify-center'>
        {image ? (
          uploading ? (
            <Loader Width={30} Height={30} />
          ) : (
            <div className='w-full h-full relative group'>
              <img
                src={datas?.image}
                alt={`${name} images`}
                className='w-full h-full object-containe rounded-xl border border-[var(--BG-100)] relative'
              />
              <div className='w-full h-full bg-[#00000090] hidden items-center justify-center gap-2.5 absolute top-0 left-0 rounded-xl group-hover:!flex'>
                <button
                  className={`size-8 group/delete bg-transparent flex items-center justify-center rounded-lg cursor-pointer hover:bg-red-100 transition-all ease-linear duration-100`}
                  onClick={() => {
                    onChange('update', null, 'acc_image_url', '');
                  }}
                >
                  <Delete
                    width={20}
                    height={20}
                    className={`stroke-[var(--fontRed)] group-hover/delete:stroke-[var(--fontRed)]`}
                    color=''
                  />
                </button>
                <button
                  className={`size-8 border bg-transparent border-transparent flex items-center justify-center rounded-lg group/eye hover:!bg-green-100`}
                  onClick={() => {
                    inputRef?.current?.click();
                  }}
                >
                  <Edit
                    width={16}
                    height={16}
                    color={''}
                    strokeWidth={'1.7'}
                    className='stroke-[var(--primary)] group-hover/eye:stroke-[var(--primary)]'
                  />
                </button>
              </div>
            </div>
          )
        ) : (
          <div className='w-full h-full'>
            <button className='w-full h-full max-h-52 font-xs weight-bold px-[0.6vw] py-[0.4vw] rounded-[0.37vw] mt-[0.4vw] cursor-pointer border border-[#F0F1F2]'>
              <div className='w-full h-full flex items-center justify-center cursor-pointer'>
                <div className='w-full h-full flex-center cursor-pointer'>
                  {uploading ? (
                    <Loader Width={30} Height={30} />
                  ) : (
                    <div
                      className={`w-full h-full flex items-center justify-center`}
                      onClick={() => {
                        inputRef?.current?.click();
                      }}
                    >
                      <AddImageSvg width={35} height={34} />
                    </div>
                  )}
                </div>
              </div>
            </button>
          </div>
        )}
      </div>
      <form
        className='w-4/6 flex flex-col justify-between'
        onSubmit={handleSubmit}
      >
        <div>
          <InputContainer
            label={'Account Name'}
            name={'account_name'}
            value={datas?.name}
            editable={editable}
            validation={'text'}
            onInputChange={(value) => {
              onInputChange('name', value);
            }}
            onError={onChangeErrors}
          />
          <div className={cn(card)}>
            <label htmlFor='type' className={cn(label)}>
              Account Type
            </label>
            <Select
              value={category_type[datas.type]}
              options={accountType}
              onChange={({ key }) => {
                onInputChange('type', key);
              }}
              placeholder={'Select type'}
              rootClassName='w-full h-12 px-3 py-2.5'
              position='bottom'
              dataTestId='account-type'
              optionClick='option-click'
            />
          </div>
        </div>
        <div className='flex justify-end px-5'>
          <button
            type='submit'
            className={`px-4 py-1.5 text-sm font-semibold rounded-md ${
              editable
                ? 'bg-[var(--primary)] text-white cursor-pointer'
                : 'bg-[var(--BG-50)] text-[var(--font-600)] cursor-not-allowed'
            }`}
          >
            Save
          </button>
        </div>
      </form>
      <input
        type='file'
        accept={'.jpg, .png'}
        ref={inputRef}
        onChange={async (e) => {
          const res = await uploadFiles(e, 'IMAGE');
          onChange('update', null, 'acc_image_url', res);
        }}
        id='uploadImg'
        hidden
      />
    </div>
  );
}
