import { useState } from "react";

import { preSendUrl } from "api/Api";

import { useSelect } from "./useSelect";
import useToggle from "./useToggle";

const uploadFile = async (file, data) => {
  let type = file?.name?.split(".").pop();

  const apiResponse = await preSendUrl(type, data).then((res) => {
    if (res?.status === 200) {
      let fileType = "";
      switch (type) {
        case "mp4":
          fileType = "video/mp4";
          break;
        case "png":
          fileType = "image/png";
          break;
        case "jpg":
          fileType = "image/jpeg";
          break;
        case "jpeg":
          fileType = "image/jpeg";
          break;
        case "pdf":
          fileType = "application/pdf";
          break;
        case "xlsx":
          fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          break;
        default:
          fileType = null;
      }
      return res?.data?.response && fileType && file
        ? getUrl(res?.data?.response, fileType, file, data)
        : undefined;
    }
  });
  return apiResponse;
};

const getUrl = async (url, fileType, file, data) => {
  let responseData = "";

  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        contentType: fileType,
      },
      body: file,
    });
    responseData =
      response?.ok && response?.status === 200
        ? response?.url?.split("?").shift()
        : undefined;
  } catch (error) {
    console.error("Error during file upload:", error?.message);
    responseData = undefined;
  }

  return responseData;
};

const defaultFileSize = {
  IMAGE: 5,
  VIDEO: 16,
  DOCUMENT: 100,
};

export function useFileUploader() {
  const [uploading, setUploading] = useToggle(false);
  const [error, setError] = useToggle(false);
  const [selectedFileFormat, setSelectedFileFormat] = useSelect(null);

  const [selectedFile, setSelectedFile] = useState(null);
  const [responseFile, setResponseFile] = useState(null);

  const uploadFiles = async (e, format, size = null, data) => {
    const maxFileSize = size ?? defaultFileSize[format];

    if (e.target.files[0]) {
      setError(false);
      setSelectedFile(null);
      let convertedFile = "";
      if (e.target.files[0]?.name.includes(".jpeg")) {
        const name = `${e.target.files[0]?.name.split(".")[0]}.jpg`;
        convertedFile = new File(e.target.files, name, {
          type: "image/jpeg",
        });
      } else {
        convertedFile = e.target.files[0];
      }
      setUploading(true);
      const responseFile = await uploadFile(convertedFile, data);
      if (responseFile) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setResponseFile(responseFile);
          setUploading(false);
          setSelectedFile(convertedFile);
          setSelectedFileFormat(format);
        };
        reader.readAsDataURL(convertedFile);
        if (format === "IMAGE")
          convertedFile.size >= maxFileSize * 1024 * 1024
            ? setError(true)
            : setError(false);

        if (format === "VIDEO")
          convertedFile.size >= maxFileSize * 1024 * 1024
            ? setError(true)
            : setError(false);

        if (format === "DOCUMENT")
          convertedFile.size >= maxFileSize * 1024 * 1024
            ? setError(true)
            : setError(false);

        return responseFile;
      }

      return null;
    } else {
      setUploading(false);
      return null;
    }
  };

  const clearFiles = () => {
    setSelectedFile(null);
    setResponseFile(null);
    setError(false);
    setUploading(false);
    setSelectedFileFormat(null);
  };

  return {
    uploading,
    setUploading,
    selectedFile,
    selectedFileFormat,
    setSelectedFile,
    responseFile,
    uploadFiles,
    error,
    setError,
    clearFiles,
    setSelectedFileFormat,
    setResponseFile,
  };
}
