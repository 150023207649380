import React, { useEffect, useRef, useState } from "react";

// assets
import Edit from "../../../../assets/customSVG/Edit";
import Close from "../../../../assets/customSVG/Close";
import Tick from "../../../../assets/customSVG/Tick";

// helpers
import { cn } from "helper/cn";
import { numberOnly, textOnly } from "../../../../helper/validation";

// hooks
import useToggle from "../../../../hooks/useToggle";
import { Modal, Tooltip } from "antd";
import AlertModal from "../../../../components/commonComponents/modal/alert/AlertModal";

export default function ProfileInfoCard({
  label,
  editable = true,
  value,
  icon = null,
  count = null,
  iconStyle = "",
  onInputChange,
  validation = null,
  rootClassName = "",
  inputContainerClassName = "",
  inputClassName = "",
  labelClassName = "",
  iconClassName,
  inputDisabledClassName = "",
  editIconsClassName,
  cancelIconClassName,
  successIconsClassName,
  onSave,
  required,
  link,
  showAlert,
  handleCancel,
  handleYes,
  handleNo,
  inputValueClass,
  style,
  tooltipKey,
}) {
  const [isEditOpen, setIsEditOpen] = useToggle(false);
  const [inputValue, setInputValue] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const editRef = useRef();

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    if (isEditOpen) {
      document.addEventListener("mousedown", handleClickOutside);

      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [value]);

  const onChange = (value) => {
    if (validation === "textOnly") {
      const res = textOnly(value);
      setInputValue(res);
    } else if (validation === "numberOnly") {
      const res = numberOnly(value);
      setInputValue(res);
    } else setInputValue(value);
  };

  const handleClickOutside = (e) => {
    if (editRef.current && !editRef.current.contains(e.target)) {
      setIsEditOpen(false);
      setInputValue(value);
    }
  };

  return (
    <div
      className={cn(
        "w-full h-auto leading-[0.8vw] py-[0.8vw] flex flex-col justify-start",
        editable ? "group/card cursor-pointer" : "",
        rootClassName
      )}
    >
      <label
        className={cn(
          "w-full font-md weight-medium text-[var(--contentText)]",
          labelClassName
        )}
      >
        {label} {required && <span style={{ color: "red" }}>*</span>}
      </label>
      <div
        className={cn(
          "w-full h-[2vw] flex items-center justify-between gap-[0.5vw] font-md weight-medium text-[var(--font-600)] mt-[0.6vw] p-[0.3vw] pl-0 border-[0.08vw] group-hover/card:border-[var(--border-50)] rounded-[0.37vw] transition-all duration-150 ease-linear",
          isEditOpen
            ? "border-[var(--border-50)] pl-[0.5vw]"
            : "border-transparent group-hover/card:pl-[0.5vw]",
          inputContainerClassName
        )}
        ref={editRef}
      >
        {typeof icon === "string" ? (
          <img
            src={icon}
            alt="icon"
            className={cn("size-[1.4vw] rounded-[0.37vw]", iconClassName)}
          />
        ) : (
          icon
        )}
        <div className={cn("w-full flex-1")}>
          {isEditOpen ? (
            <input
              type="text"
              value={inputValue ?? ""}
              className={cn(
                "w-full h-[1.8vw] text-[var(--contentText)] flex-1",
                inputClassName
              )}
              onChange={(e) => {
                onChange(e.target.value);
              }}
              maxLength={count}
              autoFocus={isEditOpen}
            />
          ) : (
            <div
              className={cn(
                "w-full full  flex items-center flex-1 truncate text-ellipsis",
                inputDisabledClassName
              )}
            >
              {link && inputValue ? (
                <a
                  href={`https://${inputValue}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 underline"
                >
                  {tooltipKey ? (
                    <Tooltip title={inputValue} placement="top">
                      <span className="truncate">{inputValue}</span>
                    </Tooltip>
                  ) : (
                    <span className="truncate">{inputValue}</span>
                  )}
                </a>
              ) : tooltipKey ? (
                <Tooltip title={inputValue || "--"} placement="top">
                  <span
                    className={cn("truncate h-[0.9vw]", inputValueClass)}
                    style={style}
                  >
                    {inputValue || "--"}
                  </span>
                </Tooltip>
              ) : (
                <span
                  className={cn("truncate h-[0.9vw]", inputValueClass)}
                  style={style}
                >
                  {inputValue || "--"}
                </span>
              )}
            </div>
          )}
        </div>
        {isEditOpen && (
          <div className="w-[3.3vw] h-[2vw] flex items-center justify-between gap-[0.3vw]">
            <button
              className={cn(
                "w-[1.5vw] h-[1.45vw] flex items-center justify-center rounded-[0.37vw] hover:bg-slate-100",
                cancelIconClassName
              )}
              onClick={() => {
                setIsEditOpen(false);
                setInputValue(value);
              }}
            >
              <Close
                stroke="#616874"
                circle={false}
                width="1.5vw"
                height="1.5vw"
              />
            </button>

            <button
              className={cn(
                "w-[1.5vw] h-[1.45vw] flex items-center justify-center rounded-[0.37vw] hover:bg-slate-100",
                successIconsClassName
              )}
              onClick={() => {
                if (!showAlert) {
                  onInputChange(inputValue);
                }
                setIsEditOpen(false);
                if (showAlert) {
                  setIsModalVisible(true);
                }
                // onSave(inputValue);
              }}
            >
              <Tick width="0.8vw" height="0.8vw" strokeWidth="3.2" />
            </button>
          </div>
        )}

        {!isEditOpen && (
          <button
            className={cn(
              "w-[1.5vw] h-[1.45vw] cursor-pointer hover:bg-slate-100 rounded-[0.37vw] flex items-center justify-center opacity-0 group-hover/card:opacity-100",
              editIconsClassName
            )}
            onClick={() => {
              setIsEditOpen(true);
              setInputValue(inputValue);
            }}
          >
            <Edit
              width="0.85vw"
              height="0.85vw"
              color={""}
              strokeWidth={"1.9"}
              className="stroke-[#898E99] group-hover/eye:stroke-[var(--contentText)]"
            />
          </button>
        )}

        <Modal
          open={isModalVisible}
          footer={null}
          className={"asp-modal-popup-small"}
          closable={false}
          centered
          mask={true}
          wrapClassName={"bg-[#00000065]"}
          destroyOnClose
          style={{
            width: "100%",
            borderRadius: 20,
            boxShadow: "0px 10px 24px 0px rgba(79, 92, 128, 0.15)",
            overflow: "hidden",
          }}
        >
          <AlertModal
            handleCancel={() => setIsModalVisible(false)}
            handleYes={() => {
              handleYes(inputValue);
              setIsModalVisible(false);
            }}
            handleNo={() => setIsModalVisible(false)}
            content={<p>Are you sure you want to edit this</p>}
            yesName="No, Keep it"
            noName="Yes, Edit"
            // loading={value}
          />
        </Modal>
      </div>
    </div>
  );
}
