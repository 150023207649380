import React, { useEffect, useReducer } from 'react';

// utils
import { useNavigate } from 'react-router-dom';

// hooks
import useToggle from '../../../hooks/useToggle';
import {
  INITIAL_STATE,
  onBoardingReducer,
} from '../../../hooks/auth/onBoardingReducer';

// redux
import { currentUserDetails } from '../../../api/Api';
import { useAspDispatch, useAspSelector } from '../../../test/jest-redux-hooks';
import {
  updateCurrentAccount,
  updateCurrentBrand,
  updateCurrentUser,
} from '../../../reduxToolkit/appSlice';

// components
import HorizontalSteps from '../components/HorizontalSteps';
import AccountCreation from '../components/onboarding/AccountCreation';
import BrandCreation from '../components/onboarding/BrandCreation';
import TeamInvite from '../components/onboarding/TeamInvite';

export default function OnBoarding() {
  const navigate = useNavigate();

  const { currentUser, acc_id, brand_id } = useAspSelector(
    (state) => state.app
  );

  const AspDispatch = useAspDispatch();

  const [data, dispatch] = useReducer(onBoardingReducer, INITIAL_STATE);
  const [showOnUnloadPopup, setshowOnUnloadPopup] = useToggle(false);
  const [isLoading, setIsLoading] = useToggle(false);

  useEffect(() => {
    const TOKEN = localStorage.getItem('authToken');
    if (!TOKEN) {
      localStorage.clear();
      navigate('/login');
    }
    fetchCurrentUserDetails();
  }, []);

  const fetchCurrentUserDetails = async () => {
    const res = await currentUserDetails();
    if (res?.status === 200) {
      AspDispatch(updateCurrentUser(res?.data));
      if (res?.data?.active_accounts?.length > 0) {
        AspDispatch(updateCurrentAccount(res?.data?.active_accounts?.[0]));
        if (res?.data?.active_accounts?.[0]?.brands?.length > 0) {
          onChange('update_step', null, 'step', 2);
          AspDispatch(
            updateCurrentBrand(res?.data?.active_accounts?.[0]?.brands?.[0])
          );

          setIsLoading(false);
        } else {
          onChange('update_step', null, 'step', 1);
          setIsLoading(false);
        }
      } else {
        onChange('update_step', null, 'step', 0);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if ((data?.step > 0 && !showOnUnloadPopup) || data?.step === 0) return;
    else {
      const handleonBeforeUnload = (e) => {
        e.preventDefault();
        return (e.returnValue = '');
      };

      window.addEventListener('beforeunload', handleonBeforeUnload, {
        capture: true,
      });

      return () => {
        window.removeEventListener('beforeunload', handleonBeforeUnload, {
          capture: true,
        });
      };
    }
  }, [data, showOnUnloadPopup]);

  useEffect(() => {
    checkValueHasChanged();
  }, [data]);

  const checkValueHasChanged = () => {
    const datas = JSON.stringify(INITIAL_STATE) !== JSON.stringify(data);
    setshowOnUnloadPopup(datas);
  };

  const onChange = (type, primaryKey, key, value) => {
    dispatch({ type, primaryKey, key, value });
  };

  return (
    <div className='w-full max-w-[478px] flex flex-col items-center overflow-hidden'>
      <div className='w-4/5 my-4'>
        <HorizontalSteps data={data} />
      </div>
      <div className='w-full min-h-[55vh] h-auto max-h-[67vh] overflow-y-scroll listScroll pr-1 pb-9'>
        {data?.step === 0 ? (
          <AccountCreation
            data={data?.account}
            onChange={onChange}
            fetchCurrentUserDetails={fetchCurrentUserDetails}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        ) : data?.step === 1 ? (
          <BrandCreation
            data={data?.brand}
            onChange={onChange}
            fetchCurrentUserDetails={fetchCurrentUserDetails}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            acc_id={acc_id}
          />
        ) : (
          data?.step === 2 && (
            <TeamInvite
              data={data?.teams}
              onChange={onChange}
              fetchCurrentUserDetails={fetchCurrentUserDetails}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              acc_id={acc_id}
              brand_id={brand_id}
            />
          )
        )}
      </div>
    </div>
  );
}
