import {
  allowedCtaButtonTypes,
  allowedCustomButtonTypes,
  getFirtstTypeOfButton,
} from "components/commonComponents/templates/Buttons/utils";

// filter all variables
const variableRegEx = /{{(.*?)}}/g;

// filter only custom variables
const customVariablesRegEx = /{{(custom-variable\d+)}}/g;

// validation patterns
const variableValidationPattern = [
  /}}{{/g,
  /(.*?){{(.*?)} /g,
  /(.*?){{(.*?)}}}(.*?)/g,
  /(.*?){{{(.*?)}}(.*?)/g,
];

export const removeDoubleStyles = (str) => {
  if (str)
    return str
      ?.replace(/\*\*(.*?)\*\*/g, "*$1*")
      ?.replace(/__(.*?)__/g, "_$1_")
      ?.replace(/~~(.*?)~~/g, "~$1~");

  return "";
};

export const replaceFormating = (str) => {
  if (str) {
    const filterTrippleStar = str.match(/\*\*\*(.*?)\*\*\*/g);
    const filterDoubleStar = str.match(/\*\*(.*?)\*\*/g);
    const filterStar = str.match(/\*(.*?)\*/g);

    const filterTripleUnderscore = str.match(/\_\_\_(.*?)_\_\_/g);
    const filterDoubleUnderscore = str.match(/\_\_(.*?)_\_/g);

    const filterTripleStrik = str.match(/~\~\~(.*?)\~\~\~/g);
    const filterDoubleStrik = str.match(/~\~(.*?)\~\~/g);

    // if (filterTrippleStar?.length > 0)
    //   str = str.replaceAll(/\*\*\*(.*?)\*\*\*/g, `<b>**$1**</b>`);
    // if (filterDoubleStar?.length > 0)
    //   str = str.replaceAll(/\*\*(.*?)\*\*/g, `<b>*$1*</b>`);
    // else str = str.replaceAll(/\*(.*?)\*/g, `<b>$1</b>`);

    //  if (filterTripleUnderscore?.length > 0)
    //    str = str.replaceAll(/\_\_\_(.*?)\_\_\_/g, `<i>_$1_</i>`);
    //  else if (filterDoubleUnderscore?.length > 0)
    //    str = str.replaceAll(/\_\_(.*?)_\_/g, `<i>_$1_</i>`);
    //  else str = str.replaceAll(/_(.*?)_/g, `<i>$1</i>`);

    //  if (filterTripleStrik?.length > 0)
    //    str = str.replaceAll(/\~\~\~(.*?)\~\~\~/g, `<s>~$1~</s>`);
    //  else if (filterDoubleStrik?.length > 0)
    //    str = str.replaceAll(/\~\~(.*?)\~\~/g, `<s>~$1~</s>`);
    //  else str = str.replaceAll(/~(.*?)\~/g, `<s>$1</s>`);

    str = str.replaceAll(/\*(.*?)\*/g, `<b>$1</b>`);
    str = str.replaceAll(/_(.*?)_/g, `<i>$1</i>`);
    str = str.replaceAll(/~(.*?)\~/g, `<s>$1</s>`);

    return str;
  }
  return null;
};

export const addLineBreake = (str) => str?.replaceAll(/\n/g, "<br/>");

export const checkVariable = (value) =>
  value?.toString()?.toLowerCase()?.match(variableRegEx);

export const checkVariablesAreValid = (value) => {
  const res = variableValidationPattern?.map((p, i) => {
    var updatedStr = value?.toString().toLowerCase().match(p);
    return updatedStr ? true : false;
  });

  return res?.filter((r) => r === true)?.length > 0 ? true : false;
};

export const replaceVariables = (str, value) => {
  const res = checkVariable(str);

  res?.map((r, i) => {
    str = str?.replaceAll(r, value?.[i]);
  });

  return str;
};

export const replaceVariableCount = (value) => {
  let count = 1;
  let updatedStr = value?.replaceAll?.(
    variableRegEx,
    function (match, variable) {
      return `{{${count++}}}`;
    }
  );

  return updatedStr;
};

export const getVariablesFromStr = (str, value) => {
  if (str) {
    const res = checkVariable(str);
    let newVariables = [];

    res?.map((r, i) => {
      const checkvariables = newVariables?.filter((v) => r === v?.title);
      if (checkvariables?.length === 0)
        newVariables?.push({
          id: i + 1,
          title: r,
          value: value?.[i],
        });
    });

    return newVariables?.length > 0 ? newVariables : [];
  }
  return [];
};

export const getDataFromTemplate = (data) => {
  const header =
    data?.components?.length > 0 &&
    data?.components?.filter((c) => c.type === "HEADER");
  const body =
    data?.components?.length > 0 &&
    data?.components?.filter((c) => c.type === "BODY");
  const footer =
    data?.components?.length > 0 &&
    data?.components?.filter((c) => c.type === "FOOTER");
  const buttons =
    data?.components?.length > 0 &&
    data?.components?.filter((c) => c.type === "BUTTONS");
  const updatedButtons = buttons?.[0]?.buttons?.map((b, i) => {
    return {
      id: i + 1,
      format:
        b.type === "QUICK_REPLY"
          ? b.text !== "Stop promotions"
            ? "CUSTOM"
            : "OPT_OUT"
          : b.type,
      ...b,
    };
  });

  let headerVariable = null;
  let bodyVariables = null;
  let updateButtonOrder = null;
  let updateCtaButton = [];
  let updateCustomButton = [];
  let customButtonsWithOptout = [];

  if (body?.[0]?.example?.body_text?.[0]?.length > 0) {
    bodyVariables = getVariablesFromStr(
      body?.[0].text,
      body?.[0]?.example?.body_text?.[0]
    );
  }

  if (header?.[0]?.example?.header_text?.[0]?.length > 0) {
    const res = checkVariable(header?.[0]?.text);
    if (res?.length > 0) {
      const check = bodyVariables?.filter((v) => v.title === res?.[0]);
      headerVariable = {
        title: res?.[0],
        id: check?.length > 0 ? check?.[0]?.id : 1,
        value:
          check?.length > 0
            ? check?.[0]?.value
            : header?.[0]?.example?.header_text?.[0],
      };
    } else headerVariable = null;
  }

  if (updatedButtons?.length > 0) {
    updateButtonOrder = getFirtstTypeOfButton(updatedButtons);
    updateCtaButton = updatedButtons?.filter((b) =>
      allowedCtaButtonTypes.includes(b.type)
    );
    updateCustomButton = updatedButtons?.filter(
      (b) =>
        allowedCustomButtonTypes.includes(b.type) &&
        b.text !== "Stop promotions"
    );
    customButtonsWithOptout = updatedButtons?.filter(
      (b) =>
        allowedCustomButtonTypes.includes(b.type) &&
        b.text === "Stop promotions"
    );

    const customButtons = [...updateCustomButton, ...customButtonsWithOptout];

    if (updateButtonOrder === "ctaButtons" && updateCtaButton?.length > 0)
      updateButtonOrder = "ctaButtons";
    if (updateButtonOrder === "custom" && customButtons?.length > 0)
      updateButtonOrder = "custom";
  }

  const customButtons = [...updateCustomButton, ...customButtonsWithOptout];

  return {
    header: header?.length > 0 ? header?.[0] : null,
    headerVariable: headerVariable,
    body: body?.length > 0 ? body?.[0] : null,
    bodyVariables: bodyVariables,
    footer: footer?.length > 0 ? footer?.[0] : null,
    buttons: updatedButtons?.length > 0 ? updatedButtons : [],
    ctaButtons: updatedButtons?.length > 0 ? updateCtaButton : [],
    customButtons: updatedButtons?.length > 0 ? customButtons : [],
    buttonsOrder: updatedButtons?.length > 0 ? updateButtonOrder : null,
  };
};
