import React, { useCallback, useEffect, useState } from "react";

// api
import { createCarouselStep1 } from "../../api/Api";

// utils
import { useNavigate } from "react-router-dom";

// assets
import Bell from "../../../../assets/customSVG/Bell";
import Key from "../../../../assets/customSVG/Key";
import SpeakerPhone from "../../../../assets/customSVG/SpeakerPhone";

// hooks
import useToggle from "../../../../hooks/useToggle";

// constants
import {
  authenticationTypes,
  LANGUAGE_OPTIONS,
  marketingTypes,
  utilityTypes,
} from "../../constant";
import {
  FEATURE_FLAG_AUTHENTICATION_TEMPLATE,
  FEATURE_FLAG_CAROUSEL_TEMPLATE,
} from "../../constant/featureFlags";

// redux
import {
  resetTemplateDetails,
  update,
  updateLanguage,
} from "../../../../reduxToolkit/templateSlice";
import {
  useAspSelector,
  useAspDispatch,
} from "../../../../test/jest-redux-hooks";

// helpers
import { cn } from "../../../../helper/cn";
import {
  getLanguageCode,
  getLanguageName,
} from "../../../../helper/setLanguageCode";

// components
import RadioOption from "../../../../components/form/RadioButton/radioOption";
import TemplateName from "./TemplateName";
import Select from "../../../../components/commonComponents/select/index";

const CreateTemplateWA = () => {
  const templateDetails = useAspSelector((state) => state?.Template);
  const { currentBrand } = useAspSelector((state) => state.app);

  const navigate = useNavigate();
  const dispatch = useAspDispatch();

  const [data, setData] = useState(null);
  const [language, setLanguage] = useState(null);
  const [templateNameError, setTemplateNameError] = useToggle(false);
  const [checkingtemplateName, setCheckingtemplateName] = useToggle(false);

  useEffect(() => {
    const language = templateDetails?.language?.[0]
      ? Object?.keys(templateDetails?.language?.[0])
      : "";

    if (language?.length > 0)
      setLanguage({
        label: language?.[0],
        value: language?.[0],
      });
    else setLanguage(null);
  }, []);

  useEffect(() => {
    setData(templateDetails);
  }, [templateDetails]);

  // if all the required deatils are filled allow to continue to template details page
  const handleContinue = useCallback(
    async (e) => {
      if (
        !templateNameError &&
        data?.category &&
        data?.categoryType &&
        data?.name &&
        data?.language?.length > 0 &&
        !checkingtemplateName
      ) {
        if (
          data?.categoryType === "carousel" &&
          (data?.continueDetails === null ||
            data?.continueDetails?.template?.name !== data?.name)
        ) {
          const res = await step1Carousel();

          if (res?.status === 200) next(res?.data);
        } else {
          navigate("/user/channels/whatsapp/create-template/template-details");
        }
      }
      return;
    },

    [data, templateNameError, checkingtemplateName]
  );

  const next = (data) => {
    dispatch(update({ key: "continueDetails", value: data }));
    navigate("/user/channels/whatsapp/create-template/template-details");
  };

  // update categorys
  const updateCategory = (value) => {
    dispatch(update({ key: "category", value: value }));
  };

  // discard the template details
  const discard = () => {
    dispatch(resetTemplateDetails());
    navigate("/user/channels/whatsapp/message-templates");
  };
  const step1Carousel = async () => {
    const payLoad = {
      category: data?.category,
      brand_id: currentBrand?.brand_id,
      name: data?.name,
      language: getLanguageCode(language?.value),
      categoryType: data?.categoryType,
    };

    const res = await createCarouselStep1(payLoad);
    return res;
  };

  // styles
  const header = "font-normal weight-bold mb-[0.3vw] text-[var(--contentText)]";
  const subHeader =
    "weight-medium text-[var(--font-400)] pt-[0.03vw] pb-[0.7vw]";
  const title = "font-md weight-bold text-[var(--contentText)]";
  const subTitle = "font-sm weight-medium text-[var(--font-400)] mt-[0.25vw]";
  const marketing = "flex gap-[0.3vw] ml-[3.1vw]";

  return (
    <div className="">
      <div>
        <div className="w-auto flex items-center justify-between bg-[var(--white)] mx-[0.7vw] mt-[0.7vw] px-[1vw] py-[1vw] rounded-t-[0.8vw]">
          <div className="font-larger weight-bold text-[var(--contentText)]">
            New message template
          </div>
          <div className="w-auto flex items-center gap-[1vw]">
            <button
              className="font-md px-[0.6vw] py-[0.37vw] weight-medium border-0 outline-none tracking-[0.02vw] text-[var(--channelToggleSwitch)] rounded-[0.37vw] hover:bg-slate-100"
              onClick={() => {
                discard();
              }}
            >
              Discard
            </button>

            <button
              type="submit"
              className={`font-md px-[0.6vw] py-[0.37vw] rounded-[0.37vw] weight-medium border-0 outline-none tracking-[0.02vw] ${
                !templateNameError &&
                data?.category &&
                data?.name &&
                data?.language?.length > 0
                  ? "!bg-[var(--primary)] !text-[var(--appHeaderBG)] cursor-pointer"
                  : "!bg-[var(--lightGrey)] !text-[var(--darkgrey)] cursor-not-allowed"
              }`}
              onClick={(e) => {
                handleContinue(e);
              }}
            >
              Continue
            </button>
          </div>
        </div>

        <div className="w-[46.5vw] h-[80vh] !overflow-y-scroll pt-[0.7vw] pb-[5vw] mx-auto flex flex-col gap-[0.5vw]">
          <div className="w-full bg-[var(--appHeaderBG)] px-[1vw] pt-[0.5vw] pb-[1vw]">
            <div className="border-b-[0.08vw] border-[var(--border-50)]">
              <h2 className={`${header}`}>Category</h2>
              <p className={`${subHeader} font-md`}>
                Choose a category that best describes your message template.
              </p>
            </div>
            <div className="flex flex-col gap-[1vw] mt-[0.5vw]">
              <div
                className={`w-full px-[0.8vw] py-[0.6vw] rounded-[0.8vw] ${
                  marketingTypes.includes(data?.categoryType)
                    ? "!bg-[var(--primaryBackground)]"
                    : "!bg-[var(--channelButton)]"
                }`}
              >
                <div className="flex-row align-center gap-[0.8vw]">
                  <div
                    className={`w-[2.5vw] h-[2.5vw] ${
                      marketingTypes.includes(data?.categoryType)
                        ? "bg-[#25c277]"
                        : "bg-white"
                    } rounded-full flex items-center justify-center`}
                  >
                    <SpeakerPhone
                      width={"1.6vw"}
                      height={"1.6vw"}
                      stroke={
                        marketingTypes.includes(data?.categoryType)
                          ? "#fff"
                          : "#616874"
                      }
                    />
                  </div>
                  <div className="flex-column">
                    <h3 className={`${header}`}>Marketing</h3>
                    <p className={`${subHeader} font-sm`}>
                      Promotions or information about your business, products or
                      services Or any message that isn't utility or
                      authentication.
                    </p>
                  </div>
                </div>

                <div
                  className={`${marketing} mt-[0.5vw] cursor-pointer`}
                  onClick={() => updateCategory("custom")}
                >
                  <RadioOption
                    value="custom"
                    selectedValue={data?.categoryType}
                    id="custom"
                    name="category"
                    onChange={() => updateCategory("custom")}
                    style={{
                      width: "1.2vw",
                      height: "1.2vw",
                      margin: "0.15vw 0 0",
                    }}
                  />
                  <label htmlFor="custom" className="cursor-pointer">
                    <h3 className={`${title}`}>Custom</h3>
                    <p className={`${subTitle} font-sm`}>
                      Send promotional offers, announcements and more to
                      increase awareness and engagement.
                    </p>
                  </label>
                </div>

                <div
                  className={cn(
                    "mt-[0.7vw] cursor-pointer",
                    marketing,
                    FEATURE_FLAG_CAROUSEL_TEMPLATE
                      ? "cursor-pointer"
                      : "cursor-not-allowed"
                  )}
                >
                  <RadioOption
                    value="carousel"
                    selectedValue={data?.categoryType}
                    onChange={() => {
                      // for feature
                      if (FEATURE_FLAG_CAROUSEL_TEMPLATE)
                        updateCategory("carousel");
                    }}
                    id="carousel"
                    name="category"
                    className={cn(
                      FEATURE_FLAG_CAROUSEL_TEMPLATE
                        ? "!cursor-pointer"
                        : "!cursor-not-allowed"
                    )}
                    style={{
                      width: "1.2vw",
                      height: "1.2vw",
                      margin: "0.15vw 0 0",
                    }}
                  />
                  <label
                    htmlFor="carousel"
                    className={cn(
                      FEATURE_FLAG_CAROUSEL_TEMPLATE
                        ? "cursor-pointer"
                        : "cursor-not-allowed"
                    )}
                  >
                    <h3 className={`${title}`}>Carousel</h3>
                    <p className={`${subTitle} font-sm`}>
                      Increase your brand visibility, reach and Engage your
                      audience with eye-catching carousels.
                    </p>
                  </label>
                </div>
              </div>
              <div
                className={`w-full px-[1.2vw] py-[0.8vw] rounded-[0.8vw] flex items-center gap-[1vw] cursor-pointer ${
                  utilityTypes.includes(data?.categoryType)
                    ? "!bg-[var(--primaryBackground)]"
                    : "!bg-[var(--channelButton)]"
                }`}
                onClick={() => updateCategory("utility")}
              >
                <div
                  className={`w-[2.5vw] h-[2.5vw] flex items-center justify-center rounded-full ${
                    utilityTypes.includes(data?.categoryType)
                      ? "bg-[#25c277]"
                      : "bg-white"
                  }`}
                >
                  <Bell
                    width={"1.6vw"}
                    height={"1.6vw"}
                    color={
                      utilityTypes.includes(data?.categoryType)
                        ? "#fff"
                        : "#616874"
                    }
                  />
                </div>
                <div className="">
                  <h2 className={`${header}`}>Utility</h2>
                  <p className={`${subHeader} font-sm !pb-0`}>
                    Messages about a specific transaction, account, order or
                    customer request.
                  </p>
                </div>
              </div>

              {/* For authentication template */}
              {FEATURE_FLAG_AUTHENTICATION_TEMPLATE && (
                <div
                  className={`w-full px-[1.2vw] py-[0.8vw] rounded-[0.8vw] flex items-center gap-[1vw] cursor-pointer ${
                    authenticationTypes.includes(data?.categoryType)
                      ? "!bg-[var(--primaryBackground)]"
                      : "!bg-[var(--channelButton)]"
                  }`}
                  onClick={() => updateCategory("authentication")}
                >
                  <div
                    className={`w-[2.5vw] h-[2.5vw] flex items-center justify-center rounded-full ${
                      authenticationTypes.includes(data?.categoryType)
                        ? "bg-[#25c277]"
                        : "bg-white"
                    }`}
                  >
                    <Key
                      width={"1.5vw"}
                      height={"1.5vw"}
                      color={
                        authenticationTypes.includes(data?.categoryType)
                          ? "#fff"
                          : "#616874"
                      }
                    />
                  </div>
                  <div className="">
                    <h2 className={`${header}`}>Authentication</h2>
                    <p className={`${subHeader} font-sm !pb-0`}>
                      Send codes to verify a transaction or login.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <TemplateName
            type={"create"}
            checking={checkingtemplateName}
            setChecking={setCheckingtemplateName}
            onChange={(val) => {
              setData({ ...data, name: val });
            }}
            error={templateNameError}
            onErrorChange={(value) => {
              setTemplateNameError(value);
            }}
          />
          <div className="w-full bg-[var(--appHeaderBG)] px-[1vw] pt-[0.5vw] pb-[1vw] flex-column">
            <div className={`${header}`}>Languages</div>
            <p className={`${subHeader} font-md`}>
              Choose languages for your message template. You can delete or add
              more languages later.
            </p>

            <Select
              value={language}
              options={LANGUAGE_OPTIONS}
              placeholder={"Select languages"}
              rootClassName="h-[2.3vw]"
              optionsClassName="!max-w-[15vw]"
              cardStyle="!max-h-[1.5vw]"
              onChange={(val) => {
                setLanguage(val);
                dispatch(updateLanguage(val?.value));
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTemplateWA;
