const convertName = (string) => {
  return string.split("_").join(" ");
};

// colorUtils.js
function hexToRgba(hex, opacity) {
  let r = 0,
    g = 0,
    b = 0;
  if (hex?.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex?.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return `rgba(${r},${g},${b},${opacity})`;
}

// FirstName

const firstNamePattern = /^[a-zA-Z0-9.\-_, ]*$/;

const validateFirstName = (firstName) => {
  return firstNamePattern.test(firstName);
};

const phoneValid = /^\?[0-9\s]+$/;

const validatePhone = (phone) => {
  return phoneValid.test(phone);
};

// Gender

const genderPattern = /^[a-zA-Z.\-_,]*$/;

const validateGender = (gender) => {
  return genderPattern.test(gender);
};

// Emoji

const emojiPattern =
  /[\u{1F600}-\u{1F64F}|\u{1F300}-\u{1F5FF}|\u{1F680}-\u{1F6FF}|\u{1F700}-\u{1F77F}|\u{1F780}-\u{1F7FF}|\u{1F800}-\u{1F8FF}|\u{1F900}-\u{1F9FF}|\u{1FA00}-\u{1FA6F}|\u{1FA70}-\u{1FAFF}|\u{2600}-\u{26FF}|\u{2700}-\u{27BF}|\u{2300}-\u{23FF}|\u{2B50}|\u{2934}|\u{2935}|\u{3030}|\u{303D}|\u{3297}|\u{3299}|\u{A9}|\u{AE}|\u{303D}|\u{3030}|\u{00A9}|\u{00AE}]/gu;

const validateEmoji = (emoji) => {
  return emojiPattern.test(emoji);
};

export {
  convertName,
  hexToRgba,
  validateFirstName,
  firstNamePattern,
  validateEmoji,
  emojiPattern,
  validateGender,
  genderPattern,
  validatePhone,
};
