import React, { useCallback, useEffect, useState } from "react";

// assets
import bg from "../../../assets/images/body-message.png";
import List from "../../../assets/customSVG/List";
import { ICONS } from "../../../assets/icons";

// helper
import { cn } from "../../../helper/cn";
import {
  addLineBreake,
  replaceFormating,
  replaceVariables,
} from "../../../modules/inbox/helper";

// utils
import { Carousel, Drawer } from "antd";
import {
  allowedCtaButtonTypes,
  allowedCustomButtonTypes,
  getCTAButtons,
} from "./Buttons/utils";

// hooks
import useToggle from "../../../hooks/useToggle";
import CTAButtonPreview from "./Buttons/CTAButtonPreview";
import QuickReplayButtonPreview from "./Buttons/QuickReplayButtonPreview";
import CarouselCardPreview from "./CarouselCardPreview";

export default function TemplatePreview({
  category,
  header,
  headerVariable,
  body,
  bodyVariables,
  footer,
  buttons,
  buttonsOrder,
  ctaButtons,
  customButtons,
  className,
  selectedTemplate,
  useTemplates,
  drawerType = "normal",
  authentication,
  carousel,
  isAddingCarouselCard,
  setIsAddingCarouselCard,
}) {
  const [openDrawer, setOpenDrawer] = useToggle(false);

  useEffect(() => {
    setOpenDrawer(false);
  }, [selectedTemplate]);

  const toggleDrawer = () => setOpenDrawer(!openDrawer);

  const total =
    buttonsOrder === "ctaButtons"
      ? [...ctaButtons, ...customButtons]
      : buttonsOrder === "custom"
      ? [...customButtons, ...ctaButtons]
      : [];

  const checkButtonsLength = total?.length <= 3 ? total : total.slice(0, 2);

  const btnStyle =
    "w-full flex items-center justify-center gap-[0.5vw] py-[0.5vw] font-sm weight-medium border-t-[0.08vw] border-[var(--border-50)] cursor-default";

  const showFooter = useCallback(() => {
    return getCTAButtons(buttons, "OPT_OUT")?.length < 1 ? true : false;
  }, [buttons]);

  return (
    <div
      className={cn(
        "w-full h-full relative overflow-y-scroll rounded-[1vw]",
        className
      )}
      style={{
        backgroundImage: `url(${bg})`,
      }}
    >
      <div className="w-full h-full relative">
        <div className="w-full h-full overflow-y-scroll py-[1vw] flex flex-col gap-[0.7vw]">
          <div className="w-full h-auto px-[1vw]">
            {category === "AUTHENTICATION" ? (
              <div className="min-h-[4vw] bg-white rounded-[0.7vw] py-[0.5vw] overflow-y-scroll">
                <div className="font-md weight-small px-[0.5vw] break-words bg-white">
                  {authentication?.body}
                </div>
                <p className="w-full font-sm weight-small bg-white px-[0.5vw] text-[var(--font-600)] pt-[0.5vw]">
                  {authentication?.content?.expiryTimeForCode
                    ? `This code expires in ${
                        authentication?.content?.expiryTime >= 1 &&
                        authentication?.content?.expiryTime <= 90
                          ? authentication?.content?.expiryTime
                          : -1
                      } Minutes.`
                    : ""}
                </p>
                <div className="h-auto px-[0.5vw] bg-white rounded-b-[0.7vw] mt-[0.8vw] text-[#007AFF]">
                  {authentication?.buttons.map((b, i) => {
                    return (
                      <div key={`authentication-preview-button-${i}`}>
                        <button className={`${btnStyle}`}>{b?.text}</button>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="min-h-[4vw] bg-white rounded-[0.7vw] py-[0.5vw] overflow-y-scroll">
                {/* header preview */}
                <div className="w-full px-[0.5vw] bg-white rounded-[0.4vw] h-full overflow-hidden">
                  {header?.format === "TEXT" && (
                    <div className="h-full font-md weight-semibold break-words">
                      {useTemplates && header?.example?.header_text?.[0] !== 0
                        ? replaceVariables(
                            header?.text,
                            header?.example?.header_text
                          )
                        : header?.text}
                      {/* {header?.text} */}
                    </div>
                  )}
                  {header?.format === "IMAGE" &&
                    header?.example?.header_handle?.length > 0 && (
                      <img
                        src={header?.example?.header_handle?.[0]}
                        className="w-full h-full object-cover rounded-[0.4vw]"
                      />
                    )}
                  {header?.format === "VIDEO" &&
                    header?.example?.header_handle?.length > 0 && (
                      <video
                        className="object-cove rounded-[0.4vw]"
                        controls
                        autoplay="autoplay"
                        // loop
                      >
                        <source
                          src={header?.example?.header_handle?.[0]}
                          type="video/mp4"
                        />
                      </video>
                    )}
                  {header?.format === "DOCUMENT" &&
                    header?.example?.header_handle?.length > 0 && (
                      <iframe
                        title="cover"
                        className="imagePreview"
                        src={header?.example?.header_handle?.[0]}
                        type="application/pdf"
                        width="100%"
                        height="150px"
                      ></iframe>
                    )}
                </div>
                {/* body preview */}
                <p
                  className="font-md weight-small px-[0.5vw] pt-[0.5vw] break-words bg-white"
                  dangerouslySetInnerHTML={{
                    __html: useTemplates
                      ? replaceVariables(
                          addLineBreake(replaceFormating(body?.text)),
                          body?.example?.body_text?.[0]
                        )
                      : addLineBreake(replaceFormating(body?.text)),
                  }}
                >
                  {/* {body?.text} */}
                </p>
                {/* footer preview */}
                {footer?.text && showFooter() && (
                  <p className="w-full font-md weight-small bg-white px-[0.5vw] text-[var(--font-600)] pt-[0.5vw]">
                    {footer?.text}
                  </p>
                )}
                {!showFooter() && (
                  <p className="w-full font-md weight-small bg-white px-[0.5vw] text-[var(--font-600)] pt-[0.5vw]">
                    <>Not interested? Tap Stop promotions</>
                  </p>
                )}
                {/* buttons preview */}
                <div className="h-auto px-[0.5vw] bg-white rounded-b-[0.7vw] mt-[0.8vw] text-[#007AFF]">
                  {checkButtonsLength.map((b, i) => {
                    return (
                      <div className="" key={`preview-button-${i}`}>
                        {allowedCtaButtonTypes.includes(b?.type) && (
                          <div>
                            {b.type === "URL" && (
                              <button className={`${btnStyle}`}>
                                <img
                                  src={ICONS?.nounLink}
                                  alt="shareBtn"
                                  className="size-[0.9vw]"
                                />
                                {b.text}
                              </button>
                            )}
                            {b.type === "PHONE_NUMBER" && (
                              <button className={`${btnStyle}`}>
                                <img
                                  src={ICONS?.call}
                                  alt="shareBtn"
                                  className="size-[0.9vw]"
                                />
                                {b.text}
                              </button>
                            )}

                            {b.type === "COPY_CODE" && (
                              <button className={`${btnStyle}`}>
                                <img
                                  src={ICONS?.CopyLeft}
                                  alt="shareBtn"
                                  className="size-[0.9vw]"
                                />
                                <span>Copy Offer Code</span>
                              </button>
                            )}
                          </div>
                        )}
                        {allowedCustomButtonTypes?.includes(b?.type) && (
                          <div className={`${btnStyle}`}>
                            <img
                              src={ICONS?.shareLink}
                              alt="shareBtn"
                              className="size-[0.9vw]"
                            />
                            <p>{b.text}</p>
                          </div>
                        )}
                      </div>
                    );
                  })}
                  {total?.length > 3 && (
                    <button
                      className="w-full h-[2vw] flex items-center justify-center gap-[0.5vw] font-md weight-medium border-t-[0.08vw] border-[var(--border-50)]"
                      onClick={() => {
                        toggleDrawer();
                      }}
                    >
                      <List color="#007AFF" width="0.9vw" height="0.9vw" />
                      See all options
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="w-full h-auto">
            {category === "CAROUSEL" && (
              <div className="min-h-[4vw] flex gap-2.5 overflow-x-scroll pb-2 listScroll px-[1vw]">
                {carousel?.cards?.map((c) => {
                  return (
                    <div className="w-full min-w-[12vw] min-h-[13rem]">
                      <CarouselCardPreview data={c} carousel={carousel} />
                    </div>
                  );
                })}
                {/* {isAddingCarouselCard && (
                  <div className='w-full min-w-[13.2vw] min-h-[13rem]'>
                    <CarouselCardPreview data={{}} />
                  </div>
                )} */}
              </div>
            )}
          </div>
        </div>
      </div>
      <Drawer
        placement="bottom"
        closable={false}
        onClick={toggleDrawer}
        open={openDrawer}
        getContainer={false}
        rootClassName={`template-preview-drawer`}
        className={`w-full ${
          drawerType === "small" ? "!rounded-t-[2vw]" : "!rounded-t-[1vw]"
        }`}
        styles={{
          body: {
            padding: drawerType === "small" ? "0.5vw 1.5vw 0" : "1.5vw",
          },
        }}
        // className={`${drawerType === 'small' ? '0.5vw 1.5vw' : '1.5vw'}`}
      >
        <div
          className={`w-full h-auto overflow-scroll ${
            drawerType === "small" ? "min-h-[10vh]" : "min-h-[30vh]"
          }`}
        >
          <div
            className={`w-[5vw] h-[0.4vw] rounded-[0.7vw] mx-auto bg-[var(--font-300)] cursor-pointer ${
              drawerType === "small" ? "mb-[1vw]" : "mb-[1vw]"
            }`}
            onClick={toggleDrawer}
          ></div>
          <div
            className={`w-full flex items-center gap-[0.5vw] font-normal weight-medium cursor-pointer ${
              drawerType === "small"
                ? "h-[2vh] mb-[0.8vw] p-[0.3vw]"
                : "h-[3vh] mb-[2vw] p-[0.5vw]"
            }`}
          >
            <div className="w-full text-center">All Options</div>
          </div>

          <>
            {buttonsOrder ? (
              buttonsOrder === "ctaButtons" ? (
                <>
                  {ctaButtons?.length > 0 && (
                    <div>
                      <CTAButtonPreview
                        type={drawerType}
                        ctaButtons={ctaButtons}
                      />
                    </div>
                  )}
                  {customButtons?.length > 0 && (
                    <div
                      className={`${
                        ctaButtons?.length > 0
                          ? "border-t-[0.08vw] border-[var(--border-50)]"
                          : ""
                      }`}
                    >
                      <QuickReplayButtonPreview
                        type={drawerType}
                        customButtons={customButtons}
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  {customButtons?.length > 0 && (
                    <div>
                      <QuickReplayButtonPreview
                        type={drawerType}
                        customButtons={customButtons}
                      />
                    </div>
                  )}
                  {ctaButtons?.length > 0 && (
                    <div
                      className={`${
                        customButtons?.length > 0
                          ? "border-t-[0.08vw] border-[var(--border-50)]"
                          : ""
                      }`}
                    >
                      <CTAButtonPreview
                        type={drawerType}
                        ctaButtons={ctaButtons}
                      />
                    </div>
                  )}
                </>
              )
            ) : (
              <></>
            )}
          </>
        </div>
      </Drawer>
    </div>
  );
}
