import React from 'react';
import './usagePlan.css';

// assets
import { ICONS } from '../../assets/icons';
import FullArrow from 'assets/customSVG/FullArrow';
import Arrow from '../../assets/customSVG/Arrow';

// utils
import { Modal } from 'antd';

// hooks
import useToggle from 'hooks/useToggle';
import { useSelect } from 'hooks/useSelect';

// redux
import { useAspSelector } from '../../test/jest-redux-hooks';

// components
import Header from 'components/commonComponents/modals/Header';
import BillingInformation from './BillingInformation';
import { cn } from '../../helper/cn';

const UsagePlan = ({ handleCancel }) => {
  const { currentBrand } = useAspSelector((state) => state.app);

  const [isUpgrade, setIsUpgrade] = useToggle(false);
  const [selectedPrice, setSelectedPrice] = useSelect('Monthly');
  const [isPlanSelected, setIsPlanSelected] = useToggle(false);
  const [selectedPlan, setSelectedPlan] = useSelect(null);
  const [isBillingInformationmodify, setIsBillingInformationmodify] =
    useToggle(false);
  const [isApplyCoupon, setIsApplyCoupon] = useToggle(false);
  const [couponCode, setCouponCode] = useSelect('');
  const [couponCodeRes, setCouponCodeRes] = useSelect('');

  const plan = [
    {
      id: 1,
      title: 'Marketing',
      credits: '1000000',
    },
    {
      id: 2,
      title: 'Utility',
      credits: '1000',
    },
    {
      id: 3,
      title: 'Authentication',
      credits: '0',
    },
    {
      id: 4,
      title: 'Service',
      credits: '10000',
    },
  ];

  const pricing = [
    {
      id: 1,
      title: 'Starter',
      custom: false,
      popular: false,
      icon: ICONS?.Starter,
      monthly: {
        price: '3,000',
        yearly: '12,000',
      },
      quarterly: {
        price: '9,000',
        yearly: '12,000',
      },
      annual: {
        price: '30,000',
        yearly: '2',
      },
      content: (
        <>
          For startups and solopreneurs looking to
          <br /> build a business across digital platforms
        </>
      ),
      btnText: 'Get Started',
    },
    {
      id: 2,
      title: 'Growth',
      custom: false,
      popular: true,
      icon: ICONS?.Growth,
      monthly: {
        price: '5,000',
        yearly: '30,000',
      },
      quarterly: {
        price: '15,000',
        yearly: '30,000',
      },
      annual: {
        price: '50,000',
        yearly: '2',
      },
      content: (
        <>
          For small to mid-scale businesses looking to <br /> expand their
          business growth
        </>
      ),
      btnText: 'Get Started',
    },
    {
      id: 3,
      title: 'Magnum',
      custom: true,
      popular: false,
      icon: ICONS?.Magnum,
      monthly: {},
      quarterly: {},
      annual: {},
      content: (
        <>
          For enterprise businesses looking for a<br /> fully featured
          customizable business suite.
        </>
      ),
      btnText: 'Contact Sales',
    },
  ];

  const handleCancelUpgradePlans = () => setIsUpgrade(false);
  const handleCancelSelectedPlans = () => setIsPlanSelected(false);

  const Planscard = ({ id, plan }) => {
    return (
      <button
        className={`w-[8vw] px-[1vw] py-[0.5vw] rounded-[2vw] font-normal weight-semibold text-center transition-colors ease-linear duration-300 ${
          selectedPrice === plan
            ? 'text-[#0ACD95] bg-white'
            : 'bg-transparent text-[#202421]'
        }`}
        onClick={() => {
          setSelectedPrice(plan);
        }}
      >
        {plan}
      </button>
    );
  };

  const modifyBtnStyle =
    'text-[var(--BrandLink)] text-sm weight-semibold px-4 py-2 rounded-md hover:bg-[#ECE8FA]';

  return (
    <div className='usagePlan'>
      <div className='flex-row space-between usagePlan-popup'>
        <h2>Plans and usage</h2>
        <img
          className='usage-popup-exit pointer'
          src={ICONS.popupX}
          alt='popup'
          onClick={handleCancel}
        />
      </div>
      <div className='usagePlan-container'>
        <div className='flex-row space-between align-center'>
          <h4 className='growth-plan'>
            {currentBrand?.brand_billing_plan_name}
          </h4>
          <button className='plan-button'>Monthly</button>
        </div>
        <span className='plan-renews'>( Renews Apr 18, 2024 )</span>
        <div className=' pointer w-[55%] plan-animation'>
          <div
            className='flex-row align-center'
            onClick={() => {
              handleCancel();
              setIsUpgrade(true);
            }}
          >
            <p className='plan-credits'>Manage your plans & credits</p>
            <img
              className='upgrade-arrow pointer'
              src={ICONS.upgradeArrow}
              alt='popup'
            />
          </div>
          <div className='plan-sliders'>
            <div className='plan-slides'></div>
          </div>
        </div>

        <div className='whatsapp-credits'>
          <div className='growth-plan-whatsapp flex-column'>
            {/* <p>WhatsApp Credits</p>
            <div className="flex-row  space-between align-center">
              <p>₹ 520.2k</p>
              <span>Remaining Amount</span>
            </div> */}
          </div>
          {/* {plan?.map((data) => (
            <div key={data?.id} className="flex-row py-2">
              <p className="plan-title">{data?.title} - </p>
              <p className="plan-remaining-credits">
                {data?.credits} <span>Remaining credits</span>
              </p>
            </div>
          ))}
          <div className=" pointer w-[35%] plan-animation">
            <div className="flex-row align-center mt-2 pointer">
              <p className="plan-credits">Get more credits</p>
              <img
                className="upgrade-arrow pointer"
                src={ICONS.upgradeArrow}
                alt="popup"
                onClick={handleCancel}
              />
            </div>
            <div className="plan-sliders">
              <div className="plan-slides"></div>
            </div>
          </div> */}
        </div>

        <div className='flex-row align-center justify-center mt-[25px]'>
          {currentBrand?.brand_billing_plan_name !== 'Magnum Plan' && (
            <button
              className='upgrade-plan-button cursor-pointer'
              onClick={() => {
                setIsUpgrade(true);
                handleCancel();
              }}
            >
              Upgrade Plan
            </button>
          )}
          {currentBrand?.brand_billing_plan_name === 'Magnum Plan' && (
            <div className='upgrade-plan-button'>Contact Sales</div>
          )}
        </div>
      </div>
      <Modal
        open={isUpgrade}
        afterOpenChange={(open) => {
          setIsUpgrade(open);
        }}
        onCancel={handleCancelUpgradePlans}
        centered
        footer={null}
        closable={false}
        mask={true}
        wrapClassName={'bg-[#00000095]'}
        destroyOnClose
        width={'70vw'}
        style={{
          width: '100%',
          borderRadius: 20,
          boxShadow: '0px 10px 24px 0px rgba(79, 92, 128, 0.15)',
          overflow: 'hidden',
        }}
      >
        <Header
          title={isPlanSelected ? 'Proceed to Checkout' : 'Select Plans'}
          prefix={
            isPlanSelected ? (
              <div
                className='rotate-180 size-9 flex items-center justify-center border border-[var(--border-100)] rounded-lg cursor-pointer'
                onClick={() => {
                  setIsPlanSelected(false);
                }}
              >
                <FullArrow width={14} height={14} />
              </div>
            ) : null
          }
          prefixStyle='mr-4'
          titleSize='largest'
          handleCancel={handleCancelUpgradePlans}
        />
        <div className='bg-[#FFFFFF] p-[2vw]'>
          {isPlanSelected ? (
            <div className='w-full h-[60vh] flex gap-5 text-[var(--contentText)]'>
              <div className='w-3/5 text-base weight-medium overflow-y-scroll'>
                <div className='flex items-center justify-between pb-7'>
                  <h4>
                    Your selected plan is{' '}
                    <span className='weight-semibold text-[#3391BD]'>
                      {selectedPlan?.title}({selectedPrice})
                    </span>
                  </h4>
                  <button className={`${modifyBtnStyle}`}>Modify</button>
                </div>
                <div className='flex flex-col gap-4 py-7 border-y border-[var(--border-100)]'>
                  <div className='flex flex-col gap-1.5'>
                    <h6 className='text-base weight-semibold'>
                      Additional Users
                    </h6>
                    <p className='text-sm weight-small'>
                      Plan includes 3 users. You can add more users or remove
                      existing ones here.
                    </p>
                  </div>
                  <div className='w-full h-[66px] flex items-center justify-between gap-5 px-4 text-sm border border-[var(--border-100)] rounded-xl'>
                    <div className='weight-semibold'>Team member(s)</div>
                    <div className='weight-medium'>₹399 x 1 (User)</div>
                    <div className='w-auto h-11 flex items-center justify-between gap-5 px-4 py-1.5 border border-[var(--border-100)] rounded-xl'>
                      <button className='w-8 h-11 flex items-center justify-center'>
                        -
                      </button>
                      <div className='weight-semibold flex items-center justify-center'>
                        3
                      </div>
                      <button className='w-8 h-11 flex items-center justify-center'>
                        +
                      </button>
                    </div>
                  </div>
                  <button className='w-fit text-left text-sm weight-medium text-[var(--BrandLink)]'>
                    Manage your team members under brand settings
                  </button>
                </div>
                <div className='pt-7'>
                  <div className='flex items-center justify-between'>
                    <h4>Billing Information</h4>
                    <button
                      className={`${modifyBtnStyle}`}
                      onClick={() => {
                        setIsBillingInformationmodify(
                          !isBillingInformationmodify
                        );
                      }}
                    >
                      {isBillingInformationmodify ? 'Save' : 'Modify'}
                    </button>
                  </div>
                  <BillingInformation edit={isBillingInformationmodify} />
                </div>
              </div>
              <div className='w-2/5 bg-[#FBFBFB] px-7 pb-7 pt-2.5 rounded-2xl'>
                <h6 className='text-base weight-bold'>Bill Summary:</h6>
                <div className='flex flex-col gap-7 pt-7 pb-9 border-b-2 border-[var(--border-100)]'>
                  <div className='min-h-9 h-auto flex flex-col gap-1.5'>
                    <div className='flex items-center justify-between gap-2.5 text-base'>
                      <h5 className='weight-semibold'>Growth Plan</h5>
                      <p className='weight-bold'>₹2500.00</p>
                    </div>
                    <p className='text-sm weight-medium text-[var(--font-400)]'>
                      Monthly
                    </p>
                  </div>
                  <div className='min-h-9 h-auto'>
                    <div className='flex items-center justify-between gap-2.5 text-base'>
                      <h5 className='weight-semibold'>Additional Users (0)</h5>
                      <p className='weight-bold'>$ 0.00</p>
                    </div>
                    <p className='text-sm weight-medium text-[var(--font-400)]'>
                      ₹399 x 1 (User)
                    </p>
                  </div>
                  <div className='min-h-9 h-auto'>
                    {isApplyCoupon ? (
                      <div className='w-full'>
                        <div className='flex items-center justify-between gap-2.5'>
                          <p className='text-sm weight-semibold text-[var(--font-600)]'>
                            Add coupon
                          </p>
                          <img
                            src={ICONS?.cancelX}
                            alt='cancel icons'
                            className='size-5 rounded-md cursor-pointer hover:bg-[var(--BG-50)]'
                            onClick={() => {
                              setIsApplyCoupon(false);
                            }}
                          />
                        </div>
                        <div
                          className={cn(
                            'w-full h-9 bg-white border-[1.5px] rounded-md flex items-center justify-between gap-2.5 px-2.5 mt-2.5',
                            couponCodeRes === 'success'
                              ? 'border-[var(--BrandLink)]'
                              : couponCodeRes === 'error'
                              ? 'border-[var(--fontRed)]'
                              : 'border-[#E8E8EA]'
                          )}
                        >
                          <input
                            type='text'
                            name='coupon'
                            id='coupon'
                            value={couponCode}
                            placeholder='Enter coupon code'
                            className='w-full '
                            onChange={(e) => {
                              setCouponCode(e.target.value);
                            }}
                          />
                          <div className='flex items-center justify-center'>
                            {couponCodeRes === 'success' ? (
                              <div>s</div>
                            ) : couponCodeRes === 'error' ? (
                              <div>e</div>
                            ) : (
                              <button
                                className={cn(
                                  'text-[var(--BrandLink)] texty-sm weight-semibold',
                                  couponCode
                                    ? 'cursor-pointer'
                                    : 'cursor-not-allowed'
                                )}
                                onClick={() => {
                                  if (couponCode) {
                                  }
                                }}
                              >
                                Apply
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className='w-fit text-sm weight-semibold text-[var(--BrandLink)] cursor-pointer flex items-center px-2.5 py-1.5 rounded-lg hover:bg-[#ECE8FA]'
                        onClick={() => {
                          setIsApplyCoupon(true);
                        }}
                      >
                        <p>Add a coupon code</p>
                      </div>
                    )}
                  </div>
                  <div className='min-h-9 h-auto'>
                    <div className='flex items-center justify-between gap-2.5 text-base'>
                      <h5 className='text-base weight-medium'>
                        Discounts & Offers
                      </h5>
                      <p className='weight-bold'>$ 0.00</p>
                    </div>
                  </div>
                </div>
                <div className='py-10'>
                  <div className='min-h-9 h-auto flex flex-col gap-1.5'>
                    <div className='flex items-center justify-between gap-2.5 text-base'>
                      <h5 className='weight-semibold'>Sub Total</h5>
                      <p className='weight-bold'>₹2500.00</p>
                    </div>
                    <p className='text-sm weight-medium text-[var(--font-400)]'>
                      Inclusive of Taxes
                    </p>
                  </div>
                </div>
                <button className='w-full h-12 rounded-md bg-[var(--primary)] text-white text-base weight-bold mt-9'>
                  Proceed to Checkout
                </button>
              </div>
            </div>
          ) : (
            <>
              <div className='flex flex-col items-center justify-center pb-[2vw] relative'>
                <div className='w-fit relative'>
                  <div className='flex flex-col items-center'>
                    <div className='w-fit flex items-center justify-center px-[1vw] py-[0.6vw] bg-[#F1F4EE] rounded-[2vw]'>
                      <Planscard id={1} plan={'Monthly'} />
                      {/* <Planscard id={2} plan={'Quarterly'} /> */}
                      <Planscard id={3} plan={'Annual'} />
                    </div>
                    <p className='mt-[0.8vw] font-large weight-bold text-[#6940F2]'>
                      🎉 Get 2 months free with annual subscription
                    </p>
                  </div>
                  <div className='absolute top-[1vw] -right-[2.1vw]'>
                    <img
                      src={ICONS?.PricingArrow}
                      alt='arrow'
                      className='size-[3.7vw] '
                    />
                  </div>
                </div>
              </div>
              <div className='w-full h-fit flex justify-between gap-[1vw]'>
                {pricing?.map((p) => {
                  return (
                    <div
                      className={`min-w-[20vw] rounded-[1.5vw] p-[0.25vw] ${
                        p.popular
                          ? // 'shadow-[0px_4px_26px_0px_#CAC5FF] bg-gradient-to-r from-[var(--primary)] to-[#6940F2]'
                            'shadow-[0px_4px_26px_0px_#CAC5FF] bg-[#6940F2]'
                          : 'shadow-[0px_4px_16px_0px_#2D303630] bg-white'
                      }`}
                    >
                      <div className='w-auto h-full bg-[var(--white)] rounded-[1.3vw] px-[1vw] py-[1.2vw]'>
                        <div className='flex items-center justify-between pb-[1vw]'>
                          <img
                            src={p.icon}
                            alt={p.title}
                            className='size-[3vw]'
                          />
                          {p.popular && (
                            <div className='px-[1vw] py-[0.25vw] bg-[#EDAB4033] rounded-[2vw] flex items-center gap-[0.4vw]'>
                              <img
                                src={ICONS?.MostPopular}
                                alt='most popular'
                              />
                              <p className='font-md weight-bold text-[#EDAB40]'>
                                Most Popular
                              </p>
                            </div>
                          )}
                        </div>
                        <h3
                          className={`text-[1.5vw] weight-bold text-[#202421] mt-[0.2vw]`}
                        >
                          {p.title}
                        </h3>
                        <div className='mt-[0.7vw]'>
                          <span className='text-[2vw] weight-semibold text-[#202421]'>
                            {p?.custom
                              ? 'Custom'
                              : p?.[selectedPrice?.toLowerCase()]?.price}
                          </span>
                          {!p?.custom && (
                            <span className='font-normal weight-medium text-[#616874]'>
                              /
                              {selectedPrice === 'Monthly' ? 'Month' : 'Yearly'}
                            </span>
                          )}
                        </div>
                        <div className='font-normal weight-medium text-[#616874] pb-[1.5vw] border-b-[0.08vw] border-[var(--border-100)]'>
                          {!p?.custom
                            ? selectedPrice === 'Monthly'
                              ? `${
                                  p?.[selectedPrice?.toLowerCase()]?.yearly
                                } / Billed Yearly`
                              : `*${
                                  p?.[selectedPrice?.toLowerCase()]?.yearly
                                } Months Free`
                            : 'Billed Monthly / Yearly'}
                        </div>

                        <p className='font-normal weight-small text-[#616874] mt-[2.5vw] leading-[1.4vw]'>
                          {p?.content}
                        </p>
                        <button
                          className={`w-full h-[2.5vw] flex items-center justify-center gap-[1vw] bg-[var(--primary)] font-normal weight-semibold text-[var(--white)] mt-[1.5vw] rounded-[0.5vw] tracking-[0.02vw] ${
                            p?.btnText !== 'Contact Sales'
                              ? 'cursor-pointer'
                              : 'cursor-default'
                          }`}
                          onClick={() => {
                            if (p?.btnText !== 'Contact Sales') {
                              setIsPlanSelected(true);
                              setSelectedPlan(p);
                            }
                          }}
                        >
                          <p>{p?.btnText}</p>
                          <div className='rotate-180'>
                            <Arrow color='#ffffff' />
                          </div>
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className='flex flex-col items-center justify-center mt-[2vw]'>
                <a
                  href='https://gainwix.ai/pricing'
                  target='_blank'
                  className='px-[0.8vw] py-[0.4vw] border-[0.08vw] border-[var(--border-50)] font-md weight-medium rounded-[0.3vw] !text-[var(--font-600)]'
                >
                  Compare Plans
                </a>
                <p className='font-md weight-medium text-center py-[1vw]'>
                  You'll be billed once a year for the entire year's access.
                  This is a non-refundable plan, so please be sure it meets your
                  needs before
                  <br />
                  subscribing. Taxes may apply depending on your location. For
                  more information, please go to{' '}
                  <a
                    href='https://gainwix.ai/pricing'
                    target='_blank'
                    className='!text-[#6940F2]'
                  >
                    https://gianwix.ai/pricing
                  </a>
                </p>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default UsagePlan;
