import React, { useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

// hooks
import useToggle from "../../../../hooks/useToggle";

// assets
import FullArrow from '../../../../assets/customSVG/FullArrow';

// redux
import { useAspDispatch } from '../../../../test/jest-redux-hooks';
import { UpdateOnboardingAccountId } from '../../../../reduxToolkit/appSlice';

// components
import SearchBar from '../../../../components/commonComponents/searchBar/index';
import OnBoardingModal from '../../components/OnBoardingModal';

export default function Overview() {
  const dispatch = useAspDispatch();

  const { account_id, brand_id } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [searchkey, setSearchkey] = useState('');
  const [openModal, setOpenModal] = useToggle(false);
  const [load, setLoad] = useToggle(false);
  const [type, setType] = useState('');

  const clearSearch = () => setSearchkey('');

  const loadData = () => setLoad(true);

  // based on route to show content
  const validGoBackPaths = [
    `/profile/account/overview/${account_id}`,
    `/profile/account/overview/${account_id}/edit`,
    `/profile/account/overview/${account_id}/${brand_id}/edit`,
  ];

  const settings = [
    '/profile/account/overview/1',
    '/profile/account/overview/1/edit',
  ];

  const backToAccountListRoutes = [
    `/profile/account/overview/${account_id}`,
    `/profile/account/overview/${account_id}/edit`,
  ];

  const backToAccountRoutes = [
    `/profile/account/overview/${account_id}/${brand_id}/edit`,
  ];

  const showSearchbar = [
    `/profile/account/overview`,
    `/profile/account/overview/${account_id}`,
  ];

  return (
    <div className="w-full h-full">
      <div className="w-full h-14 px-5 flex items-center justify-between bg-white">
        <div className="flex items-center gap-1">
          {validGoBackPaths.includes(pathname) && (
            <div
              className="w-fit h-9 px-2.5 flex items-center justify-center cursor-pointer rounded-md hover:bg-[var(--BG-50)] rotate-180"
              onClick={() => {
                if (backToAccountListRoutes.includes(pathname))
                  navigate('/profile/account/overview');
                if (backToAccountRoutes.includes(pathname))
                  navigate(`/profile/account/overview/${account_id}`);
              }}
              data-testid="navigate-back"
            >
              <FullArrow width={18} height={18} colior={"var(--BG-100)"} />
            </div>
          )}
          {showSearchbar.includes(pathname) ? (
            <SearchBar
              searchkey={searchkey}
              setSearchkey={setSearchkey}
              onChange={(value) => {
                setSearchkey(value);
              }}
            />
          ) : [`/profile/account/overview/${account_id}/edit`].includes(
              pathname
            ) ? (
            <div>Account Settings</div>
          ) : (
            [
              `/profile/account/overview/${account_id}/${brand_id}/edit`,
            ].includes(pathname) && <div>Brand Settings</div>
          )}
        </div>
        <div>
          {[`/profile/account/overview/${account_id}`]?.includes(pathname) ? (
            <button
              className='px-5 py-1.5 rounded-lg bg-[var(--primary)] text-white font-md weight-medium'
              onClick={() => {
                setType('brand');
                setOpenModal(true);
                dispatch(UpdateOnboardingAccountId({ acc_id: account_id }));
              }}
            >
              Add Brand
            </button>
          ) : (
            [`/profile/account/overview`]?.includes(pathname) && (
              <button
                className="px-5 py-1.5 rounded-lg bg-[var(--primary)] text-white font-md weight-medium"
                onClick={() => {
                  setType('account');
                  setOpenModal(true);
                }}
              >
                Add Account
              </button>
            )
          )}
        </div>
      </div>
      <Outlet context={[searchkey, setSearchkey, clearSearch, load, setLoad]} />
      <OnBoardingModal
        creation_type={type}
        open={openModal}
        setOpen={setOpenModal}
        loadData={loadData}
      />
    </div>
  );
}
