import React from "react";
import "../../../styles/Broadcast.css";

import { AudienceList } from "../../../../../constant/app/campaign/Broadcast";
import { ICONS } from "../../../../../assets/icons";
import IndividualView from "./IndividualView";

const BroadcastSegmentView = ({ segment, response }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear().toString().substr(-2);
    return `${day}/${month}/${year}`;
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const meridiem = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return `${formattedHours}:${formattedMinutes} ${meridiem}`;
  };
  const segmentMode = segment?.settings;
  const send = segment?.settings?.send_setting;

  const segmentView = segment?.settings?.segment_settings;
  return (
    <div className="Broadcast-segments ">
      <div className="BroadcastSegments-container">
        <div>
          <p>Define Audience Segments</p>
          <span>Select how you would like to segment the audience(s)</span>
        </div>
        <div className="SendSetting-container flex-column ">
          <div className=" flex-row mb-6">
            {AudienceList?.map((data) => {
              if (
                (segmentMode?.segment_mode === "individual" &&
                  data?.name === "Use as Individual File") ||
                (segmentMode?.segment_mode === "merge" &&
                  data?.name === "Merge Files")
              ) {
                return (
                  <React.Fragment key={data?.id}>
                    <div
                      className="flex-row align-center"
                      style={{
                        padding: "10px 10px 24px 20px",
                        borderBottom: "1px solid var(--font-50)",
                      }}
                    >
                      <div className="flex-row align-center pointer">
                        <label className="checkBoxContainer">
                          <input
                            type="checkbox"
                            name="checkbox"
                            checked={
                              (segmentMode?.segment_mode === "individual" &&
                                data?.name === "Use as Individual File") ||
                              (segmentMode?.segment_mode === "merge" &&
                                data?.name === "Merge Files")
                            }
                            readOnly
                          />
                        </label>
                        <div className="setting-setting flex-column">
                          <span>{data?.name}</span>
                          <p>{data?.content}</p>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              } else {
                return null;
              }
            })}
          </div>
          <div className="flex-column pl-10">
            {segmentMode?.segment_mode === "merge" && (
              <>
                {segmentView?.map((data, index) => {
                  const segments = data?.segments;
                  return (
                    <React.Fragment key={data?.id || index}>
                      {segments?.map((segment, index) => {
                        return (
                          <div className="" key={segment?.id}>
                            {segment?.id > 1 && (
                              <>
                                {send === "send_now" ? (
                                  <div className="flex-column">
                                    <div className="flex-column">
                                      <div className=" Delay-container flex-row align-center   ">
                                        <img
                                          src={ICONS?.delayIcon}
                                          alt="delay"
                                          className="delay-image"
                                        />
                                        <p>Delay</p>
                                      </div>
                                      <div
                                        className="Delay-wrapper flex-row align-center  pl-5 gap-5 ml-10"
                                        style={{
                                          borderLeft:
                                            "1px solid var(--font-200)",
                                          margin: "4px",
                                        }}
                                      >
                                        <span>Wait for</span>
                                        <div
                                          className="flex-row align-center flex-end"
                                          style={{
                                            border: "1px solid var(--font-50)",
                                            padding: "10px",
                                          }}
                                        >
                                          <div className="flex-row flex-end align-center space-between ">
                                            <span className="no-spinner">
                                              {segment.delay}
                                            </span>
                                          </div>
                                        </div>

                                        <div
                                          style={{
                                            height: "4.5vh",
                                            width: "12vw",
                                            border: "1px solid var(--font-50)",
                                            borderRadius: "6px",
                                            fontSize: "0.75vw",
                                            fontWeight: "500",
                                            color: "var(--font-600)",
                                          }}
                                        >
                                          Minutes
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="Calendar-container flex-row w-100 space-between align-center">
                                    <div className="aaaa  w-70 flex-row align-center">
                                      <img
                                        src={ICONS?.delayIcon}
                                        alt="delay"
                                        className="delay-image"
                                      />
                                      <span className="send_later pl-5">
                                        Scheduled on
                                      </span>

                                      <div className="ml-10">
                                        <div className="flex-row align-center pointer">
                                          <input
                                            iconPrefix={
                                              ICONS?.BroadcastCalendarIcon
                                            }
                                            defaultValue={formatDate(
                                              segment?.scheduled_at
                                            )}
                                            className="date-time"
                                          />
                                        </div>
                                      </div>
                                      <div className="broadcast-calendar  flex-row align-center">
                                        <div className="At p-5">@</div>

                                        <div className="flex-row align-center pointer ml-5">
                                          <input
                                            className="date-time"
                                            value={formatTime(
                                              segment?.scheduled_at
                                            )}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                            <div className=" Delay-container flex-row align-center w-100 space-between ">
                              <div className="flex-row align-center ">
                                <img
                                  src={ICONS?.BroadcastSegmentIcon}
                                  alt=""
                                  className="delay-image"
                                />
                                <span>Segment - {index + 1}</span>
                              </div>
                            </div>
                            <div
                              className="Delay-wrapper w-100  flex-row align-center  "
                              style={{
                                borderLeft: "1px solid var(--font-200)",
                                paddingLeft: "20px",
                                margin: "5px",
                              }}
                            >
                              <div className="segment-containers flex-row align-center pt-20">
                                <span className="detail">Audience Count</span>

                                <div className="increment w-100 flex-row align-center">
                                  <div
                                    style={{
                                      border: "1px solid var(--font-50)",
                                      padding: "10px",
                                    }}
                                    className="w-80"
                                  >
                                    <div
                                      className="w-100 space-between flex-row align-center"
                                      style={{
                                        fontSize: "0.75vw",
                                        fontWeight: "600",
                                        color: "var(--textBlack)",
                                      }}
                                    >
                                      <span className="w-100">
                                        {segment?.end}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </>
            )}
            {segmentMode?.segment_mode === "individual" && (
              <IndividualView response={response} segment={segment} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BroadcastSegmentView;
