import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

// api
import { accountList } from "../../api/Api";

// hooks
import useToggle from "../../../../hooks/useToggle";

// components
import AccountCard from "../../components/AccountCard";
import Loader from "../../../../components/commonComponents/Loader/Index";

export default function AccountsList() {
  const [searchKey, clearSearch, load, setLoad] = useOutletContext();
  const [account, setAccount] = useState([]);
  const [isAccount, setIsAccount] = useToggle(false);

  useEffect(() => {
    fetchAccountList(null);
  }, [load]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchAccountList(searchKey);
    }, [500]);

    return () => clearTimeout(timeout);
  }, [searchKey]);

  const fetchAccountList = async (searchKey) => {
    setIsAccount(true);
    try {
      const res = await accountList({ search: searchKey });
      setAccount(res?.data);
      if (setLoad) setLoad(false);
    } catch (error) {
      console.warn(error);
    } finally {
      setIsAccount(false);
    }
  };

  return (
    <div className="w-full h-full px-5 py-5">
      <h2 className="text-2xl weight-bold text-[var(--contentText)] mb-1">
        Accounts
      </h2>
      {!isAccount ? (
        <div className="w-full max-h-[80vh] grid grid-cols-4 gap-5 pt-6 pb-6 overflow-y-scroll max-[1600px]:grid-cols-3 max-[1600px]:pb-12 max-[1240px]:grid-cols-2 max-[1240px]:pb-12 max-[1440px]:pb-12 min-[1930px]:grid-cols-5">
          {account?.accounts_list?.length > 0 &&
            account?.accounts_list?.map((d) => {
              return (
                <div key={d.id} className="max-w-96 h-full">
                  <AccountCard
                    name={d.acc_name}
                    image={d.acc_image_url}
                    id={d.acc_id}
                    role={d.role_name}
                    metrics={d.accounts_metrics}
                    clearSearch={clearSearch}
                    loadData={() => {
                      fetchAccountList(null);
                    }}
                  />
                </div>
              );
            })}
        </div>
      ) : (
        <div className="w-full h-16 bg-white rounded-md flex flex-1 justify-center items-center p-4 mt-3.5">
          <Loader Width={35} Height={35} />
        </div>
      )}
      {!isAccount && (!account || account?.accounts_list?.length === 0) && (
        <div className="w-full h-28 bg-white rounded-md flex flex-1 justify-center items-center p-4 mt-3.5">
          <p>No Data</p>
        </div>
      )}
    </div>
  );
}
