import React, { Children, useEffect, useState } from "react";

// assets
import Arrow from "assets/customSVG/Arrow";
import Calender from "assets/customSVG/Calender";

// utils
import { Popover } from "antd";
import moment from "moment";

// hooks
import useRangePicker from "hooks/calenders/useRangePicker";
import useToggle from "hooks/useToggle";

// helper
import { cn } from "helper/cn";
import { dateConverter } from "helper/dateConverter";
import { ICONS } from "../../../assets/icons";
import Select from "../select";

const rangeTypes = [
  {
    id: 1,
    label: "Today",
    value: "today",
  },
  { id: 2, label: "Yesterday", value: "yesterday" },
  { id: 3, label: "Last 7 days", value: "lastWeek" },
  { id: 4, label: "Last 14 days", value: "last14days" },
  { id: 5, label: "Last 28 days", value: "last28days" },
  { id: 6, label: "This Month", value: "thisMonth" },
  { id: 7, label: "Previous Quarter", value: "prevQuarter" },
  { id: 8, label: "This Quarter", value: "thisQuarter" },
  {
    id: 9,
    label: "Custom",
    value: "today",
  },
];

const getYears = (start) => {
  const currentYear = new Date().getFullYear();
  let years = [];

  for (let i = start; i <= currentYear; i++) {
    years.push({ label: i.toString(), value: i.toString() });
  }

  return years.toReversed();
};

export default function MonthCalender({
  startDate,
  endDate,
  onChange,
  onUpdate,
  primary,
  customRanges = primary,
  children,
  footer = primary,
  allowToChangeYear = false,
}) {
  const [openRangePicker, setOpenrangePicker] = useToggle(false);
  const [showYear, setShowYear] = useToggle(false);
  const [selectedYear, setSelectedYear] = useState(null);
  const [reset, setReset] = useToggle(true);
  const [selectRangeType, setSelectRangeType] = useState({
    prev: "Last 7 days",
    current: "Last 7 days",
  });

  const [selectedRange, setSelectedRange] = useState({
    start: null,
    end: null,
  });

  const {
    today,
    timeZone,
    current,
    months,
    days,
    getCustomranges,
    getPrevMonth,
    getNextMonth,
    resetToCurrentMonth,
  } = useRangePicker();

  useEffect(() => {
    if (openRangePicker) {
      resetToCurrentMonth(startDate);
      setSelectRangeType({
        ...selectRangeType,
        current: selectRangeType?.prev,
      });
    }

    const year = new Date(startDate)?.getFullYear()?.toString();
    if (year) setSelectedYear(year);
    else setSelectedYear(new Date()?.getFullYear()?.toString());
  }, [openRangePicker]);

  const values = getCustomranges();

  const currentMonth = dateConverter(current);
  const selectedStart = dateConverter(selectedRange?.start);
  const selectedEnd = dateConverter(selectedRange?.end);


  const monthDatesList = (
    <div className="w-full flex gap-2.5 p-2.5 bg-white shadow-[0px_4px_30px_0px_#2D30361A] select-none">
      {customRanges && (
        <div className="w-36 flex flex-col justify-center gap-1.5 border-r pr-2.5">
          {rangeTypes?.map((r, i) => {
            return (
              <div
                key={r.label}
                className={cn(
                  "w-full h-7 flex justify-between items-center text-xs weight-medium cursor-pointer pl-2.5 pr-1 py-1 rounded-md hover:bg-[var(--BG-50)]"
                )}
                onClick={() => {
                  if (onChange) onChange(values[r.value]);
                  resetToCurrentMonth(r.value.start);
                  if (r.label !== "Custom") {
                    setReset(true);
                    setSelectedRange({ start: null, end: null });
                    setOpenrangePicker(false);
                    setSelectRangeType({
                      ...selectRangeType,
                      prev: r.label,
                    });
                  }
                }}
              >
                <div>{r.label}</div>
                {selectRangeType.current === r.label && (
                  <img
                    src={ICONS?.Selected}
                    alt="selected"
                    className="size-4"
                  />
                )}
              </div>
            );
          })}
        </div>
      )}
      <div className="w-72 flex flex-col gap-2.5 pl-5">
        <div className="w-full flex items-center justify-between text-base weight-semibold text-[var(--contentText)] pb-10 border-b border-[#F0F1F2]">
          <div className="">Select Date</div>
          <div className="flex items-center gap-2">
            <div
              className="size-6 flex items-center justify-center rounded-md hover:bg-[var(--primaryLight)] cursor-pointer group"
              onClick={() => {
                getPrevMonth();
              }}
              data-testid="Pre-month"
            >
              <Arrow
                width={12}
                height={12}
                color=""
                className={
                  "cursor-pointer stroke-[var(--font-600)] group-hover:stroke-[var(--primary)] transition-colors ease-linear duration-300"
                }
              />
            </div>
            {allowToChangeYear ? (
              <div
                onClick={() => {
                  setShowYear(true);
                }}
              >
                {/* hi */}
                {currentMonth?.formatedDateWithoutDate}
                <Select
                  value={selectedYear}
                  options={getYears(1900) || []}
                  onChange={(value) => {
                    setSelectedYear(value?.value);
                    // const start = moment(selectedRange?.start);
                    // const date = `${start.date()}-${start.month()}-${selectedYear}`;
                    // const updateDate = moment(date);
                    // console.log("moment(date)", moment(date), date);
                    // setSelectRangeType({
                    //   ...selectRangeType,
                    //   start: moment(date),
                    // });

                    // console.log(
                    //   " `${start.date()}-${start.month()}-${selectedYear}`",
                    //   `${start.date()}-${start.month()}-${value?.value}`
                    // );
                  }}
                  rootClassName="border-none hover:border"
                />
              </div>
            ) : (
              <div className="w-[84px] flex flex-1 items-center justify-center">
                {currentMonth?.formatedDateWithoutDate}
              </div>
            )}
            <div
              className="size-6 flex items-center justify-center rounded-md hover:bg-[var(--primaryLight)] cursor-pointer group rotate-180"
              onClick={() => {
                getNextMonth();
              }}
              data-testid="Next-month"
            >
              <Arrow
                width={12}
                height={12}
                color=""
                className={
                  "cursor-pointer stroke-[var(--font-600)] group-hover:stroke-[var(--primary)] transition-colors ease-linear duration-300"
                }
              />
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col gap-2.5 text-xs">
          <div className="w-full flex items-center weight-semibold text-[var(--font-600)]">
            {days.map((d, i) => {
              return (
                <div
                  key={i}
                  className="w-10 flex flex-1 items-center justify-center"
                >
                  {d}
                </div>
              );
            })}
          </div>
          <div className="w-full flex flex-wrap items-center weight-semibold">
            {months().map((m, i) => {
              const { date, currentMonth, disabled } = m;
              const currentDate = today.format("YYYY-MM-DD");
              const dates = moment(date);
              const d = moment(date).format("YYYY-MM-DD");
              const start =
                startDate && !selectedRange?.start
                  ? moment(startDate).format("YYYY-MM-DD")
                  : moment(selectedRange?.start).format("YYYY-MM-DD");
              const end =
                endDate && !selectedRange?.end
                  ? moment(endDate).format("YYYY-MM-DD")
                  : moment(selectedRange?.end).format("YYYY-MM-DD");
              return (
                <div
                  key={i}
                  className={cn(
                    `w-[40px] h-8 flex items-center justify-center relative`
                  )}
                >
                  <div
                    className={cn(
                      `w-7 h-6 flex items-center justify-center cursor-pointer py-0.5`,
                      currentMonth
                        ? "text-[var(--contentText)]"
                        : "text-[var(--font-100)]",
                      !primary
                        ? (!start || start === d) && currentDate === d
                          ? "w-6 h-5 bg-[var(--primary)] text-[var(--white)] rounded-[40px]"
                          : currentDate === d
                          ? "w-6 h-5 text-[var(--primary)]"
                          : d === start
                          ? "w-[40px] h-7 bg-[var(--primary)] text-[var(--white)] rounded-[40px]"
                          : !disabled
                          ? "hover:bg-[var(--primaryLight)] hover:text-[var(--primary)] rounded-full"
                          : "text-[var(--font-100)] cursor-not-allowed"
                        : primary
                        ? start && end
                          ? (!start || start === d || end === d) &&
                            currentDate === d &&
                            primary
                            ? "w-6 h-5 bg-[var(--primary)] text-[var(--white)] rounded-[40px]"
                            : currentDate === d && primary
                            ? "w-6 h-5 text-[var(--primary)]"
                            : (d === start || d === end) && primary
                            ? "w-[40px] h-7 bg-[var(--primary)] text-[var(--white)] rounded-[40px]"
                            : start < d && end > d && primary
                            ? "w-full h-4 bg-[var(--primaryLight)]"
                            : !disabled
                            ? "hover:bg-[var(--primaryLight)] hover:text-[var(--primary)] rounded-full"
                            : "text-[var(--font-100)] cursor-not-allowed"
                          : !disabled
                          ? "hover:bg-[var(--primaryLight)] hover:text-[var(--primary)] rounded-full"
                          : "text-[var(--font-100)] cursor-not-allowed"
                        : ""
                    )}
                    onClick={() => {
                      if (!disabled) {
                        // const start = moment(selectedRange?.start);
                        // const date = `${start.date()}-${start.month()}-${selectedYear}`;
                        // const updateDate = moment(date);
                        // console.log("moment(date)", moment(date), date);
                        // setSelectRangeType({
                        //   ...selectRangeType,
                        //   start: moment(date),
                        // });
                        setSelectRangeType({
                          ...selectRangeType,
                          current: "Custom",
                        });
                        if (primary) {
                          if (reset) {
                            setReset(false);
                            setSelectedRange({ start: date, end: date });
                          }
                          if (!reset && (!start || end)) {
                            setReset(false);
                            setSelectedRange({ start: date, end: date });
                          }
                          if (!reset && start && end && start === end) {
                            setReset(true);
                            if (start > d)
                              setSelectedRange({
                                ...selectedRange,
                                start: date,
                              });
                            else
                              setSelectedRange({ ...selectedRange, end: date });
                          }
                        } else {
                          if (onUpdate) onUpdate(date);
                          setOpenrangePicker(false);
                          setSelectedRange({ start: date, end: date });
                        }

                      }
                    }}
                    data-testid={`DateClick-${d}`}
                  >
                    {primary && start !== end && start === d && (
                      <span className='w-3 h-4 contents-[""] absolute top-[25%] right-0 bg-[var(--primaryLight)]' />
                    )}
                    {dates?.date()}
                    {primary && start !== end && end === d && (
                      <span className='w-3 h-4 contents-[""] absolute top-[25%] left-0 bg-[var(--primaryLight)]' />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          {footer && (
            <div className="flex items-center justify-end gap-2.5 mt-10 text-xs weight-medium">
              <button
                className="px-4 py-1.5 weight-medium rounded-md hover:bg-[var(--BG-50)] text-[var(--contentText)] border border-[var(--border-100)] hover:border-[var(--BG-50)]"
                // onClick={() => {
                //   setOpenrangePicker(false);
                //   setReset(true);
                //   setSelectedRange({ start: null, end: null });
                //   setSelectRangeType({
                //     ...selectRangeType,
                //     prev: selectRangeType?.prev,
                //   });
                // }}
                onClick={() => {
                  if (selectedRange?.start) {
                    if (primary) {
                      if (onInputChange) onInputChange(selectedRange);
                    } else {
                      if (onInputChange)
                        onInputChange({
                          start: selectedRange.start,
                          end: selectedRange.start,
                        });
                    }
                    setOpenrangePicker(false);
                    setSelectedRange({ start: null, end: null });
                    setSelectRangeType({
                      ...selectRangeType,
                      prev: selectRangeType?.current,
                    });
                  }
                }}
              >
                Cancel
              </button>
              <button
                className={cn(
                  "px-4 py-1.5 rounded-md border",
                  selectedRange.start
                    ? "bg-[var(--primary)] border-[var(--primary)] text-[var(--white)] cursor-pointer"
                    : "bg-[var(--BG-100)] border-[var(--BG-100)] text-[var(--font-600)] cursor-not-allowed"
                )}
                onClick={() => {
                  if (selectedRange?.start) {
                    if (primary) onChange(selectedRange);
                    else onChange(selectedRange?.start);
                    setOpenrangePicker(false);
                    setSelectedRange({ start: null, end: null });
                    setSelectRangeType({
                      ...selectRangeType,
                      prev: selectRangeType?.current,
                    });
                  }
                }}
              >
                Update
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
  return (
    <Popover
      trigger={["click"]}
      placement="bottomLeft"
      content={monthDatesList}
      arrow={false}
      open={openRangePicker}
      onOpenChange={(open) => {
        if (!primary) {
          setOpenrangePicker(false);
        }
      }}
    >
      {children ? (
        <div
          onClick={() => {
            setOpenrangePicker(true);
          }}
          data-testid="calendar"
        >
          {Children.only(children)}
        </div>
      ) : (
        <div
          className="w-fit px-5 py-2 flex items-center justify-center gap-2.5 rounded-md bg-[#F4F6F8] text-sm weight-semibold text-[#616874] cursor-pointer"
          onClick={() => {
            setOpenrangePicker(true);
          }}
        >
          <Calender width={15} height={15} strokeWidth={2} />
          <div className="flex items-center justify-center gap-5 rounded-md bg-[#F4F6F8] text-sm weight-semibold text-[#616874]">
            <div>
              {startDate && !selectedRange?.start
                ? moment(startDate)?.format("MMM DD, YYYY")
                : selectedRange?.start
                ? selectedStart?.formatedDate
                : today.format("MMM DD, YYYY")}
            </div>
            {((!selectedRange?.start &&
              !selectedRange?.start &&
              moment(startDate).format("MMM DD, YYYY") !==
                moment(endDate).format("MMM DD, YYYY")) ||
              moment(selectedRange?.start).format("MMM DD, YYYY") !==
                moment(selectedRange?.end).format("MMM DD, YYYY")) && (
              <>
                <div className="weight-semibold">-</div>
                <div>
                  {endDate && !selectedRange?.end
                    ? moment(endDate)?.format("MMM DD, YYYY")
                    : selectedRange?.end
                    ? selectedEnd?.formatedDate
                    : today.format("MMM DD, YYYY")}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </Popover>
  );
}
