import React from 'react';

const Contact = ({
  width = '16',
  height = '16',
  color = '#2D3036',
  strokeWidth = '1.5',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M14.7616 13.2838C14.7616 13.2838 13.8573 14.172 13.6357 14.4324C13.2747 14.8176 12.8494 14.9995 12.2918 14.9995C12.2382 14.9995 12.181 14.9995 12.1274 14.9959C11.0659 14.9282 10.0794 14.5144 9.33952 14.1613C7.31653 13.184 5.54017 11.7965 4.06403 10.038C2.84523 8.572 2.03032 7.21658 1.49062 5.7613C1.15822 4.87314 1.0367 4.18117 1.09031 3.52843C1.12605 3.1111 1.28689 2.76512 1.58355 2.46907L2.80234 1.25276C2.97748 1.08868 3.16333 0.999512 3.34562 0.999512C3.57079 0.999512 3.75307 1.13505 3.86745 1.24919C3.87102 1.25276 3.8746 1.25633 3.87817 1.25989C4.0962 1.46321 4.3035 1.67365 4.52152 1.89837C4.63232 2.01251 4.7467 2.12665 4.86107 2.24435L5.83682 3.21811C6.21569 3.5962 6.21569 3.94575 5.83682 4.32384C5.73317 4.42728 5.63309 4.53072 5.52944 4.63059C5.22921 4.93735 5.46506 4.70198 5.15411 4.98019C5.14696 4.98733 5.13981 4.9909 5.13624 4.99803C4.82886 5.30478 4.88604 5.6044 4.95038 5.80771C4.95395 5.81841 4.95753 5.82911 4.9611 5.83981C5.21487 6.45332 5.57229 7.03115 6.11556 7.71956L6.11914 7.72312C7.10561 8.93586 8.1457 9.88109 9.29301 10.6052C9.43955 10.6979 9.58967 10.7728 9.73263 10.8441C9.8613 10.9083 9.98283 10.969 10.0865 11.0332C10.1008 11.0403 10.1151 11.051 10.1294 11.0582C10.2509 11.1188 10.3653 11.1473 10.4832 11.1473C10.7799 11.1473 10.9657 10.9618 11.0265 10.9012L11.7271 10.2021C11.8486 10.0808 12.0416 9.93454 12.2668 9.93454C12.4884 9.93454 12.6707 10.0737 12.7815 10.1949C12.785 10.1985 12.785 10.1985 12.7886 10.2021L14.758 12.1674C15.1261 12.5312 14.7616 13.2838 14.7616 13.2838Z'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Contact;
