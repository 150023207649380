import React from "react";
import Close from "../../../../../assets/customSVG/Close";
import Label from "../../commonComponents/label";
import FilterOperator from "./FilterOperator";
import {
  handleDeleteFilter,
  updateFilterOperator,
} from "../../../../../reduxToolkit/crmSlice";
import { useDispatch } from "react-redux";
import AddIcon from "../../../../../assets/customSVG/AddIcon";
import { hexToRgba } from "../../../helpers";

const RenderFilters = ({
  filters,
  operator,
  setOperator = () => {},
  handleAddNewFilter = () => {},
}) => {
  const dispatch = useDispatch();

  const handleModalState = (operator = "and") => {
    setOperator((prev) => {
      return { operator, isModalOpen: !prev.isModalOpen };
    });
  };
  return (
    <div className="flex gap-[.5vw] flex-wrap duration-200 max-h-[9vh] overflow-y-scroll scroll_visible">
      {filters?.map((filter) => {
        const { selectedFilter, selectedField, fieldValue } = filter?.value;
        return (
          <div className="flex items-center" key={filter.id}>
            <div className="bg-gray-100 rounded-md ps-[.8vw] pe-[.4vw] py-[.2vw]- text-[.8vw] text-[#616874] flex gap-[.2vw] items-center">
              <div className="flex items-center gap-[.4vw]">
                <span className="capitalize font-[500]">{selectedFilter}</span>{" "}
                <span>{selectedField}</span>{" "}
                {Array.isArray(fieldValue) ? (
                  <div className="flex gap-[.4vw]">
                    {fieldValue.map((item) => {
                      return (
                        <Label
                          id={item.id}
                          key={item.id}
                          // labelClassNames={"text-gray-500"}
                          mainClassNames={"bg-white"}
                          color={item?.display_color}
                          style={{
                            backgroundColor: hexToRgba(
                              item?.display_color,
                              0.2
                            ),
                          }}
                          label={item?.tag}
                          closeColor="gray"
                          showClose={fieldValue?.length > 1}
                          closeAction={(_, label_id, e) => {
                            dispatch(
                              handleDeleteFilter({
                                id: filter.id,
                                remove_label: true,
                                label_id,
                              })
                            );
                          }}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <span>{fieldValue}</span>
                )}
              </div>
              <span
                className="cursor-pointer"
                onClick={() => {
                  dispatch(handleDeleteFilter({ id: filter.id }));
                }}
              >
                <Close
                  width="30"
                  height="30"
                  strokeWidth="1.6"
                  stroke="#616874"
                  circle={false}
                />
              </span>
            </div>

            <FilterOperator
              onClick={(e, ops) => {
                const { id } = e.target.dataset;
                dispatch(updateFilterOperator({ id, operator: ops }));
              }}
              id={filter.id}
              selected={filter.operator}
            >
              <span
                className={`bg-gray-100 duration-200 capitalize rounded-md  overflow-hidden cursor-pointer text-[.8vw] flex items-center font-[500] ${
                  filter.operator ? "px-[.5vw] h-full ms-[.4vw]" : ""
                }`}
              >
                {filter.operator}
              </span>
            </FilterOperator>
          </div>
        );
      })}
      <FilterOperator
        open={operator.isModalOpen}
        onOpenChange={() => handleModalState()}
        onClick={(_, ops) => {
          // handleClick();
          handleAddNewFilter();
          handleModalState(ops);
        }}
      >
        <span
          className={`bg-gray-100 rounded-md cursor-pointer duration-200  overflow-hidden ${
            filters.length ? "scale-100 h-full" : "scale-0 h-0"
          }`}
          data-testid="AddClick"
        >
          <AddIcon stroke="#616874" strokeWidth={1.4} />
        </span>
      </FilterOperator>
    </div>
  );
};

export default RenderFilters;
