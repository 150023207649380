import React, { useEffect, useState } from "react";
import "../../../../components/commonComponents/ListViewHeader/ListViewHeader.css";

import "../../../../components/commonComponents/tables/Table.css";
import "../../../../components/form/radio/radioSecond.css";

import { ICONS } from "../../../../assets/icons/index";

import {
  useAspDispatch,
  useAspSelector,
} from "../../../../test/jest-redux-hooks";

import Status from "../../../../components/commonComponents/tables/Status";

import { useNavigate } from "react-router-dom";
import NoData from "../campaigns/NoData";
import dayjs from "dayjs";
import SearchBar from "../../../../components/commonComponents/searchBar";
import CampaignFilter from "../../../../components/commonComponents/ListViewHeader/CampaignFilter";
import { campaignAPI } from "../../api/Api";
import { Popover, Skeleton } from "antd";
import {
  updateActiveTab,
  updateTabList,
} from "../../../../reduxToolkit/CampaignSlice";
import Calendar from "../../../../components/commonComponents/calendar/Calendar";
import moment from "moment";
import useCalendar from "../../../../hooks/useCalender";
import { calendarHeader } from "../../../../constant/app/campaign/campaign";

const allMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function Api() {
  const [count, setCount] = useState(0);
  const [api, setApi] = useState([]);

  const [page, setPage] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [isFilter, setIsFilter] = useState("");
  const [isLoadingApi, setIsLoadingApi] = useState(false);
  const [continueLoading, setContinueLoading] = useState(true);
  const [startPopoverVisible, setStartPopoverVisible] = useState(false);
  const [inputCalenderData, setInputCalender] = useState(null);
  const todayFormatted = moment().format("MMM DD, YYYY");
  const oneMonthFromToday = moment().add(1, "month").format("MMM DD, YYYY");

  // Set the initial states for start and end dates
  const [selectStartDate, setSelectStartDate] = useState(todayFormatted);
  const [selectEndDate, setSelectEndDate] = useState(oneMonthFromToday);

  const navigate = useNavigate();
  const dispatch = useAspDispatch();

  const { tableColumn } = useAspSelector((state) => state.Campaign);

  const { currentBrand } = useAspSelector((state) => state.app);

  const columnLength = tableColumn?.filter((item) => item?.selected)?.length;
  const tdStyle = {
    width: `calc(67% / 3)`,
  };

  const getCampaignApiList = (brandId, payload) => {
    if (brandId) {
      campaignAPI(brandId, payload)
        .then((res) => {
          setIsLoadingApi(true);

          if (res && res?.data?.campaigns?.length > 0) {
            const newData =
              payload?.page === 1
                ? res?.data?.campaigns
                : [...api, ...res?.data?.campaigns];
            setApi(newData);
            setCount(res?.data?.campaigns_count);
            setPage(page + 1);
            setIsLoadingApi(false);
          }
        })
        .finally(() => {
          setIsLoadingApi(false);
        });
    } else {
      setApi([]);
    }
  };

  useEffect(() => {
    getCampaignApiList(currentBrand?.brand_id, {
      page: page,
      limit: 10,
      name: isFilter,
    });
  }, [currentBrand]);

  const loadNextPage = () => {
    setIsLoadingApi(true);

    if (api?.length < count) {
      getCampaignApiList(currentBrand?.brand_id, {
        page: page,
        limit: 10,
      });
    }
  };

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;

    if (scrollTop + clientHeight >= scrollHeight - 20) {
      loadNextPage();
    }
  };

  const tableRowClicked = (data) => {
    dispatch(
      updateTabList([
        { id: 1, tabName: "campaignList" },
        {
          id: data?.id,
          tabName: "campaignName",
          name: data?.name,
          allData: data,
        },
        // { id: 3, tabName: 'okr' },
      ])
    );
    dispatch(updateActiveTab("campaignName"));
  };

  const handleTableRow = (e, item) => {
    tableRowClicked(item);
    navigate(`/user/campaign/perpetual/${item?.id}`, { state: { item } });
  };
  const statusExists = api?.some((item) => {
    if (selectedStatus.length > 0) return selectedStatus.includes(item?.status);
    return true;
  });

  const templateSearch = (value) => {
    setIsFilter(value);
  };

  const onFilterChange = (status) => {
    if (selectedStatus?.includes(status)) {
      const filtered = selectedStatus?.filter((s) => s !== status);
      setSelectedStatus(filtered);
    } else {
      setSelectedStatus([...selectedStatus, status]);
    }
  };

  const handleDateSelection = (date) => {
    const parsedDate = moment(date, "MMM D, YYYY");
    const selectedDate = parsedDate.isValid() ? parsedDate.toDate() : null;

    const formattedDate = moment(selectedDate).format("MMM DD, YYYY");
    setSelectStartDate(formattedDate);
    if (selectedDate) {
      setInputCalender((prev) => {
        const newState = prev?.map((item) => {
          if (item.key === "Duration") {
            return {
              ...item,
              value: `${moment(selectedDate).format("ll")}`,
            };
          }
          return item;
        });
        return newState;
      });

      setStartPopoverVisible(false);
    }
  };

  const showSkeletonApi = isLoadingApi && api?.length === 0 && continueLoading;

  const content = (
    <Calendar
      heading="Select duration"
      singleSelect={true}
      clickedDateFunc={handleDateSelection}
      style={{ width: "100%", minWidth: "350px", height: "220px " }}
    />
  );

  return (
    <>
      <div className="w-100 flex-row align-center space-between list-view-wrapper">
        <div className="w-[15vw] ">
          <SearchBar
            searchKey={isFilter}
            setSearchKey={setIsFilter}
            onChange={(text) => templateSearch(text)}
          />
        </div>
        <div className="flex-row align-center">
          {api?.length > 0 && (
            <CampaignFilter
              onFilterChange={onFilterChange}
              selectedStatus={selectedStatus}
            />
          )}
        </div>
      </div>
      <div className="campaign-list">
        <Skeleton
          active
          style={{ width: "79vw", padding: "20px" }}
          loading={showSkeletonApi}
          showSkeletonApi={showSkeletonApi}
          paragraph={{ rows: 8 }}
          className=".ant-skeleton-paragraph >li +li"
        >
          <div className="tableWrapper !h-[85vh]" onScroll={handleScroll}>
            {api?.length > 0 && statusExists && currentBrand ? (
              <table
                style={{
                  width:
                    columnLength > 4 ? `${400 + columnLength * 200}px` : "100%",
                }}
              >
                <thead>
                  <tr className="tableHeaderRow">
                    <th>
                      <div className="pl-10 campaignName ">Campaign name</div>
                    </th>

                    {tableColumn?.map(
                      (column) =>
                        column?.selected && (
                          <th key={column?.name}>{column?.name}</th>
                        )
                    )}
                  </tr>
                </thead>

                <tbody>
                  {api?.map((item) => {
                    const start = new Date(item?.start_date);
                    const end = new Date(item?.end_date);

                    const startDateString = `${
                      allMonths[start?.getMonth()]
                    } ${start?.getDate()}, ${start?.getFullYear()}`;
                    const endDateString = `${
                      allMonths[end?.getMonth()]
                    } ${end?.getDate()}, ${start?.getFullYear()}`;

                    return (
                      ((selectedStatus?.length > 0 &&
                        selectedStatus?.includes(item?.status)) ||
                        selectedStatus?.length === 0) &&
                      item?.campaign_name
                        ?.toLowerCase()
                        ?.includes(isFilter?.toLowerCase()) &&
                      statusExists && (
                        <React.Fragment key={item?.id || index}>
                          <tr className="listRow">
                            <td>
                              <div
                                className="rowBar"
                                style={{
                                  backgroundColor: item?.campaign_color,
                                }}
                              />
                              <div className="flex-row firstColumnWrapper">
                                <div className="campaignNameBox flex-column  justify-center pl-10">
                                  <div className="flex-row align-center ">
                                    <p
                                      onClick={(event) =>
                                        handleTableRow(event, item)
                                      }
                                    >
                                      {item?.campaign_name
                                        ? item.campaign_name
                                            ?.charAt(0)
                                            ?.toUpperCase() +
                                          item?.campaign_name?.slice(1)
                                        : ""}
                                    </p>

                                    <div
                                      className={`flex-row  ${
                                        item?.selected
                                          ? "iconWrapperActive "
                                          : "iconWrapper"
                                      }`}
                                    ></div>
                                  </div>

                                  <div
                                    className="flex-row campaignDetails align-center"
                                    onClick={(event) =>
                                      handleTableRow(event, item)
                                    }
                                  >
                                    Created @
                                    <img
                                      src={ICONS?.campaignCalendar}
                                      alt={"campaignDate"}
                                      className="campaignBoxImage"
                                    />
                                    <span className="weight-semibold">
                                      {dayjs(item?.created_at).format(
                                        "MMM DD hh:mm a"
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            {tableColumn[0]?.selected && (
                              <td
                                style={tdStyle}
                                onClick={(event) => handleTableRow(event, item)}
                              >
                                <div className="flex-row justify-center ">
                                  <span className="status-complete">
                                    <Status status={item?.status} />
                                  </span>
                                </div>
                              </td>
                            )}

                            {tableColumn[1]?.selected && (
                              <td
                                style={tdStyle}
                                onClick={(event) => handleTableRow(event, item)}
                              >
                                <div className="status-complete">API</div>
                              </td>
                            )}

                            {tableColumn[2]?.selected && (
                              <td
                                style={tdStyle}
                                onClick={(event) => handleTableRow(event, item)}
                              >
                                <div className="duration flex-row align-center justify-center">
                                  <img
                                    src={ICONS?.navCalendar}
                                    alt="img"
                                    className="durationCalender"
                                  />
                                  <span className="status-complete">
                                    {startDateString || "---"} - {endDateString}
                                  </span>
                                </div>
                                <div className="duration-open-calendar"></div>
                              </td>
                            )}
                          </tr>
                        </React.Fragment>
                      )
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <NoData isLoadingApi={isLoadingApi} />
            )}
          </div>
        </Skeleton>
      </div>
    </>
  );
}

export default Api;
