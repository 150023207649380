import React from 'react';

export default function DragIcon({
  width = '13',
  height = '18',
  color = '#0ACD95',
  strokeWidth = '1.5',
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 13 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 13.5C8 13.0858 8.33579 12.75 8.75 12.75C9.16421 12.75 9.5 13.0858 9.5 13.5C9.5 13.9142 9.16421 14.25 8.75 14.25C8.33579 14.25 8 13.9142 8 13.5Z'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.5 13.5C3.5 13.0858 3.83579 12.75 4.25 12.75C4.66421 12.75 5 13.0858 5 13.5C5 13.9142 4.66421 14.25 4.25 14.25C3.83579 14.25 3.5 13.9142 3.5 13.5Z'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 9C8 8.58579 8.33579 8.25 8.75 8.25C9.16421 8.25 9.5 8.58579 9.5 9C9.5 9.41421 9.16421 9.75 8.75 9.75C8.33579 9.75 8 9.41421 8 9Z'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.5 9C3.5 8.58579 3.83579 8.25 4.25 8.25C4.66421 8.25 5 8.58579 5 9C5 9.41421 4.66421 9.75 4.25 9.75C3.83579 9.75 3.5 9.41421 3.5 9Z'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 4.5C8 4.08579 8.33579 3.75 8.75 3.75C9.16421 3.75 9.5 4.08579 9.5 4.5C9.5 4.91421 9.16421 5.25 8.75 5.25C8.33579 5.25 8 4.91421 8 4.5Z'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.5 4.5C3.5 4.08579 3.83579 3.75 4.25 3.75C4.66421 3.75 5 4.08579 5 4.5C5 4.91421 4.66421 5.25 4.25 5.25C3.83579 5.25 3.5 4.91421 3.5 4.5Z'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
