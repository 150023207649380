export const getPhoneNumberLength = (countryCode) => {
  const phoneLengths = {
    91: 10, // India: 10 digits
    1: 10, // United States/Canada: 10 digits
    44: 10, // United Kingdom: 10 digits
    61: 9, // Australia: 9 digits
    49: 10, // Germany: 10 digits
    33: 9, // France: 9 digits
    55: 11, // Brazil: 11 digits
    86: 11, // China: 11 digits
    81: 10, // Japan: 10 digits
    971: 9, // UAE: 9 digits after country code +971
    // Add more as needed
  };

  return phoneLengths[countryCode];
};
