import React, { useEffect, useReducer, useState } from "react";
import "../../../styles/Preview.css";
import { images } from "../../../../../assets/images";

import "../../../styles/Broadcast.css";
import { ICONS } from "../../../../../assets/icons";
import TemplatePreview from "../../../../../components/commonComponents/templates/TemplatePreview";

import { useFileUploader } from "hooks/useFileUploader";

import Loader from "../../../../../components/commonComponents/Loader/Index";

import {
  INITIAL_STATE,
  templateReducer,
} from "hooks/templates/templateReducers";
import { useLocation } from "react-router-dom";

const language = {
  en: "English",
  en_US: "English_US",
  ta: "Tamil",
  en_GB: "English_US",
  te: "Telugu",
  Kn: "Kannada",
};

const BroadcastMessageView = ({
  segment,
  onClose,
  setSelectedId3,
  data,
  selectedTemplate,
  setSelectedTemplate,
}) => {
  const location = useLocation();
  const handleClose = () => {
    onClose();
    const updatedSegment = { ...segment };
    updatedSegment.settings.template_name = "";
    setSelectedTemplate([]);
    setSelectedId3(false);
  };

  const [templateData, templateDispatch] = useReducer(
    templateReducer,
    INITIAL_STATE
  );

  const { uploading, selectedFile, setSelectedFile, uploadFiles, error } =
    useFileUploader();
  const {
    header,
    headerVariable,
    body,
    bodyVariables,
    footer,
    buttons,
    buttonsOrder,
    ctaButtons,
    customButtons,
    carousel,
    authentication,
  } = templateData;

  useEffect(() => {
    templateDispatch({
      type: "update_template",
      data: selectedTemplate,
    });
  }, [selectedTemplate]);

  const updateTemplate = (type, key, value) => {
    if (selectedTemplate)
      if (["HEADER", "BODY", "FOOTER", "BUTTONS"].includes(type)) {
        const filterType = selectedTemplate?.components?.filter(
          (c) => c.type === type
        );

        const filterWithoutType = selectedTemplate?.components?.filter(
          (c) => c.type !== type
        );

        const updatedData = {
          ...selectedTemplate,
          components: [
            ...filterWithoutType,
            {
              ...filterType?.[0],
              [key]: value,
            },
          ],
        };
        setSelectedTemplate(updatedData);
      } else {
        setSelectedTemplate({ ...selectedTemplate, [key]: value });
      }
  };

  const fileName = (url) => {
    const names = url?.split("/");
    return names?.[names.length - 1];
  };

  // styles
  const uploadContainer =
    "flex items-center gap-[0.3vw] bg-[var(--primary)] px-[0.5vw] py-[0.1.5vw] cursor-pointer";
  const errorStyle = "text-[var(--fontRed)] font-md weight-400 my-[0.5vw]";


  return (
    <div className="BroadcastMessage-container w-90 space-between flex-row">
      <div className="broadcast-wrapper w-58 flex-column">
        <div className="select-heading flex-column">
          <span>Select Template</span>
          <span style={{ padding: "0px" }}>
            Select the approved message templates to broadcast your customers.
          </span>
        </div>
        <div className="flex-column w-100">
          <>
            <div className="select-template flex-column mt-20 py-2 pb-20">
              <div className=" flex-row w-50 align-center pb-10">
                <img src={images?.BroadcastWhatsappImage} alt="whatsappLogo" />
                <p className="template-name-info">
                  {/* {segment?.settings?.template_name} */}
                  {selectedTemplate?.name}
                </p>
                <span
                  className="status-color flex align-center justify-center"
                  style={{
                    color:
                      segment?.settings?.category === "MARKETING"
                        ? "#f6a723"
                        : "#ED4F9D",
                    background:
                      segment?.settings?.category === "MARKETING"
                        ? "#FFFBEB"
                        : "#fdf2f8",
                    padding:
                      segment?.settings?.category === "MARKETING"
                        ? "5px !important"
                        : "5px !important",
                  }}
                >
                  {selectedTemplate?.category?.charAt(0)?.toUpperCase() +
                    selectedTemplate?.category?.slice(1)?.toLowerCase()}
                </span>
                <p
                  className="status-Language flex-row align-center justify-center pl-5 pr-5"
                  style={{
                    color: "var(--dashboardprimary)",
                    backgroundColor: "hsla(203, 78%, 95%, 1)",
                    borderRadius: "5px",
                    marginLeft: "10px",
                  }}
                >
                  {language[selectedTemplate?.language]}
                </p>
                {data && (
                  <img
                    src={ICONS?.deleteIcon}
                    alt="delete"
                    className="Delay-image"
                    onClick={() => handleClose()}
                    onMouseOver={(e) =>
                      (e.currentTarget.src = ICONS?.BroadcastRedDeleteIcon)
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.src = ICONS?.deleteIcon)
                    }
                  />
                )}
              </div>
              {/* {(segment?.status !== 12 && segment?.status !== 11) && (
                <div className="w-full h-[15vh] pt-[1vw] border-t-[0.08vw] border-[var(--border-50)] p-10">
                  <h3 className="font-normal weight-bold text-[var(--contentText)]">
                    Select Media File
                  </h3>
                  <p className="font-md weight-medium text-[var(--font-400)] mt-[0.3vw]">
                    Upload a media for this template
                  </p>
                  {["IMAGE", "VIDEO", "DOCUMENT"]?.includes(header?.format) ? (
                    <div className="mt-[0.7vw]">
                      <div className="flex items-center gap-[0.8vw]">
                        <div>
                          {header?.format === "IMAGE" && (
                            <img
                              src={header?.example?.header_handle?.[0]}
                              alt="preview images"
                              className="size-[4vw] font-xs weight-medium rounded-[0.5vw]"
                            />
                          )}
                          {header?.format === "VIDEO" && (
                            <div className="h-[4vw] flex items-center gap-[0.3vw] bg-[var(--primary)] px-[0.5vw] py-[0.1.5vw] rounded-[0.5vw] cursor-pointer">
                              <VideoSvg
                                color="var(--white)"
                                width={"3vw"}
                                height={"3vw"}
                              />
                            </div>
                          )}
                          {header?.format === "DOCUMENT" && (
                            <iframe
                              title="cover"
                              src={header?.example?.header_handle?.[0]}
                              type="application/pdf"
                              width="100%"
                              height="150px"
                            ></iframe>
                          )}
                        </div>
                        <div>
                          <p className="w-[90%] font-md weight-semibold text-[var(--font-600)] truncate">
                            {selectedFile
                              ? selectedFile?.name
                              : fileName(header?.example?.header_handle?.[0])}
                          </p>
                          <button className="w-[7vw] font-xs weight-bold bg-[var(--primary)] text-[var(--white)] px-[0.6vw] py-[0.4vw] rounded-[0.37vw] mt-[0.4vw]">
                            <div className="flex items-center justify-center">
                              <input
                                type="file"
                                accept={
                                  header?.format === "IMAGE"
                                    ? ".jpg, .png"
                                    : header?.format === "VIDEO"
                                    ? ".mp4"
                                    : header?.format === "DOCUMENT" && ".pdf"
                                }
                                onChange={async (e) => {
                                  const res = await uploadFiles(
                                    e,
                                    header?.format
                                  );
                                  updateTemplate("HEADER", "example", {
                                    header_handle: [res],
                                  });
                                }}
                                id="uploadImg"
                                hidden
                              />

                              <label
                                for="uploadImg"
                                className="w-full flex-center"
                              >
                                {uploading ? (
                                     <Loader
                                      Width={20}
                                      Height={20}
                                      loaderBg='white'
                                  />
                                ) : (
                                  <div className={`${uploadContainer}`}>
                                    Choose media
                                  </div>
                                )}
                              </label>
                            </div>
                          </button>
                        </div>
                      </div>
                      <>
                        {header?.format === "IMAGE" && error && (
                          <div className={`${errorStyle}`}>
                            Image size should be less than 5MB
                          </div>
                        )}

                        {header?.format === "VIDEO" && error && (
                          <div className={`${errorStyle}`}>
                            Video size should be less than 16MB
                          </div>
                        )}

                        {header?.format === "DOCUMENT" && error && (
                          <div className={`${errorStyle}`}>
                            Document size should be less than 100MB
                          </div>
                        )}
                      </>
                    </div>
                  ) : (
                    <div className="w-full h-[10vh] flex-center font-md weight-medium text-[var(--font-600)]">
                      No media files found on this template.
                    </div>
                  )}
                </div>
              )} */}
            </div>
          </>
        </div>
      </div>
      <div className="MessagePreview w-40">
        <div className="TestMessage">
          <p className="flex-row align-center">Message Preview</p>
        </div>
        <div className="w-full min-h-[65vh] max-h-[70vh] h-full">
          <div className="w-[18vw] min-h-[57vh] max-h-[59vh] border-[0.4vw] border-[var(--white)] rounded-[2vw] my-[0.7vw] shadow-[0px_4px_30px_0px_#2D303620]">
            <div className="h-[6vh] flex items-center gap-[0.5vw] bg-[#077C70] rounded-t-[1.5vw] px-[0.6vw]">
              <div className="size-[2.1vw] bg-[var(--white)] rounded-full flex items-center justify-center font-xs">
                Logo
              </div>
              <div className="text-[var(--white)]">
                <h3 className="font-md weight-bold capitalize leading-[1.2vw]">
                  {segment?.settings?.send_from_phone_name}
                </h3>
                <span className="font-md weight-bold capitalize flex-row leading-[1.2vw]">
                  {segment?.settings?.country_code}
                  <p className="pl-5">
                    {segment?.settings?.send_from_phone_number}
                  </p>
                </span>
              </div>
            </div>
            <TemplatePreview
              header={header}
              headerVariable={headerVariable}
              body={body}
              bodyVariables={bodyVariables}
              footer={footer}
              buttons={buttons}
              // useTemplates={true}
              buttonsOrder={buttonsOrder}
              ctaButtons={ctaButtons}
              customButtons={customButtons}
              carousel={carousel}
              authentication={authentication}
              data={selectedTemplate}
              category={selectedTemplate?.subcategory_type}
              className={"rounded-none min-h-[45vh] max-h-[45vh]"}
              drawerType="small"
            />
            <img
              src={images?.TemplatePreviewBottom}
              alt="send msg"
              className="w-[17.35vw] h-[2.3vw] rounded-b-[1.5vw]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BroadcastMessageView;
