import AspApi from 'api/ApiConfig';

export const accountList = async (params) => {
  let qpm = [];
  if (params?.search) qpm.push(`search=${params?.search}`);

  let qpmString = '';
  if (qpm.length > 0) qpmString = `?${qpm.join('&')}`;

  const res = await AspApi.get(`api/v1/account/accounts_list${qpmString}`);
  return res;
};

export const singleAccount = async (params) => {
  let qpm = [];
  if (params.invited_members)
    qpm.push(`invited_members=${params.invited_members}`);

  let qpmString = '';
  if (qpm.length > 0) qpmString = `?${qpm.join('&')}`;

  const res = await AspApi.get(`api/v1/account/${params.acc_id}${qpmString}`);
  return res;
};

export const singleAccountupdate = async (params, payload) => {
  const res = await AspApi.patch(`api/v1/account/${params.acc_id}`, payload);
  return res;
};

export const brandList = async (params) => {
  let qpm = [];
  if (params.search) qpm.push(`search=${params.search}`);

  let qpmString = '';
  if (qpm.length > 0) qpmString = `?${qpm.join('&')}`;
  const res = await AspApi.get(
    `api/v1/account/${params.acc_id}/brands${qpmString}`
  );
  return res;
};

export const singleBrand = async (params) => {
  const res = await AspApi.get(`api/v1/account/${params.acc_id}`);
  return res;
};

export const inviteMembersToBrand = async (params) => {
  const res = await AspApi.get(`api/v1/account/${params.acc_id}`);
  return res;
};
