import React, { useState } from "react";

// assets
import DragIcon from "../../../../assets/customSVG/DragIcon";
import Xmark from "../../../../assets/customSVG/Xmark";

// helper
import { cn } from "../../../../helper/cn";
import useToggle from "../../../../hooks/useToggle";
import AddCircle from "../../../../assets/customSVG/AddCircle";
import { updateToggleToast } from "../../../../reduxToolkit/appSlice";
import {
  useAspDispatch,
  useAspSelector,
} from "../../../../test/jest-redux-hooks";
import { carouselCardDelete, carouselCardDrag } from "../../api/Api";
import { isValidHttpsUrl } from "../../../../helper/checkUrls";

export default function Tabs({
  data,
  selectedCard,
  onSelect,
  addTab,
  closeTab,
  dispatch,
}) {
  const [isDragging, setIsDragging] = useToggle(false);
  const [draggingItem, setDraggingItem] = useState(null);
  const { currentBrand, toggleToast } = useAspSelector((state) => state?.app);
  const deleteDispatch = useAspDispatch();
  const templateDetails = useAspSelector((state) => state?.Template);

  const onDrop = (event, index) => {
    event.preventDefault();
    const tab = event.dataTransfer.getData("tab");
    const parsedData = JSON.parse(tab);

    const removeData = data.filter((n) => n.id !== parsedData?.id);
    const start = removeData.slice(0, index);
    const end = removeData.slice(index);

    let updatedData = [...start, parsedData, ...end];
    const newData = updatedData?.map((c, i) => {
      return { ...c, display_order: i + 1 };
    });

    const template_ids =
      templateDetails?.continueDetails?.template?.id ||
      templateDetails?.continueDetails?.template_id ||
      templateDetails?.continueDetails?.id;

    const payLoad = {
      brand_id: currentBrand?.brand_id,
      cards: newData?.map((c) => {
        return { card_id: c?.id, order: c?.display_order };
      }),
    };

    carouselCardDrag(template_ids, payLoad).then((res) => {});

    dispatch({
      type: "update_carousel",
      updateType: "update",
      key: "cards",
      value: updatedData,
      // value: updatedData?.map((d, i) => {
      //   return { ...d, id: i + 1 };
      // }),
    });
    setIsDragging(false);
    setDraggingItem(null);
  };

  const onDrag = (event, item) => {
    event.dataTransfer.setData("tab", JSON.stringify(item));
    setIsDragging(true);
    setDraggingItem(item);
  };

  const onDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleCardDelete = async (card, id) => {
    const payload = {
      brand_id: currentBrand?.brand_id,
    };
    try {
      const res = await carouselCardDelete(
        templateDetails?.continueDetails?.template?.id ||
          templateDetails?.continueDetails?.template_id,
        id,
        payload
      );
      if (res?.status === 200) {
        deleteDispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: "Template deleted successfully",
              status: "Success",
              duration: "",
            },
          ])
        );
        if (closeTab) {
          closeTab(card);
        }
        return;
      }
      if (res?.status !== 200) {
        deleteDispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: "Unable to delete template. Please try again!",
              status: "Error",
              duration: "",
            },
          ])
        );
        return;
      }
    } catch (e) {
      console.warn(e);
    }
  };

  const checkToAllowAddCard = data.every(
    (item) =>
      item.bodyText &&
      item.image &&
      item.buttons.every((button) => {
        if (button.type === "URL") {
          return button.url && isValidHttpsUrl(button.url);
        }
        if (button.type === "PHONE_NUMBER") {
          return button.text && button.phone_number;
        }
        return button.text;
      }) &&
      item.bodyVariables.every((bodyVariable) => bodyVariable.value)
  );

  return (
    <div className="w-full flex font-default weight-medium">
      {data?.map((c, index) => {
        return (
          <div
            className={cn(
              "w-full max-w-[8vw] h-9 flex flex-1 gap-[0.4vw] cursor-pointer group border-r transition-all ease-linear duration-200 hover:cursor-move",
              // selectedCard?.id === c?.id
              c?.isSelected
                ? "bg-white rounded-t-[0.4vw] border-white"
                : "border-[var(--border-100)] px-[0.3vw] last:border-none",
              +selectedCard?.id - 1 === +c?.id ? "border-[#F0ECFE]" : ""
            )}
            onDrop={(e) => {
              onDrop(e, index);
            }}
            onDragOver={onDragOver}
          >
            <div
              className={cn(
                "w-full h-8 flex items-center px-[0.3vw] relative rounded-[0.4vw]",
                selectedCard?.id !== c?.id
                  ? "hover:bg-gray-200 rounded-[0.4vw]"
                  : ""
              )}
              onDragStart={(e) => {
                onDrag(e, c);
              }}
              draggable
            >
              <div
                className="w-full max-w-[7vw] flex flex-1 items-center gap-[0.4vw]"
                onClick={() => {
                  onSelect(c);
                }}
              >
                <DragIcon
                  color={
                    selectedCard?.id === c?.id
                      ? "var(--primary)"
                      : "var(--font-600)"
                  }
                />
                <div className={cn("w-full group-hover:max-w-[calc(100%-)]")}>
                  #{c?.display_order} {c?.name}
                  {/* #{c?.id} Card */}
                </div>
              </div>
              {data?.length > 1 && (
                <div
                  className={cn(
                    "w-[1.5vw] hidden h-full items-center justify-center absolute top-0 right-0",
                    selectedCard?.id === c?.id
                      ? "!flex right-0"
                      : 'group-hover:!flex right-0 group-hover:bg-gray-200 group-hover:rounded-r-[0.4vw] before:contents-[""] before:w-[0vw] before:h-full before:bg-gradient-to-r before:from-gray-100 before:to-gray-200',
                    data?.length > 8 ? "before:!w-[0.3vw]" : ""
                  )}
                >
                  <div
                    className={cn(
                      "size-4 hover:bg-red-200 rounded-full flex items-center justify-center group/delete hover:cursor-pointer"
                    )}
                    onClick={() => {
                      // if (closeTab) {
                      //   closeTab(c);
                      handleCardDelete(c, c?.id);
                      // }
                    }}
                  >
                    <Xmark
                      width={8}
                      height={8}
                      color=""
                      className={cn(
                        "hidden group-hover:!block group-hover/delete:stroke-[var(--fontRed)]",
                        selectedCard?.id === c?.id
                          ? "stroke-[var(--font-600)] !block"
                          : "stroke-[var(--font-600)]"
                      )}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      })}

      {data?.length < 10 && (
        <div
          className={cn(
            `w-auto min-w-[5vw] h-8 rounded-[0.4vw] px-[1vw] hover:bg-gray-200 flex items-center gap-[0.4vw] ml-[0.2vw] ${
              !checkToAllowAddCard ? "cursor-not-allowed" : "cursor-pointer"
            }`
          )}
          onClick={() => {
            if (addTab && checkToAllowAddCard) addTab();
            if (onSelect && checkToAllowAddCard) onSelect();
          }}
        >
          <AddCircle width={16} stroke="#333843" height={16} strokeWidth={2} />
          <p>Add card</p>
        </div>
      )}
    </div>
  );
}
