import { generateSlug } from "helper/generats";

import {
  checkIsoriginalVariable,
  fliterWithCurlyBrackets,
  updateVariablesOrder,
} from "modules/channels/components/templates";

import {
  addCtaButton,
  getFirtstTypeOfButton,
} from "components/commonComponents/templates/Buttons/utils";

import { checkVariable, getVariablesFromStr } from "helper/templates";

import {
  allowedCtaButtonTypes,
  allowedCustomButtonTypes,
} from "components/commonComponents/templates/Buttons/utils";

const carouselSluglength = 16;

export const INITIAL_STATE = {
  id: null,
  header: null,
  headerVariable: null,
  body: {
    type: "BODY",
    text: "",
  },
  bodyVariables: [],
  footer: null,
  buttons: [],
  ctaButtons: [],
  customButtons: [],
  buttonsOrder: null,

  selection: {
    start: null,
    end: null,
  },

  authentication: {
    body: "{{otp-number}} is your verification code. For your security, do not share this code.",
    codeDeliverySetup: "copy-code",
    zeroTabTerms: true,
    appSetup: [
      {
        id: 1,
        packageName: "",
        appSignatureHash: "",
      },
    ],
    content: {
      securityRecommendation: true,
      expiryTimeForCode: false,
      expiryTime: 10,
    },
    buttons: [
      {
        id: 1,
        label: "Copy code",
        placeholder: "Copy code",
        text: "Copy code",
        type: "OTP",
        otp_type: "COPY_CODE",
      },
    ],
    messageValidity: {
      validity: true,
      period: 10,
    },
  },

  carousel: {
    header: "IMAGE",
    button1: "CUSTOM",
    button2: "NONE",
    bodyVariables: [],
    cards: [
      // {
      //   id: 1,
      //   image: null,
      //   slug: generateSlug(carouselSluglength),
      //   bodyText: "",
      //   bodyVariables: [],
      //   isSelected: true,
      //   name: "Card 1",
      //   display_order: 1,
      //   whatsapp_carousel_template_id: null,
      //   buttons: [
      //     {
      //       id: 1,
      //       type: "QUICK_REPLY",
      //       format: "CUSTOM",
      //       text: "",
      //       label: "CUSTOM",
      //       slug: "",
      //     },
      //   ],
      // },
    ],
  },
};

const HEADER_TYPES_INITIAL_VALUES = {
  NONE: null,
  TEXT: {
    type: "HEADER",
    format: "TEXT",
    text: "",
  },
  MEDIA: {
    type: "HEADER",
    format: "MEDIA",
    example: {
      header_handle: [""],
    },
  },
  IMAGE: {
    type: "HEADER",
    format: "IMAGE",
    example: {},
  },
  VIDEO: {
    type: "HEADER",
    format: "VIDEO",
    example: {},
  },
  DOCUMENT: {
    type: "HEADER",
    format: "DOCUMENT",
    example: {},
  },
};

const buttonOptions = {
  URL: "Visit Website",
  PHONE_NUMBER: "Call Phone Number",
  CUSTOM: "Quick Reply",
  NONE: "None",
};

const checkSpace = (text) => {
  return text?.length !== 0 ? text?.toString()?.endsWith(" ") : true;
};

export const templateReducer = (state, action) => {
  const {
    type,
    updateType,
    format,
    text,
    variableId,
    variableTitle,
    variableValue,
    mediaUrl,
    updateVariableObj,
    btnObj,
    data,
    id,
    primaryKey,
    key,
    value,
    category,
    continueDetails,
  } = action;

  const checkBody = state?.bodyVariables?.filter(
    (v) => v?.title === variableTitle
  );

  const filterData = (arr, key) => {
    let filteredData = null;
    if (arr?.length > 0) filteredData = arr.filter((a) => a.type === key);
    else filteredData = null;

    return filteredData;
  };

  const getDefaultBodyVariable = (title) => {
    return state?.bodyVariables?.filter((v) => v?.title === title);
  };

  const updateCarouselGlobalBodyVariable = (id, title, value, obj) => {
    let cardsBodyVariables = state?.carousel?.bodyVariables?.map((v) =>
      v?.title === title ? { ...v, value: value } : v
    );

    let cardsVariable = state?.carousel?.cards?.map((c) => {
      if (c?.id === id)
        return {
          ...c,
          ...obj,
          bodyVariables: c?.bodyVariables?.map((v) =>
            v?.title === title ? { ...v, value: value } : v
          ),
        };
      return c;
    });

    let bodyVariables = state?.bodyVariables?.map((v) => {
      return v?.title === title ? { ...v, value: value } : v;
    });

    return { cardsBodyVariables, cardsVariable, bodyVariables };
  };

  const getBodyVariable = (title) => {
    return state?.bodyVariables?.filter((v) => v?.title === title);
  };

  const getCarouselBodyVariable = (title) => {
    return state?.carousel?.bodyVariables?.filter((v) => v?.title === title);
  };

  const createNewCardButtons = () => {
    let buttons = [];
    buttons.push({
      ...addCtaButton(null, state?.carousel?.button1),
      id: 1,
      slug: "",
      label:
        state?.carousel?.button1 === "QUICK_REPLY"
          ? "CUSTOM"
          : buttonOptions[state?.carousel?.button1],
    });
    if (state?.carousel?.button2 !== "NONE")
      buttons.push({
        ...addCtaButton(null, state?.carousel?.button2),
        id: 2,
        slug: "",
        label:
          state?.carousel?.button2 === "CUSTOM"
            ? "CUSTOM"
            : state?.carousel?.button2,
        // : buttonOptions[state.carousel.button2],
      });

    return buttons;
  };

  const updateCradsVariables = (cards, order = "default") => {
    let arr = [];

    const res = cards?.map((c, i) => {
      c?.bodyVariables?.map((v) => {
        const checkArr = arr?.filter((a) => a?.title === v?.title);
        const bodyVariable = getDefaultBodyVariable(v?.title);
        if (checkArr?.length === 0)
          arr.push({ ...v, value: bodyVariable?.[0]?.value || v?.value });
      });
      if (order === "asc") return { ...c, id: i + 1 };
      return c;
    });
    return {
      cards: res,
      variables: arr,
    };
  };

  const onSelectCarouselCrard = (data, id) => {
    return data?.map((d) => {
      if (d?.id === id) return { ...d, isSelected: true };
      return { ...d, isSelected: false };
    });
  };

  const getId = (items) => {
    return items?.length === 0
      ? 1
      : items.reduce(
          (maxId, item) => (item?.id > maxId ? item?.id : maxId),
          -Infinity
        );
  };

  // const updateCraouselCardData = (data, id, key, value) => {
  //   let arr = [];
  //   data?.map((c) => {
  //     const text = key === 'bodyText' ? value : c.bodyText;
  //     const variables = updateVariablesOrder(text, c.bodyVariables);

  //     if (id)
  //       if (c?.id === id)
  //         return { ...c, [key]: value, bodyVariables: variables };
  //       else return c;
  //     else return { ...c, [key]: value, bodyVariable: variables };
  //   });

  //   return { cards: data, variables: [] };
  // };

  switch (type) {
    case "update":
      if (primaryKey)
        return {
          ...state,
          [primaryKey]: {
            ...[primaryKey],
            [key]: value,
          },
        };
      else
        return {
          ...state,
          [key]: value,
        };
    // header
    case "header":
      if (format === "NONE") state.headerVariable = null;
      if (updateType === "format") {
        return {
          ...state,
          header: HEADER_TYPES_INITIAL_VALUES[format],
          headerVariable: null,
        };
      }
      if (updateType === "text") {
        const newvariables = updateVariablesOrder(text, [state.headerVariable]);
        const body = state?.bodyVariables?.filter(
          (v) => v.title === newvariables?.[0]?.title
        );
        const update = newvariables?.map((v) => {
          if (v.title === body?.[0]?.title)
            return { ...v, value: body?.[0]?.value };
          return v;
        });
        if (newvariables?.length > 0) {
          return {
            ...state,
            headerVariable: update?.[0],
            header: {
              ...state.header,
              text: text,
              example: {
                header_text: [update?.[0]?.value],
              },
            },
          };
        }

        return {
          ...state,
          headerVariable: null,
          header: {
            ...state.header,
            text: text,
          },
        };
      }
      if (updateType === "variable")
        return {
          ...state,
          headerVariable: {
            title: variableTitle,
            // id: checkBody?.[0]?.id ?? variableId,
            value: checkBody?.[0]?.value ?? "",
          },
          header: {
            ...state.header,
            text: `${state?.header?.text}${
              checkSpace(state?.header?.text)
                ? variableTitle
                : ` ${variableTitle}`
            }`,
            example: {
              header_text: [checkBody?.[0]?.value ?? ""],
            },
          },
        };
      if (updateType === "mediaUrl")
        return {
          ...state,
          header: {
            ...state.header,
            example: {
              header_handle: [mediaUrl],
            },
          },
        };
      break;

    // body
    case "body":
      if (updateType === "text") {
        const newvariables = updateVariablesOrder(text, state.bodyVariables);
        if (newvariables?.length > 0) {
          return {
            ...state,
            body: {
              ...state.body,
              text: text,
              example: {
                body_text: [
                  newvariables?.map((r) => {
                    return r.value;
                  }),
                ],
              },
            },
          };
        } else {
          return {
            ...state,
            body: {
              type: "BODY",
              text: text,
            },
          };
        }
      }

      if (updateType === "variable") {
        const variables =
          state?.bodyVariables?.length > 0
            ? state?.bodyVariables
            : [{ title: variableTitle, value: variableValue }];

        const newvariables = updateVariablesOrder(text, variables);
        const update = newvariables?.map((v) => {
          if (
            category === "default" &&
            v?.title === state?.headerVariable?.title
          ) {
            return { ...v, value: state?.headerVariable?.value };
          } else if (category === "carousel") {
            const body = getCarouselBodyVariable(v?.title);
            if (body?.length > 0) return { ...v, value: body?.[0]?.value };
            return v;
          }

          return v;
        });

        if (update?.length > 0)
          return {
            ...state,
            body: {
              ...state.body,
              text: text,
              example: {
                body_text: [
                  update?.map((r) => {
                    return r.value;
                  }),
                ],
              },
            },
            bodyVariables: update,
          };

        return {
          ...state,
          body: {
            type: "BODY",
            text: text,
          },
          bodyVariables: [],
        };
      }
      break;

    // variables update (header, body)
    case "update_variable":
      const res = state?.bodyVariables?.map((v) => {
        if (v.title === variableTitle)
          return {
            ...v,
            value: variableValue,
          };
        return v;
      });

      const newvariables = updateVariablesOrder(state.body.text, res);
      let categoryData = { ...state.carousel };

      if (category === "carousel") {
        const { cardsBodyVariables } = updateCarouselGlobalBodyVariable(
          null,
          variableTitle,
          variableValue
        );

        const cardsVariable = state.carousel.cards?.map((c) => {
          return {
            ...c,
            bodyVariables: c?.bodyVariables?.map((v) =>
              v.title === variableTitle ? { ...v, value: variableValue } : v
            ),
          };
        });

        categoryData = {
          ...categoryData,
          bodyVariables: cardsBodyVariables,
          cards: cardsVariable,
        };
      }

      if (state.headerVariable?.title === variableTitle) {
        return {
          ...state,
          headerVariable: {
            ...state.headerVariable,
            value: variableValue,
          },
          header: {
            ...state.header,
            example: {
              header_text: [variableValue],
            },
          },
          body: {
            ...state.body,
            example: {
              body_text: [
                newvariables?.map((r) => {
                  return r.value;
                }),
              ],
            },
          },
          bodyVariables: newvariables,
          carousel: categoryData,
        };
      } else {
        return {
          ...state,
          body: {
            ...state.body,
            example: {
              body_text: [
                newvariables?.map((r) => {
                  return r.value;
                }),
              ],
            },
          },
          bodyVariables: newvariables,
          carousel: categoryData,
        };
      }

    // footer
    case "footer":
      if (text?.length > 0) {
        if (state?.footer?.text?.length > 0)
          return {
            ...state,
            footer: {
              type: "FOOTER",
              text: text,
            },
          };

        return {
          ...state,
          footer: {
            type: "FOOTER",
            text: text,
          },
        };
      }

      return { ...state, footer: null };

    // buttons
    case "buttons":
      let newButtons = [];
      let order = null;
      if (updateType === "add") {
        newButtons = [...state.buttons, btnObj];
      } else if (updateType === "update") {
        newButtons = state.buttons.map((b) => {
          if (b.id === btnObj?.id)
            return { ...b, [btnObj?.key]: btnObj?.value };
          return b;
        });
      } else if (updateType === "remove") {
        newButtons = state.buttons.filter((b) => b.id !== btnObj?.id);
      } else if (updateType === "replace") {
        newButtons = state.buttons.map((b) => {
          if (b.id === btnObj?.id) return btnObj;
          return b;
        });
      } else newButtons = state.buttons;

      const cta = newButtons?.filter((b) =>
        allowedCtaButtonTypes.includes(b?.type)
      );

      const custom = newButtons?.filter((b) =>
        allowedCustomButtonTypes.includes(b?.type)
      );

      const newUpdateCustomButton = custom?.filter(
        (b) =>
          allowedCustomButtonTypes.includes(b.type) &&
          b.text !== "Stop promotions"
      );
      const newCustomButtonsWithOptout = custom?.filter(
        (b) =>
          allowedCustomButtonTypes.includes(b.type) &&
          b.text === "Stop promotions"
      );

      const newCustomButtons = [
        ...newUpdateCustomButton,
        ...newCustomButtonsWithOptout,
      ];

      if (newButtons.length === 0) {
        order = allowedCtaButtonTypes.includes(btnObj?.type)
          ? "ctaButtons"
          : allowedCustomButtonTypes?.includes(btnObj?.type)
          ? "custom"
          : "";
      } else {
        if (state.buttonsOrder === "ctaButtons" && cta.length > 0)
          order = "ctaButtons";
        else order = "custom";

        if (state.buttonsOrder === "custom" && custom.length > 0)
          order = "custom";
        else order = "ctaButtons";
      }

      return {
        ...state,
        buttons:
          order === "custom"
            ? [...newCustomButtons, ...cta]
            : [...cta, ...newCustomButtons],
        ctaButtons: cta,
        customButtons: newCustomButtons,
        buttonsOrder: order,
      };

    case "update_athentication":
      if (updateType === "codeDeliverySetup")
        return {
          ...state,
          authentication: {
            ...state.authentication,
            [key]: value,
          },
        };
      else if (updateType === "appSetup_add")
        return {
          ...state,
          authentication: {
            ...authentication,
            appSetup: value,
          },
        };
      else if (updateType === "content")
        return {
          ...state,
          authentication: {
            ...state.authentication,
            body: text,
            content: {
              ...state.authentication.content,
              [key]: value,
            },
          },
        };
      else if (updateType === "message_validity")
        return {
          ...state,
          authentication: {
            ...state.authentication,
            messageValidity: {
              ...state.authentication.messageValidity,
              [key]: value,
            },
          },
        };
      else if (updateType === "update_buttons")
        return {
          ...state,
          authentication: {
            ...state.authentication,
            buttons: state?.authentication?.buttons?.map((b) => {
              if (id === b.id) return { ...b, [key]: value };
              return b;
            }),
          },
        };
      break;

    case "update_carousel":
      if (updateType === "update") {
        if (key === "header")
          return {
            ...state,
            carousel: {
              ...state.carousel,
              [key]: value,
              cards: state.carousel.cards?.map((c) => {
                let buttons = [];
                if (key === "button1" && value) {
                  buttons?.push({
                    id: 1,
                    ...c?.buttons[0],
                    ...value,
                    label:
                      value?.format === "QUICK_REPLY"
                        ? "CUSTOM"
                        : buttonOptions?.[value?.format],
                  });
                } else if (key !== "button1") {
                  buttons?.push(c?.buttons[0]);
                }

                if (key === "button2" && value) {
                  buttons?.push({
                    id: 2,
                    ...c?.buttons[1],
                    ...value,
                    label:
                      value?.format === "CUSTOM"
                        ? "CUSTOM"
                        : buttonOptions?.[value?.format],
                  });
                } else if (key !== "button2" && c?.buttons?.length > 1) {
                  buttons?.push(c?.buttons[1]);
                }

                return { ...c, image: "", buttons: buttons };
              }),
            },
          };

        return {
          ...state,
          carousel: {
            ...state.carousel,
            [key]: value,
          },
        };
      } else if (updateType === "add_card") {
        // let buttons = [];
        // buttons.push({
        //   ...addCtaButton(null, state.carousel.button1),
        //   id: 1,
        //   slug: '',
        //   label:
        //     state.carousel.button1 === 'QUICK_REPLY'
        //       ? 'CUSTOM'
        //       : buttonOptions[state.carousel.button1],
        // });
        // if (state.carousel.button2 !== 'NONE')
        //   buttons.push({
        //     ...addCtaButton(null, state.carousel.button2),
        //     id: 2,
        //     slug: '',
        //     label:
        //       state.carousel.button2 === 'CUSTOM'
        //         ? 'CUSTOM'
        //         : state.carousel.button2,
        //     // : buttonOptions[state.carousel.button2],
        //   });

        let buttons = createNewCardButtons();

        const id =
          state?.carousel?.cards?.length > 0
            ? getId(state?.carousel?.cards) + 1
            : 1;

        const newData = [...state.carousel.cards, data];

        const res = onSelectCarouselCrard(newData, data?.id);

        return {
          ...state,
          carousel: {
            ...state.carousel,
            // cards: res,
            cards: res?.map((c, i) => {
              return { ...c, display_order: i + 1 };
            }),
          },
        };
      } else if (updateType === "remove_card") {
        const res = state.carousel.cards?.filter((c) => c.id !== id);

        const { cards, variables } = updateCradsVariables(res);

        return {
          ...state,
          carousel: {
            ...state.carousel,
            bodyVariables: variables,
            // cards: cards,
            // cards: res,
            cards: cards?.map((c, i) => {
              return { ...c, display_order: i + 1 };
            }),
          },
        };
      } else if (updateType === "select_card") {
        return {
          ...state,
          carousel: {
            ...state.carousel,
            cards: onSelectCarouselCrard(state.carousel.cards, id),
          },
        };
      } else if (updateType === "update_card") {
        const updateCrds = state?.carousel?.cards?.map((c) => {
          if (c.id === id) {
            let newVariables = c.bodyVariables;
            if (key === "bodyText") {
              newVariables = updateVariablesOrder(value, c.bodyVariables);
              newVariables?.map((v) => {
                const bodyVariable = getDefaultBodyVariable(v?.title);
                const carouselBodyVariable = getCarouselBodyVariable(v?.title);

                return bodyVariable?.length > 0 &&
                  bodyVariable?.[0]?.title === v?.title
                  ? { ...v, value: bodyVariable?.[0]?.value }
                  : carouselBodyVariable?.length > 0 &&
                    carouselBodyVariable?.[0]?.title === v?.title
                  ? { ...v, value: carouselBodyVariable?.[0]?.value }
                  : v;
              });
            }
            return {
              ...c,
              [key]: value,
              bodyVariables: newVariables,
            };
          }

          return c;
        });

        const { cards, variables } = updateCradsVariables(
          updateCrds,
          id,
          variableTitle,
          variableValue
        );

        return {
          ...state,
          carousel: {
            ...state.carousel,
            bodyVariables: variables,
            cards: cards,
          },
        };
      } else if (updateType === "update_card_button") {
        const res = state.carousel.cards?.map((c) => {
          if (c.card_id === id) {
            c.buttons = c.buttons?.map((b) => {
              if (b.id === btnObj?.id) return { ...b, ...btnObj };
              return b;
            });
          }

          return c;
        });

        const variables = state.carousel.bodyVariables.map((v) =>
          v.title === variableTitle ? { ...v, value: variableValue } : v
        );

        return {
          ...state,
          carousel: {
            ...state.carousel,
            bodyVariables: variables,
            cards: res,
          },
        };
      } else if (updateType === "update_all_buttons") {
        let newButton1 =
          key === "button1"
            ? value
              ? value?.format
              : state.carousel?.button1
            : state.carousel?.button1;

        let newButton2 =
          key === "button2"
            ? value
              ? value?.format
              : "NONE"
            : state.carousel?.button2;

        const res = state.carousel.cards?.map((c) => {
          let buttons = [];
          if (key === "button1" && value) {
            buttons.push({
              id: 1,
              ...c.buttons[0],
              ...value,
              label:
                value?.format === "QUICK_REPLY"
                  ? "CUSTOM"
                  : buttonOptions[value?.format],
            });
          } else if (key !== "button1") {
            buttons.push(c.buttons[0]);
          }

          if (key === "button2" && value) {
            buttons.push({
              id: 2,
              ...c.buttons[1],
              ...value,
              label:
                value?.format === "CUSTOM"
                  ? "CUSTOM"
                  : buttonOptions[value?.format],
            });
          } else if (key !== "button2" && c.buttons?.length > 1) {
            buttons.push(c.buttons[1]);
          }

          return { ...c, buttons: buttons };
        });

        return {
          ...state,
          carousel: {
            ...state.carousel,
            button1: newButton1,
            button2: newButton2,
            cards: res,
          },
        };
      } else if (updateType === "add_variable") {
        const bodyVariable = getDefaultBodyVariable(variableTitle);
        const carouselBodyVariable = getBodyVariable(variableTitle);

        const updateCards = state?.carousel?.cards?.map((c) => {
          if (c.id === id) {
            const variables =
              c?.bodyVariables?.length > 0
                ? c?.bodyVariables
                : bodyVariable?.length > 0
                ? [...c.bodyVariables, ...bodyVariable]
                : carouselBodyVariable?.length > 0
                ? [...c.bodyVariables, ...carouselBodyVariable]
                : [{ title: variableTitle, value: variableValue }];

            const newVariables = updateVariablesOrder(text, variables);

            const update = newVariables?.map((v) => {
              if (v?.title === bodyVariable?.[0]?.title)
                return {
                  ...v,
                  value: v?.value
                    ? v?.value
                    : bodyVariable?.length > 0
                    ? bodyVariable?.[0]?.value
                    : carouselBodyVariable?.length > 0
                    ? carouselBodyVariable?.[0]?.value
                    : variableValue,
                };
              return v;
            });

            return {
              ...c,
              bodyText: text,
              bodyVariables: update,
            };
          }
          return c;
        });

        // const update = updateCraouselCardData(
        //   state?.carousel?.cards,
        //   id,
        //   'bodyText',
        //   text
        // );

        // const datas = updateCraouselCardData(
        //   updateCards,
        //   id,
        //   variableTitle,
        //   variableValue
        // );

        const { variables, cards } = updateCradsVariables(updateCards);

        return {
          ...state,
          carousel: {
            ...state.carousel,
            bodyVariables: variables,
            cards: updateCards,
          },
        };
      } else if (updateType === "update_variable") {
        const { cardsBodyVariables, bodyVariables } =
          updateCarouselGlobalBodyVariable(id, variableTitle, variableValue);

        const cardsVariable = state.carousel.cards?.map((c) => {
          return {
            ...c,
            bodyVariables: c?.bodyVariables?.map((v) =>
              v.title === variableTitle ? { ...v, value: variableValue } : v
            ),
          };
        });

        return {
          ...state,
          bodyVariables: bodyVariables,
          carousel: {
            ...state.carousel,
            bodyVariables: cardsBodyVariables,
            cards: cardsVariable,
          },
        };
      }

    // update the data for edit and duplicate template
    case "update_template":
      if (data?.subcategory_type === "AUTHENTICATION") {
        const BODY = filterData(data?.components, "BODY");
        const FOOTER = filterData(data?.components, "FOOTER");
        const BUTTONS = filterData(data?.components, "BUTTONS");
        const validity_period =
          data?.validity_period && data?.validity_period !== -1 ? true : false;

        const updatedButtons = BUTTONS?.[0]?.buttons?.map((b, i) => {
          return {
            ...b,
            id: i + 1,
            text: b?.otp_type === "COPY_CODE" ? "Copy code" : "COPY_CODE",
            placeholder:
              b?.otp_type === "COPY_CODE" ? "Copy code" : "COPY_CODE",
          };
        });
        return {
          ...state,
          authentication: {
            ...state.authentication,
            // content: BODY,
            body: BODY?.[0]
              ? BODY?.[0]?.add_security_recommendation
                ? "{{otp-number}} is your verification code. For your security, do not share this code."
                : "{{otp-number}} is your verification code."
              : null,
            content: {
              securityRecommendation: BODY?.[0]?.add_security_recommendation,
              expiryTimeForCode: FOOTER?.[0]?.code_expiration_minutes
                ? true
                : false,
              expiryTime: FOOTER?.[0]?.code_expiration_minutes
                ? FOOTER?.[0]?.code_expiration_minutes
                : 10,
            },
            buttons: updatedButtons,
            messageValidity: {
              validity: validity_period,
              period: validity_period ? data?.validity_period : 10,
            },
          },
        };
      } else if (
        data?.subcategory_type === "UTILITY" ||
        data?.subcategory_type === "CUSTOM"
      ) {
        const header = data?.components?.filter((c) => c.type === "HEADER");
        const body = data?.components?.filter((c) => c.type === "BODY");
        const footer = data?.components?.filter((c) => c.type === "FOOTER");
        const buttons = data?.components?.filter((c) => c.type === "BUTTONS");
        const updatedButtons = buttons?.[0]?.buttons?.map((b, i) => {
          if (b.type === "PHONE_NUMBER")
            return {
              ...b,
              id: i + 1,
              format: b?.type,
              phone_number: b?.phone_number,
            };
          else
            return {
              id: i + 1,
              format:
                b.type === "QUICK_REPLY"
                  ? b.text !== "Stop promotions"
                    ? "CUSTOM"
                    : "OPT_OUT"
                  : b.type,
              ...b,
            };
        });

        let headerVariable = null;
        let bodyVariables = null;
        let updateButtonOrder = null;
        let updateCtaButton = [];
        let updateCustomButton = [];
        let customButtonsWithOptout = [];

        if (body?.[0]?.example?.body_text?.[0]?.length > 0) {
          bodyVariables = getVariablesFromStr(
            body?.[0].text,
            body?.[0]?.example?.body_text?.[0]
          );
        }

        if (header?.[0]?.example?.header_text?.[0]?.length > 0) {
          const res = checkVariable(header?.[0]?.text);
          if (res?.length > 0) {
            const check = bodyVariables?.filter((v) => v.title === res?.[0]);
            headerVariable = {
              title: res?.[0],
              id: check?.length > 0 ? check?.[0]?.id : 1,
              value:
                check?.length > 0
                  ? check?.[0]?.value
                  : header?.[0]?.example?.header_text?.[0],
            };
          } else headerVariable = null;
        }

        if (updatedButtons?.length > 0) {
          updateButtonOrder = getFirtstTypeOfButton(updatedButtons);
          updateCtaButton = updatedButtons?.filter((b) =>
            allowedCtaButtonTypes.includes(b.type)
          );
          updateCustomButton = updatedButtons?.filter(
            (b) =>
              allowedCustomButtonTypes.includes(b.type) &&
              b.text !== "Stop promotions"
          );
          customButtonsWithOptout = updatedButtons?.filter(
            (b) =>
              allowedCustomButtonTypes.includes(b.type) &&
              b.text === "Stop promotions"
          );

          const customButtons = [
            ...updateCustomButton,
            ...customButtonsWithOptout,
          ];

          if (updateButtonOrder === "ctaButtons" && updateCtaButton?.length > 0)
            updateButtonOrder = "ctaButtons";
          if (updateButtonOrder === "custom" && customButtons?.length > 0)
            updateButtonOrder = "custom";
        }

        const customButtons = [
          ...updateCustomButton,
          ...customButtonsWithOptout,
        ];

        return {
          header: header?.length > 0 ? header?.[0] : null,
          headerVariable: headerVariable,
          body: body?.length > 0 ? body?.[0] : null,
          bodyVariables: bodyVariables,
          footer: footer?.length > 0 ? footer?.[0] : null,
          buttons: updatedButtons?.length > 0 ? updatedButtons : [],
          ctaButtons: updatedButtons?.length > 0 ? updateCtaButton : [],
          customButtons: updatedButtons?.length > 0 ? customButtons : [],
          buttonsOrder: updatedButtons?.length > 0 ? updateButtonOrder : null,
        };
      } else if (data?.subcategory_type === "CAROUSEL") {
        const body =
          data?.components?.length > 0 &&
          data?.components?.filter((c) => c.type === "BODY");
        let carouselBodyVariables = null;

        console.log(data, body);
        if (body?.[0]?.example?.body_text?.[0]?.length > 0) {
          carouselBodyVariables = updateVariablesOrder(
            body?.[0].text,
            body?.[0]?.example?.body_text?.[0]
          );
        }

        let buttonLabels = {
          PHONE_NUMBER: "Call Phone Number",
          URL: "Visit website",
          CUSTOM: "Quick Reply",
          NONE: "None",
        };
        const cards = data?.cards;

        const header =
          cards?.[0]?.components?.length > 0 &&
          cards?.[0]?.components?.filter((c) => c?.type === "HEADER");

        const updatedCards = cards?.map((c, i) => {
          const header =
            c?.components?.length > 0 &&
            c?.components?.filter((c) => c?.type === "HEADER");
          const buttons =
            c?.components?.length > 0 &&
            c?.components?.filter((c) => c?.type === "BUTTONS");
          const body =
            c?.components?.length > 0 &&
            c?.components?.filter((c) => c?.type === "BODY");
          const bodyVariables = getVariablesFromStr(
            body?.[0]?.text,
            body?.[0]?.example?.body_text?.[0]
          );

          const newButtons = buttons?.[0]?.buttons?.map((b, i) => {
            return {
              ...b,
              format: b?.type,
              id: i + 1,
              label: buttonLabels[b?.type],
            };
          });

          return {
            ...c,
            image: header?.[0]?.example?.header_handle?.[0] || "",
            bodyText: body?.[0]?.text || "",
            bodyVariables: bodyVariables?.length > 0 ? bodyVariables : [],
            buttons: newButtons || [
              {
                id: 1,
                type: "QUICK_REPLY",
                format: "CUSTOM",
                text: "",
                label: "CUSTOM",
                slug: "",
              },
            ],
            isSelected: i === 0 ? true : false,
            card_id: i + 1,
          };
        });

        const { cards: newCards, variables } =
          updateCradsVariables(updatedCards);

        const buttons =
          cards?.[0]?.components?.length > 0 &&
          cards?.[0]?.components?.filter((c) => c?.type === "BUTTONS");

        const changeButtonsType = (type) => {
          return type === "QUICK_REPLY" ? "CUSTOM" : type;
        };

        return {
          ...state,
          body: body?.length > 0 ? body?.[0] : null,
          bodyVariables: carouselBodyVariables,
          carousel: {
            header: header?.[0]?.format || "IMAGE",
            button1:
              changeButtonsType(buttons?.[0]?.buttons?.[0]?.type) || "CUSTOM",
            button2:
              changeButtonsType(buttons?.[0]?.buttons?.[1]?.type) || "NONE",
            bodyVariables: variables,
            cards: newCards || [],
          },
        };
      }

    // if (true) {
    //   const cards = [
    //     { ...state.carousel.cards[0], ...continueDetails?.card[0] },
    //   ];

    //   console.log(continueDetails);

    //   return {
    //     ...state,
    //     id: continueDetails?.template?.id,
    //     carousel: {
    //       ...state.carousel,
    //       cards: cards,
    //     },
    //   };
    // }

    case "reset":
      return { ...INITIAL_STATE };

    default:
      break;
  }
};
