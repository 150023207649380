import { primaryColor } from "../../../constants";
import Loader from "../../../../../components/commonComponents/Loader/Index";
const index = ({
  label = "",
  loading = "",
  disabled = false,
  onClick = () => {},
  color = "",
  textColor = "",
  classNames = "",
  ...rest
}) => {
  return (
    <button
      onClick={onClick}
      disabled={loading || disabled}
      {...rest}
      className={`
      bg-[${color || primaryColor}] min-w-[4vw]  text-[#fff]
      rounded-md px-[1vw] text-[.75vw] font-[500] h-[4vh] flex items-center duration-300 ${
        disabled && "bg-slate-300 disabled-row"
      } ${classNames}`}
    >
      {loading && (
        <Loader
          Width="20px"
          Height="20px"
          loaderBg="4px solid white"
          loaderColor="4px solid var(--primary)"
        />
      )}
      {label}
    </button>
  );
};

export default index;
