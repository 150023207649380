import React, { useEffect, useState } from 'react';

// api
import { brandList } from '../../api/Api';

// utils
import { useOutletContext, useParams } from 'react-router-dom';

// hooks
import useToggle from '../../../../hooks/useToggle';

// components
import BrandsCard from '../../components/BrandsCard';
import Loader from '../../../../components/commonComponents/Loader/Index';

export default function BrandsList() {
  const { account_id } = useParams();
  const [searchKey, clearSearch, load, setLoad] = useOutletContext();

  const [isBrand, setIsBrand] = useToggle(false);
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    fetchBrandsList(null);
  }, [load]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchBrandsList(searchKey);
    }, [500]);

    return () => clearTimeout(timeout);
  }, [searchKey]);

  const fetchBrandsList = async (searchKey) => {
    setIsBrand(true);
    try {
      const res = await brandList({ acc_id: account_id, search: searchKey });
      setBrands(res?.data);
      if (setLoad) setLoad(false);
    } catch (error) {
      console.warn(error);
    } finally {
      setIsBrand(false);
    }
  };

  return (
    <div className='w-full h-full px-5 py-5'>
      <h2 className='text-2xl weight-bold text-[var(--contentText)] mb-1'>
        Brands
      </h2>
      {!isBrand ? (
        <div className='w-full h-full max-h-[80vh] grid grid-cols-4 gap-5 pt-6 pb-6 overflow-y-scroll max-[1600px]:grid-cols-3 max-[1600px]:pb-12 min-[1930px]:grid-cols-5'>
          {brands?.brands_list?.length > 0 &&
            brands?.brands_list?.map((d) => {
              return (
                <div key={d.id} className='max-w-96 h-full'>
                  <BrandsCard
                    account_id={account_id}
                    id={d.brand_id}
                    name={d.brand_name}
                    image={d.brand_img_url}
                    plan={d.brand_plan}
                    role={d.role_name}
                    status={d.brand_status}
                    remaining_amount={d.remaining_amount}
                    total_users={d.total_users}
                    clearSearch={clearSearch}
                  />
                </div>
              );
            })}
        </div>
      ) : (
        <div className='w-full h-16 bg-white rounded-md flex flex-1 justify-center items-center p-4 mt-3.5'>
          <Loader Width={35} Height={35} />
        </div>
      )}
      {!isBrand && (!brands || brands?.brands_list?.length === 0) && (
        <div className='w-full h-28 bg-white rounded-md flex flex-1 justify-center items-center p-4 mt-3.5'>
          <p>No Data</p>
        </div>
      )}
    </div>
  );
}
