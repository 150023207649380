import React from 'react';

export default function AddImageSvg({
  width = '35',
  height = '34',
  color = '#616874',
  strokeWidth = '2.66667',
  className = '',
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 35 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M7.32857 31.8905L22.4262 17.681L29.5309 24.7857M7.32857 31.8905H25.0905C28.0334 31.8905 30.419 29.5048 30.419 26.5619V17.681M7.32857 31.8905C4.38568 31.8905 2 29.5048 2 26.5619V8.80001C2 5.85712 4.38568 3.47144 7.32857 3.47144H18.8738M28.6428 12.0476L28.6428 7.02382M28.6428 7.02382L28.6428 2M28.6428 7.02382L23.619 7.02382M28.6428 7.02382L33.6667 7.02382M12.6571 11.4643C12.6571 12.9357 11.4643 14.1286 9.99285 14.1286C8.52141 14.1286 7.32857 12.9357 7.32857 11.4643C7.32857 9.99285 8.52141 8.80001 9.99285 8.80001C11.4643 8.80001 12.6571 9.99285 12.6571 11.4643Z'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
