import React from "react";
import "../../../styles/BroadcastTable.css";
import "../../../styles/Preview.css";

import "../../../../../components/commonComponents/tables/Table.css";

import { ICONS } from "../../../../../assets/icons";
import { SegmentHeaderList as audienceColumn } from "../../../../../constant/app/campaign/Broadcast";

const formatTimestamp = (timestamp) => {
  const dt = new Date(timestamp);
  const options = {
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    hourCycle: "h12",
  };

  let formattedDate = dt.toLocaleString("en-US", options).toLowerCase();

  formattedDate = formattedDate.replace(/\b\w/g, (match) =>
    match.toUpperCase()
  );

  return formattedDate;
};

const AudienceSegmentReport = ({ segment }) => {
  const columnLength = audienceColumn?.filter((item) => item?.selected)?.length;
  const segments = segment?.settings?.segment_settings;

  return (
    <div
      style={{
        overflow: "scroll",
      }}
    >
      <div className=" flex-row">
        <table
          style={{
            padding: "0 16px 0 0",
            width: columnLength > 7 ? `${200 + columnLength * 50}px` : "100%",
          }}
        >
          <div className="flex-row w-[87vw] align-center px-2">
            <div className="segment_name flex-row w-20">Segment details</div>
            <div className="w-80 flex-row align-center">
              {audienceColumn?.map(
                (column) =>
                  column?.selected && (
                    <th
                      key={column?.name}
                      className="header_name w-[10vw] text-center"
                    >
                      {column?.name}
                    </th>
                  )
              )}
            </div>
          </div>

          <>
            {segments?.map((item, index) => (
              <div className="Audience_list_main" key={item?.id}>
                <div className="flex-row align-center px-5 py-2">
                  <div className="w-20 flex-row align-center">
                    <img src={ICONS?.BroadcastSegmentIcon} alt="segment" />
                    <span>Audience List: {index + 1}</span>
                  </div>
                  <tr className="w-80 flex-row align-center text-[0.729vw] audi">
                    {audienceColumn[0]?.selected && (
                      <td className="w-[9vw] flex-row justify-center">
                        <span> {item?.audience_count}</span>
                      </td>
                    )}

                    {audienceColumn[1]?.selected && (
                      <td className="w-[10vw] flex-row justify-center pl-10">
                        <div
                          className=""
                          style={{
                            display: "flex",
                          }}
                        >
                          {" "}
                          {item?.sent_count}
                        </div>
                      </td>
                    )}

                    {audienceColumn[2]?.selected && (
                      <td className="w-[10vw] flex-row justify-center pl-10">
                        <div>{item?.delivered_count}</div>
                      </td>
                    )}
                    {audienceColumn[3]?.selected && (
                      <td className="w-[10vw] flex-row justify-center pl-10">
                        <div>{item?.opened_count}</div>
                      </td>
                    )}
                    {audienceColumn[4]?.selected && (
                      <td className="w-[10vw] flex-row justify-center pl-10">
                        <div className=""> {item?.clicks_count}</div>
                      </td>
                    )}
                    {audienceColumn[5]?.selected && (
                      <td className="w-[10vw] flex-row justify-center pl-10">
                        <div className="">{item?.leads_count}</div>
                      </td>
                    )}
                    {audienceColumn[6]?.selected && (
                      <td className="w-[10vw] flex-row justify-center pl-10">
                        <div className="flex-row align-center justify-center">
                          {item?.bounced_count}
                        </div>
                      </td>
                    )}
                  </tr>
                </div>

                <div className="segment_list">
                  <div className="flex-column">
                    {item?.segments?.map((segmentItem) => (
                      <div className="flex-row w-100" key={segmentItem?.id}>
                        <div className="w-20 flex-column">
                          <div className="flex-row align-center">
                            <img
                              src={ICONS?.BroadcastNewSegmentIcon}
                              alt="segment"
                              style={{ width: "10px", height: "10px" }}
                            />
                            <span>Segment - {segmentItem?.id}</span>
                          </div>
                          <div className="segment_column flex-row align-center">
                            {segmentItem?.status === 12 && (
                              <span>Completed @</span>
                            )}
                            {segmentItem?.status === 11 && <span>Running</span>}
                            {segmentItem?.status === 10 && (
                              <span>Starts @</span>
                            )}

                            <img
                              src={ICONS?.calenderScheduled}
                              alt=""
                              style={{ width: "15px", height: "15px" }}
                            />
                            {segmentItem?.status === 12 && (
                              <span>
                                {formatTimestamp(segmentItem?.updated_at)}
                              </span>
                            )}
                            {segmentItem?.status === 10 && (
                              <span>
                                {formatTimestamp(segmentItem?.scheduled_at)}
                              </span>
                            )}
                          </div>
                        </div>
                        <tr className="Right-sight w-80 flex-row space-between text-[0.729vw] align-center">
                          {audienceColumn[0]?.selected && (
                            <td>
                              <span className=" flex-row w-[8vw] justify-center ">
                                {segmentItem?.end}
                              </span>
                            </td>
                          )}

                          {audienceColumn[1]?.selected && (
                            <td>
                              <span className=" flex-row w-[8vw] justify-center  ">
                                {segmentItem?.sent_count}
                              </span>
                            </td>
                          )}
                          {audienceColumn[2]?.selected && (
                            <td>
                              <span className=" flex-row w-[8vw] justify-center  ">
                                {segmentItem?.delivered_count}
                              </span>
                            </td>
                          )}
                          {audienceColumn[3]?.selected && (
                            <td>
                              <span className=" flex-row w-[8vw] justify-center  ">
                                {segmentItem?.opened_count}
                              </span>
                            </td>
                          )}
                          {audienceColumn[4]?.selected && (
                            <td>
                              <span className=" flex-row w-[8vw] justify-center ">
                                {segmentItem?.clicks_count}
                              </span>
                            </td>
                          )}
                          {audienceColumn[5]?.selected && (
                            <td>
                              <span className=" flex-row w-[8vw] justify-center ">
                                {segmentItem?.leads_count}
                              </span>
                            </td>
                          )}
                          {audienceColumn[6]?.selected && (
                            <td>
                              <span className=" flex-row w-[8vw] justify-center ">
                                {segmentItem?.bounced_count}
                              </span>
                            </td>
                          )}
                        </tr>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </>
        </table>
      </div>
    </div>
  );
};

export default AudienceSegmentReport;
