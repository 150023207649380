import React, { useEffect, useRef, useState } from "react";
import { ICONS } from "../../../../assets/icons/index";
import {
  useAspDispatch,
  useAspSelector,
} from "../../../../test/jest-redux-hooks";
import { broadcastPartDeleteApi, getBroadcastPastApi } from "../../api/Api";
import { updateGlobalLoader } from "../../../../reduxToolkit/appSlice";
import CustomDelete from "../../../../assets/customSVG/CustomDelete";
import Loader from "../../../../components/commonComponents/Loader/Index";
import { setContactsRemaining1 } from "../../../../reduxToolkit/broadcastSlice";

const FileDownload = ({
  response,
  setResponse,
  broadcastId,
  setResponse1,
  setSelectedId1,
  broadCasts,
  setIndexRem,
  setSelectedIndexSegments,
  selectedIndex,
}) => {
  const [loadingStates, setLoadingStates] = useState({});
  const [hoverStates, setHoverStates] = useState({});
  const dispatch = useAspDispatch();
  const { dateCombine } = useAspSelector((state) => state?.Campaign);

  const handleMouseOver = (id) => {
    setHoverStates((prevState) => ({
      ...prevState,
      [id]: true,
    }));
  };

  const handleMouseLeave = (id) => {
    setHoverStates((prevState) => ({
      ...prevState,
      [id]: false,
    }));
  };

  const handleDelete = (broadcastPartId, index) => {
    setLoadingStates((prevState) => ({
      ...prevState,
      [broadcastPartId]: true,
    }));

    if (response?.length === 0) {
      setSelectedId1(false);
    }

    setSelectedIndexSegments(false);

    broadcastPartDeleteApi(broadcastId, broadcastPartId)
      .then((res) => {
        if (res.status === 200) {
          const updatedFileData = response.filter(
            (file) => file.broadcastPartId !== broadcastPartId
          );

          const updatedResponse = response.filter(
            (file) => file.broadcastPartId !== broadcastPartId
          );

          const adjustedResponse = updatedFileData.map((file, index) => ({
            ...file,
            id: index + 1,
            segments:
              selectedIndex === "send_now"
                ? [{ id: 1, start: 0, end: 0, delay: 0 }]
                : [{ id: 1, start: 0, end: 0, schedule_on: dateCombine }],
          }));

          const adjustedupdatedResponse = updatedResponse.map(
            (file, index) => ({
              ...file,
              id: index + 1,
              segments:
                selectedIndex === "send_now"
                  ? [{ id: 1, start: 0, end: 0, delay: 0 }]
                  : [{ id: 1, start: 0, end: 0, schedule_on: dateCombine }],
            })
          );

          setResponse(adjustedResponse);
          setResponse(adjustedupdatedResponse);
          setResponse1(adjustedResponse);
          setResponse1(adjustedupdatedResponse);
        }
      })

      .finally(() => {});
    setIndexRem(false);
  };

  useEffect(() => {
    if (response?.length === 0 && broadCasts && broadCasts.id) {
      dispatch(updateGlobalLoader(true));
      getBroadcastPastApi(broadCasts?.id)
        .then((res) => {
          dispatch(updateGlobalLoader(false));
          if (res && res.data?.broadcast_parts?.length > 0) {
            setSelectedId1(true);

            if (broadCasts.settings.segment_mode === "merge") {
              const updatedResponse = res.data.broadcast_parts.map(
                (part, index) => {
                  const segmentSettings =
                    broadCasts.settings.segment_settings.find(
                      (setting) =>
                        setting.broadcast_part_id === part.broadcastPartId
                    );

                  return {
                    ...part,
                    id: index + 1,
                    segments: segmentSettings ? segmentSettings.segments : [],
                  };
                }
              );
              setResponse(updatedResponse);
              const updatedResponse1 = res.data.broadcast_parts.map(
                (part, index) => {
                  const segmentSettings =
                    broadCasts.settings.segment_settings.find(
                      (setting) =>
                        setting.broadcast_part_id === part.broadcastPartId
                    );
                  return {
                    ...part,
                    id: index + 1,
                    segments: segmentSettings ? [] : segmentSettings.segments,
                  };
                }
              );
              setResponse1(updatedResponse1);
            } else {
              const updatedResponse1 = res.data.broadcast_parts.map(
                (part, index) => {
                  const segmentSettings =
                    broadCasts.settings.segment_settings.find(
                      (setting) =>
                        setting.broadcast_part_id === part.broadcastPartId
                    );

                  return {
                    ...part,
                    id: index + 1,
                    segments: segmentSettings ? segmentSettings.segments : [],
                  };
                }
              );
              setResponse1(updatedResponse1);
              const updatedResponse = res.data.broadcast_parts.map(
                (part, index) => {
                  const segmentSettings =
                    broadCasts.settings.segment_settings.find(
                      (setting) =>
                        setting.broadcast_part_id === part.broadcastPartId
                    );
                  return {
                    ...part,
                    id: index + 1,
                    segments: segmentSettings ? [] : segmentSettings.segments,
                  };
                }
              );
              setResponse(updatedResponse);
            }

            const metrics = {};
            res.data.broadcast_parts.forEach((file) => {
              metrics[file.id] = file.file_metrics;
            });
          }
        })
        .finally(() => {
          dispatch(updateGlobalLoader(false));
        });
    }

    // if (
    //   location?.state?.item &&
    //   broadCasts?.settings?.send_setting === "send_now" &&
    //   broadCasts?.settings?.segment_settings?.[0]?.segments?.[0]?.delay
    // ) {
    //   setResponse({});
    //   setResponse1({});
    // }
  }, [response, broadCasts]);

  return (
    <div className="flex-column">
      {response?.map((response, index) => {
        return (
          <>
            {response?.file_name && (
              <div
                className="FileMain w-100 flex-row space-between align-center"
                key={response.id}
              >
                <div className=" File-container flex-row align-center ">
                  <div className=" File-wrapper flex-column">
                    <div className="flex-row align-center pointer">
                      <img src={ICONS?.AudienceFileIcon} alt="csv icon" />
                      <div className="flex-column">
                        <p>{response?.file_name}</p>

                        {response?.valid_count > 0 && (
                          <div className="flex-row align-center ">
                            <p className="flex-row align-center">
                              <img
                                src={ICONS?.BroadcastProfileIcon}
                                alt="BroadcastRedInfoIcon"
                                style={{
                                  width: "15px",
                                }}
                              />
                              <p
                                className="flex-row "
                                style={{ padding: "0px 5px" }}
                              >
                                <p className="valid-data">
                                  {response?.valid_count}
                                </p>
                                <span
                                  className="pl-2 pr-6 flex-row align-center"
                                  style={{ borderRight: "1px solid #D1D3D8" }}
                                >
                                  Unique Contacts
                                </span>
                              </p>
                            </p>
                            <p className="flex-row align-center pl-4">
                              <img
                                src={ICONS?.BroadcastCopyIcon}
                                alt="BroadcastRedInfoIcon"
                                style={{
                                  width: "15px",
                                }}
                              />
                              <p
                                className="flex-row align-center"
                                style={{ padding: "0px 5px" }}
                              >
                                <p valid-data className="valid-data">
                                  {response?.duplicate_count}
                                </p>
                                <span
                                  className="pl-2 px-6"
                                  style={{ borderRight: "1px solid #D1D3D8" }}
                                >
                                  Duplicates
                                </span>
                              </p>
                            </p>
                            <p className="flex-row align-center">
                              <img
                                src={ICONS?.BroadcastRedInfoIcon}
                                alt="BroadcastRedInfoIcon"
                                style={{
                                  width: "15px",
                                }}
                              />
                              <p className="valid-data flex-row pt-5 pb-5 pl-5">
                                {response?.invalid_count}
                              </p>
                              <span className="">Invalids</span>
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  key={response?.id}
                  onMouseOver={() => handleMouseOver(response?.id)}
                  onMouseLeave={() => handleMouseLeave(response?.id)}
                  style={{
                    background: hoverStates[response?.id]
                      ? "#FFEEED"
                      : "transparent",
                    borderRadius: "4px",
                    border: hoverStates[response?.id] ? "0px solid red" : "",
                  }}
                  className="pointer"
                  onClick={() => handleDelete(response.broadcastPartId)}
                >
                  {loadingStates[response.broadcastPartId] ? (
                    <Loader Width={20} Height={20} loaderBg="white" />
                  ) : (
                    <CustomDelete
                      color={
                        hoverStates[response?.file_name] ? "#F96056" : "red"
                      }
                    />
                  )}
                </div>
              </div>
            )}
          </>
        );
      })}
    </div>
  );
};

export default FileDownload;
