//  assets
import DocSvg from 'assets/customSVG/DocSvg';
import ImageSvg from 'assets/customSVG/ImageSvg';
import VideoSvg from 'assets/customSVG/VideoSvg';
import Map from 'assets/customSVG/Map';
import { ICONS } from 'assets/icons';
import { images } from 'assets/images';

export const LANGUAGE_OPTIONS = [
  // {
  //   value: "Arabic",
  //   label: "arabic",
  // },
  {
    value: "Bengali",
    label: "Bengali",
  },
  // {
  //   value: "Chinese",
  //   label: "chinese",
  // },
  // {
  //   value: "Dutch",
  //   label: "dutch",
  // },
  {
    value: 'English',
    label: 'English',
  },
  // {
  //   value: "German",
  //   label: "german",
  // },
  {
    value: "Hindi",
    label: "Hindi",
  },
  {
    value: 'Tamil',
    label: 'Tamil',
  },
  // {
  //   value: "Afrikaans",
  //   label: "Afrikaans",
  // },
  // {
  //   value: "Albanian",
  //   label: "Albanian",
  // },
  // {
  //   value: "Azerbaijani",
  //   label: "Azerbaijani",
  // },
  // {
  //   value: "Bulgarian",
  //   label: "Bulgarian",
  // },
  // {
  //   value: "Catalan",
  //   label: "Catalan",
  // },
  // {
  //   value: "Chinese-CHN",
  //   label: "Chinese-CHN",
  // },
  // {
  //   value: "Chinese-HKG",
  //   label: "Chinese-HKG",
  // },
  // {
  //   value: "Chinese-TAI",
  //   label: "Chinese-TAI",
  // },
  // {
  //   value: "Croatian",
  //   label: "Croatian",
  // },
  // {
  //   value: "Czech",
  //   label: "Czech",
  // },
  // {
  //   value: "Danish",
  //   label: "Danish",
  // },
  // {
  //   value: "English-US",
  //   label: "English-US",
  // },
  {
    label: 'English (US)',
    value: 'English (US)',
  },
  {
    value: 'English (UK)',
    label: 'English (UK)',
  },
  // {
  //   value: "Estonian",
  //   label: "Estonian",
  // },
  // {
  //   value: "Filipino",
  //   label: "Filipino",
  // },
  // {
  //   value: "Finnish",
  //   label: "Finnish",
  // },
  // {
  //   value: "French",
  //   label: "French",
  // },
  // {
  //   value: "Georgian",
  //   label: "Georgian",
  // },
  // {
  //   value: "German",
  //   label: "German",
  // },
  // {
  //   value: "Greek",
  //   label: "Greek",
  // },
  // {
  //   value: "Gujarati",
  //   label: "Gujarati",
  // },
  // {
  //   value: "Hausa",
  //   label: "Hausa",
  // },
  // {
  //   value: "Hebrew",
  //   label: "Hebrew",
  // },
  // {
  //   value: "Hungarian",
  //   label: "Hungarian",
  // },
  // {
  //   value: "Indonesian",
  //   label: "Indonesian",
  // },
  // {
  //   value: "Irish",
  //   label: "Irish",
  // },
  // {
  //   value: "Italian",
  //   label: "Italian",
  // },
  // {
  //   value: "Japanese",
  //   label: "Japanese",
  // },
  {
    value: "Kannada",
    label: "Kannada",
  },
  // {
  //   value: "Kazakh",
  //   label: "Kazakh",
  // },
  // {
  //   value: "Kinyarwanda",
  //   label: "Kinyarwanda",
  // },
  // {
  //   value: "Korean",
  //   label: "Korean",
  // },
  // {
  //   value: "Kyrgyz-Kyrgyzstan",
  //   label: "Kyrgyz-Kyrgyzstan",
  // },
  // {
  //   value: "Lao",
  //   label: "Lao",
  // },
  // {
  //   value: "Latvian",
  //   label: "Latvian",
  // },
  // {
  //   value: "Lithuanian",
  //   label: "Lithuanian",
  // },
  // {
  //   value: "Macedonian",
  //   label: "Macedonian",
  // },
  // {
  //   value: "Malay",
  //   label: "Malay",
  // },
  {
    value: "Malayalam",
    label: "Malayalam",
  },
  {
    value: "Marathi",
    label: "Marathi",
  },
  // ///
  // {
  //   value: "Norwegian",
  //   label: "Norwegian",
  // },
  // {
  //   value: "Persian",
  //   label: "Persian",
  // },
  // {
  //   value: "Polish",
  //   label: "Polish",
  // },
  // {
  //   value: "Portuguese-BR",
  //   label: "Portuguese-BR",
  // },
  // {
  //   value: "Portuguese-POR",
  //   label: "Portuguese-POR",
  // },
  // {
  //   value: "Punjabi",
  //   label: "Punjabi",
  // },
  // {
  //   value: "Romanian",
  //   label: "Romanian",
  // },
  // {
  //   value: "Russian",
  //   label: "Russian",
  // },
  // {
  //   value: "Serbian",
  //   label: "Serbian",
  // },
  // {
  //   value: "Slovak",
  //   label: "Slovak",
  // },
  // {
  //   value: "Slovenian",
  //   label: "Slovenian",
  // },
  // {
  //   value: "Spanish",
  //   label: "Spanish",
  // },
  // {
  //   value: "Spanish-ARG",
  //   label: "Spanish-ARG",
  // },
  // {
  //   value: "Spanish-SPA",
  //   label: "Spanish-SPA",
  // },
  // {
  //   value: "Spanish-MEX",
  //   label: "Spanish-MEX",
  // },
  // {
  //   value: "Swahili",
  //   label: "Swahili",
  // },
  // {
  //   value: "Swedish",
  //   label: "Swedish",
  // },
  {
    value: "Telugu",
    label: "Telugu",
  },
  // {
  //   value: "Thai",
  //   label: "Thai",
  // },
  // {
  //   value: "Turkish",
  //   label: "Turkish",
  // },
  // {
  //   value: "Ukrainian",
  //   label: "Ukrainian",
  // },
  // {
  //   value: "Urdu",
  //   label: "Urdu",
  // },
  // {
  //   value: "Uzbek",
  //   label: "Uzbek",
  // },
  // {
  //   value: "Vietnamese",
  //   label: "Vietnamese",
  // },
  // {
  //   value: "Zulu",
  //   label: "Zulu",
  // },
];

export const CATEGORIES_OPTIONS = [
  { label: 'Marketing', value: 'MARKETING', icon: ICONS?.Speaker },
  { label: 'Utility', value: 'UTILITY', icon: ICONS?.bell },
  { label: 'Authentication', value: 'AUTHENTICATION', icon: ICONS?.Key },
];

export const HEADER_OPTIONS = [
  { label: 'None', value: 'None' },
  { label: 'Text', value: 'Text' },
  { label: 'Media', value: 'Media' },
];

export const WHATSAPP_CATEGORY_OPTIONS = [
  {
    id: 1,
    icon: ICONS?.setting,
    value: 'Automotive',
    selected: false,
  },
  {
    id: 2,
    icon: ICONS?.beautySpa,
    value: 'Beauty, spa & salon',
    selected: false,
  },
  {
    id: 3,
    icon: ICONS?.cloths,
    value: 'Clothing',
    selected: false,
  },
  {
    id: 4,
    icon: ICONS?.eduCap,
    value: 'Education',
    selected: false,
  },
  {
    id: 5,
    icon: ICONS?.tv,
    value: 'Entertainment',
    selected: false,
  },
  {
    id: 6,
    icon: ICONS?.CalendarIcon,
    value: 'Event planning & service',
    selected: false,
  },
  {
    id: 7,
    icon: ICONS?.dollar,
    value: 'Finance & banking',
    selected: false,
  },
  {
    id: 8,
    icon: ICONS?.food,
    value: 'Food & groceries',
    selected: false,
  },
];

export const BROADCAST_DATA = [
  {
    image: images?.Roast,
    description:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    views: {
      like: 5000,
      watch: 4500,
      share: 3500,
    },
  },
  {
    image: images?.Roast,
    description:
      //
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    views: {
      like: 5000,
      watch: 5000,
      share: 5000,
    },
  },
];

export const BUTTONS_LIST = [
  {
    value: 'Visit Website',
    label: 'URL',
    disabled: false,
    length: 2,
  },
  {
    value: 'Call Phone Number',
    label: 'PHONE_NUMBER',
    disabled: false,
    length: 1,
  },
  // { value: 'Complete form', label: 'numberButton', disabled: true, length: 1 },
  {
    value: 'Copy Offer Code',
    label: 'COPY_CODE',
    disabled: false,
    length: 1,
  },
];

export const CUSTOM_BUTTONS_LIST = [
  {
    label: 'Marketing opt-out',
    value: 'Opt-out',
    type: 'OPT_OUT',
    disabled: false,
    length: 1,
  },
  {
    label: 'Custom',
    value: 'Custom',
    type: 'CUSTOM',
    disabled: false,
    length: null,
  },
  // { value: 'Complete form', label: 'numberButton', disabled: true, length: 1 },
];

export const marketingTypes = ['custom', 'carousel'];
export const utilityTypes = ['utility'];
export const authenticationTypes = ['authentication'];
export const zeroTabAutoFill = ['zero-tap'];
export const oneTabAutoFill = ['one-tap'];
export const copyCode = ['copy-code'];

export const MEDIA_TYPES = [
  {
    id: 1,
    label: 'Images',
    icon: ImageSvg,
    format: 'IMAGE',
    disabled: false,
  },
  {
    id: 2,
    label: 'Video',
    icon: VideoSvg,
    format: 'VIDEO',
    disabled: false,
  },
  {
    id: 3,
    label: 'Document',
    icon: DocSvg,
    format: 'DOCUMENT',
    disabled: false,
  },
  {
    id: 4,
    label: 'Location',
    icon: Map,
    format: 'LOCATION',
    disabled: true,
  },
];
