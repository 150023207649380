import React from "react";
import { ICONS } from "../../../../../assets/icons";
import { Search } from "../Search";

const NoCrm = ({ no }) => {
  return (
    <div
      className="flex-column align-center justify-center mt-20 pt-20"
      style={{ height: "" }}
    >
      <div className="w-full flex-column align-center justify-center">
        <img
          src={ICONS?.crmEmpty}
          alt="noTemplate"
          className="w-[10vw] h-[10vw]"
        />
        <span className="font-larger weight-semibold text-[var(--textBlack)] py-[0.7vw]">
          Your CRM is empty
        </span>
        <span className="w-1/5 font-md text-[var(--font-600)] text-center">
          There are currently no contacts listed in your CRM. Add contacts to
          manage.
        </span>
      </div>
      <div>{no && <Search />}</div>
    </div>
  );
};

export default NoCrm;
