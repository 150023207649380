import React, { useEffect, useState } from "react";
import { Search } from "./Search";
import { MainFilter } from "./MainFilter";
import { useSelector } from "react-redux";
import { contactOriginApi, crmListShow } from "../../api/Api";
import {
  useAspDispatch,
  useAspSelector,
} from "../../../../test/jest-redux-hooks";
import Header from "./contact/Header";
import MainContactList from "./contact/MainContactList";
import { useParams } from "react-router-dom";
import { setOrigins } from "../../../../reduxToolkit/crmSlice";

let page = 1;
let count = 0;
let count1 = 0;

const index = () => {
  const { crmColumn, originApi } = useSelector((store) => store.crm);
  const [crmLists, setCrmLists] = useState([]);
  const { currentBrand, toggleToast } = useAspSelector((state) => state.app);
  const [isLoading, setIsLoading] = useState(false);
  const [no, setNo] = useState(false);
  const [isFilter, setIsFilter] = useState("");
  const [filters, setFilters] = useState({});
  const [origins, setOrigins] = useState([]);
  const [newContactActive, setNewContactActive] = useState(false);

  const id = useParams();
  const dispatch = useAspDispatch();

  const getCrmList = (brandId, payload) => {
    if (brandId) {
      const queryParams = {
        page: payload?.page,
        limit: payload?.limit,
        search: payload?.search,
        filters: Object.keys(filters)?.length > 0 ? filters : undefined,
      };

      crmListShow(brandId, queryParams)
        .then((res) => {
          if (res && res?.data?.data?.length > 0) {
            setNo(true);
            const newData =
              payload?.page === 1
                ? res?.data?.data
                : [...crmLists, ...res?.data?.data];
            setCrmLists(newData);
            count = res?.data?.contact_initial_count;
            count1 = res?.data?.contact_total_count;
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setCrmLists([]);
    }
  };

  useEffect(() => {
    page = 1;
    setCrmLists([]);
    setIsLoading(true);

    if (currentBrand?.brand_id) {
      getCrmList(currentBrand?.brand_id, {
        page: page,
        limit: 10,
        search: isFilter,
        filters: filters,
      });
    }
  }, [currentBrand, isFilter, filters]);

  const loadNextPage = () => {
    page += 1;
    setIsLoading(true);
    if (crmLists.length < count && !isLoading) {
      // setIsLoading(false);

      getCrmList(currentBrand?.brand_id, {
        page: page,
        limit: 10,
        search: isFilter,
        filters: filters,
      });
    }
  };

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  const templateSearch = (value) => {
    setIsFilter(value);
  };

  const contactOrigins = () => {
    contactOriginApi().then((res) => {
      if (res && res?.data?.contact_origin_types?.length > 0) {
        setOrigins(res?.data?.contact_origin_types);
      }
    });
  };

  useEffect(() => {
    if (newContactActive) {
      contactOrigins();
    }
  }, [newContactActive]);

  const [visibleColumns, setVisibleColumns] = useState(
    crmColumn
      ?.filter((column) => column?.selected)
      ?.map((column) => column?.label)
  );

  return (
    <div className="h-full bg-[var(--white)] w-[calc(100vw-18vw-2.28vw+23px)]">
      <Search
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        templateSearch={templateSearch}
        isFilter={isFilter}
        setIsFilter={setIsFilter}
        setNo={setNo}
        no={no}
        origins={origins}
        setNewContactActive={setNewContactActive}
        newContactActive={newContactActive}
      />
      <MainFilter onFilterChange={handleFilterChange} />
      <Header count={count} count1={count1} crmLists={crmLists} />
      <MainContactList
        crmLists={crmLists}
        isLoading={isLoading}
        visibleColumns={visibleColumns}
        count={count}
        loadNextPage={loadNextPage}
        isFilter={isFilter}
        setNo={setNo}
        no={no}
        setNewContactActive={setNewContactActive}
        newContactActive={newContactActive}
      />
    </div>
  );
};

export default index;
