import React from 'react';

export default function Shop({
  width = '26',
  height = '24',
  color = '#ED4F9D',
  strokeWidth = '2.5',
  className = '',
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 26 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M4.70503 2C3.84353 2.00233 3.0887 2.577 2.8567 3.40667L1.46503 7.15333C1.04436 8.73517 1.6577 10.4113 3.00003 11.3483C4.02886 12.0617 5.3412 12.2277 6.5152 11.793C7.6892 11.3585 8.57703 10.3778 8.89336 9.16667L9.17336 7.16667L9.98503 2H4.70503ZM21.03 2C21.8915 2.00233 22.6464 2.577 22.8784 3.40667L24.4284 6.915C24.5025 7.07483 24.5584 7.2425 24.595 7.415C24.8872 8.926 24.2622 10.4673 23 11.3483C21.9712 12.0617 20.6589 12.2277 19.4849 11.793C18.3109 11.3585 17.423 10.3778 17.1067 9.16667L16.8334 7.15333L15.7517 2H21.03Z'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.98511 2H15.7518M9.16677 7.15333L9.15011 8.12333C9.15011 10.2468 10.8716 11.9683 12.9951 11.9683C15.1186 11.9683 16.8401 10.2468 16.8401 8.12333L16.8334 7.15333M3.00011 11.3483L2.90844 18.6267C2.89778 19.5175 3.24428 20.3757 3.87061 21.0095C4.49694 21.6433 5.35078 22 6.24178 22H9.53511M23.0004 11.3483L22.8704 18.6667C22.8704 20.5075 21.3781 22 19.5371 22H16.2038M16.2038 22V20.3333C16.2038 18.4923 14.7114 17 12.8704 17C11.0294 17 9.53711 18.4923 9.53711 20.3333V22H16.2038Z'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
