import React from "react";

import { ICONS } from "../../../../assets/icons/index";
import { Button } from "../../../form/Button/Button";
import { cn } from "../../../../helper/cn";

function NewAlert({
  handleCancel,
  handleYes,
  handleNo,
  content,
  yesName,
  noName,
  loading,
  icon,
  title,
  type = "old",
}) {
  return (
    <div
      className={cn(
        "p-[30px] alertInfoWrapper",
        type === "new" ? "rounded-2xl" : ""
      )}
    >
      <div className="header w-100 flex-row  flex-start">
        {icon && (
          <img
            className="alert-icons pointer"
            src={icon}
            alt="popup"
            onClick={handleCancel}
          />
        )}
      </div>
      <div className="flex-column mt-20">
        {/* <img className="alert pointer" src={ICONS.AlertIcon} alt="popup" /> */}
        <div className="mt-10 title">{title}</div>
        <div className="mt-20 content">{content}</div>
        <div className="flex-row flex-end align-center mt-10  gap-5">
          <div
            className="mr-[15px] noButton cursor-pointer"
            onClick={() => handleNo()}
          >
            {yesName}
          </div>
          <Button
            label={noName}
            size={"medium"}
            onClick={() => handleYes()}
            loading={loading}
            data-testid="custom-button"
          />
        </div>
      </div>
    </div>
  );
}

export default NewAlert;
