import React, { useState } from "react";
import RadioButton from "../../../../../components/form/radio/RadioButton";
import ListViewSearch from "../../../../../components/commonComponents/ListViewHeader/ListViewSearch";

export const Address = ({
  countryListss,
  selectedLocation,
  handleLocationClick,
  locationType,
}) => {
  const [searchKey, setSearchKey] = useState("");

  const handleSearch = (value) => {
    setSearchKey(value);
  };
  const filteredCountryList = countryListss.filter((data) =>
    data.name.toLowerCase().includes(searchKey.toLowerCase())
  );
  return (
    <div className="country-popover flex-column w-100 p-10 ">
      <ListViewSearch searchingText={(text) => handleSearch(text)} />
      {filteredCountryList?.map((data) => {
        return (
          <div
            className="flex-column pointer p-10"
            key={data?.id}
            onClick={() => {
              handleLocationClick(
                "country",
                data?.name, 
                data?.id,
                locationType
              );
            }}
          >
            <div className="flex-row align-center justify-between">
              <span>{data?.name}</span>
              {selectedLocation.country.id === data?.id && (
                <RadioButton selected={true} />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
