import React from "react";
import "../../../styles/Broadcast.css";

import { ICONS } from "../../../../../assets/icons";

import RadioButton from "../../../../../components/form/radio/RadioButton";
import { SendSettingList } from "../../../../../constant/app/campaign/Broadcast";
import SendFormView from "./SendFormView";

const SettingBroadView = ({ segment }) => {
  const segmentSettings = segment?.settings;
  const sendLaterDt = segmentSettings.send_later_dt;

  const [date, timePart1, timePart2] = sendLaterDt.split(" ");
  const time = `${timePart1} ${timePart2}`;

  const removeSpacesAndUnderscoresAndLowercase = (str) => {
    return str ? str.replace(/[\s_]/g, "").toLowerCase() : "";
  };


  return (
    <div className="BroadcastSetting-mainContainer flex-row">
      <div className="flex-column w-70 ">
        <div className="setting-wrapper">
          <span>Broadcast Name</span>
          <div
            className="mt-10 flex-row align-center pl-10"
            style={{
              height: "4.5vh",
              border: "1px solid var(--font-100)",
              borderRadius: "6px",
              fontSize: "0.75vw",
              fontWeight: "500",
              color: "var(--textBlack)",
            }}
          >
            {segment?.name}
          </div>
        </div>

        <div className="divider" />

        <div className="setting-wrapper flex-column">
          <span className="channels mt-10">Select Channels</span>
          <span>Select your communication channel</span>
          <div className="Broadcast-channel flex-row space-between mt-10">
            <div className="flex-row align-center">
              <img src={ICONS?.whatsApp} alt="whatsapp" />
              <span className="setting-whatsapp">WhatsApp</span>
            </div>
            <RadioButton selected={true} style={{ marginTop: "-10px" }} />
          </div>
        </div>

        <div className="divider" />

        <div className="setting-wrapper flex-column mt-10">
          <SendFormView segmentSettings={segmentSettings} />
        </div>

        <div className="divider" />

        <div className="flex-column">
          <div className="SendSetting-container flex-column">
            <div
              className="setting-wrapper flex-column"
              style={{ marginBottom: "0 !important" }}
            >
              <span>Select setting</span>
              <span>When should we send this broadcast?</span>
            </div>

            {SendSettingList?.map((data) => {
              return (
                <div
                  className="w-100 flex-column align-center"
                  style={{ padding: "0px 20px" }}
                  key={data?.id}
                >
                  <div
                    className="w-100 flex-row align-center"
                    style={{
                      opacity:
                        removeSpacesAndUnderscoresAndLowercase(data.name) ===
                        removeSpacesAndUnderscoresAndLowercase(
                          segmentSettings?.send_setting
                        )
                          ? 1
                          : 0.2,
                    }}
                  >
                    <label className="checkBoxContainer mb-10">
                      <input
                        type="checkbox"
                        name="checkbox"
                        defaultChecked={
                          removeSpacesAndUnderscoresAndLowercase(data?.name) ===
                          removeSpacesAndUnderscoresAndLowercase(
                            segmentSettings?.send_setting
                          )
                        }
                      />
                    </label>
                    <div className="setting-setting flex-column">
                      <span>{data?.name}</span>

                      <p>{data?.content}</p>
                    </div>
                  </div>
                </div>
              );
            })}
            {segmentSettings?.send_setting === "send_later" && (
              <div className="flex-row align-center mt-10 ml-20">
                <span
                  style={{
                    border: "1px solid var(--font-50)",
                    padding: "9px 20px 9px 10px",
                    borderRadius: "6px",
                    fontSize: "0.75vw",
                    fontWeight: "500",
                    color: "var(--textBlack)",
                  }}
                >
                  {date}
                </span>
                <input
                  type="text"
                  defaultValue={time}
                  className="date-time ml-10"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingBroadView;
