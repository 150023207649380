import React, { useState } from "react";
import Dropdown from "../../commonComponents/dropdown";
import Button from "../../commonComponents/button";
import AudienceFile from "../../../../../assets/icons/AudienceFile.svg";
import SampleXLSXFile from "../../../../../assets/SampleXLSXFile.xlsx";

import {
  contactFilters,
  crmFontColor,
  csvInfo,
  primaryColor,
} from "../../../constants";
import { ICONS } from "../../../../../assets/icons";
import redDelete from "../../../../../assets/icons/redelete.svg";
import {
  handleContactModelSelection,
  handelDeleteFile,
  setFiles,
} from "../../../../../reduxToolkit/crmSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import UploadLoading from "./UploadLoading";
import CsvInfo from "./CsvInfo";

import SelectMergeOption from "./SelectMergeOption";
import { read, utils } from "xlsx";
import { uploadFile } from "../../../../../helper/uploadFile";

const ImportContacts = ({
  selectOpen,
  setSelectOpen,
  importStep,
  setImportStep,
}) => {
  const [contactsFilters, setContactsFilters] = useState([...contactFilters]);
  // const [selectedContactSource, setSelectedContactSource] = useState(null);
  const { files } = useSelector((store) => store.crm);
  const dispatch = useDispatch();
  const [mergeOption, setMergeOption] = useState("REM");
  const handleModelContactSelection = (id) => {
    // dispatch(handleContactModelSelection({ id }));
    setContactsFilters((prev) => {
      return prev.map((item) => {
        if (item.id === id) {
          return { ...item, selected: true };
        }
        return { ...item, selected: false };
      });
    });

    setSelectOpen(false);
  };
  const selectedContactSource = contactsFilters.find((item) => item.selected);
  const Icon = selectedContactSource?.icon;

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    dispatch(
      setFiles({
        file: {
          id: files.length + 1,
          fileName: e.target.files[0].name,
          step: 1,
          progress: 70,
          openFileInfo: false,
          // fileObj: e.target.files[0],
        },
      })
    );
  };

  const processContacts = (contacts, phoneRegex) => {
    // console.log({ contacts, phoneRegex });
    const uniquePhoneNumbers = new Set();
    const validContacts = [];
    const duplicateContacts = [];
    const invalidContacts = [];

    contacts.forEach((contact) => {
      const countryCode = Number(contact["country-code"]);
      const phoneNumber = Number(contact["mobile-number"]);
      // setMobile(phoneNumber);
      const fullPhoneNumber = countryCode
        ? `${countryCode}${phoneNumber}`
        : phoneNumber;

      const isValid = phoneRegex.test(fullPhoneNumber);
      const isDuplicate = uniquePhoneNumbers.has(phoneNumber);

      if (isValid) {
        // setSelectedId1(true);
        if (!isDuplicate) {
          uniquePhoneNumbers.add(phoneNumber);
          validContacts.push(contact);
        } else {
          duplicateContacts.push(contact);
        }
      } else {
        invalidContacts.push(contact);
      }
    });

    const mapContactData = (contactList) =>
      contactList.map((contact) => ({
        row: contact.__rowNum__ || null,
        mobile1: Number(
          contact["country-code"]
            ? `${contact["country-code"]}${contact["mobile-number"]}`
            : `${contact["mobile-number"]}`
        ),

        details: {
          ["email-id"]: contact["email-id"] || null,
          ["last-name"]: contact["last-name"] || null,
          ["media-url"]: contact["media-url"] || null,
          ["shop-name"]: contact["shop-name"] || null,
          ["first-name"]: contact["first-name"] || null,
          ["website-url"]: contact["website-url"] || null,
          ["mobile-number"]: Number(contact["mobile-number"]) || null,
          ["custom-variable"]: contact["custom-variable"] || null,
          ["custom-variable1"]: contact["custom-variable1"] || null,
          ["custom-variable2"]: contact["custom-variable2"] || null,
          ["custom-variable3"]: contact["custom-variable3"] || null,
          ["custom-variable4"]: contact["custom-variable4"] || null,
          ["custom-variable5"]: contact["custom-variable5"] || null,
        },
      }));

    if (validContacts?.length === 0 && contacts[0]["mobile-number"]) {
      return;
    }

    const validData =
      validContacts?.length > 0 ? mapContactData(validContacts) : 0;
    const invalidData =
      invalidContacts?.length > 0 ? mapContactData(invalidContacts) : 0;
    const duplicateData =
      duplicateContacts?.length > 0 ? mapContactData(duplicateContacts) : 0;

    return {
      validData,
      invalidData,
      duplicateData,
      validContacts,
      duplicateContacts,
      invalidContacts,
    };
  };
  const handleFile = async (file) => {
    // const filesName = file?.name?.split('.')[0];
    const phoneRegex = /^91[0-9]{10}$/;

    try {
      // let responseFile = await uploadFile(file);

      const data = await file.arrayBuffer();
      const workbook = read(data, { type: "array" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const contacts = utils.sheet_to_json(worksheet);

      // if (Object.keys(contacts).length === 0) {
      //   // setFileLoader(false);
      // }

      const {
        validData,
        invalidData,
        duplicateData,
        validContacts,
        duplicateContacts,
        invalidContacts,
      } = processContacts(contacts, phoneRegex);

      // if (!contacts[0]['mobile-number']) {
      //   // setFileLoader(false);
      //   // return;
      // }

      // if (responseFile) {
      //   const newFileData = {
      //     id: response.length + 1,
      //     file_name: filesName,
      //     file_path: responseFile,
      //     broadcastPartId: null,
      //     valid_count: validContacts.length,
      //     duplicate_count: duplicateContacts.length,
      //     invalid_count: invalidContacts.length,
      //     segments: [newData],
      //   };

      //   const payLoad = {
      //     file_name: filesName,
      //     file_path: responseFile,
      //   };

      //   const createBroadcastPartResponse = await broadcastPartApi(id, payLoad);

      //   if (createBroadcastPartResponse?.status === 200)
      //     if (createBroadcastPartResponse.status === 200) {
      //       const broadcastPartId = createBroadcastPartResponse.data.id;
      //       setBroadcastPartId((prevIds) => [...prevIds, broadcastPartId]);
      //       newFileData.broadcastPartId = broadcastPartId;

      //       const updatedData = {
      //         broadcast_part_id: broadcastPartId,
      //         valid_count: validContacts.length,
      //         duplicate_count: duplicateContacts.length,
      //         invalid_count: invalidContacts.length,
      //         file_date_valid: validData,
      //         file_date_in_valid: invalidData,
      //         file_date_duplicate: duplicateData,
      //       };

      //       setData(updatedData);

      //       const fileDataResponse = await broadcastPartFileData(updatedData);
      //     }
      // }
    } catch (error) {}
  };

  return (
    <div className="grid grid-rows-[10fr,1fr] h-[90%]">
      <div className="px-[1vw] w-full text-[.75vw] mt-[1vh] crm-model pb-[1vw]">
        <div>
          <p className="mb-[1vh]">Select a contact origin</p>
          <Dropdown
            open={selectOpen}
            heading=""
            data={contactsFilters}
            width="min-w-[43vw]"
            height="max-h-[32vh]"
            containerClassNames="px-[1.6vw]"
            editFilter={handleModelContactSelection}
            button={
              <div className="">
                <div
                  className={` flex justify-between items-center my-[2vh]- py-[1vh] ps-[.5vw] pe-[.7vw] cursor-pointer transition-colors duration-100 text-[0.85vw] border border-gray-200 rounded-lg`}
                  // onClick={() => {}}
                  onClick={() => setSelectOpen(!selectOpen)}
                >
                  <p
                    className={`text-[.73vw] text-[#898E99] flex items-center gap-[.4vw]`}
                  >
                    {Icon && <Icon width="1.3vw" />}

                    {selectedContactSource?.label || "Select Source"}
                  </p>
                  <span
                    className={`duration-300 ${
                      selectOpen ? "-rotate-180" : "rotate-0"
                    }`}
                  >
                    <div
                      className={`border-[0.4vw] rounded-t-[0.2vw] border-transparent border-t-[#777] translate-y-[25%]`}
                    />
                  </span>
                </div>
              </div>
            }
          />
        </div>
        <div className="flex justify-between mt-[1vw]">
          <p>Upload a single file or multiple file</p>
          <a href={SampleXLSXFile} className="flex items-center gap-[.5vw]">
            <img src={ICONS.AudienceDownloadIcon} alt="" />
            <span className={`text-[${primaryColor}]`}>Download sample</span>
          </a>
        </div>

        <div className="mt-[1.3vw] px-[3.5vw]">
          <div className="border-[.13vw]-border-dashed border-gray-300 rounded-md ">
            <label>
              <input
                onChange={handleFileUpload}
                type="file"
                name="file"
                hidden
                accept=".xlsx"
              />
              <span className="text-gray-600 ttest rounded-lg box-border bg-gray-100 w-full- h-full- flex flex-col justify-center items-center gap-[.7vw] py-[2vw] cursor-pointer">
                <span
                  className={`bg-[${primaryColor}] min-w-[4vw]  text-[#fff] rounded-md px-[1vw] text-[.75vw] font-[500] h-[3.7vh] flex items-center transition-all`}
                >
                  Choose file
                </span>
                <span>( Drag & Drop the file to upload )</span>
                {/* <span>.xlsx</span> */}
              </span>
            </label>
          </div>

          {!!files.length &&
            files.map((item, i) => {
              const openFileInfo = item.openFileInfo;
              return (
                <div
                  key={i}
                  className="my-[1vw] loader-shadow rounded-lg py-[.7vw] px-[1vw] flex justify-between"
                >
                  <div className="flex items-center justify-between w-[92%]">
                    <div
                      className={`flex ${
                        openFileInfo ? "min-w-max" : "max-w-[8vw]"
                      } items-center gap-[.6vw] duration-1000`}
                    >
                      {/* csv file image */}
                      <img src={AudienceFile} alt="" />
                      <div className="text-[.6vw]">
                        <p className="text-[.7vw] font-[600]">
                          {item.fileName}
                        </p>
                        <div
                          className={`overflow-hidden duration-200 flex items-center justify-start${
                            openFileInfo
                              ? "opacity-1 max-h-[2vw]"
                              : "opacity-0 max-h-[0]"
                          }`}
                        >
                          {csvInfo.map((item, i) => (
                            <CsvInfo
                              key={item.label}
                              label={item.label}
                              Icon={item.Icon}
                              isLast={csvInfo.length === i + 1}
                            />
                          ))}
                        </div>
                      </div>
                    </div>

                    <UploadLoading
                      progress={item.progress}
                      step={1}
                      isUploading={openFileInfo}
                      // handleUploadAnime={setFiles}
                    />
                  </div>
                  <span
                    onClick={() => {
                      dispatch(handelDeleteFile({ id: item.id }));
                      // console.log(item.id);
                    }}
                    className="flex items-center -ms-[0.5vw]"
                  >
                    <img src={redDelete} alt="" />
                  </span>
                </div>
              );
            })}

          {/* {!files.length && ( */}
          <div
            className={` duration-200 ${
              !!files.length ? "scale-1" : "scale-0"
            }`}
          >
            <hr />
            <SelectMergeOption
              selected={mergeOption}
              setSelected={setMergeOption}
            />
          </div>
          {/* )} */}

          {/* <div>
        <input
          type="file"
          name=""
          id=""
          multiple="multiple"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
          </div> */}
        </div>
      </div>
      <div className="sticky bottom-0 ">
        <hr />
        <div className="px-[1vw] py-[.7vw]">
          <Button
            classNames="ms-auto"
            label="Continue"
            disabled={files.length === 0}
            onClick={() => setImportStep(2)}
          />
        </div>
      </div>
    </div>
  );
};

export default ImportContacts;
