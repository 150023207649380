import React, { useCallback, useEffect, useReducer } from 'react';

// assets
import Mail from 'assets/customSVG/Mail';
import AddIcon from 'assets/customSVG/AddIcon';
import Delete from 'assets/customSVG/Delete';

// reducers
import {
  accountDetailsReducer,
  INITIAL_STATE,
} from '../../reducers/accountDetailsReducer';

// hooks
import useToggle from '../../../../hooks/useToggle';

// constants
import { ACCOUNT_COUNTS } from '../../constants/home';

// helper
import { cn } from '../../../../helper/cn';

// components
import ProfileDetails from '../../components/ProfileDetails';
import Loader from '../../../../components/commonComponents/Loader/Index';
import InviteMember from '../../../settings/components/InviteMember';
import { useParams } from 'react-router-dom';
import { singleAccount, singleAccountupdate } from '../../api/Api';

export default function SingleAccountDetails() {
  const { account_id } = useParams();
  const [data, dispatch] = useReducer(accountDetailsReducer, INITIAL_STATE);
  const [isLoading, setIsLoading] = useToggle(false);

  useEffect(() => {
    fetchAccountList();
  }, []);

  const fetchAccountList = async () => {
    try {
      setIsLoading(true);
      const res = await singleAccount({
        acc_id: account_id,
        invited_members: true,
      });
      // setAccount(res?.data);
      dispatch({ type: 'update_data', data: res?.data });
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const validation = useCallback(() => {
    return true;
  }, [data]);

  const onChange = (type, id, key, value) => {
    dispatch({ type: type, id: id, key: key, value: value });
  };

  // styles
  const inputContainer =
    'w-full h-12 flex items-center gap-2 py-2.5 px-3 border border-[#D1D3D8] rounded-lg';
  const input = 'w-full font-sm font-medium';

  return (
    <div className='w-full h-full max-h-[85vh] bg-white p-4 border-t border-[#F0F1F2] overflow-y-scroll'>
      <div className='w-full h-full max-h-60 flex gap-5'>
        <div className='w-1/2'>
          <ProfileDetails
            account_id={account_id}
            name={data?.acc_name}
            image={data?.acc_image_url}
            type={data?.acc_type}
            onChange={onChange}
          />
        </div>
        <div className='w-1/2 max-h-60 py-2.5 px-5 grid grid-cols-2 2xl:grid-cols-3 items-center gap-3 border border-[#F0F1F2] rounded-2xl shadow-[0px_1.5px_2px_0px_#1018281A]'>
          {ACCOUNT_COUNTS?.map((c) => {
            return (
              <div className='w-auto h-11 flex items-center gap-3.5'>
                <div
                  className={`size-12 flex items-center justify-center rounded-lg`}
                  style={{
                    background: c.bg ? c.bg : 'none',
                  }}
                >
                  <c.icon width={24} height={24} color={c.color} />
                </div>
                <div className='flex flex-col gap-1 justify-center'>
                  {c.value && (
                    <h4 className='text-2xl font-bold text-[var(--contentText)] leading-none'>
                      {/* {c.value} */}
                      {data?.account_metrics?.[c.key]}
                    </h4>
                  )}
                  <p className='text-sm font-semibold text-[var(--font-600)]'>
                    {c.label}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* <form
        className='w-full h-fit flex flex-col items-center pt-11'
        onSubmit={handleSubmit}
      >
        <h5 className='w-full text-lg font-bold text-left'>
          Invite people to Gochew Grill
        </h5>
        {data?.invite_people?.map((m) => {
          return (
            <div className='w-full flex items-center gap-3.5 mt-4'>
              <div className={cn(inputContainer)}>
                <div>
                  <Mail width='18px' height='18px' />
                </div>
                <input
                  name={`mail${m.id}`}
                  id={`mail${m.id}`}
                  type='email'
                  placeholder='Ex:- yourmail@email.com'
                  className={cn(input)}
                  value={m.value}
                  onChange={(e) => {
                    const { value } = e.target;
                    onChange('update_people', m.id, 'value', value);
                  }}
                />
              </div>
              {data?.invite_people?.length > 1 && (
                <div
                  className={`size-8 group bg-transparent flex items-center justify-center rounded-[0.4vw] hover:bg-red-200 cursor-pointer`}
                  onClick={() => {
                    onChange('remove_people', m.id);
                  }}
                >
                  <Delete
                    width='20'
                    height='20'
                    className={`stroke-[#898E99] group-hover:stroke-red-500`}
                    color=''
                  />
                </div>
              )}
            </div>
          );
        })}

        <div className='w-full flex justify-between items-center'>
          <div
            className={cn(
              'flex items-center',
              data?.invite_people.length < 5
                ? 'text-[var(--primary)] cursor-pointer'
                : 'text-[var(--font-600)] cursor-not-allowed'
            )}
            onClick={() => {
              if (data?.invite_people?.length < 5) onChange('add_people');
            }}
          >
            <AddIcon
              stroke={
                data?.invite_people?.length < 5
                  ? 'var(--primary)'
                  : 'var(--font-600)'
              }
            />{' '}
            Add New
          </div>
          <button
            type='submit'
            className={cn(
              'w-44 h-11 rounded-[10px] px-4 py-2 text-base weight-semibold mt-9 flex items-center justify-center',
              validation()
                ? isLoading
                  ? 'cursor-not-allowed bg-[var(--primary)] text-[var(--white)]'
                  : 'bg-[var(--primary)] text-[var(--white)] cursor-pointer'
                : 'bg-[var(--BG-100)] text-[var(--font-600)] cursor-not-allowed'
            )}
          >
            {isLoading ? (
              <div className='flex items-center justify-center'>
                <Loader
                  Width={20}
                  Height={20}
                  loaderBg='white'
                  // loaderColor='var(--primary)'
                />
              </div>
            ) : (
              'Send Invite'
            )}
          </button>
        </div>
      </form> */}

      <div>
        <InviteMember data={data?.invited_members} />
      </div>
    </div>
  );
}
