import React, { useEffect, useState } from 'react';
import '../styles/Brand.css';

// assets
import Delete from '../../../assets/customSVG/Delete';
import Share from '../../../assets/customSVG/Share';
import Xmark from '../../../assets/customSVG/Xmark';

// API
import { BrandsList } from '../api/Api';

// helpers
import { dateConverter } from '../../../helper/dateConverter';
import { getRandomColorsBassedOnLetters } from '../../../helper/colors';

// components
import {
  brandCategory,
  BrandInviteList,
} from '../../../constant/app/brand/brand';
import BrandMember from './BrandMember';
import { useAspSelector } from '../../../test/jest-redux-hooks';

// sttyles
const BrandStyle = {
  width: `calc(100% / 4)`,
};

const STATUS_COLOR = {
  10: {
    bgColor: 'var(--primaryBackground)',
    color: 'var(--TextPrimary)',
  },
  Inactive: {
    bgColor: 'var(--lightRed)',
    color: 'var(--fontRed)',
  },
  1: {
    bgColor: 'var(--primaryOpacity)',
    color: 'var(--a)',
  },
  Expired: {
    bgColor: 'var(--font-50)',
    color: 'var(--font-600)',
  },
};

const STATUS = {
  10: 'Active',
  '-1': 'Inactive',
  1: 'Expired',
};

const InviteMember = ({ data, allowDelete, allowInvite }) => {
  const { currentBrand } = useAspSelector((state) => state.app);

  const roleId = {
    4: 'Brand Manager',
    5: 'Campaigns Manager',
    6: 'Content Manager',
  };

  const STATUS = {
    '-1': 'Deleted',
    1: 'Pending',
    10: 'Active',
  };

  const color = getRandomColorsBassedOnLetters('Mukesh');

  return (
    <div className=''>
      <div className='flex items-center px-2.5 py-4'>
        <p className='text-[0.95vw] weight-semibold'>Invited members</p>
      </div>
      <div className='w-full h-full rounded-2xl flex flex-col bg-[var(--BG-50)] py-2.5 pl-2.5 pr-0.5'>
        {data?.length > 0 ? (
          <>
            <div className='w-full flex items-center font-default weight-semibold py-3 text-[#2D3036]'>
              <div className='w-2/5 pl-2.5'>Name</div>
              <div className='w-3/5 flex items-center justify-between text-center gap-5'>
                <div style={BrandStyle}>Created</div>
                <div style={BrandStyle}>Role</div>
                <div style={BrandStyle}>Last Active</div>
                <div style={BrandStyle}>Status</div>
              </div>
            </div>
            <div className='w-full h-auto max-h-[50vh] flex flex-col gap-2.5 overflow-hidden overflow-y-scroll pr-2 listScroll'>
              {data?.map((item, id) => {
                // const nameConvertion =
                //   item?.to_first_name && item?.to_last_name
                //     ? `${item?.to_first_name} ${item?.to_last_name}`
                //     : item?.to_first_name
                //     ? item?.to_first_name
                //     : item?.to_last_name
                //     ? item?.to_last_name
                //     : '--';
                const nameConvertion = item?.user_name;
                const email = item?.user_email ? item?.user_email : '';

                const name =
                  nameConvertion !== '--'
                    ? nameConvertion
                        ?.split(' ')
                        ?.map((w) => w.slice(0, 1).toString())
                    : email?.split(' ')?.map((w) => w.slice(0, 1).toString());

                const isExpired =
                  item?.status === 1 &&
                  dateConverter(item?.invite_token_expire_at)
                    ?.formatedDateWithYear <=
                    dateConverter()?.formatedDateWithYear
                    ? true
                    : false;

                const color = getRandomColorsBassedOnLetters(name);

                return (
                  <div
                    className={`w-full h-fit min-h-14 flex items-center py-1.5 mx-px rounded-xl bg-[var(--background)] relative group `}
                  >
                    <div className='w-2/5 p-2.5 flex items-center'>
                      <div
                        className='size-10 min-w-10 rounded-full flex items-center justify-center font-base font-semibold text-[var(--contentText)] uppercase'
                        style={{
                          color: color.color,
                          background: color.backgroundColor,
                        }}
                      >
                        {name}
                      </div>

                      <div className='w-full h-9 flex flex-col justify-center pl-2.5'>
                        <div className='flex items-center'>
                          {nameConvertion && (
                            <p className='max-w-[60%] truncate font-default weight-semibold text-[var(--contentText)]'>
                              {nameConvertion}
                            </p>
                          )}
                          {[1, 'Expired'].includes(item?.status) && (
                            <div className='items-center gap-2.5 px-2.5 hidden group-hover:!flex'>
                              {allowDelete && (
                                <button
                                  className={`w-6 h-fit  group/delete bg-transparent flex items-center justify-center rounded-[0.4vw] cursor-pointer`}
                                  onClick={() => {}}
                                >
                                  <Delete
                                    width={16}
                                    height={16}
                                    className={`stroke-[#898E99] group-hover/delete:stroke-[var(--fontRed)]`}
                                    color=''
                                  />
                                </button>
                              )}
                              {allowInvite && (
                                <button
                                  className={`w-6 h-fit  group/delete bg-transparent flex items-center justify-center rounded-[0.4vw] cursor-pointer`}
                                  onClick={() => {}}
                                >
                                  <Share
                                    width={17}
                                    height={17}
                                    className={`fill-[#898E99] group-hover/delete:fill-[var(--contentText)]`}
                                    color=''
                                    strokeWidth='0'
                                  />
                                </button>
                              )}
                              {/* <button
                                className={`w-6 h-fit  group/close bg-transparent flex items-center justify-center rounded-[0.4vw] cursor-pointer`}
                                onClick={() => {}}
                              >
                                <Xmark
                                  width={10}
                                  height={10}
                                  className={`stroke-[#898E99] group-hover/close:stroke-[var(--fontRed)]`}
                                  color=''
                                />
                              </button> */}
                            </div>
                          )}
                        </div>
                        <div className='w-full flex-1 flex items-center text-[0.69vw] weight-medium text-[var(--channelToggleSwitch)]'>
                          <p className='max-w-[90%] truncate pr-4 font-md weight-semibold text-[var(--font-400)]'>
                            {item?.user_email}
                          </p>
                          {/* <p
                            className='px-2 py-0.5 h-fit rounded-full flex items-center justify-center font-xs weight-semibold'
                            style={{
                              backgroundColor: isExpired
                                ? STATUS_COLOR['Expired']?.bgColor
                                : STATUS_COLOR[item?.status]?.bgColor,
                              color: isExpired
                                ? STATUS_COLOR['Expired']?.color
                                : STATUS_COLOR[item?.status]?.color,
                            }}
                          >
                            {isExpired ? 'Expired' : STATUS[item?.status]}
                          </p> */}
                        </div>
                      </div>
                    </div>
                    <div className='w-3/5 h-full flex items-center justify-between text-center font-md weight-medium gap-5 '>
                      <div
                        style={BrandStyle}
                        className='flex items-center justify-center'
                      >
                        {item?.user_created_at
                          ? dateConverter(item?.user_created_at)
                              ?.formatedDateWithoutYear
                          : '--'}
                      </div>

                      <div
                        style={BrandStyle}
                        className='flex items-center justify-center'
                      >
                        {item?.role_name}
                        {/* {roleId[item.role_id]} */}
                        {/* <BrandMember MemberRole={item?.Role} open /> */}
                      </div>

                      <div
                        style={BrandStyle}
                        className='flex items-center justify-center'
                      >
                        {/* {item?.Time} */}
                        {item?.user_last_login_at
                          ? dateConverter(item?.user_last_login_at)
                              ?.formatedDateWithoutYear
                          : '--'}
                      </div>

                      <div
                        className='flex items-center justify-center'
                        style={BrandStyle}
                      >
                        <label className='flex items-center relative w-max select-none'>
                          <input
                            type='checkbox'
                            className='appearance-none transition-colors w-12 h-5 rounded-full bg-[var(--BG-50)] outline-none'
                            checked={
                              ['Expired', 'Inactive'].includes(item?.status)
                                ? false
                                : true
                            }
                            onChange={() => {
                              // const res = brandsList?.invites_sent?.map((d) => {
                              //   if (item.id === d.id)
                              //     if (
                              //       ['Expired', 'Inactive'].includes(
                              //         item?.status
                              //       )
                              //     )
                              //       return { ...d, status: 'Active' };
                              //     else return { ...d, status: 'Inactive' };
                              //   return d;
                              // });
                              // setBrandsList({ invites_sent: res });
                            }}
                          />
                          {['Expired', 'Inactive'].includes(item?.status) ? (
                            <span className='absolute weight-medium text-[0.6vw] uppercase right-1.5 text-[var(--font-600)]'>
                              {' '}
                              OFF{' '}
                            </span>
                          ) : (
                            <span className='absolute weight-medium text-[0.6vw] uppercase left-1.5 text-white'>
                              {' '}
                              ON{' '}
                            </span>
                          )}
                          <span className='w-4 h-4 right-[30px] absolute rounded-full transform transition-transform bg-[var(--BG-100)]' />
                        </label>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <div className='flex items-center justify-center my-20 font-default weight-semibold'>
            No Data
          </div>
        )}
      </div>
    </div>
  );
};

export default InviteMember;
