import React, { useEffect, useState } from "react";

// utils
import { Outlet, useLocation, useNavigate } from "react-router-dom";

// constant
import { channelList as channelListDefault } from "../../../constant/app/channel/index";

// API
import { templateMetrix, templateSyncApi } from "../api/Api";

// hooks
import useToggle from "../../../hooks/useToggle";

// redux
import { updateToggleToast } from "../../../reduxToolkit/appSlice";
import { useAspDispatch, useAspSelector } from "../../../test/jest-redux-hooks";

// components
import SubSideBar from "../../../components/commonComponents/subSideBar";
import WhatsappMainHeader from "../components/whatsapp/WhatsappMainHeader";

const insightsTabs = ["/user/channels/whatsapp/insights"];
const messageTamplateTabs = [
  "/user/channels/whatsapp/message-template",
  "/user/channels/whatsapp/create-template",
  "/user/channels/whatsapp/create-template/template-details",
  "/user/channels/whatsapp/edit-template/template-details",
  "/user/channels/whatsapp/duplicate-template/template-details",
];
const phoneNumbersTabs = ["/user/channels/whatsapp/phonenumbers"];

function ListOfChannels({ templateDetails, isLoading, numberDetails }) {
  const navigate = useNavigate();

  const { currentBrand, toggleToast } = useAspSelector((state) => state?.app);
  const dispatch = useAspDispatch();
  const { pathname } = useLocation();

  const [syncing, setSyncing] = useToggle(false);
  const [activeTabName, setActiveTabName] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (currentBrand?.brand_id) fetchTemplateMetrix(currentBrand?.brand_id);
  }, [currentBrand]);

  const fetchTemplateMetrix = async (brandId) => {
    try {
      const res = await templateMetrix(brandId);
      setData(res?.data);
    } catch (e) {
      console.warn(e);
    }
  };

  const brandId = currentBrand?.brand_id;

  const onClick = () => {
    if (brandId) {
      setSyncing(true);
      templateSyncApi(brandId)
        .then((res) => {
          if (res.status === 200) {
            dispatch(
              updateToggleToast([
                ...toggleToast,
                {
                  id: toggleToast?.length + 1,
                  content: "Whatsapp Templates Synced Successfully",
                  status: "Success",
                  duration: "",
                },
              ])
            );
          } else {
            dispatch(
              updateToggleToast([
                ...toggleToast,
                {
                  id: toggleToast?.length + 1,
                  content:
                    res?.status?.error || "Whatsapp Templates Sync failed",
                  status: "Error",
                  duration: "",
                },
              ])
            );
          }
        })
        .finally(() => {
          setSyncing(false);
        });
    } else {
    }
  };

  const initialBlur = () => {
    const channels = document.querySelector(".campaign-rightLayout");

    // switch (pathname) {
    //   case "/user/channels/whatsapp":
    //     channels.classList.add("initial-blur");
    //     break;
    //   default:
    //     channels.classList.remove("initial-blur");
    //     break;
    // }
  };

  useEffect(() => {
    updateTabName();
    // initialBlur();
  }, [pathname]);

  const navListSelect = (item) => {
    setActiveTabName(item?.name);
  };

  const updateTabName = () => {
    if (currentBrand?.is_whatsapp_connected === true) {
      if (insightsTabs.includes(pathname)) {
        setActiveTabName("Insights");
      } else if (messageTamplateTabs.includes(pathname)) {
        setActiveTabName("Message Templates");
      } else if (phoneNumbersTabs.includes(pathname)) {
        setActiveTabName("Phone Numbers");
      } else {
        navigate("/user/channels/whatsapp/message-templates");
        setActiveTabName("Message Templates");
        // navigate('/user/channels/whatsapp/insights');
        // setActiveTabName('insights');
      }
    } else {
      setActiveTabName("WhatsApp");
    }
  };

  useEffect(() => {
    updateTabName();
  }, [currentBrand?.is_whatsapp_connected, pathname, activeTabName]);

  return (
    <div className="flex-row campaignWrapper">
      <SubSideBar
        isListShow={currentBrand?.is_whatsapp_connected === true}
        list={channelListDefault}
        activeNav={activeTabName}
        onSelect={(item) => navListSelect(item)}
      />

      <div className="campaign-rightLayout">
        {currentBrand?.is_whatsapp_connected === true && (
          <WhatsappMainHeader
            onClick={onClick}
            data={data}
            isLoading={isLoading}
            syncing={syncing}
          />
        )}
        <Outlet context={[syncing, setSyncing]} />
      </div>
    </div>
  );
}

export default ListOfChannels;
