import React from 'react';
import './radioOption.css';

// helper
import { cn } from '../../../helper/cn';

const RadioOption = ({
  value,
  selectedValue,
  onChange,
  id,
  name,
  style,
  className,
}) => {
  const handleRadioChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className={cn('round', className)} style={style}>
      <input
        type='radio'
        id={id}
        value={value}
        name={name}
        checked={selectedValue === value}
        onChange={handleRadioChange}
        className={cn(className)}
      />
      <label htmlFor={value} style={style} className={cn(className)}></label>
    </div>
  );
};

export default RadioOption;
