import React, { useCallback, useState } from 'react';

// assets
import { ICONS } from '../../../../assets/icons';
import Eye from '../../../../assets/customSVG/Eye';
import FullArrow from '../../../../assets/customSVG/FullArrow';
import CircleTick from '../../../../assets/customSVG/CircleTick';

// utils
import { Link, useNavigate } from 'react-router-dom';

// API
import { ChangePassword } from '../../api/SignUp';

// constants
import {
  checkingValidation,
  passwordValidations,
} from '../../../../constant/auth/authValidation';

// helper
import { cn } from '../../../../helper/cn';
import { removeSpace } from '../../../../helper/validation';

// hooks
import useToggle from '../../../../hooks/useToggle';

// redux
import {
  useAspDispatch,
  useAspSelector,
} from '../../../../test/jest-redux-hooks';
import {
  updateCurrentUser,
  updateToggleToast,
} from '../../../../reduxToolkit/appSlice';

// conponent
import Loader from '../../../../components/commonComponents/Loader/Index';

export default function Step3({ data, token, onChange }) {
  const { toggleToast } = useAspSelector((state) => state.app);
  const dispatch = useAspDispatch();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useToggle(false);

  const [showPassword, setShowPassword] = useToggle(false);
  const [showConfirmPassword, setShowConfirmPassword] = useToggle(false);
  const [errorValues, setErrorValues] = useState(passwordValidations);
  const [error, setError] = useState({
    password: false,
    confirm_password: false,
    focused: true,
  });

  const card = 'flex flex-col gap-3';
  const label = 'text-base weight-semibold leading-6 text-[#2D3036]';
  const inputContainer =
    'w-full h-12 flex items-center gap-2 py-2.5 px-3 border rounded-lg';
  const input = 'w-full text-sm weight-medium';

  const checkToShowSubmit = useCallback(() => {
    const res = errorValues.filter((e) => e.value);
    return res?.length === errorValues?.length;
  }, [errorValues]);

  const onErrorChange = useCallback(
    ({ type, key, value }) => {
      if (type === 'both') {
        setError({
          password: value,
          confirm_password: value,
        });
      } else {
        setError({ ...error, [key]: value });
      }
    },
    [data]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      checkToShowSubmit() &&
      !error?.password &&
      !error?.confirm_password &&
      data?.password === data?.confirm_password
    ) {
      const payload = {
        token: data?.token,
        email: data?.mail,
        password: data?.password,
        password_confirmation: data?.confirm_password,
      };
      if (token) payload['invite_token'] = token;
      setIsLoading(true);
      const res = await ChangePassword(payload);
      if (res?.status === 200) {
        localStorage?.setItem('authToken', res?.data?.auth_token);
        dispatch(updateCurrentUser(res?.data?.current_user));
        setIsLoading(false);
        if (token) navigate('/Accounts');
        else navigate('/on-boarding');
      } else {
        setIsLoading(false);
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: res?.response?.data?.error,
              status: 'Error',
              duration: '',
            },
          ])
        );
      }
    }
  };

  return (
    <div className="max-w-[470px] text-[#2D3036] mx-auto">
      <h2 className="text-2xl weight-bold text-center mt-8 pb-6">
        Setup password to your account
      </h2>
      <form className="" onSubmit={handleSubmit}>
        <div className={cn(card, "mt-3")}>
          <label htmlFor="password" className={cn(label)}>
            Password
            <sup className="text-red-500">*</sup>
          </label>
          <div
            className={cn(
              inputContainer,
              error?.password ? "border-red-400" : "border-[#D1D3D8]"
            )}
          >
            <img src={ICONS?.lock} alt="lock icon" />
            <input
              name="password"
              id="password"
              type={showPassword ? "text" : "password"}
              placeholder="*************"
              className={cn(input)}
              value={data?.password}
              onChange={(e) => {
                const { name, value } = e.target;

                const removedSpace = removeSpace(value);
                onChange("update", name, removedSpace);

                let newErrorValues = checkingValidation(
                  name,
                  data,
                  errorValues,
                  removedSpace
                );
                const filteredValues = newErrorValues.filter((e) => e.value);
                setErrorValues(newErrorValues);
                if (error?.password && removedSpace) {
                  onErrorChange({
                    type: "single",
                    key: "password",
                    value: false,
                  });
                }
                if (!removedSpace) {
                  onErrorChange({
                    type: "single",
                    key: "password",
                    value: true,
                  });
                } else if (
                  removedSpace &&
                  filteredValues?.length !== passwordValidations?.length
                )
                  onErrorChange({
                    type: "single",
                    key: "password",
                    value: true,
                  });
                else if (
                  removedSpace &&
                  filteredValues?.length !== passwordValidations?.length
                )
                  onErrorChange({
                    type: "single",
                    key: "password",
                    value: false,
                  });
                else if (removedSpace && data?.confirm_password) {
                  if (removedSpace !== data?.confirm_password) {
                    onErrorChange({
                      type: "single",
                      key: "confirm_password",
                      value: true,
                    });
                  } else {
                    onErrorChange({
                      type: "both",
                      value: false,
                    });
                  }
                }
              }}
              onBlur={() => {
                let newErrorValues = checkingValidation(
                  "password",
                  data,
                  errorValues,
                  data?.password
                );
                const filteredValues = newErrorValues.filter((e) => e.value);
                if (!data?.password)
                  onErrorChange({
                    type: "single",
                    key: "password",
                    value: true,
                  });
                else if (
                  data?.password &&
                  filteredValues?.length !== passwordValidations?.length
                )
                  onErrorChange({
                    type: "single",
                    key: "password",
                    value: true,
                  });
              }}
            />
            <div
              className="cursor-pointer"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
              data-testid="password-eye"
            >
              <Eye color={showPassword ? "#000000" : "#898E99"} />
            </div>
          </div>
        </div>

        <div className="mt-4">
          <h3 className="text-sm weight-medium pb-2">
            Your Password must contain
          </h3>
          <div className="grid grid-cols-2 gap-x-6">
            {errorValues?.map((e) => {
              return (
                <div className="flex items-center gap-2.5 py-1">
                  <CircleTick
                    color={e.value ? "var(--primary)" : "var(--font-600)"}
                  />
                  <p
                    className={cn(
                      "text-xs weight-medium",
                      e.value
                        ? "text-[var(--contentText)]"
                        : "text-[var(--font-600)]"
                    )}
                  >
                    {e.msg}
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        <div className={cn(card, "mt-6")}>
          <label htmlFor="confirmPassword" className={cn(label)}>
            Confirm Password
            <sup className="text-red-500">*</sup>
          </label>
          <div
            className={cn(
              inputContainer,
              error?.confirm_password ? "border-red-400" : "border-[#D1D3D8]"
            )}
          >
            <img src={ICONS?.lock} alt="lock icon" />
            <input
              name="confirm_password"
              id="confirmPassword"
              type={showConfirmPassword ? "text" : "password"}
              placeholder="*************"
              className={cn(input)}
              value={data?.confirm_password}
              onChange={(e) => {
                const { name, value } = e.target;

                const removedSpace = removeSpace(value);
                onChange("update", name, removedSpace);

                if (data?.password && checkToShowSubmit()) {
                  if (data?.password === removedSpace) {
                    onErrorChange({
                      type: "single",
                      key: "confirm_password",
                      value: false,
                    });
                  } else {
                    onErrorChange({
                      type: "single",
                      key: "confirm_password",
                      value: true,
                    });
                  }
                } else if (data?.password && !checkToShowSubmit()) {
                  onErrorChange({
                    type: "single",
                    key: "password",
                    value: true,
                  });
                } else {
                  onErrorChange({
                    type: "both",
                    value: false,
                  });
                }
              }}
              onFocus={() => {
                if (!data?.password)
                  onErrorChange({ type: "both", value: true });
                else if (data?.password && !checkToShowSubmit())
                  onErrorChange({ type: "both", value: true });
              }}
            />
            <div
              className="cursor-pointer"
              onClick={() => {
                setShowConfirmPassword(!showConfirmPassword);
              }}
              data-testid="confirmPassword-eye"
            >
              <Eye color={showConfirmPassword ? "#000000" : "#898E99"} />
            </div>
          </div>
        </div>

        {(data?.password && data?.confirm_password && data?.password) !==
          data?.confirm_password && (
          <div className="mt-1 flex items-center gap-2">
            <img src={ICONS?.alertIconError} alt="alert icon" />
            <p className="text-xs weight-medium text-[#FF543E]">
              Your passwords do not match.
            </p>
          </div>
        )}

        <div className="flex flex-col items-center mt-6">
          <button
            type="submit"
            className={cn(
              "min-w-44 h-12 w-fit rounded-[10px] px-4 py-2 text-base weight-semibold flex items-center justify-center",
              checkToShowSubmit() &&
                !error?.password &&
                !error?.confirm_password &&
                data?.password === data?.confirm_password
                ? isLoading
                  ? "cursor-not-allowed bg-[var(--primary)] text-[var(--white)]"
                  : "bg-[var(--primary)] text-[var(--white)] cursor-pointer"
                : "bg-[var(--BG-50)] text-[var(--font-600)] cursor-not-allowed"
            )}
          >
            {isLoading ? (
              <div className='size-[2vw] flex items-center justify-center'>
                <Loader Width={20} Height={20} loaderBg='white' />
              </div>
            ) : (
              "Complete Registration"
            )}
          </button>
        </div>
      </form>
      <div className="flex items-center justify-center gap-1 text-base weight-medium border-t border-[var(--border-50)] mt-6 pt-4">
        <p>Already have an account?</p>
        <Link to={"/login"} className="text-[var(--a)]">
          Login
        </Link>
      </div>
      <div
        className="flex items-center justify-center gap-2 mt-4 cursor-pointer"
        onClick={() => {
          onChange("back");
        }}
      >
        <div className="rotate-180">
          <FullArrow color="#898E99" />
        </div>
        <p className="text-base weight-semibold text-[#898E99]">Back</p>
      </div>
    </div>
  );
}
