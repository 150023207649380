import React, { useEffect, useState } from 'react';

// API
import { templateLists, templateMetrix } from '../../../api/Api';

// constant
import { posterlist } from '../../../../../constant/app/campaign/campaign';

// hooks
import useToggle from 'hooks/useToggle';
import { useSelect } from 'hooks/useSelect';
import { useSearch } from 'hooks/useSearch';

// redux
import {
  useAspDispatch,
  useAspSelector,
} from '../../../../../test/jest-redux-hooks';
import { setData } from 'reduxToolkit/channelSlice';

// components
import OverviewCard from './components/OverviewCard';
import TemplateList from './components/TemplateList';
import TemplateListHeader from './components/TemplateListHeader';
import { useOutletContext } from 'react-router-dom';

let limit = 10;
let page = 1;
let count = 0;

const MessageTemplate = () => {
  const [templates, setTemplates] = useState([]);
  const [datas, setDatas] = useState([]);
  const [isFilterModal, setIsFilterModal] = useToggle(false);
  const [loading, setLoading] = useToggle(true);
  const [counts, setCounts] = useState(null);
  const [isLoadingNextPage, setIsLoadingNextPage] = useToggle(false);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedCategory, setSelectedCategory] = useSelect([]);
  const [isOverviewLoading, setIsOverviewLoading] = useToggle(false);
  const [overviewCount, setOverviewCount] = useSelect(null);
  const { searchKey, setSearchKey, onChange: onSearch } = useSearch('');

  const [syncing, setSyncing] = useOutletContext();
  const channelData = useAspSelector((state) => state?.Channel);
  const { currentBrand } = useAspSelector((state) => state?.app);
  const dispatch = useAspDispatch();

  const { data } = channelData || {};

  useEffect(() => {
    if (!syncing && currentBrand?.brand_id)
      fetchTemplateMetrix(currentBrand?.brand_id);
  }, [currentBrand, syncing]);

  const fetchTemplateMetrix = async (brandId) => {
    setIsOverviewLoading(true);
    try {
      const res = await templateMetrix(brandId);
      setOverviewCount(res?.data);
    } catch (e) {
      console.warn(e);
    } finally {
      setIsOverviewLoading(false);
    }
  };

  useEffect(() => {
    if (!syncing) {
      page = 1;
      if (limit > 0)
        fetchTemplateLists(true, currentBrand?.brand_id, {
          page: page,
          limit: limit,
          name: searchKey,
          status:
            selectedStatus?.length > 0
              ? selectedStatus?.length > 1
                ? selectedStatus
                    ?.map((s) => {
                      return s.status;
                    })
                    ?.join(',')
                : selectedStatus?.map((s) => {
                    return s.status;
                  })?.[0]
              : null,
          category:
            selectedCategory?.length > 0
              ? selectedCategory?.length > 1
                ? selectedCategory
                    ?.map((c) => {
                      return c.status;
                    })
                    ?.join(',')
                : selectedCategory?.map((c) => {
                    return c.status;
                  })?.[0]
              : null,
        });
    }
  }, [currentBrand, selectedStatus, selectedCategory, syncing]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      page = 1;
      if (limit > 0)
        fetchTemplateLists(true, currentBrand?.brand_id, {
          page: page,
          limit: limit,
          name: searchKey,
          status:
            selectedStatus?.length > 0
              ? selectedStatus?.length > 1
                ? selectedStatus
                    ?.map((s) => {
                      return s.status;
                    })
                    ?.join(',')
                : selectedStatus?.map((s) => {
                    return s.status;
                  })?.[0]
              : null,
          category:
            selectedCategory?.length > 0
              ? selectedCategory?.length > 1
                ? selectedCategory
                    ?.map((c) => {
                      return c.status;
                    })
                    ?.join(',')
                : selectedCategory?.map((c) => {
                    return c.status;
                  })?.[0]
              : null,
        });
    }, 500);

    return () => clearTimeout(timeout);
  }, [searchKey]);

  useEffect(() => {
    setDatas(templates);
  }, [templates]);

  const fetchTemplateLists = async (load, brandId, payload) => {
    if (brandId) {
      if (load) setLoading(true);
      if (!load) setIsLoadingNextPage(true);

      await templateLists(brandId, payload)
        .then((res) => {
          if (res && res.data?.templates?.length > 0) {
            if (load) setCounts(res?.data?.templates_overview);
            if (page === 1) setTemplates(res?.data?.templates);
            else setTemplates([...templates, ...res?.data?.templates]);
            count = res?.data?.template_count;
            if (load) dispatch(setData(res?.data));
          } else {
            setTemplates([]);
          }
        })
        .finally(() => {
          if (load) setLoading(false);
          if (!load) setIsLoadingNextPage(false);
        });
    } else {
      setTemplates([]);
    }
  };

  const loadTemplateList = async () => {
    page = 1;
    if (limit > 0)
      fetchTemplateLists(true, currentBrand?.brand_id, {
        page: page,
        limit: limit,
        name: searchKey,
        status:
          selectedStatus?.length > 0
            ? selectedStatus?.length > 1
              ? selectedStatus
                  ?.map((s) => {
                    return s.status;
                  })
                  ?.join(',')
              : selectedStatus?.map((s) => {
                  return s.status;
                })?.[0]
            : null,
        category:
          selectedCategory?.length > 0
            ? selectedCategory?.length > 1
              ? selectedCategory
                  ?.map((c) => {
                    return c.status;
                  })
                  ?.join(',')
              : selectedCategory?.map((c) => {
                  return c.status;
                })?.[0]
            : null,
      });
  };

  const onFilterChange = (data, item) => {
    if (data?.name === 'STATUS') updateStatus(item);

    if (data?.name === 'CATEGORY') updateCategory(item);

    setIsFilterModal(false);
  };

  const loadNextPage = () => {
    fetchTemplateLists(false, currentBrand?.brand_id, {
      page: page + 1,
      limit: limit,
      name: searchKey,
      status:
        selectedStatus?.length > 0
          ? selectedStatus
              ?.map((s) => {
                return s.status;
              })
              ?.join(',')
          : null,
      category:
        selectedCategory?.length > 0
          ? selectedCategory?.length > 1
            ? selectedCategory
                ?.map((c) => {
                  return c.status;
                })
                ?.join(',')
            : selectedCategory?.map((c) => {
                return c.status;
              })?.[0]
          : null,
    });
    page += 1;
  };

  const updateStatus = (item) => {
    const checkIsExist = selectedStatus?.filter(
      (s) => s?.value === item?.value
    );

    if (checkIsExist?.length > 0) {
      const filtered = selectedStatus?.filter((s) => s?.value !== item?.value);
      setSelectedStatus(filtered);
    } else {
      const newList = [...selectedStatus, item];
      setSelectedStatus(newList);
    }
  };

  const updateCategory = (item) => {
    const checkIsExist = selectedCategory?.filter(
      (s) => s?.value === item?.value
    );

    if (checkIsExist?.length > 0) {
      const filtered = selectedCategory.filter((c) => c?.value !== item?.value);
      setSelectedCategory(filtered);
    } else {
      const newList = [...selectedCategory, item];
      setSelectedCategory(newList);
    }
  };

  const updateSingleTemplateData = (data) => {
    const res = templates?.map((t) => {
      if (t?.name === data?.name)
        return {
          ...t,
          category: data?.category,
          // components: data?.components,
          language: data?.language,
          meta_modified_at: data?.last_updated_time,
          name: data?.name,
          rejected_reason: data?.rejected_reason,
          status: data?.status,
        };
      return t;
    });
    setTemplates(res);
  };

  const clearAllFilter = () => {
    setDatas(templates);
    // onSearch('');
    setSelectedStatus([]);
    setSelectedCategory([]);
  };

  return (
    <div
      className='w-full max-h-[83vh] overflow-hidden'
      style={{ background: 'white' }}
    >
      <OverviewCard
        list={posterlist}
        isLoading={loading}
        data={overviewCount?.templates_overview}
      />

      <h1 className='px-[1.3vw] text-[var(--textBlack)] font-larger weight-bold'>
        Templates
      </h1>
      <div className='pb-[1vw]'>
        <TemplateListHeader
          isFilters={isFilterModal}
          setIsFilters={setIsFilterModal}
          onFilterChange={onFilterChange}
          selectedStatus={[...selectedStatus, ...selectedCategory]}
          searchKey={searchKey}
          setSearchKey={setSearchKey}
          onChange={onSearch}
          clearAllFilter={clearAllFilter}
          loading={loading}
        />
      </div>
      <div className='px-[1.3vw]'>
        <TemplateList
          templateDetails={datas}
          selectedStatus={selectedStatus}
          isLoading={loading}
          isLoadingNextPage={isLoadingNextPage}
          data={data}
          length={templates?.length}
          count={count}
          loadNextPage={loadNextPage}
          updateSingleTemplateData={updateSingleTemplateData}
          loadTemplateList={loadTemplateList}
        />
      </div>
    </div>
  );
};

export default MessageTemplate;
