import React from 'react';
import { useNavigate } from 'react-router-dom';

// assets
import Settings from '../../../assets/customSVG/Settings';

import { useAspSelector, useAspDispatch } from '../../../test/jest-redux-hooks';
import {
  updateAccountDetails,
  updateCurrentBrand,
} from '../../../reduxToolkit/appSlice';

// helper
import { cn } from '../../../helper/cn';
import { abbrNum } from '../../../helper/abbrNum';
import { getRandomColorsBassedOnLetters } from 'helper/colors';

const brandStatus = {
  10: 'Active',
};

export default function BrandsCard({
  account_id,
  id,
  name,
  image,
  plan,
  role,
  status,
  remaining_amount,
  total_users,
  clearSearch,
}) {

  const { currentAccount, currentUser } = useAspSelector((state) => state.app);
  const dispatch = useAspDispatch();

  const navigate = useNavigate();

  const updateAccountAndBrandDetails = () => {
    let selectedAccount = currentUser?.active_accounts?.filter(
      (a) => +a.account_id === +account_id
    );

    const selectedBrand = selectedAccount[0]?.brands?.filter(
      (b) => b?.brand_id === id
    );

    let newAccountDetails = {
      currentAccount: selectedAccount?.[0],
      currentBrand: selectedBrand?.[0],
    };

    dispatch(updateAccountDetails(newAccountDetails));
    // dispatch(updateCurrentBrand(selectedBrand?.[0]));
  };

  const colors = getRandomColorsBassedOnLetters(name);
  // styles
  const cardContainer = 'w-auto h-6 flex items-center justify-between';
  const label = 'text-sm weight-semibold text-[var(--font-600)]';

  return (
    <div className='group w-full h-full max-w-[380px] max-h-[290px] relative bg-white text-[var(--contentText)] pt-4 pb-3.5 rounded-xl border border-[var(--border-50)] cursor-pointer'>
      <div
        className='w-full h-full'
        onClick={() => {
          clearSearch();
          navigate(`/user/channels`);
        }}
        data-testid='brandCard-Click'

      >
        <div className='w-full h-[4rem] flex items-center gap-4 px-4 pb-2'>
          <div>
            {image ? (
              <img src='' alt='' />
            ) : (
              <div
                className='size-12 bg-[var(--BG-50)] flex items-center justify-center rounded-full text-lg weight-bold'
                style={{
                  color: colors.color,
                  background: colors.backgroundColor,
                }}
              >
                {name.slice(0, 1).toUpperCase()}
              </div>
            )}
          </div>
          <div className='flex flex-col justify-center gap-1'>
            <h4 className='w-full max-w-[200px] truncate text-base weight-medium flex-1'>
              {name}
            </h4>
            <p className='w-fit rounded text-xs font-semibold px-2.5 py-1 bg-[var(--BG-50)] text-[var(--font-600)]'>
              {role}
            </p>
          </div>
        </div>
        <div className='w-full h-full'>
          <div className='w-full h-auto flex flex-col px-5 py-7 gap-4 border-t border-[#E8E8EA]'>
            <div className={cn(cardContainer)}>
              <div className={cn(label)}>Plan</div>
              <div className='text-lg weight-bold bg-gradient-to-r from-[#0ACD95] from-20% to-[#6940F2] bg-clip-text text-transparent'>
                {plan}
              </div>
            </div>
            <div className={cn(cardContainer)}>
              <div className={cn(label)}>No. of users</div>
              <div className='text-lg weight-bold'>
                <span>{total_users}</span>
              </div>
            </div>
            <div className={cn(cardContainer)}>
              <div className={cn(label)}>Status</div>
              <div className='text-lg weight-bold'>
                <span>{brandStatus[status]}</span>
              </div>
            </div>
          </div>
          <div className='w-full h-11 px-5 pt-2.5 pb-1 border-t border-[#E8E8EA] flex items-center gap-4'>
            <span className='text-2xl weight-bold text-[var(--a)]'>
              ₹ {abbrNum(remaining_amount, 3)}
            </span>
            <span className='text-sm weight-medium text-[var(--font-400)]'>
              Remaining Amount
            </span>
          </div>
        </div>
      </div>
      {/* <div
        className='absolute top-5 right-5'
        onClick={() => {
          navigate(`${id}/edit`);
          clearSearch()
        }}
      >
        <Settings width={16} height={16} strokeWidth='2' />
      </div> */}
    </div>
  );
}
