import React from "react";
import { primaryColor } from "../../../constants";

// use relative to parent component while using this.
const LineLoader = ({ visible }) => {
  return (
    <div
      className={`w-full z-[101] h-[0.4vh] absolute left-0 top-0 duration-200 bg-green-400 ${
        visible ? "-translate-y-0" : "-translate-y-4"
      }`}
    >
      <div className={`bg-[${primaryColor}] w-full h-full absolute ani`}></div>
    </div>
  );
};

export default LineLoader;
