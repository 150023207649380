const ACCOUNT_OWNER = 'Account Owner';
const ACCOUNT_MANAGER = 'Account Manager';
const BRAND_MANAGER = 'Brand Manager';
const CAMPAIGN_MANAGER = 'Campaign Manager';
const CONTENT_CREATER = 'Content Creater';

const ACCOUNT_LEVEL_ACCESS = (role) =>
  role ? [ACCOUNT_OWNER, ACCOUNT_MANAGER].includes(role) : false;

const BRAND_LEVEL_ACCESS = (role) =>
  role
    ? [BRAND_MANAGER, CAMPAIGN_MANAGER, CONTENT_CREATER]
        .includes(role)
        .includes(role)
    : false;

export {
  ACCOUNT_OWNER,
  ACCOUNT_MANAGER,
  BRAND_MANAGER,
  CAMPAIGN_MANAGER,
  CONTENT_CREATER,
  ACCOUNT_LEVEL_ACCESS,
  BRAND_LEVEL_ACCESS,
};
