import React from 'react';

// assets
import { ICONS } from 'assets/icons';

// utils
import { Modal } from 'antd';

// helper
import { cn } from 'helper/cn';

// components
import Loader from 'components/commonComponents/Loader/Index';

export default function Alert({
  open,
  setOpen,
  rootClassName = '',
  content,
  contentClassName = '',
  contentStyle = {},
  yesContent,
  yesButtonClassName = '',
  yesButtonStyle = {},
  yesDisabled = false,
  yesLoading = false,
  noContent,
  noButtonStyle = {},
  noDisabled = false,
  noButtonClassName = '',
  handleYes,
  handleNo,
}) {
  const handleCancel = () => setOpen(false);

  const btnStyle =
    'min-w-[4vw] h-[2vw] px-[0.8vw] py-[0.3vw] font-normal weight-bold rounded-[0.37vw]';

  return (
    <Modal
      open={open}
      afterOpenChange={(open) => setOpen(open)}
      onCancel={handleCancel}
      footer={false}
      centered={true}
      closable={false}
      width={'auto'}
      maskClosable
      mask={true}
      wrapClassName={'bg-[#00000095]'}
      destroyOnClose
      style={{
        width: '100%',
        borderRadius: 20,
        boxShadow: '0px 10px 24px 0px rgba(79, 92, 128, 0.15)',
      }}
    >
      <div
        className={cn(
          'min-w-[30vw] max-w-[50vw] w-auto flex p-[0.5vw]',
          rootClassName
        )}
      >
        <div className='w-[95%] flex flex-col items-center py-[0.7vw] px-[1vw]'>
          <img src={ICONS?.AlertIcon} alt='popup' className='size-[2vw]' />
          <span
            className={cn(
              'font-normal weight-medium text-[var(--contentBlack)] pt-[0.7vw]',
              contentClassName
            )}
            style={contentStyle}
          >
            {content}
          </span>
          <div className='flex items-center justify-center gap-[1vw] pt-[2vw]'>
            {noContent && (
              <button
                className={cn(
                  'h-auto hover:bg-[var(--BG-25)] rounded-[0.37vw]',
                  btnStyle,
                  noDisabled
                    ? 'text-[var(--font-200)] cursor-not-allowed'
                    : 'text-[var(--font-600)] cursor-pointer',
                  noButtonClassName
                )}
                style={noButtonStyle}
                onClick={() => {
                  if (!noDisabled) handleNo();
                }}
              >
                {noContent}
              </button>
            )}
            {yesContent && (
              <button
                className={cn(
                  yesDisabled
                    ? 'bg-[var(--lightGrey)] text-[var(--darkgrey)] cursor-not-allowed'
                    : 'bg-[var(--primary)] text-[var(--white)] cursor-pointer',
                  btnStyle,
                  yesButtonClassName
                )}
                style={yesButtonStyle}
                onClick={() => {
                  if (!yesDisabled) handleYes();
                }}
              >
                {yesLoading ? (
                  <div className='w-full h-full flex items-center justify-center'>
                    <Loader Width={25} Height={25} loaderBg='white' />
                  </div>
                ) : (
                  yesContent
                )}
              </button>
            )}
          </div>
        </div>
        <div
          className='w-[5%] h-fit cursor-pointer rounded-full'
          onClick={() => {
            handleCancel();
          }}
        >
          <img src={ICONS?.popupX} alt='popup' className={`size-[1.2vw]`} />
        </div>
      </div>
    </Modal>
  );
}
