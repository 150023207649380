import React, { useEffect } from 'react';
import '../../modules/auth/style/auth.css';

import {
  createSearchParams,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { images } from '../../assets/images/index';
import { Title } from '../../components/commonComponents/Title/Title';
import { Terms } from '../../components/commonComponents/Terms & Conditions/Terms';
import Divider from '../../components/commonComponents/divider/Divider';

import { useAspSelector, useAspDispatch } from '../../test/jest-redux-hooks';
import { updateSignUpSteps } from '../../reduxToolkit/authSlice';

import Back from '../../modules/auth/components/AuthBack';
import { cn } from 'helper/cn';

function Index({ children }) {
  let { pathname, params } = useLocation();
  const navigate = useNavigate();

  const [queryParams] = useSearchParams();


  const { signUp } = useAspSelector((state) => state.authentication);
  const { currentUser, currentBrand } = useAspSelector((state) => state.app);
  const { resetPasswordEmail } = useAspSelector(
    (state) => state.authentication
  );

  const tokenValidate = queryParams?.get('token');
  const email = localStorage.getItem('email');

  const dispatch = useAspDispatch();

  const validRoutes = [
    '/sign-up',
    '/on-boarding',
    '/verify-email',
    '/login',
    '/forgot-password',
    '/reset-password',
  ].includes(pathname);

  const routeValidation =
    pathname === '/forgot-password' ||
    pathname === '/reset-password' ||
    pathname === '/login';
  let titleText, paragraph;

  // navigate({
  //   pathname: '/on-boarding',
  //   search: createSearchParams({
  //     token: validateToken,
  //   }).toString(),
  // });

  useEffect(() => {
    const TOKEN = localStorage.getItem('authToken');
    if (TOKEN) {
      if (!pathname.includes('/sign-up'))
        if (currentUser && !pathname.includes('/verify-email')) {
          if (currentUser?.active_accounts?.length === 0) {
            navigate('/on-boarding');
            return;
          } else if (
            currentUser &&
            pathname.includes('/login') &&
            email &&
            tokenValidate
          ) {
            return;
          } else if (
            currentUser &&
            currentUser?.active_accounts?.length > 0 &&
            !pathname.includes('/on-boarding')
          ) {
            if (pathname === '/login') navigate('/user/home');
            else if (pathname !== '/reset-password') navigate('/Accounts');
            return;
          } else if (
            currentUser &&
            currentUser?.active_accounts?.length > 0 &&
            pathname.includes('/on-boarding')
          ) {
            navigate('/on-boarding');
            return;
          } else {
            navigate('/sign-up');
            // navigate({
            //   pathname: '/sign-up',
            //   search: createSearchParams({
            //     token: tokenValidate,
            //     email: email,
            //   }).toString(),
            // });
            return;
          }
        } else if (pathname.includes('/verify-email')) {
          //  navigate('/on-boarding');
          return;
        } else {
          return;
        }
    } else {
      if (pathname.includes('/sign-up')) {
        return;
      } else if (pathname.includes('/on-boarding')) {
        return;
      } else if (pathname.includes('/verify-email')) {
        return;
      } else if (!TOKEN && queryParams?.get('token')) {
        return;
      } else {
        localStorage.clear();
        navigate('/login');
        // navigate({
        //   pathname: '/sign-up',
        //   search: createSearchParams({
        //     token: tokenValidate,
        //   }).toString(),
        // });
        return;
      }
    }
  }, []);

  const goBack = () => {
    dispatch(
      updateSignUpSteps({ ...signUp, step: signUp?.step === 2 ? 1 : 0 })
    );
    dispatch;
  };

  switch (pathname) {
    case '/login':
      titleText = `Welcome back`;
      paragraph = 'Let’s do something great today';
      break;
    case '/sign-up':
      titleText = ``;
      paragraph = '';
      break;
    case '/resend':
      titleText = ` Verify your account`;
      paragraph = '';
      break;
    case '/forgot-password':
      titleText = 'Forgot Password?';
      paragraph = 'No worries, we’ll send you a link to reset your password.';
      break;
    case '/reset-password':
      titleText = 'Reset Password';
      paragraph = `Enter a new password for ${resetPasswordEmail}`;
      break;
    case '/stepper':
      titleText =
        'Let’s Ignite Growth with Conversational Relationship Platform';
      paragraph = '';
      break;

    case '/Accounts':
      titleText = 'Choose an account you wish to use';
      paragraph =
        'Selecting an account, you will be redirected to it’s primary brand.';
      break;

    default:
      titleText = '';
      paragraph = '';
  }

  const navigationFunc = (navTo) =>
    navTo === 'login' ? navigate('/login') : navigate('/sign-up');

  return (
    <div className='authWrapper'>
      <div className='leftLayout'>
        <img
          alt='leftLayoutApton'
          className='leftLayoutImg'
          src={images?.leftLayoutApton}
        />
        {/* <span className="rightsText">
          © 2024 Gainwix Inc. All Rights Reserved
        </span> */}
      </div>

      <div className='rightLayout'>
        <div
          className={cn(
            pathname === '/sign-up' || pathname === '/on-boarding'
              ? 'h-[20%] flex flex-col justify-end'
              : 'header'
          )}
        >
          <Title content={titleText} paragraph={paragraph} />
        </div>
        <div
          className={cn(
            pathname === '/sign-up' || pathname === '/on-boarding'
              ? 'max-h-[73%] h-full flex flex-col items-center'
              : 'body'
          )}
        >
          <Outlet />
          {children}
          {/* {pathname === '/sign-up' && (
            <Divider color={'#d1d3d8'} width={'45%'} />
          )} */}
          {pathname === '/forgot-password' || pathname === '/reset-password' ? (
            <Back onClick={() => navigate('/login')} title={'Back to login'} />
          ) : (
            '/sign-up' &&
            signUp?.step !== 0 && <Back onClick={goBack} title={'Back'} />
          )}
        </div>
        {!pathname.includes('/on-boarding') && (
          <div className='footer'>
            <Terms />
          </div>
        )}
      </div>
    </div>
  );
}

export default Index;

//
