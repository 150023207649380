import React, { useState } from 'react';
import './appLayout.css';
import '../../components/form/Button/Button.css';

import { ICONS } from '../../assets/icons/index';
import HeaderDropDown from '../../components/commonComponents/HeaderDropDown/HeaderDropDown';
import { Popover } from 'antd';
import UsagePlan from './UsagePlan';
import { useLocation } from 'react-router-dom';

const getBackgroundClass = (planName) => {
  switch (planName) {
    case 'Free Plan':
      return 'freePlanBackground';
    case 'Growth Plan':
      return 'growthPlanBackground';
    case 'Magnum Plan':
      return 'enterprisePlanBackground';
    default:
      return '';
  }
};

function Header() {
  const { pathname } = useLocation();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleCancel = () => setIsModalVisible(false);

  const handleVisibleChange = () => {
    setIsModalVisible(!isModalVisible);
  };

  return (
    <header>
      <div className='header-container flex-row align-center space-between'>
        <div className='appIcon'>
          {/* <img src={ICONS?.logoGain} alt="logoGain" /> */}
          <img src={ICONS?.logoApton} alt='logoApton' />
        </div>

        {pathname.includes('/user') && (
          <div className='header-line'>
            <HeaderDropDown />
          </div>
        )}
      </div>

      <div className='alignRight'>
        {/* <button
          className={` ${getBackgroundClass(
            currentBrand?.brand_billing_plan_name
          )}`}
        >
          {currentBrand?.brand_billing_plan_name}
        </button> */}

        <Popover
          content={<UsagePlan handleCancel={handleCancel} />}
          trigger='click'
          overlayClassName='custom-popover'
          open={isModalVisible}
          onOpenChange={handleVisibleChange}
          arrow={false}
        >
          <button className='usage-plan flex-row align-center'>
            <img alt='usage' src={ICONS.usage} className='usage-plan-img' />
            <p>Usage & Plan</p>
          </button>
        </Popover>
      </div>
    </header>
  );
}

export default Header;
