import React from "react";
import Close from "../../../../../assets/icons/close.svg";
import { headingColor } from "../../../constants";
import back from "../../../../../assets/images/Back.svg";

const ModelContent = ({
  showSidebar,
  sideNavLabel,
  ModelSideNav,
  headerLabel,
  Toggle,
  children,
  rootClassName = "",
  mainClassName = "",
  sideBarMainClassName = "",
  Footer = "",
  showBack = false,
  backAction = () => {},
  ...rest
}) => {
  return (
    <div className={`h-full grid grid-cols-[2fr,6fr] ${rootClassName}`}>
      {showSidebar && (
        <>
          <div
            className={`bg-[#F4F6F8] p-[1vw] border-r border-r-slate-200 space-y-[2.2vh] ${sideBarMainClassName}`}
          >
            {sideNavLabel && (
              <h2
                className={`font-bold text-[${headingColor}] capitalize text-[2vh] mb-[2vw]`}
              >
                {sideNavLabel}
              </h2>
            )}

            <>{ModelSideNav}</>
          </div>
        </>
      )}
      <div className={`overflow-scroll ${mainClassName}`}>
        {headerLabel && (
          <>
            <div id="modelMain" className="sticky top-0 px-[1vw] pt-[.5vw]">
              <div className="flex items-center border-b border-b-gray-100 justify-between bg-white pb-4-h-[10vh]">
                <p className="flex min-h-[6vh]  *:duration-200 items-center relative ">
                  <span
                    onClick={backAction}
                    className={`absolute cursor-pointer opacity-0  ${
                      showBack ? "scale-[1_important] opacity-100" : "scale-0"
                    }  text-[1.5vw] h-full flex items-center border-e border-e-gray-100 pe-4 me-4`}
                  >
                    <img className="w-[2vw] ms-[1vw]" src={back} alt="" />
                  </span>
                  <span
                    className={`${
                      showBack ? "ps-[5vw]" : "ps-[0]"
                    } flex items-center font-[600] text-[.95vw] h-[2vw]`}
                  >
                    {headerLabel}
                  </span>
                </p>
                <span>
                  <span
                    className="cursor-pointer"
                    onClick={Toggle}
                    data-testid="close_click"
                  >
                    <img src={Close} alt="" />
                  </span>
                </span>
              </div>
              {/* <Underline weight="thin" /> */}
            </div>
          </>
        )}
        <>{children}</>
        {Footer && <div className="sticky">{Footer}</div>}
      </div>
    </div>
  );
};

export default ModelContent;
