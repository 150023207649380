import React, { useCallback, useEffect, useState } from "react";

// constants
import { MEDIA_TYPES } from "../../constant";

// helper
import { cn } from "../../../../helper/cn";

// utils
import {
  addCtaButton,
  CAROUSEL_BUTTONS,
} from "../../../../components/commonComponents/templates/Buttons/utils";

// components
import RadioButton from "components/form/radio/RadioButton";
import Select from "../../../../components/commonComponents/select";
import CardSkeleton from "../skeleton/carousel/CardSkeleton";
import CarouselCard from "./CarouselCard";
import Tabs from "./Tabs";
import TemplateBody from "./TemplateBody";
import VariableComponent from "./VariableComponent";
import { useAspSelector } from "../../../../test/jest-redux-hooks";
import { Button } from "../../../../components/form/Button/Button";

import { carouselAddCard } from "../../api/Api";
import { ICONS } from "../../../../assets/icons";

export const buttonOptions = {
  URL: "Visit Website",
  PHONE_NUMBER: "Call Phone Number",
  CUSTOM: "Quick Reply",
  NONE: "None",
};

export default function CarouselTemplate({
  id,
  language,
  body,
  dispatch,
  headerVariable,
  variables,
  allow,
  showEmojiPicker,
  setShowEmojiPicker,
  data,
  isAddingCarouselCard,
  setIsAddingCarouselCard,
  setSelectedCard,
  selectedCard,
}) {
  const templateDetails = useAspSelector((state) => state?.Template);
  const { currentBrand } = useAspSelector((state) => state?.app);

  useEffect(() => {
    if (selectedCard)
      setSelectedCard(data?.cards?.filter((c) => c?.isSelected == true)?.[0]);
    else setSelectedCard(data?.cards?.[0]);
  }, [data]);

  useEffect(() => {
    if (isAddingCarouselCard) {
      updateSelectedCardData();
    }
  }, [isAddingCarouselCard]);

  const onSelect = (d) => {
    dispatch({
      type: "update_carousel",
      updateType: "select_card",
      id: d?.id,
    });
    setSelectedCard(d);
  };

  const updateSelectedCardData = useCallback(() => {
    onSelect(data?.cards?.[data?.cards?.length - 1]);
    return;
  }, [data]);

  // common styles
  const header =
    "pb-[0.4vw] font-default weight-semibold text-[var(--contentText)] flex items-center gap-[0.2vw]";
  const subHeader =
    "font-md weight-medium text-[var(--font-400)] pt-[0.03vw] pb-[0.5vw]";

  const createNewCardButtons = (data) => {
    let buttons = [];
    buttons?.push({
      ...addCtaButton(null, data?.button1),
      id: 1,
      slug: "",
      label:
        data?.button1 === "QUICK_REPLY"
          ? "CUSTOM"
          : buttonOptions[data?.button1],
    });
    if (data?.button2 !== "NONE")
      buttons?.push({
        ...addCtaButton(null, data?.button2),
        id: 2,
        slug: "",
        label: data?.button2 === "CUSTOM" ? "CUSTOM" : data?.button2,
        // : buttonOptions[data.button2],
      });

    return buttons;
  };

  const getId = (items) => {
    return items?.length === 0
      ? 0
      : items?.reduce(
          (maxId, item) => (item?.card_id > maxId ? item?.card_id : maxId),
          0
        );
  };

  const addNewCard = (id, payload) => {
    setIsAddingCarouselCard(true);
    try {
      const res = carouselAddCard(id, payload);
      setIsAddingCarouselCard(false);
      return res;
    } catch (error) {
      console.warn(error);
    }
  };

  const addCard = useCallback(
    async (brandId) => {
      const buttons = createNewCardButtons(data);

      const id = getId(data?.cards) + 1;
      // const id = data?.cards?.length + 1;

      const newCard = {
        id: id,
        card_id: id,
        image: null,
        name: `Card ${id}`,
        slug: "",
        bodyText: "",
        display_order: id,
        whatsapp_carousel_template_id: null,
        bodyVariables: [],
        buttons: buttons,
        isSelected: false,
      };

      const template_ids =
        templateDetails?.continueDetails?.template?.id ||
        templateDetails?.continueDetails?.template_id ||
        templateDetails?.continueDetails?.id;

      const payload = {
        brand_id: brandId,
        name: newCard?.name,
      };

      const res = await addNewCard(template_ids, payload);

      if (res?.status === 200) {
        dispatch({
          type: "update_carousel",
          updateType: "add_card",
          data: { ...newCard, ...res?.data?.card },
        });
      }
    },
    [data]
  );

  const getButtonCarouselOptions = (otherButtonSelection, sliceIndex = 0) => {
    let options = CAROUSEL_BUTTONS;

    if (sliceIndex) {
      options = options.slice(sliceIndex);
    }

    if (otherButtonSelection === "Call Phone Number") {
      options = options.filter(
        (option) => option?.label !== "Call Phone Number"
      );
    }

    return options;
  };

  return (
    <div>
      <div className="mb-[1vw]">
        <TemplateBody
          type="default"
          category="carousel"
          title={"Body Message"}
          subTitle={
            "Enter the text for your message in the language that you've selected."
          }
          language={language}
          body={body}
          dispatch={dispatch}
          headerVariable={headerVariable}
          variables={variables}
          allow={allow}
          showEmojiPicker={showEmojiPicker}
          setShowEmojiPicker={setShowEmojiPicker}
          textAreaClassName={"h-[10vh]"}
        />
      </div>

      <div className="border-t border-t-text-[var(--border-50)]">
        <div className=" mt-10">
          <h3 className={cn(header)}>Carousel Settings</h3>
          <p className={subHeader}>
            The media header format and button types must be the same across all
            cards that make up a carousel template.
          </p>
        </div>

        <div className="mt-[0.8vw]">
          <h3 className={cn(header)}>
            Carousel Header
            <sup className="text-[var(--fontRed)]">*</sup>
          </h3>
          <p className={subHeader}>Choose an image or video.</p>
          <div className={`mt-[1vw] flex items-center gap-5`}>
            {MEDIA_TYPES?.slice(0, 2)?.map((t) => {
              return (
                <div
                  key={`Media-type-${t.format}`}
                  className={`w-[10vw] h-auto flex gap-[0.5vw] px-[0.25vw] py-[0.25vw] border-[0.08vw] rounded-[0.6vw]  ${
                    !allow
                      ? "cursor-not-allowed"
                      : allow
                      ? !t.disabled
                        ? data?.header === t?.format
                          ? "bg-[var(--white)] border-[var(--white)] shadow-[0px_4px_30px_0px_#2D30361A]"
                          : "bg-transparent border-[var(--border-50)] cursor-pointer"
                        : "!cursor-not-allowed opacity-50"
                      : "!cursor-pointer"
                  }`}
                  onClick={() => {
                    if (allow) {
                      if (!t.disabled) {
                        if (data?.header !== t?.format) {
                          dispatch({
                            type: "update_carousel",
                            updateType: "update",
                            key: "header",
                            value: t.format,
                          });
                        }
                      }
                    }
                  }}
                >
                  <div
                    className={`w-[7.9vw] px-[0.3vw] py-[0.25vw] flex items-center gap-[0.7vw] bg-[var(--white)]`}
                  >
                    <div
                      className={`flex items-center justify-center rounded-[0.4vw] p-[0.3vw] ${
                        data?.header === t?.format
                          ? "bg-[#F0FDF4]"
                          : "bg-[#f0f1f2]"
                      }`}
                    >
                      <t.icon
                        color={`${
                          data?.header === t?.format ? "#25C277" : "#2D3036"
                        }`}
                        width={"1.5vw"}
                        height={"1.5vw"}
                      />
                    </div>
                    <div
                      className={`font-md weight-semibold text-[var(--contentText)]`}
                    >
                      {t.label}
                    </div>
                  </div>
                  <div className="">
                    {data?.header === t?.format && (
                      <RadioButton selected={true} />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="mt-[2.5vw]">
          <h3 className={cn(header)}>Buttons</h3>
          <p className={subHeader}>At least one button is mandatory</p>
          <div className="w-full mt-[1vw] flex flex-col gap-[1vw]">
            <div className="flex items-center gap-[2vw]">
              <div className="font-default weight-bold">
                Card Button 1<sup className="text-[var(--fontRed)]">*</sup>
              </div>
              <div className="w-[18vw]">
                <Select
                  value={buttonOptions[data?.button1] ?? "None"}
                  options={getButtonCarouselOptions(
                    buttonOptions[data?.button2],
                    1
                  )}
                  placeholder={"Select type"}
                  rootClassName="w-full h-[2.3vw] flex-1"
                  optionsClassName="!max-w-full"
                  cardStyle="!max-h-[1.5vw]"
                  onChange={(val) => {
                    const res = addCtaButton(null, val?.format);
                    dispatch({
                      type: "update_carousel",
                      updateType: "update_all_buttons",
                      key: "button1",
                      id: 1,
                      value: { ...val, ...res },
                    });
                  }}
                />
              </div>
            </div>
            <div className="flex items-center gap-[2vw]">
              <div className="font-default weight-bold">Card Button 2</div>
              <div className="w-[18.2vw] pl-5">
                <Select
                  value={buttonOptions[data?.button2] ?? "None"}
                  options={getButtonCarouselOptions(
                    buttonOptions[data?.button1]
                  )}
                  placeholder={"Select type"}
                  rootClassName="w-full h-[2.3vw] flex-1"
                  optionsClassName="!max-w-full"
                  cardStyle="!max-h-[1.5vw]"
                  onChange={(val) => {
                    const res = addCtaButton(null, val?.format);
                    dispatch({
                      type: "update_carousel",
                      updateType: "update_all_buttons",
                      key: "button2",
                      id: 2,
                      value: res,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-[#F0ECFE] border border-[#6940F2] rounded-[0.5vw] p-[0.5vw] mt-[2vw] gap-4 flex-row">
          <img
            src={ICONS?.carouselBulb}
            alt="carouselBulb"
            className="pb-[22px] pl-5"
          />
          <p className="font-default">
            Cards should have a media header (image or video), body text, and at
            least one button. It supports 2 buttons. Buttons can be the same or
            a mix of quick reply buttons, phone number buttons, or URL buttons.
          </p>
        </div>
      </div>

      <div className="mt-20">
        <div className={cn(header)}>Carousel cards</div>
        <span className={subHeader}>
          You need at least 2 cards, but you can add up to 10.
        </span>
      </div>

      {data?.cards?.length > 0 ? (
        <>
          <div className="w-full bg-[var(--BG-50)] p-[0.6vw] mt-[2vw] flex flex-col rounded-[0.6vw]">
            <Tabs
              isLoading={isAddingCarouselCard}
              data={data?.cards}
              selectedCard={selectedCard}
              onSelect={onSelect}
              dispatch={dispatch}
              addTab={() => {
                if (data?.cards?.length < 10) {
                  // dispatch({
                  //   type: "update_carousel",
                  //   updateType: "add_card",
                  // });
                  // // updateSelectedCardData();
                  // setIsAddingCarouselCard(true);
                  addCard(currentBrand?.brand_id);
                }
              }}
              closeTab={(card) => {
                if (data?.cards?.length > 1) {
                  dispatch({
                    type: "update_carousel",
                    updateType: "remove_card",
                    id: card?.id,
                  });
                  if (selectedCard?.id === card?.id)
                    onSelect(data?.cards?.[card?.id - 2]);
                  else onSelect(data?.cards?.[0]);
                }
              }}
            />
            {isAddingCarouselCard ? (
              <div className="w-full h-full min-h-[46vh] p-[0.6vw] bg-white flex items-center justify-center">
                {/* <Loader Width={40} Height={40} /> */}
                <CardSkeleton />
              </div>
            ) : (
              <CarouselCard
                template_id={id}
                format={data?.header}
                data={selectedCard}
                setData={setSelectedCard}
                dispatch={dispatch}
                allow={allow}
                language={language}
                headerVariable={headerVariable}
                variables={variables}
                button1={data?.button1}
                button2={data?.button2}
              />
            )}
          </div>
          {data?.bodyVariables?.length > 0 && (
            <div className="mt-[1.5vw]">
              <p className="font-md weight-bold text-[var(--contentText)]">
                Fallback text for cards body content
              </p>
              {data?.bodyVariables?.map((v, i) => {
                return (
                  <div key={i} className="py-[0.5vw]">
                    <VariableComponent
                      name={v?.title}
                      value={v?.value}
                      onChange={(e) => {
                        dispatch({
                          id: selectedCard?.id,
                          type: "update_carousel",
                          updateType: "update_variable",
                          variableTitle: v?.title,
                          variableValue: e?.target?.value,
                        });
                      }}
                    />
                  </div>
                );
              })}

              {/* {data?.bodyVariables?.map((v, i) => {
                const cardVariable = data?.cards[0]?.bodyVariables.find(
                  (cv) => cv.title === v?.title
                );
                const variableValue =
                  v?.value !== undefined ? v?.value : cardVariable?.value;
                return (
                  <div key={i} className="py-[0.5vw]">
                    <VariableComponent
                      name={v?.title}
                      value={v?.value}
                      onChange={(e) => {
                        dispatch({
                          id: selectedCard?.id,
                          type: "update_carousel",
                          updateType: "update_variable",
                          variableTitle: v?.title,
                          variableValue: e?.target?.value,
                        });
                      }}
                    />
                  </div>
                );
              })} */}
            </div>
          )}
        </>
      ) : (
        <div className="w-full bg-[var(--BG-50)] p-[2vw] mt-[2vw] flex flex-col rounded-[0.6vw] min-h-[40vh]">
          <div className="w-full min-h-[34vh] bg-white flex flex-row justify-center align-center rounded-xl">
            <div className="w-40">
              <h3 className={cn(header)}>Add Carousel Cards</h3>
              <p className={subHeader}>
                Feature multiple deals or discounts in a visually appealing
                format, increasing engagement and conversions.
              </p>
              <Button
                label={"Add Carousel Card "}
                size={"medium"}
                onClick={() => {
                  addCard(currentBrand?.brand_id);
                }}
                style={{ marginTop: "10px" }}
                iconPrefix={ICONS?.addBGwhite}
              />
            </div>
            <img src={ICONS?.carouselAddCardIamge} alt="carouselAddCardIamge" />
          </div>
        </div>
      )}
    </div>
  );
}
