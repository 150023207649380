import React, { useState } from "react";
import { useSearch } from "hooks/useSearch";
import SearchBar from "../../../../components/commonComponents/searchBar";
import Button from "../commonComponents/button";
import addColIcon from "../../../../assets/icons/add_column.svg";
import Modal from "../commonComponents/modal";
import { Popover, Tooltip } from "antd";
import {
  ModelHeadings,
  contactFilters,
  headingColor,
  lifeCycleStages,
} from "../../constants";
import Dropdown from "../commonComponents/dropdown";
import UploadFromCsv from "./contactModel/UploadFromCsv";
import AddSingleContact from "./contactModel/AddSingleContact";
import { useDispatch } from "react-redux";
import {
  handleDynamicContactSelect,
  handleSingleColsAction,
  updateSingleContactDetails,
} from "../../../../reduxToolkit/crmSlice";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { cityListApi, countryListApi, stateListApi } from "../../api/Api";
import { SendPeopleList } from "../../../../constant/app/campaign/campaign";
import RadioButton from "../../../../components/form/radio/RadioButton";

export const Search = ({
  setVisibleColumns,
  isFilter,
  setIsFilter,
  templateSearch,
  no,
  origins,
  setNewContactActive,
}) => {
  const [selectOpen, setSelectOpen] = useState(false);
  const [active, setActive] = useState(false);
  const [importFrom, setImportFrom] = useState("contact");
  const [importStep, setImportStep] = useState(1);
  const [locationFilter, setLocationFilter] = useState("");
  const [contactSources, setContactSources] = useState([]);
  const [value1, setValue1] = useState("");
  const [lifeCycle, setLifeCycle] = useState(lifeCycleStages);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [countryListss, setCountryListss] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [selectedLocation, setSelectedLocation] = useState({
    country: { name: "", id: null },
    state: { name: "", id: null },
    city: { name: "", id: null },
  });

  const { dynamicColumns, crmColumn } = useSelector((store) => store.crm);
  const [selectedColumns, setSelectedColumns] = useState(
    crmColumn.map((col) => col.id)
  );
  const [popoverVisible, setPopoverVisible] = useState(false);
  const handleVisibleChange = (visible) => {
    setPopoverVisible(visible);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    setContactSources(origins);
  }, [origins]);

  function handleClick() {
    setActive(false);
    setSelectOpen(false);
    setNewContactActive(false);
    resetForm();
  }

  const handleSaveClick = () => {
    setActive(true);
    setNewContactActive(true);
  };

  const handleLocationClick = (type, name, id) => {
    setSelectedLocation((prev) => ({
      ...prev,
      [type]: { name, id },
    }));
    const dispatchAction = (key, value) => {
      dispatch(
        handleSingleColsAction({
          key,
          value,
          field: "address",
        })
      );
    };

    if (type === "country") {
      dispatchAction("country", id);
    } else if (type === "state") {
      dispatchAction("state", id);
    } else if (type === "city") {
      dispatchAction("city", id);
    }
    setPopoverVisible(false);
  };

  const countryLists = () => {
    countryListApi().then((res) => {
      if (res?.data) {
        setCountryListss(res?.data?.data);
      }
    });
  };

  const stateLists = () => {
    stateListApi(selectedLocation?.country?.id).then((res) => {
      if (res?.data) {
        setStateList(res?.data?.data);
      }
    });
  };

  const cityLists = () => {
    cityListApi(selectedLocation?.state?.id).then((res) => {
      if (res?.data) {
        setCityList(res?.data?.data);
      }
    });
  };

  useEffect(() => {
    if (active) {
      countryLists();
    }

    if (selectedLocation?.country?.id) {
      stateLists();
    }

    if (selectedLocation?.state?.id) {
      cityLists();
    }
  }, [
    importFrom,
    selectedLocation?.country?.id,
    selectedLocation?.state?.id,
    active,
  ]);

  const locationSearch = (value) => {
    setLocationFilter(value);
  };

  useEffect(() => {
    localStorage.setItem("dynamicClos", JSON.stringify(dynamicColumns));
  }, [dynamicColumns]);

  const handleColumnToggle = (id) => {
    const nonToggleIds = [1, 2, 3, 4, 6];

    if (nonToggleIds.includes(id)) {
      return;
    }

    setVisibleColumns((prevState) => {
      const column = crmColumn.find((col) => col.id === id);
      if (column) {
        return prevState.includes(column.label)
          ? prevState.filter((label) => label !== column.label)
          : [...prevState, column.label];
      }
      return prevState;
    });

    setSelectedColumns((prevState) => {
      if (prevState.includes(id)) {
        return prevState.filter((columnId) => columnId !== id);
      } else {
        return [...prevState, id];
      }
    });
  };

  const resetForm = () => {
    const singleContactUploadDetails = {
      contact: {
        first_name: "",
        last_name: "",
        phone_number: "",
        phone_number_code: "+91",
        email: "",
        alternative_mobile: [{ phone_number: "", phone_number_code: "+91" }],
        contact_origin: "",
        // life_cycle_stage: "",
        opted_out: [],
        whatsApp_opt_out: false,
        email_opt_out: false,
        SMS_opt_out: false,
      },
      address: {
        address: "",
        country: "",
        state: "",
        city: "",
        "zip_code / postal_code": "",
      },
    };

    for (const field in singleContactUploadDetails) {
      for (const key in singleContactUploadDetails[field]) {
        dispatch(
          handleSingleColsAction({
            key: key,
            value: singleContactUploadDetails[field][key],
            field: field,
          })
        );
      }
    }

    dispatch(
      updateSingleContactDetails({
        access_name: "selected_tags",
        value: [],
      })
    );
    setContactSources([]);
    setValue1("");
    setIsButtonDisabled(false);
    setSelectedLocation({
      country: { name: "", id: null },
      state: { name: "", id: null },
      city: { name: "", id: null },
    });
  };

  const Column = () => {
    return (
      <div className="country-popover1 flex-column w-100 bg-white max-h-[45vh] !min-w-[13vw] pl-10 pr-10 pt-20 pb-20  b-radius-6">
        <p className="py-[.3vh] px-[0.5vw] text-[.8vw] font-[500] rounded-md text-[var(--font-400)] ">
          Columns
        </p>
        {crmColumn?.map((item) => (
          <div
            className="w-100 bg-white pl-10 pr-10 pt-20 pb-20 text-[.8vw] font-[400] pointer flex-row align-center space-between"
            onClick={() => handleColumnToggle(item?.id)}
          >
            {item?.label}
            {selectedColumns.includes(item.id) && (
              <RadioButton selected={selectedColumns.includes(item.id)} />
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="w-full border-b-[0.1vw] border-[var(--border-50)] p-[0.5vw] flex justify-between items-center overflow-hidden">
      {no && (
        <div className="w-[19.5vw] h-[2.1vw]">
          <SearchBar
            searchKey={isFilter}
            setSearchKey={setIsFilter}
            onChange={(text) => templateSearch(text)}
          />
        </div>
      )}
      <div className="w-100 flex transition-all items-center align-end justify-end me-[1vw] gap-[.6vw]">
        {no && (
          <Popover
            placement="top"
            color={"black"}
            arrow={false}
            trigger="click"
            content={<Column />}
          >
            <Tooltip title={"Column"}>
              <img
                src={addColIcon}
                alt=""
                className="hover:bg-gray-100 duration-200 cursor-pointer rounded-md me-[1vw]- size-[2.2vw]"
              />
            </Tooltip>
          </Popover>
        )}

        <Modal
          active={active}
          closable={false}
          onClick={handleClick}
          headerLabel={ModelHeadings[importFrom].mainLabel?.[importStep - 1]}
          sideNavLabel={"Import contacts"}
          Button={
            <Button
              label="New Contact"
              onClick={() => handleSaveClick()}
              classNames="w-100 flex-row align-end justify-end"
              data-testid="New_contact_click"
            />
          }
          showBack={importStep > 1}
          backAction={() => setImportStep((prev) => prev - 1)}
          ModelSideNav={
            <div className="space-y-[1.5vh]">
              {Object.keys(ModelHeadings).map((item) => {
                const { Icon, sideNavLabel } = ModelHeadings[item];
                const isSelected = item === importFrom;
                return (
                  <div
                    onClick={() => {
                      setImportFrom(item);
                      setImportStep(1);
                    }}
                    data-testid="leftside"
                    key={item}
                    className={`flex gap-3 items-center py-[0.5vh] rounded-md px-[.5vw] text-[.8vw] font-[400] cursor-pointer ${
                      isSelected && "bg-[var(--primary)] transition-all"
                    }`}
                  >
                    <Icon
                      width={"1vw"}
                      fill={isSelected ? "#fff" : headingColor}
                    />
                    <p
                      className={`text-[${
                        isSelected ? "#fff" : headingColor
                      }] font-[${isSelected && "500"}]`}
                    >
                      {sideNavLabel}
                    </p>
                  </div>
                );
              })}
            </div>
          }
        >
          {importFrom === "csv" && (
            <UploadFromCsv
              selectOpen={selectOpen}
              setSelectOpen={setSelectOpen}
              importStep={importStep}
              setImportStep={setImportStep}
            />
          )}

          {importFrom === "contact" && (
            <AddSingleContact
              onClick={handleVisibleChange}
              countryListss={countryListss}
              popoverVisible={popoverVisible}
              setPopoverVisible={setPopoverVisible}
              stateList={stateList}
              handleLocationClick={handleLocationClick}
              cityList={cityList}
              selectedLocation={selectedLocation}
              setActive={setActive}
              active={active}
              isFilter={isFilter}
              setIsFilter={setIsFilter}
              templateSearch={templateSearch}
              locationSearch={locationSearch}
              locationFilter={locationFilter}
              setLocationFilter={setLocationFilter}
              resetForm={resetForm}
              contactSources={contactSources}
              setContactSources={setContactSources}
              lifeCycle={lifeCycle}
              setLifeCycle={setLifeCycle}
              origins={origins}
              setValue1={setValue1}
              value1={value1}
              setIsButtonDisabled={setIsButtonDisabled}
              isButtonDisabled={isButtonDisabled}
            />
          )}
        </Modal>
      </div>
    </div>
  );
};
