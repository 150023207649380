import React from 'react';
import CheckBox from '../../../../components/commonComponents/checkbox';

export default function ConversationClose({
  type = '',
  data,
  className,
  value,
  onChange,
}) {
  return (
    <CheckBox
      data={data}
      value={value}
      className={className}
      onChange={onChange}
    />
  );
}
