import React from 'react';

export default function CardSkeleton({ isLoading }) {
  const skeleton = 'w-full h-full bg-[var(--BG-50)] rounded-[0.4vw]';

  return (
    <div className='w-full h-full bg-white flex gap-[2vw] p-[1.5vw] animate-pulse'>
      <div class='w-72 h-56 rounded-[1vw] bg-gray-200'></div>
      <div class='w-full h-full'>
        <div className='w-full h-12 flex gap-[0.3vw] justify-between mb-6'>
          <div class='w-1/2 h-12 flex flex-col gap-[0.7vw]'>
            <div class='w-1/2 h-5 rounded-[1vw] bg-gray-200'></div>
            <div class='w-full h-3.5 rounded-[1vw] bg-gray-200'></div>
          </div>
          <div class='w-1/2 h-12 flex items-center justify-end mt-[0.5vw]'>
            <div class='w-24 h-12 rounded-[1vw] bg-gray-200'></div>
          </div>
        </div>

        <div className='w-full flex flex-col gap-[0.7vw]'>
          <div class='w-full h-32 rounded-[1vw] bg-gray-200'></div>
          <div className='flex items-center justify-end gap-[0.5vw]'>
            <div class='w-9 h-6 rounded-[0.3vw] bg-gray-200'></div>
            <div class='w-9 h-6 rounded-[0.3vw] bg-gray-200'></div>
            <div class='w-9 h-6 rounded-[0.3vw] bg-gray-200'></div>
            <div class='w-14 h-6 rounded-[0.3vw] bg-gray-200'></div>
          </div>
        </div>

        <div className='w-full flex gap-[0vw] justify-between mt-[0.5vw]'>
          <div class='w-1/2 flex flex-col gap-[0.7vw]'>
            <div class='w-1/2 h-5 rounded-[1vw] bg-gray-200'></div>
            <div class='w-full h-3.5 rounded-[1vw] bg-gray-200'></div>
          </div>
        </div>
        <div class='w-full h-11 flex items-center justify-end gap-[1vw] mt-[1vw]'>
          <div class='w-1/4 h-9 rounded-[0.5vw] bg-gray-200'></div>
          <div class='w-full h-9 rounded-[0.5vw] bg-gray-200'></div>
        </div>
        {/* <div class='w-full h-11 flex items-center justify-end gap-[1vw]'>
          <div class='w-1/4 h-9 rounded-[0.5vw] bg-gray-200'></div>
          <div class='w-full h-9 rounded-[0.5vw] bg-gray-200'></div>
        </div> */}
      </div>
    </div>
  );
}
