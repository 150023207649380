import React from 'react';

// Custom svg
import Star from '../../../../assets/customSVG/Star';

export default function ConversationStar({
  data,
  className,
  width,
  height,
  onChange,
  ...props
}) {
  return (
    <div
      className={`cursor-pointer ${className}`}
      onClick={() => {
        onChange(data);
      }}
      {...props}
    >
      <Star
        width={width ?? 15}
        height={height ?? 15}
        fill={data?.isStarred ? '#F6A723' : 'none'}
        stroke={data?.isStarred ? '#F6A723' : '#898E99'}
      />
    </div>
  );
}
