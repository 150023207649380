import MessagePlus from '../../../assets/customSVG/MessagePlus';
import MessageTyping from '../../../assets/customSVG/MessageTyping';
import Star from '../../../assets/customSVG/Star';
import { ICONS } from '../../../assets/icons/index';

export const leftInboxList = [
  {
    id: 1,
    icon: ICONS?.whatsApp,
    name: 'Gochew Grill',
    num: '+91 9876543210',
    count: 1100,
  },
];

export const conversationTypes = [
  {
    id: 1,
    type: 'opened',
    icon: 'inboxMessageTyping',
    msg: 'Opened conversations.',
    icon: MessageTyping,
  },
  {
    id: 2,
    type: 'closed',
    icon: 'inboxMessagePlus',
    msg: 'Closed conversations.',
    icon: MessagePlus,
  },
  {
    id: 3,
    type: 'starred',
    icon: 'inboxStar',
    msg: 'Starred conversations.',
    icon: Star,
  },
];

export const conversationMessage = [
  { id: 1, icon: ICONS?.conversationMessage, list: '10 Open conversations.' },
  { id: 2, icon: ICONS?.conversationMessage, list: '10 Closed conversations.' },
  {
    id: 3,
    icon: ICONS?.conversationMessage,
    list: '10 Starred conversations.',
  },
];

export const conversationMessageList = [
  {
    id: 1,
    icon: ICONS?.inboxProfile,
    icon1: ICONS?.whatsApp,
    name: 'Riya Magar',
    phoneNumber: '(+91) 984 304 3000',
    content:
      'Lorem ipsum dolor sit amet consectetur. Commodo habitant enim vivamus eu elementum nulla platea velit.',
    time: '2024-03-03T19:37:00.000Z',
    isStarred: true,
    isClose: false,
  },
  {
    id: 2,
    icon: ICONS?.inboxProfile,
    icon1: ICONS?.whatsApp,
    name: 'Shweta Nigam',
    phoneNumber: '(+91) 984 304 3000',
    content:
      'Lorem ipsum dolor sit amet consectetur. Commodo habitant enim vivamus eu elementum nulla platea velit.',
    time: '2024-04-03T19:37:00.000Z',
    isStarred: false,
    isClose: true,
  },
  {
    id: 3,
    icon: ICONS?.inboxProfile,
    icon1: ICONS?.whatsApp,
    name: null,
    phoneNumber: '(+91) 984 304 3000',
    content:
      'Lorem ipsum dolor sit amet consectetur. Commodo habitant enim vivamus eu elementum nulla platea velit.',
    time: '2024-04-03T21:08:00.000Z',
    isStarred: false,
    isClose: false,
  },
];

// export const conversationMessageMiddle = [
//   {
//     id: 1,
//     icon: ICONS?.inboxProfile,
//     icon1: ICONS?.Whatsapp,
//     name: 'Riya Magar',
//     content: 'Last replied Oct, 18 - 10:30AM',
//     time: '2024-04-03T21:08:00.000Z',
//     isStarred: true,
//   },
// ];

export const templatePopover = [
  {
    id: 1,
    name: 'exclusive_dinner_deal',
    language: 'English',
    active: 'Active - Quality pending',
  },
  {
    id: 2,
    name: 'exclusive_breakfast_deal',
    language: 'English',
    active: 'Active - Quality pending',
  },
  {
    id: 3,
    name: 'exclusive_diwali_offer',
    language: 'English',
    active: 'Active - Quality pending',
  },
  {
    id: 4,
    name: 'exclusive_dinner_deal',
    language: 'English',
    active: 'Active - Quality pending',
  },
  {
    id: 5,
    name: 'exclusive_dinner_deal',
    language: 'English',
    active: 'Active - Quality pending',
  },
];

export const inboxChat = [
  {
    id: 1,
    content:
      'Hello! I heard you have a special food offer. Can you tell me more about it?',
  },
  {
    id: 2,
    content:
      'Hello! I heard you have a special food offer. Can you tell me more about it?',
  },
  {
    id: 3,
    content:
      'Hello! I heard you have a special food offer. Can you tell me more about it?',
  },
];

export const inboxVariables = [
  {
    id: 1,
    placeholder: '{{first name}}',
  },
  {
    id: 2,
    placeholder: '{{email}}',
  },
  {
    id: 3,
    placeholder: '{{product 1}}',
  },

  {
    id: 4,
    placeholder: '{{orders}}',
  },
  {
    id: 5,
    placeholder: '{{custom variable240}}',
  },
];

export const inboxVariablesSample = [
  {
    id: 1,
    placeholder: 'Ex:- joyel',
  },
  {
    id: 2,
    placeholder: 'Ex:- abc@mail.com',
  },
  {
    id: 3,
    placeholder: 'Ex:- text',
  },

  {
    id: 4,
    placeholder: 'Ex:- text',
  },
  {
    id: 5,
    placeholder: 'Ex:- text',
  },
];

export const inboxTemplateLink = [
  {
    id: 1,
    link: 'View offer',
    icon: ICONS?.ReplayIcon,
  },
  {
    id: 2,
    link: 'Order Now',
  },
  {
    id: 3,
    link: 'More Details',
  },
];

export const inboxButtonList = [
  {
    id: 1,
    button: 'Button1',
  },
  {
    id: 2,
    button: 'Button2',
  },
  {
    id: 3,
    button: 'Button3',
  },
  {
    id: 4,
    button: 'Button4',
  },
  {
    id: 5,
    button: 'Button5',
  },
  {
    id: 6,
    button: 'Button6',
  },
];
