import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updatedInputValue: {},
  contactsRemaining1: 0,
  contactsRemainingMerge1: 0,
  totalContact: 0,
  delayValues: {},
  selectedDate: {},
  selectedTime: {},
  mergeSelectedTime: {},
  mergeSelectedDate: {},

  // 2nd file individual
  delayValues1: 0,
  contactsRemaining2: 0,
  contactsRemainingMerge2: 0,
  selectedDate1: {},
  selectedTime1: {},

  error: null,
  individualError: {},

  clearError: {},
};

export const authSlice = createSlice({
  name: "broadcast",
  initialState,
  reducers: {
    setUpdatedInputValue: (state, action) => {
      const { id, segmentId, inputValue } = action.payload;
      state.updatedInputValue = {
        [`${id}-${segmentId}`]: inputValue,
      };
    },
    setContactsRemaining1: (state, action) => {
      state.contactsRemaining1 = action.payload;
    },
    setContactsRemainingMerge1: (state, action) => {
      state.contactsRemainingMerge1 = action.payload;
    },
    setTotalContact: (state, action) => {
      state.totalContact = action.payload;
    },
    setDelayValue: (state, action) => {
      state.delayValues = action.payload;
    },
    setSelectedDate: (state, action) => {
      const { id, segmentId, date, type } = action.payload;
      if (type === "clear") {
        state.selectedDate = {};
      } else {
        state.selectedDate = {
          ...state.selectedDate,
          [`${id}-${segmentId}`]: date,
        };
      }
    },
    setSelectedTime: (state, action) => {
      const { id, segmentId, time, type } = action.payload;
      if (type === "clear") {
        state.selectedTime = {};
      } else {
        const data = {
          ...state.selectedTime,
          [`${id}-${segmentId}`]: time,
        };
        const news = Object.entries(data);
        const update = news.map((v) => {
          if (v[0] === `${id}-${segmentId}`) return [v[0], time];
          else if (v[0] > `${id}-${segmentId}`) return [v[0], ""];
          else return v;
        });
        var result = {};
        for (var i = 0; i < update.length; i++) {
          result[update[i][0]] = update[i][1];
        }
        state.selectedTime = result;
        // state.selectedTime = {
        //   ...state.selectedTime,
        //   [`${id}-${segmentId}`]: time,
        // };
      }
    },
    setSelectedDateMerge: (state, action) => {
      const { id, segmentId, date, type } = action.payload;
      if (type === "clear") {
        state.mergeSelectedDate = {};
      } else {
        state.mergeSelectedDate = {
          ...state.mergeSelectedDate,
          [`${id}-${segmentId}`]: date,
        };
      }
    },
    setSelectedTimeMerge: (state, action) => {
      const { id, segmentId, time, type } = action.payload;
      if (type === "clear") {
        state.mergeSelectedTime = {};
      } else {
        const data = {
          ...state.mergeSelectedTime,
          [`${id}-${segmentId}`]: time,
        };
        const news = Object.entries(data);
        const update = news.map((v) => {
          if (v[0] === `${id}-${segmentId}`) return [v[0], time];
          else if (v[0] > `${id}-${segmentId}`) return [v[0], ""];
          else return v;
        });
        var result = {};
        for (var i = 0; i < update.length; i++) {
          result[update[i][0]] = update[i][1];
        }
        state.mergeSelectedTime = result;
        // state.mergeSelectedTime = {
        //   ...state.mergeSelectedTime,
        //   [`${id}-${segmentId}`]: time,
        // };
      }
    },

    // 2nd file individual
    setDelayValue1: (state, action) => {
      state.delayValues1 = action.payload;
    },
    setContactsRemaining2: (state, action) => {
      state.contactsRemaining2 = action.payload;
    },

    setSelectedDate1: (state, action) => {
      const { id, date, type } = action.payload;
      if (type === "clear") {
        state.selectedDate1 = {};
      } else {
        state.selectedDate1 = {
          ...state.selectedDate1,
          [`${id}`]: date,
        };
      }
    },
    setSelectedTime1: (state, action) => {
      const { id, time, type } = action.payload;
      if (type === "clear") {
        state.selectedTime1 = {};
      } else {
        state.selectedTime1 = {
          ...state.selectedTime1,
          [`${id}`]: time,
        };
      }
    },

    setError: (state, action) => {
      state.error = action.payload;
    },

    setIndividualError: (state, action) => {
      state.individualError = action.payload;
    },

    clearOtherErrors: (state, action) => {
      const { segmentId } = action.payload;
      Object.keys(state).forEach((key) => {
        if (key !== segmentId) {
          state[key] = false;
        }
      });
    },

    clearOtherErrorsIndividual: (state, action) => {
      const { segmentId, id } = action.payload;
      Object.keys(state).forEach((key) => {
        if (key !== segmentId) {
          state[key] = false;
        }
      });
    },

    clearIndividualErrors: (state, action) => {
      state.individualError = {};
    },
  },
});

export const {
  setContactsRemaining1,
  setTotalContact,
  setUpdatedInputValue,
  setDelayValue,
  setSelectedDate,
  setSelectedTime,
  setDelayValue1,
  setContactsRemaining2,
  setContactsRemainingMerge1,
  setSelectedDate1,
  setSelectedTime1,
  mergeSetSelectedTime,
  setError,
  clearOtherErrors,
  setIndividualError,
  clearOtherErrorsIndividual,
  clearIndividualErrors,
  setSelectedDateMerge,
  setSelectedTimeMerge,
} = authSlice.actions;

export default authSlice.reducer;
