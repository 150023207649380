import { Popover } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

// hooks
import useToggle from 'hooks/useToggle';
import { useSearch } from 'hooks/useSearch';

// helper
import { getRandomColors } from 'helper/colors';

// components
import Tag from './Tag';
import {
  FEATURE_FLAG_ADD_TAGS_TO_CONTACT_DETAILS,
  FEATURE_FLAG_VIEW_TAGS_IN_CONTACT_DETAILS,
} from '../../../modules/inbox/constants/FeatureFlag';

export const list = [
  {
    id: 1,
    name: 'Mukesh',
    color: '#F52E72',
  },
  {
    id: 2,
    name: 'Mukesh1',
    color: '#F59A2E',
  },
  {
    id: 3,
    name: 'Mukesh2',
    color: '#38B4C1',
  },
  {
    id: 4,
    name: 'Mukesh3',
    color: '#38B4C1',
  },
];

const TAGS_COLORS = [
  '#F52E72',
  '#38B4C1',
  '#F59A2E',
  '#386FC1',
  '#7941D3',
  '#C13838',
  '#616874',
];

export default function AddTags({ allTags = list, addedTags = [] }) {
  const [isAddTagOpen, setIsAddtagOpen] = useToggle(false);
  const { searchKey, setSearchKey, onChange, clearSearch } = useSearch();
  const [tags, setTags] = useState(allTags?.slice(0, 2));
  const [tagList, setTagList] = useState(addedTags);
  const [filteredTags, setFilteredTags] = useState([]);
  const [isError, setIsError] = useToggle(false);

  useEffect(() => {
    setFilteredTags(remainingTags());
  }, [tags, tagList]);

  useEffect(() => {
    setTagList(list);
  }, [list]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchKey) addTag(searchKey);
  };

  const checktagIsExist = (tag) =>
    tags.filter((t) => t?.name?.toLowerCase().includes(tag.toLowerCase()));

  const remainingTags = useCallback(() => {
    let newList = [];

    const newTags = tags.map((t) => t.name);
    tagList.map((tl) => {
      if (!newTags.includes(tl.name)) newList.push(tl);
    });

    return newList;
  }, [tags, tagList]);

  const searchTags = (value) => {
    onChange(value);

    const res = remainingTags().filter((t) =>
      t?.name?.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredTags(res);

    if (checkSearchTags(value)?.length !== 0) setIsError(true);
    else setIsError(false);
  };

  const deleteAddedTag = (id) => {
    setTags(tags.filter((l) => l.id !== id));
    setFilteredTags(remainingTags());
  };

  const deleteTagFromList = (id) => {
    setTagList(tagList.filter((l) => l.id !== id));
    setTags(tags.filter((l) => l.id !== id));
    setFilteredTags(remainingTags());
  };

  const addTag = (tag, id = null) => {
    const checkTags = checktagIsExist(tag);
    const checkSameTag = tags.filter(
      (t) => t?.name?.toLowerCase() === tag.toLowerCase()
    );
    const filteredTags = tagList.filter((t) =>
      t?.name?.toLowerCase().includes(tag.toLowerCase())
    );
    if (checkTags?.length === 0 && filteredTags?.length === 0) {
      const newTag = {
        id: tagList?.length + 1,
        name: tag,
        color: getRandomColors(TAGS_COLORS),
      };
      setTags([...tags, newTag]);
      setTagList([...tagList, newTag]);
    } else if (checkTags?.length === 0 && filteredTags?.length === 1) {
      setTags([...tags, filteredTags?.[0]]);
    } else if (checkSameTag?.length === 0 && filteredTags?.length > 0) {
      setTags([...tags, { name: tag, color: getRandomColors(TAGS_COLORS) }]);
    }

    if (checkSearchTags(tag)?.length === 0) {
      clearSearch();
      setFilteredTags(remainingTags());
    }
  };

  const checkSearchTags = (tag) =>
    tags.filter((t) => t?.name?.toLowerCase() === tag.toLowerCase());

  return (
    <div>
      {FEATURE_FLAG_VIEW_TAGS_IN_CONTACT_DETAILS && (
        <div className='flex items-center flex-wrap gap-2.5 pb-4'>
          {tags.map((t) => {
            return (
              <div>
                <Tag
                  id={t.id}
                  name={t.name}
                  color={t.color}
                  deleteTag={deleteAddedTag}
                />
              </div>
            );
          })}
        </div>
      )}
      <Popover
        trigger={['click']}
        open={isAddTagOpen}
        onOpenChange={(open) => {
          if (FEATURE_FLAG_ADD_TAGS_TO_CONTACT_DETAILS) setIsAddtagOpen(open);
        }}
        placement='bottom'
        arrow={false}
        content={
          <div className='w-[400px] h-full p-4 bg-white rounded-lg'>
            <form onSubmit={handleSubmit}>
              <div
                className={`w-full h-fit flex items-center font-md weight-medium py-1.5 px-2.5 border-[0.08vw] border-[var(--card-border)] rounded-[0.4vw] bg-[var(--BG-25)] text-[var(--font-600)]`}
              >
                <input
                  type='text'
                  className={`w-[95%] font-md weight-small bg-transparent`}
                  maxLength={20}
                  value={searchKey}
                  placeholder='Search or create new tag'
                  onChange={(e) => {
                    const { value } = e.target;
                    searchTags(value);
                  }}
                />
                <div
                  className={`w-[5%] flex items-center justify-end font-sm weight-medium text-[var(--font-400)] mt-[0.3vw] text-center align-middle`}
                >
                  {searchKey ? searchKey?.length : 0}/20
                </div>
              </div>
            </form>
            {isError && (
              <div
                className={`text-xs font-semibold text-[var(--fontRed)] pt-1.5`}
              >
                Already Exist
              </div>
            )}
            <div className='flex items-center flex-wrap gap-2.5 mt-2.5'>
              {filteredTags.map((t) => {
                return (
                  <div
                    className='cursor-pointer'
                    onClick={() => {
                      addTag(t.name, id);
                    }}
                  >
                    <Tag
                      id={t?.id}
                      name={t?.name}
                      color={t?.color}
                      addTag={addTag}
                      deleteTag={deleteTagFromList}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        }
      >
        <button
          className={`flex items-center gap-[0.5vw] font-md weight-medium ${
            FEATURE_FLAG_ADD_TAGS_TO_CONTACT_DETAILS
              ? 'cursor-pointer'
              : 'cursor-not-allowed'
          }`}
          onClick={() => {
            if (FEATURE_FLAG_ADD_TAGS_TO_CONTACT_DETAILS) setIsAddtagOpen(true);
          }}
        >
          <p className='!size-[1.3vw] border-[0.1vw] border-[var(--border-100)] rounded-full flex items-center justify-center weight-bold'>
            +
          </p>
          <p>Add tags</p>
        </button>
      </Popover>
    </div>
  );
}
