import AspApi from "api/ApiConfig";
import { Base64 } from "js-base64";

// CRM - SINGLE CONTACT ADD  -------------------

export const countryListApi = async () => {
  const res = await AspApi.get(`/api/v1/countries`);
  return res;
};

export const cityListApi = async (provinceId) => {
  const res = await AspApi.get(`/api/v1/cities?province_id=${provinceId}`);
  return res;
};
export const stateListApi = async (countryId) => {
  const res = await AspApi.get(`/api/v1/provinces?country_id=${countryId}`);
  return res;
};

export const contactOriginApi = async () => {
  const res = await AspApi.get(`/api/v1/crm/contact_origin_types`);
  return res;
};

// export const stateListApi = async (countryId, query) => {
//   let qpm = [];

//   if (query?.page) qpm.push(`page=${query?.page}`);
//   if (query?.limit) qpm.push(`limit=${query?.limit}`);
//   if (query?.name) qpm.push(`name=${query?.name}`);
//   // if (query?.status) qpm.push(`status=${query?.status}`);

//   let qpmString = "";
//   if (qpm?.length > 0) qpmString = "" + qpm.join("&");
//   const res = await AspApi.get(
//     `/api/v1/provinces?country_id=${countryId}&${qpmString}`
//   );
//   return res;
// };

// export const cityListApi = async (provinceId, query) => {
//   let qpm = [];

//   if (query?.page) qpm.push(`page=${query?.page}`);
//   if (query?.limit) qpm.push(`limit=${query?.limit}`);
//   if (query?.name) qpm.push(`name=${query?.name}`);
//   // if (query?.status) qpm.push(`status=${query?.status}`);

//   let qpmString = "";
//   if (qpm?.length > 0) qpmString = "" + qpm.join("&");

//   const res = await AspApi.get(
//     `/api/v1/cities?province_id=${provinceId}&${qpmString}`
//   );
//   return res;
// };

export const checkingApi = async (brandId, query) => {
  let qpm = [];

  if (query?.contact_type)
    qpm.push(`contact_type=${encodeURIComponent(query.contact_type)}`);
  if (query?.phone_number)
    qpm.push(`phone_number=${encodeURIComponent(query.phone_number)}`);
  if (query?.phone_country_code)
    qpm.push(
      `phone_country_code=${encodeURIComponent(query.phone_country_code)}`
    );
  if (query?.email) qpm.push(`email=${encodeURIComponent(query.email)}`);

  const qpmString = qpm.length > 0 ? `&${qpm.join("&")}` : "";
  const res = await AspApi.get(
    `/api/v1/crm/check_contact?brand_id=${brandId}${qpmString}`
  );
  return res;
};

export const saveSingleContactApi = async (brandId, payLoad) => {
  const res = await AspApi.post(
    `/api/v1/crm/contact_person?brand_id=${brandId}`,
    payLoad
  );
  return res;
};

export const addTagsApi = async (brandId, payLoad) => {
  const res = await AspApi.post(
    `/api/v1/crm/tags?brand_id=${brandId}`,
    payLoad
  );
  return res;
};

export const tagsListApi = async (brandId, query) => {
  let qpm = [];

  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);
  if (query?.search) qpm.push(`search=${query?.search}`);
  // if (query?.status) qpm.push(`status=${query?.status}`);

  let qpmString = "";
  if (qpm?.length > 0) qpmString = "" + qpm.join("&");

  const res = await AspApi.get(
    `/api/v1/crm/all_tags?brand_id=${brandId}&${qpmString}`
  );

  return res;
};

// CRM - TABLE  -------------------

export const crmListShow = async (brandId, query) => {
  let qpm = [];

  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);
  if (query?.search) qpm.push(`search=${query?.search}`);
  if (query?.filters)
    qpm.push(`filters=${Base64.encode(JSON.stringify(query?.filters))}`);

  let qpmString = "";
  if (qpm?.length > 0) qpmString = "" + qpm.join("&");

  const res = await AspApi.get(
    `/api/v1/crm/contact_persons?brand_id=${brandId}&${qpmString}`
  );
  return res;
};

// CRM - SingleContactView  -------------------

export const crmSingleContactView = async (brandId, id) => {
  const res = await AspApi.get(
    `/api/v1/crm/contact_person/${id}?brand_id=${brandId}`
  );
  return res;
};

export const crmContactTypes = async () => {
  const res = await AspApi.get(`/api/v1/crm/contact_categories`);
  return res;
};

export const crmProfessions = async () => {
  const res = await AspApi.get(`/api/v1/crm/professions`);
  return res;
};

// CRM - EDIT

export const crmSingleContactEdit = async (payLoad, id) => {
  const res = await AspApi.patch(`/api/v1/crm/contact_person/${id}`, payLoad);
  return res;
};

//CRM-CLEAR
export const crmClear = async (locationId, brandId, contactPersonId) => {
  const res = await AspApi.delete(
    `/api/v1/crm/location/${locationId}?brand_id=${brandId}&contact_person_id=${contactPersonId}`
  );
  return res;
};

export const crmTagsEdit = async (id, brandId, payLoad) => {
  const res = await AspApi.put(
    `/api/v1/crm/tag/${id}?brand_id=${brandId}`,
    payLoad
  );
  return res;
};

export const crmTagEditSingle = async (brandId, id, payLoad) => {
  const res = await AspApi.patch(
    `/api/v1/crm/tag/${id}?brand_id=${brandId}`,
    payLoad
  );
  return res;
};

export const crmTagDelete = async (id, brandId) => {
  const res = await AspApi.delete(`/api/v1/crm/tag/${id}?brand_id=${brandId}`);
  return res;
};

export const crmTagEdit = async (brandId, id, payLoad) => {
  const res = await AspApi.delete(
    `/api/v1/crm/contact_person_tag/${id}?brand_id=${brandId}&cp_id=${payLoad.cp_id}`
  );
  return res;
};

//CRM ADD
export const crmSingleContactAdd = async (payLoad, id) => {
  const res = await AspApi.post(
    `/api/v1/crm/contact_person/${id}/contact`,
    payLoad
  );
  return res;
};

export const crmPhoneDelete = async (payLoad, id) => {
  try {
    const res = await AspApi.delete(
      `/api/v1/crm/contact_person/${id}/contact`,
      { data: payLoad } // `data` is needed here to pass payload with DELETE request
    );
    return res;
  } catch (error) {
    console.error("Error deleting phone:", error);
    throw error; // or handle the error as needed
  }
};
