import React, { useState } from 'react';
import '../../../styles/contact.css';

import { ICONS } from '../../../../../assets/icons';
import { Popover, Skeleton } from 'antd';

import RadioButton from '../../../../../components/form/radio/RadioButton';
import SearchBar from '../../../../../components/commonComponents/searchBar';
import ContactTable from './ContactTable';
import Paginations from './Paginations';
import ContactViewFilter from '../../../../../components/commonComponents/ListViewHeader/ContactViewFilter';
import Loader from '../../../../../components/commonComponents/Loader/Index';
import ContactSelectView from './ContactSelectView';

const ContactView = ({
  contactList,
  handlePageChange,
  count,
  page,
  setPage,
  loadNextPage,
  templateSearch,
  isFilter,
  setIsFilter,
  contactDetails,
  handleContactClick,
  pageSize,
  setPageSize,
  handlePageChangeRow,
  handleContactClick1,
  isLoading,
  selectedStatus,
  onFilterChange,
  segment,
  downloadBroadcastContacts,
  isLoader,
}) => {
  const [popoverContact, setPopoverContact] = useState(false);

  const statusExists = contactList?.broadcast_contacts?.some((item) => {
    if (selectedStatus.length > 0) {
      return selectedStatus.includes(item?.status);
    } else {
      return item?.to_number?.includes(isFilter);
    }
  });

  const handleContactClick2 = (item) => {
    handleContactClick(item);
    setPopoverContact(false);
  };

  const ContactData = ({ setContactList }) => {
    return (
      <>
        {setContactList?.name === 'No files' ? (
          <div className='filter-dropdown'>
            <p className='filter_wrapper'>{setContactList?.name} </p>
          </div>
        ) : (
          <div className='filter-wrapper-main_A'>
            {setContactList?.map((item, index) => (
              <div
                className='flex-row w-100 space-between pointer'
                key={item.id || index}
                onClick={() => handleContactClick2(item)}
                style={{
                  borderBottom:
                    index !== setContactList.length - 1
                      ? '1px solid var(--font-50)'
                      : 'none',
                }}
              >
                <p> {item.name}</p>
                <div
                  className=''
                  style={{
                    transform: 'translate(5px, 5px)',
                  }}
                >
                  {contactDetails?.name === item?.name && (
                    <RadioButton
                      selected={contactDetails?.name === item?.name}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </>
    );
  };

  return (
    <div className='contactView_container'>
      <div className='contact_wrapper flex-row'>
        <Popover
          placement='bottom'
          content={<ContactData setContactList={contactList?.contact_files} />}
          trigger='click'
          arrow={false}
          open={popoverContact}
          onOpenChange={(open) => setPopoverContact(open)}
        >
          <div className='  flex-row space-between pointer'>
            <p className='ellipsis-texts'>
              {contactDetails?.name ||
                contactList?.contact_files?.[0]?.name ||
                contactList?.contact_files?.name ||
                'No files'}
            </p>
            <img src={ICONS.BroadcastChevronDownIcon} alt='Chevron' />
          </div>
        </Popover>
      </div>
      <div className='w-100 flex-row space-between search-main-container'>
        <div className='search_container'>
          <SearchBar
            searchKey={isFilter}
            setSearchKey={setIsFilter}
            onChange={(text) => templateSearch(text)}
          />
        </div>
        <div className='flex align-center'>
          <ContactViewFilter
            onFilterChange={onFilterChange}
            selectedStatus={selectedStatus}
          />

          <div>
            {isLoader ? (
              <Loader Width={20} Height={20} loaderBg='white' />
            ) : (
              <img
                src={ICONS?.Download}
                alt='download'
                className='cursor-pointer'
                onClick={() => downloadBroadcastContacts(contactList)}
              />
            )}
          </div>
        </div>
      </div>
      {statusExists && contactList?.broadcast_contacts?.length > 0 ? (
        <>
          <ContactTable
            contactList={contactList}
            isFilter={isFilter}
            selectedStatus={selectedStatus}
            statusExists={statusExists}
            isLoading={isLoading}
          />
          <div className='flex-row flex-start w-100 my-4 pl-10'>
            <Paginations
              handlePageChange={handlePageChange}
              page={page}
              setPage={setPage}
              count={count}
              loadNextPage={loadNextPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              handlePageChangeRow={handlePageChangeRow}
              handleContactClick1={handleContactClick1}
              contactDetails={contactDetails}
              contactList={contactList}
            />
          </div>
        </>
      ) : (
        <div style={{ background: '#f0f1f2' }}>
          <Skeleton active loading={isLoading} rootClassName='w-[98.5%]'>
            <ContactSelectView />
          </Skeleton>
        </div>
      )}
    </div>
  );
};

export default ContactView;
