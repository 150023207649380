import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './appLayout.css';

// assets
import { ICONS } from '../../assets/icons';

// helper
import { cn } from '../../helper/cn';

// constant
import { ACCOUNT_SETTINGS_LIST } from '../../constant/app/appLayout';

// redux
import { useAspDispatch, useAspSelector } from '../../test/jest-redux-hooks';

// components
import Alert from '../../components/commonComponents/modals/Alert';
import { Logout } from '../../api/Api';
import useToggle from '../../hooks/useToggle';

export default function AccountSettingsSideBar({}) {
  const { currentUser } = useAspSelector((state) => state?.app);
  const dispatch = useAspDispatch();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isLogoutModalOpen, setIsLogoutModalOpen] = useToggle(false);
  const [isLogout, setIsLogout] = useToggle(false);

  const isActive = (activeNav) => pathname.includes(activeNav);

  // logout
  const handleNo = () => setIsLogoutModalOpen(false);
  const handleYes = () => {
    setIsLogout(true);

    Logout()
      .then((res) => {
        if (res?.status === 200) {
          navigate('/login');
          localStorage.clear();
        } else {
          console.warn('error', res);
        }
      })
      .finally(() => {
        setIsLogout(false);
      });
  };

  return (
    <nav className='w-full flex flex-1 flex-col'>
      <div className='w-[5rem] h-full flex flex-col flex-1'>
        {ACCOUNT_SETTINGS_LIST?.map((item) => (
          <div
            key={item?.id}
            className={cn(
              'w-full h-16 flex flex-col items-center justify-center gap-2 cursor-pointer px-2.5 my-2.5',
              isActive(item?.activeNav)
                ? 'bg-[#0acd95] text-[var(--white)] weight-semibold'
                : 'hover:bg-[var(--BG-50)]'
            )}
            onClick={() => {
              navigate(`/profile${item?.activeNav}`);
            }}
          >
            <item.icon
              color={isActive(item?.activeNav) ? 'white' : '#2d3036'}
              width={24}
              height={24}
            />
            <span
              className='font-xs weight-semibold'
              style={{ color: isActive(item?.activeNav) ? 'white' : '#2d3036' }}
            >
              {item?.name}
            </span>
          </div>
        ))}
      </div>

      <div
        className='w-full h-11 flex items-center justify-center hover:bg-[var(--BG-50)] cursor-pointer'
        onClick={() => {
          setIsLogoutModalOpen(true);
        }}
      >
        <img src={ICONS?.logout} alt={'Logout icon'} className='images' />
      </div>
      <Alert
        open={isLogoutModalOpen}
        setOpen={setIsLogoutModalOpen}
        handleNo={handleNo}
        handleYes={handleYes}
        content={<p>Are you sure you want to Logout ?</p>}
        yesContent='Yes'
        noContent='No'
        yesLoading={isLogout}
        yesButtonClassName='py-[0.25vw]'
        noButtonClassName='py-[0.25vw]'
      />
    </nav>
  );
}
