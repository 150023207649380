export const textOnly = (str) => {
  return str.replaceAll(/[^a-zA-Z0-9\s]/g, '');
};

export const numberOnly = (str) => {
  return str.replaceAll(/[^0-9\s]/g, "");
};

export const validateEmail = (value) => {
  // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (!emailRegex.test(value)) return false;

  return true;
};

export const validatePassword = (password) => {
  if (!password) {
    return false;
  }
  if (password.length < 8) {
    return 'Password must be at least 8 characters long';
  }
  if (!/[A-Z]/.test(password)) {
    return 'Password must contain at least one uppercase letter';
  }
  if (!/[a-z]/.test(password)) {
    return 'Password must contain at least one lowercase letter';
  }
  if (!/\d/.test(password)) {
    return 'Password must contain at least one digit';
  }
  if (!/[^a-zA-Z0-9]/.test(password)) {
    return 'Password must contain at least one special character';
  }
  return '';
};

export const shortenEmail = (value) => {
  const email = value.split('@');
  const domain = email?.[1]?.split('.');

  return `${email?.[0].slice(0, 3)}*****@${email?.[1]?.slice(0, 2)}*****.${
    domain?.[1]
  }`;
};

export const emojiBlocker = (str) => {
  const emojiRegex = /[\p{Emoji_Presentation}\p{Emoji}\u200D]/gu;
  if (emojiRegex.test(str)) {
    return str.replaceAll(emojiRegex, '');
  }
  return str;
};

export const removeSpace = (str) => str.replace(/ /g, '');
