import React, { useState } from "react";

// utils
import { useNavigate } from "react-router-dom";
import { Tooltip } from "antd";

// assets
import Settings from "assets/customSVG/Settings";

// helper
import { ACCOUNT_LEVEL_ACCESS } from "helper/RolebasedAccess";
import { getRandomColorsBassedOnLetters } from "helper/colors";

// components
import OverviewCarousel from './OverviewCarousel';
import OnBoardingModal from './OnBoardingModal';
import useToggle from '../../../hooks/useToggle';
import { useAspDispatch } from '../../../test/jest-redux-hooks';
import { UpdateOnboardingAccountId } from '../../../reduxToolkit/appSlice';

export default function AccountCard({
  id,
  name,
  image,
  metrics,
  role,
  clearSearch,
  loadData,
}) {
  const navigate = useNavigate();

  const dispatch = useAspDispatch();

  const [openModal, setOpenModal] = useToggle(false);

  const countsList = [
    {
      id: 1,
      label: "No. of brands",
      value: metrics?.total_brands,
    },
    {
      id: 2,
      label: "No. of people",
      value: metrics?.total_users,
    },
    {
      id: 3,
      label: "Active brands",
      value: metrics?.active_brands,
    },
    {
      id: 4,
      label: "In-active brands",
      value: metrics?.inactive_brands,
    },
    {
      id: 5,
      label: "Pending Invoices",
      value: metrics?.pendinng_invoices,
    },
  ];

  const viewAccount = () => navigate(`${id}`);

  const colors = getRandomColorsBassedOnLetters(name);

  return (
    <div className="group w-full h-full max-w-[380px] max-h-[240px] relative bg-white text-[var(--contentText)] pt-4 pb-3.5 rounded-xl border border-[var(--border-50)] cursor-pointer">
      <div className="w-full h-full">
        <div
          className="w-full h-[4rem] flex items-center gap-4 px-4 pb-2"
          onClick={(e) => {
            clearSearch();
            viewAccount();
          }}
          data-testid="Accountcard-click"
        >
          <div>
            {image ? (
              <img
                src={image}
                alt=""
                className="w-[3.5rem] h-[3.5rem] object-cover rounded-full"
              />
            ) : (
              <div
                className="size-12 bg-[var(--BG-50)] flex items-center justify-center rounded-full text-lg weight-bold"
                style={{
                  color: colors.color,
                  background: colors.backgroundColor,
                }}
              >
                {name.slice(0, 1).toUpperCase()}
              </div>
            )}
          </div>
          <div className="flex flex-col justify-center gap-1">
            <h4 className="w-full max-w-[200px] truncate text-base weight-medium">
              {name}
            </h4>
            {ACCOUNT_LEVEL_ACCESS(role) && (
              <p className="w-fit rounded text-xs font-semibold px-2.5 py-1 bg-[var(--BG-50)] text-[var(--font-600)]">
                {role}
              </p>
            )}
          </div>
        </div>
        <div className="w-full">
          <div className="w-full h-full border-t border-[#E8E8EA] relative">
            <OverviewCarousel
              data={countsList}
              viewAccount={viewAccount}
              className="group-hover:!flex hidden"
            />
          </div>
          <div className="px-5">
            <button
              className="w-full h-9 text-sm weight-bold bg-[var(--primary)] text-white rounded-xl"
              onClick={() => {
                setOpenModal(true);
                dispatch(
                  UpdateOnboardingAccountId({
                    acc_id: id,
                  })
                );
              }}
            >
              Add Brand
            </button>
          </div>
        </div>
      </div>
      <Tooltip title="Account settings">
        <div
          className="absolute top-5 right-5 z-50"
          onClick={(e) => {
            clearSearch();
            navigate(`${id}/edit`);
          }}
          data-testid="account-settings"
        >
          <Settings width={16} height={16} strokeWidth="2" />
        </div>
      </Tooltip>
      <OnBoardingModal
        open={openModal}
        setOpen={setOpenModal}
        creation_type={'brand'}
        accountData={{ id: id }}
        loadData={loadData}
      />
    </div>
  );
}
