import { BUTTONS_LIST } from 'modules/channels/constant';

export const allowedCtaButtonTypes = ['PHONE_NUMBER', 'URL', 'COPY_CODE'];
export const allowedCustomButtonTypes = ['QUICK_REPLY'];

// call to action select option converstion
export const optionConvertion = {
  URL: 'Visit Website',
  PHONE_NUMBER: 'Call Phone Number',
  COPY_CODE: 'Copy Offer Code',
};

export const customButtonOptionConversion = {
  OPT_OUT: 'Marketing opt-out',
  CUSTOM: 'Custom',
};

export const CAROUSEL_BUTTONS = [
  {
    label: 'None',
    type: 'NONE',
    format: 'NONE',
  },
  {
    label: 'Quick Reply',
    type: 'QUICK_REPLY',
    format: 'CUSTOM',
  },
  {
    label: 'Visit Website',
    type: 'URL',
    format: 'URL',
  },
  {
    label: 'Call Phone Number',
    type: 'PHONE_NUMBER',
    format: 'PHONE_NUMBER',
  },
];

export const addCtaButton = (type, format, text, url, code, number) => {
  const initialData = {
    PHONE_NUMBER: {
      type: 'PHONE_NUMBER',
      format: format ?? 'PHONE_NUMBER',
      text: text ?? '',
      phone_number: number ?? '',
    },
    URL: {
      type: 'URL',
      format: format ?? 'URL',
      text: text ?? '',
      url: url ?? '',
    },
    COPY_CODE: {
      type: 'COPY_CODE',
      format: format ?? 'COPY_CODE',
      text: 'Copy offer code',
      example: [code ?? ''],
    },
    OPT_OUT: {
      type: 'QUICK_REPLY',
      format: format ?? '',
      text: 'Stop promotions',
    },
    CUSTOM: {
      type: 'QUICK_REPLY',
      format: format ?? '',
      text: text ?? '',
    },
  };

  return initialData[format];
};

// get count from the buttons array
export const getCTAButtons = (buttons, format) =>
  buttons?.filter((b) => format === b?.format);

// get max count from the cta buttons list
const getLengthFromButtonList = (format) =>
  BUTTONS_LIST?.filter(({ label }) => format === label);

// check available to add the cta button type
export const getNumberOfButtons = (buttons, format) =>
  getCTAButtons(buttons, format)?.length >=
  getLengthFromButtonList(format)?.[0]?.length;

// check available to add OPT_OUT button
export const checkToAddOptout = (buttons) =>
  getCTAButtons(buttons, 'OPT_OUT') < 1 ? true : false;

// check to show first button type
export const getFirtstTypeOfButton = (buttons) => {
  if (allowedCtaButtonTypes.includes(buttons?.[0]?.type)) {
    return 'ctaButtons';
  } else if (allowedCustomButtonTypes.includes(buttons?.[0]?.type))
    return 'custom';

  return null;
};

// filter buttons values based on the api needs
export const filterValidValuesFromButton = (buttons) => {
  return buttons.map((b) => {
    if (b.type === 'URL') return { type: b.type, text: b.text, url: b.url };
    if (b.type === 'PHONE_NUMBER')
      return {
        type: b.type,
        text: b.text,
        phone_number: `+91${b.phone_number}`,
      };
    if (b.type === 'COPY_CODE')
      return { type: b.type, text: b.text, example: [b?.example?.[0]] };

    return { type: b.type, text: b.text };
  });
};

// update opt-out button to last
export const updateOptoutTolast = (buttons) => {
  const customButtonsWithoutOptout = buttons?.filter(
    (b) =>
      allowedCustomButtonTypes.includes(b.type) && b.text !== 'Stop promotions'
  );
  const customButtonsWithOptout = buttons?.filter(
    (b) =>
      allowedCustomButtonTypes.includes(b.type) && b.text === 'Stop promotions'
  );

  return [...customButtonsWithoutOptout, ...customButtonsWithOptout];
};
