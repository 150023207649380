import React, { useCallback, useState } from 'react';

// assets
import { ICONS } from '../../../../assets/icons/index';
import Business from '../../../../assets/customSVG/Business';
import Campaigns from '../../../../assets/customSVG/navCampaign';
import Influencer from '../../../../assets/customSVG/Influencer';

// API
import { CreateAccount, WhatsappOTPSent } from '../../api/OnBoarding';

// redux
import {
  useAspDispatch,
  useAspSelector,
} from '../../../../test/jest-redux-hooks';
import {
  UpdateOnboardingAccountId,
  updateToggleToast,
} from '../../../../reduxToolkit/appSlice';

// helper
import { cn } from '../../../../helper/cn';
import { numberOnly } from '../../../../helper/validation';

// hooks
import { useFileUploader } from '../../../../hooks/useFileUploader';
import useTimerCount from 'hooks/useTimerCount';

// components
import Loader from '../../../../components/commonComponents/Loader/Index';
import Select from '../../../../components/commonComponents/select/index';
import CountryCode from '../../../../components/commonComponents/countryCode/index';
import OtpInput from 'components/commonComponents/otp';

export const accountType = [
  {
    id: 1,
    label: (
      <div className='flex items-center gap-3'>
        <Business width={20} height={20} />
        <p>Business Entity</p>
      </div>
    ),
    value: 'Business Entity',
    key: 'Business',
  },
  {
    id: 2,
    label: (
      <div className='flex items-center gap-3'>
        <Campaigns width={20} height={20} />
        <p>Marketing Agency</p>
      </div>
    ),
    value: 'Marketing Agency',
    key: 'Agency',
  },
  {
    id: 3,
    label: (
      <div className='flex items-center gap-3'>
        <Influencer width={20} height={20} />
        <p>Influencer</p>
      </div>
    ),
    value: 'Influencer',
    key: 'Creator',
  },
];

export const accountTypevalues = {
  'Business Entity': 'Business',
  'Marketing Agency': 'Agency',
  Influencer: 'Creator',
};

const countryList = [
  {
    label: 'India',
    value: '+91',
    shortName: 'IN',
  },
  // {
  //   label: 'United States',
  //   value: '+1',
  //   shortName: 'US',
  // },
  // {
  //   label: 'Japan',
  //   value: '+81',
  //   shortName: 'JP',
  // },
  // {
  //   label: 'Brazil',
  //   value: '+55',
  //   shortName: 'BR',
  // },
  // {
  //   label: 'Russia',
  //   value: '+7',
  //   shortName: 'RU',
  // },
  // {
  //   label: 'Canada',
  //   value: '+1',
  //   shortName: 'CA',
  // },
  // {
  //   label: 'Israel',
  //   value: '+972',
  //   shortName: 'IL',
  // },
];

const otpLength = 6;
const minutes = 2;
const secounds = 0;

export default function AccountCreation({
  data,
  onChange,
  fetchCurrentUserDetails,
  isLoading,
  setIsLoading,
  className,
}) {
  const { toggleToast } = useAspSelector((state) => state.app);
  const [countryCode, setCountryCode] = useState('+91');

  const dispatch = useAspDispatch();

  const {
    uploading,
    setUploading,
    selectedFile,
    selectedFileFormat,
    setSelectedFile,
    responseFile,
    uploadFiles,
    error,
    setError,
    clearFiles,
  } = useFileUploader();

  const [
    remainingMinutes,
    remainingSeconds,
    setRemainingMinutes,
    setRemainingSeconds,
  ] = useTimerCount(0, 0);

  // styles
  const card = 'w-full flex flex-col gap-3 mt-4';
  const label = 'text-base weight-semibold leading-6 text-[#2D3036]';
  const inputContainer =
    'w-full h-12 flex items-center gap-2 border border-[#D1D3D8] rounded-lg';
  const input = 'w-full h-full text-sm weight-medium px-3 rounded-lg';

  const validation = useCallback(() => {
    return (
      data?.name &&
      data?.type &&
      data?.code &&
      data?.phone_number &&
      data?.otp?.length === otpLength &&
      data?.isOTP &&
      !error
    );
  }, [data, error]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validation()) {
      setIsLoading(true);
      const datas = {
        acc_name: data?.name,
        acc_type: accountTypevalues[data?.type],
        country_alpha_code: data?.code,
        phone_number: `${countryCode}${data?.phone_number}`,
        otp: data?.otp,
      };
      if (data?.image) datas['acc_img_url'] = data?.image;
      const res = await CreateAccount(datas);
      if (res?.status === 200) {
        onChange('update_account', null, null, res?.data?.account);
        dispatch(
          UpdateOnboardingAccountId({
            acc_id: res?.data?.account?.id,
          })
        );
        setIsLoading(false);
      } else {
        setIsLoading(false);
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: res?.response?.data?.error,
              status: 'Error',
              duration: '',
            },
          ])
        );
      }
    }
  };

  const sendOTP = async () => {
    if (data?.phone_number && data?.phone_number?.length === 10) {
      const res = await WhatsappOTPSent({
        phone_number: [`${countryCode}${data?.phone_number}`],
      });
      if (res?.status === 200) {
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: 'Check your Whatsapp for OTP',
              status: 'Success',
              duration: '',
            },
          ])
        );
        onChange('update', 'account', 'isOTP', true);
        setRemainingMinutes(minutes);
        setRemainingSeconds(secounds);
      }
    } else if (data?.phone_number?.length > 0) {
      dispatch(
        updateToggleToast([
          ...toggleToast,
          {
            id: toggleToast?.length + 1,
            content: 'Invalid phone number!',
            status: 'Error',
            duration: '',
          },
        ])
      );
    }
  };

  return (
    <div
      className={cn(
        'w-full max-w-[570px] min-h-[400px] flex flex-col items-center overflow-y-scroll',
        className
      )}
    >
      <div
        className={cn(
          'w-full flex items-center justify-between px-4 py-3 mt-6 bg-slate-50 rounded-[10px] border',
          error ? 'border-red-400 animate-shake' : 'border-[var(--border-50)]'
        )}
      >
        <div className='flex-row align-center'>
          <div className='rounded-[10px] size-12 bg-slate-100'>
            {!responseFile && (
              <div className='p-[14px]'>
                <img
                  src={ICONS.uploadStep}
                  alt='UploadStep'
                  className='upload-image-before'
                />
              </div>
            )}

            {selectedFile && (
              <div className='relative'>
                <img
                  src={responseFile}
                  alt='SelectedImage'
                  className='uploadStep-image'
                />
                <img
                  className='absolute pointer -top-2 -right-2 size-4'
                  src={ICONS.Exit}
                  alt='popup'
                  onClick={() => {
                    clearFiles();
                    onChange('update', 'account', 'image', null);
                  }}
                />
              </div>
            )}
          </div>
          <div className='upload-content'>
            <p className='account-image'>Account Image</p>
            <p className='recommended-file'>
              {selectedFile ? (
                <p> {selectedFile?.name}</p>
              ) : (
                <p>Recommended file 320*320 px, less than 1MB.</p>
              )}
            </p>
          </div>
        </div>

        <div>
          <button className=''>
            <input
              type='file'
              onChange={async (e) => {
                const res = await uploadFiles(e, 'IMAGE', 1);
                if (res) {
                  onChange('update', 'account', 'image', res);
                }
              }}
              accept='.jpg, .jpeg, .png'
              id='uploadImg'
              hidden
            />
            <label
              for='uploadImg'
              className='w-full flex-center cursor-pointer'
            >
              <div
                className={cn(
                  'min-w-[100px] text-sm weight-semibold text-[var(--background)] bg-[var(--primary)] flex items-center justify-center rounded-md px-4 py-1.5 tracking-wide'
                )}
              >
                {uploading ? (
                  <Loader Width={20} Height={20} loaderBg='white' />
                ) : (
                  <p>Upload</p>
                )}
              </div>
            </label>
          </button>
        </div>
      </div>
      {error && (
        <div className='w-full text-left text-xs mt-1 px-1 text-red-400'>
          File Should be less than 1MB.
        </div>
      )}
      <form className='w-full' onSubmit={handleSubmit}>
        <div className={cn(card)}>
          <label htmlFor='name' className={cn(label)}>
            Account Name<sup className='text-red-500'>*</sup>
          </label>
          <div className={cn(inputContainer)}>
            <input
              name='name'
              id='name'
              type='text'
              placeholder='Ex:- Company org'
              className={cn(input)}
              value={data?.name}
              onChange={(e) => {
                const { name, value } = e.target;
                onChange('update', 'account', name, value);
              }}
            />
          </div>
        </div>
        <div className={cn(card)}>
          <label htmlFor='type' className={cn(label)}>
            Account Type<sup className='text-red-500'>*</sup>
          </label>
          <Select
            value={data?.type}
            options={accountType}
            onChange={({ value }) => {
              if (value) onChange('update', 'account', 'type', value);
            }}
            placeholder={'Select type'}
            rootClassName='w-full h-12 px-3 py-2.5'
            position='bottom'
            dataTestId='account-type'
            optionClick='option-click'
          />
        </div>
        <div className={cn(card)}>
          <label htmlFor='type' className={cn(label, '')}>
            Enter a Mobile Number<sup className='text-red-500'>*</sup>
          </label>
          <div className='flex items-center gap-5 justify-between'>
            <CountryCode
              list={countryList}
              value={countryCode}
              className='h-12'
              onChange={(val) => {
                if (val) {
                  onChange('update', 'account', 'code', val?.shortName);
                  setCountryCode(val);
                }
              }}
            />
            <div className={cn(inputContainer)}>
              <input
                name='phone_number'
                id='phone_number'
                type='text'
                placeholder='EX:- 0000 000 000'
                className={cn(
                  input,
                  data?.isOTP ? 'cursor-not-allowed' : 'cursor-text'
                )}
                value={data?.phone_number}
                onChange={(e) => {
                  const { name, value } = e.target;
                  const updatedValue = numberOnly(value);
                  onChange('update', 'account', name, updatedValue);
                }}
                disabled={data?.isOTP}
                maxLength={10}
              />
            </div>
          </div>
        </div>
        <div className='w-full mt-4 flex items-center justify-between'>
          {data?.isOTP && (
            <div
              className='!text-sm !font-medium text-[var(--primary)] cursor-pointer'
              onClick={() => {
                onChange('update_number', null, null, null);
              }}
            >
              Change Number
            </div>
          )}
          <div className='w-full flex flex-1 items-center justify-end text-sm weight-semibold gap-4'>
            {data?.isOTP && (
              <p className='text-[var(--primary)]'>
                {remainingMinutes?.toString().padStart(2, '0')}:
                {remainingSeconds?.toString().padStart(2, '0')}
              </p>
            )}
            {data?.isOTP ? (
              <p
                className={`${
                  remainingMinutes === 0 && remainingSeconds === 0
                    ? 'text-[#2D3036] cursor-pointer'
                    : 'text-[var(--font-600)] cursor-not-allowed'
                }`}
                onClick={async () => {
                  if (remainingMinutes === 0 && remainingSeconds === 0) {
                    if (data?.phone_number) {
                      const res = await WhatsappOTPSent({
                        phone_number: [`${countryCode}${data?.phone_number}`],
                      });
                      if (res?.status === 200) {
                        setRemainingMinutes(minutes);
                        setRemainingSeconds(secounds);
                      }
                    }
                  }
                }}
              >
                Resend code?
              </p>
            ) : (
              <p
                className={cn(
                  'text-sm weight-bold',
                  data?.phone_number
                    ? 'text-[var(--primary)] cursor-pointer'
                    : ' text-[var(--font-600)] cursor-not-allowed'
                )}
                onClick={() => {
                  sendOTP();
                }}
              >
                Get OTP on Whatsapp
              </p>
            )}
          </div>
        </div>
        <div className='mt-6'>
          <p
            className={cn(
              'text-base weight-semibold',
              data?.isOTP
                ? 'text-[var(--contentTexts)]'
                : 'text-[var(--font-600)]'
            )}
          >
            Enter OTP to verify your mobile number
          </p>
        </div>
        <div className='w-full mt-4 pb-9'>
          <OtpInput
            length={otpLength}
            data={data?.otp}
            onChange={(data) => {
              onChange('update', 'account', 'otp', data);
            }}
            className={cn(
              'w-11 h-11',
              data?.isOTP ? 'text-[var(--primary)]' : 'text-[var(--font-600)]'
            )}
            disabled={!data?.isOTP}
          />
        </div>

        <div className='flex justify-center mt-9'>
          <button
            type='submit'
            className={cn(
              'w-44 h-12 rounded-[10px] px-4 py-2 text-base weight-semibold flex items-center justify-center',
              validation()
                ? isLoading
                  ? 'cursor-not-allowed bg-[var(--primary)] text-[var(--white)]'
                  : 'bg-[var(--primary)] text-[var(--white)] cursor-pointer'
                : 'bg-[var(--BG-100)] text-[var(--font-600)] cursor-not-allowed'
            )}
          >
            {isLoading ? (
              <div className='size-[2vw] flex items-center justify-center'>
                <Loader Width={20} Height={20} loaderBg='white' />
              </div>
            ) : (
              'Create Account'
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
