import React, { useMemo, useState } from "react";
import Group from "../../../../../assets/customSVG/Group";
import { crmFontColor } from "../../../constants";
import Tag from "../../../../../assets/customSVG/Tag";
import Tags from "./Tags";
import useTags from "../../../../../hooks/useTags";

const Header = ({ count, count1, crmLists }) => {
  const selectedTags = useMemo(() => {
    return [];
  }, []);

  // open action/close action columns
  const [isTagsDropdownOpen, setIsTagsDropdownOpen] = useState(false);
  const toggleTagsDropdown = () => {
    setIsTagsDropdownOpen(!isTagsDropdownOpen);
  };

  return (
    <div className="flex justify-between bg-white px-[2vw] py-[1.5vh] text-[1.7vh]">
      <p className="font-semibold">
        {crmLists?.length > 0 ? (
          <>
            {`${count} contacts`}{" "}
            <span style={{ color: "var(--font-400)", fontWeight: "600" }}>
              match of {count1}
            </span>
          </>
        ) : (
          <>
            0 contacts{" "}
            <span style={{ color: "var(--font-400)", fontWeight: "600" }}>
              match of {count1}
            </span>
          </>
        )}
        {/* <span className="text-gray-400"> of {10000}</span> */}
      </p>
      <div className="grid grid-cols-2- grid-cols-1 place-content-center ">
        {/* <Tags
          active={true}
          toggle={toggleTagsDropdown}
          tags={tags}
          className="right-0 top-[2vw] bg-red-300- crm-dropdown"
          Button={
            <div>
              <span
                className=" duration-200 flex items-center gap-[.3vw] cursor-pointer"
                onClick={toggleTagsDropdown}
              >
                <Tag fill={crmFontColor} />
                <span
                  className={`text-[${crmFontColor}] text-[1.5vh] font-[600]`}
                >
                  Add Tag
                </span>
              </span>
            </div>
          }
          zIndex={100}
        /> */}
        {/* hidden header column */}
        <span className="flex- items-center gap-[.5vw] cursor-pointer hidden">
          <Group fill={crmFontColor} />
          <p className={`text-[${crmFontColor}] text-[1.5vh] font-[600]`}>
            Add Group
          </p>
        </span>
      </div>
    </div>
  );
};

export default Header;

function ToggleButton({ toggle }) {
  return (
    <div>
      <span
        className="flex items-center gap-[.3vw] cursor-pointer"
        // onClick={toggle}
      >
        <Tag fill={crmFontColor} />
        <span className={`text-[${crmFontColor}] text-[1.5vh] font-[600]`}>
          Add Tag
        </span>
      </span>
    </div>
  );
}
