import React from 'react';

export default function ArrowWithCircle({
  width = '24',
  height = '24',
  color = '#0ACD95',
  strokeWidth = 2,
  className = '',
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 34 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <rect
        x='1'
        y='1'
        width='32'
        height='32'
        rx='16'
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d='M17.8333 12.625L22 17M22 17L17.8333 21.375M22 17L12 17'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
