import { useEffect, useMemo, useState } from "react";
import Label from "../../commonComponents/label";
import Search from "../../commonComponents/search";
import { Popover } from "antd";
import {
  addTagsApi,
  crmTagDelete,
  crmTagsEdit,
  tagsListApi,
} from "../../../api/Api";
import {
  useAspDispatch,
  useAspSelector,
} from "../../../../../test/jest-redux-hooks";
import { updateToggleToast } from "../../../../../reduxToolkit/appSlice";
import { useSelector } from "react-redux";
import { setTagList } from "../../../../../reduxToolkit/crmSlice";
import { hexToRgba } from "../../../helpers";
import { useParams } from "react-router-dom";

let count = 0;

const Tags = ({
  toggle,
  className,
  zIndex = 60,
  Button,
  mainWidth = "w-[21vw]",
  tags,
  setTags = () => {},
  addAction,
  // removeFunction,
  closeAction = () => {},
  placeholder = "Search or create new tag",
  ...rest
}) => {
  const [visible, setVisible] = useState(false);
  const { tagList } = useSelector((store) => store.crm);
  const [isFilter, setIsFilter] = useState("");

  const {
    singleContactUploadDetails: { contact, address },
    singleContactDetails,
  } = useSelector((store) => store.crm);

  const selectedTags = useMemo(() => {
    return singleContactDetails["selected_tags"];
  }, [singleContactDetails?.selected_tags]);

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };
  const dispatch = useAspDispatch();
  const { currentBrand, toggleToast } = useAspSelector((state) => state.app);

  function getRandomHexColor() {
    const randomInt = () => Math.floor(Math.random() * 256);
    const componentToHex = (c) => c.toString(16).padStart(2, "0");

    const r = randomInt();
    const g = randomInt();
    const b = randomInt();

    return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
  }

  const handleAddTag = async (e, newTag) => {
    e.preventDefault();
    e.stopPropagation();

    const payLoad = {
      tag_name: newTag,
      display_color: getRandomHexColor(),
    };

    addTagsApi(currentBrand?.brand_id, payLoad).then((createTagsResponse) => {
      if (createTagsResponse?.status === 200) {
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: "Tag created successfully",
              status: "Success",
              duration: "",
            },
          ])
        );

        const createdTag = createTagsResponse?.data;
        dispatch(setTagList((prevTags) => [...prevTags, createdTag]));
        setIsFilter("");
        tagsLists(currentBrand?.brand_id);
      } else {
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content:
                createTagsResponse?.response?.data?.error ||
                "Tag creation failed",
              status: "Error",
              duration: "",
            },
          ])
        );
      }
    });
  };

  const tagsLists = (brandId, payLoad) => {
    if (brandId) {
      tagsListApi(brandId, payLoad)
        .then((res) => {
          if (res && res?.data?.tags_data) {
            dispatch(setTagList(res?.data?.tags_data));
            count = res?.data?.tags_count;
          }
        })
        .finally(() => {});
    } else {
      dispatch(setTagList([]));
    }
  };

  const handleDeleteTag = (id) => {
    const brandId = currentBrand?.brand_id;
    crmTagDelete(id, brandId).then((res) => {
      if (res?.status === 200) {
        tagsLists(currentBrand?.brand_id, { search: isFilter });
      }
    });
  };

  useEffect(() => {
    if (visible) {
      tagsLists(currentBrand?.brand_id, { search: isFilter });
    }
  }, [visible, currentBrand, isFilter, selectedTags]);

  const templateSearch = (searchTerm) => {
    setIsFilter(searchTerm);
  };

  return (
    <Popover
      zIndex={zIndex}
      content={
        <div
          className={`crm-dropdown  rounded-2xl bg-white p-[1vw] left-0 z-0 ${mainWidth}`}
        >
          <div
            className="flex flex-wrap gap-[1.5vh]"
            style={{ height: "auto", maxHeight: "15vh", overflow: "scroll" }}
          >
            {selectedTags?.map((tagId) => {
              const tag = tagList?.find((t) => {
                return t?.id === tagId;
              });
              {
                return (
                  <Label
                    id={tag?.id}
                    key={tag?.id}
                    label={tag?.tag}
                    color={tag?.display_color}
                    style={{
                      backgroundColor: hexToRgba(tag?.display_color, 0.2),
                    }}
                    closeAction={closeAction}
                    setVisible={setVisible}
                    handleDeleteTag={handleDeleteTag}
                  />
                );
              }
            })}
          </div>
          <div className="">
            <form>
              <Search
                placeholder={placeholder}
                onSubmit={(e, value) => handleAddTag(e, value)}
                templateSearch={templateSearch}
              />
            </form>
          </div>
          <div
            className="flex flex-wrap gap-[1.5vh]"
            style={{ height: "auto", maxHeight: "15vh", overflow: "scroll" }}
          >
            {Array.isArray(tagList) &&
              tagList?.length > 0 &&
              tagList
                ?.filter((item) => !selectedTags.includes(item.id))
                ?.map((item) => {
                  return (
                    <Label
                      id={item?.id}
                      onClick={addAction}
                      showClose={false}
                      key={item?.id}
                      label={item?.tag}
                      color={item?.display_color}
                      style={{
                        backgroundColor: hexToRgba(item?.display_color, 0.2),
                      }}
                      setVisible={setVisible}
                      handleDeleteTag={handleDeleteTag}
                    />
                  );
                })}
          </div>
        </div>
      }
      arrow={false}
      {...rest} // will contain open
      placement="bottomLeft"
      open={visible}
      onOpenChange={handleVisibleChange}
      trigger="click"
    >
      {Button}
    </Popover>
  );
};

export default Tags;
