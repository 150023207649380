import React, { useState } from 'react';
import Arrow from '../../../assets/customSVG/Arrow';

import { cn } from '../../../helper/cn';

export default function OverviewCarousel({ data, viewAccount, className }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const limit = Math.round((data?.length - 3) / 3);

  const handleNext = () => {
    if (currentIndex < limit)
      setCurrentIndex((prevIndex) => (prevIndex + 2) % data?.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 2 + data?.length) % data?.length
    );
  };

  return (
    <div className='w-full h-full relative'>
      <div
        className='w-full h-[108px] relative px-5 py-7'
        onClick={() => {
          if (viewAccount) viewAccount();
        }}
      >
        <div className='w-full flex items-center justify-between gap-2'>
          {data?.slice(currentIndex, currentIndex + 3).map((c, index) => (
            <div
              key={c.id}
              className='w-1/3 h-[52px] flex flex-col items-center justify-between'
            >
              <div className='text-lg weight-bold'>{c.value}</div>
              <div className='text-xs weight-semibold text-[var(--font-600)] text-center'>
                {c.label}
              </div>
            </div>
          ))}
        </div>
      </div>
      {currentIndex > 0 && (
        <button
          className={cn(
            'w-7 h-8 rounded-md bg-[var(--BG-50)] items-center justify-center hidden absolute top-10 -left-0 !z-50 cursor-pointer',
            className
          )}
          onClick={handlePrev}
        >
          <Arrow />
        </button>
      )}
      {currentIndex < limit && (
        <button
          className={cn(
            'w-7 h-8 rounded-md bg-[var(--BG-50)] items-center justify-center hidden absolute top-10 -right-0 !z-50 rotate-180 cursor-pointer',
            className
          )}
          onClick={handleNext}
        >
          <Arrow />
        </button>
      )}
    </div>
  );
}
