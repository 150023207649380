import React, { useEffect, useReducer } from "react";
import { Modal } from "antd";

// assets
import Xmark from "../../../assets/customSVG/Xmark";

// api
import { currentUserDetails } from "../../../api/Api";

// hooks
import useToggle from "../../../hooks/useToggle";

// reducers
import {
  INITIAL_STATE,
  onBoardingReducer,
} from "../../../hooks/auth/onBoardingReducer";
import { useAspDispatch, useAspSelector } from "../../../test/jest-redux-hooks";
import {
  updateCurrentAccount,
  updateCurrentBrand,
  updateCurrentUser,
} from "../../../reduxToolkit/appSlice";

// components
import HorizontalSteps from "../../auth/components/HorizontalSteps";
import AccountCreation from "../../auth/components/onboarding/AccountCreation";
import BrandCreation from "../../auth/components/onboarding/BrandCreation";
import TeamInvite from "../../auth/components/onboarding/TeamInvite";
import Header from "../../../components/commonComponents/modals/Header";

export default function OnBoardingModal({
  open,
  setOpen,
  creation_type,
  loadData,
}) {
  const AspDispatch = useAspDispatch();

  const { currentUser, acc_id, brand_id } = useAspSelector(
    (state) => state.app
  );

  const [data, dispatch] = useReducer(onBoardingReducer, INITIAL_STATE);

  const [isLoading, setIsLoading] = useToggle(false);

  useEffect(() => {
    if (creation_type === "account") onChange("update_step", null, "step", 0);
    else if (creation_type === "brand")
      onChange("update_step", null, "step", 1);
  }, [open, creation_type]);

  const onChange = (type, primaryKey, key, value) => {
    dispatch({ type, primaryKey, key, value });
  };

  const fetchCurrentUserDetails = async () => {
    const res = await currentUserDetails();
    if (res?.status === 200) {
      AspDispatch(updateCurrentUser(res?.data));
      if (res?.data?.active_accounts?.length > 0) {
        AspDispatch(updateCurrentAccount(res?.data?.active_accounts?.[0]));
        if (res?.data?.active_accounts?.[0]?.brands?.length > 0) {
          onChange("update_step", null, "step", 2);
          AspDispatch(
            updateCurrentBrand(res?.data?.active_accounts?.[0]?.brands?.[0])
          );

          setIsLoading(false);
        } else {
          onChange("update_step", null, "step", 1);
          setIsLoading(false);
        }
      } else {
        onChange("update_step", null, "step", 0);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setOpen(false);
    onChange("reset");
    if (loadData) loadData();
  };

  return (
    <Modal
      open={open}
      width={"40vw"}
      height={"80vh"}
      centered
      footer={null}
      closable={false}
      mask={true}
      wrapClassName={"bg-[#00000095]"}
      style={{
        width: "100%",
        borderRadius: 20,
        boxShadow: "0px 10px 24px 0px rgba(79, 92, 128, 0.15)",
        overflow: "hidden",
      }}
      destroyOnClose={true}
    >
      <div className="w-full flex flex-col items-center overflow-hidden p-4">
        <div className="w-full flex items-center justify-end">
          <div
            className="size-6 hover bg-[var(--BG-50)] rounded-full flex items-center justify-center cursor-pointer"
            onClick={handleCancel}
          >
            <Xmark />
          </div>
        </div>
        <div className="text-2xl font-bold">
          Let&apos;s Setup your {creation_type}
        </div>
        <div className="w-4/5 my-4">
          <HorizontalSteps data={data} />
        </div>
        <div className="w-full min-h-[55vh] h-auto max-h-[75vh] overflow-y-scroll listScroll pr-1 pb-9">
          {data?.step === 0 ? (
            <AccountCreation
              data={data?.account}
              onChange={onChange}
              fetchCurrentUserDetails={fetchCurrentUserDetails}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              className="max-w-full max-h-[55vh] px-8"
            />
          ) : data?.step === 1 ? (
            <BrandCreation
              data={data?.brand}
              onChange={onChange}
              fetchCurrentUserDetails={fetchCurrentUserDetails}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              className="max-w-full max-h-[50vh] px-8"
              acc_id={acc_id}
            />
          ) : (
            data?.step === 2 && (
              <TeamInvite
                data={data?.teams}
                onChange={onChange}
                fetchCurrentUserDetails={fetchCurrentUserDetails}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                acc_id={acc_id}
                brand_id={brand_id}
              />
            )
          )}
        </div>
      </div>
    </Modal>
  );
}
