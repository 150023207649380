import { ICONS } from "assets/icons";

export const channelList = [
  {
    id: 1,
    icon: ICONS?.whatsappLeft,
    name: "WhatsApp",
    label: "whatsapp",
    link: "/user/channels/whatsapp",
    icon1: ICONS?.chevronDown,
    subMenu: [
      // {
      //   id: 1,
      //   name: 'Insights',
      //   label: 'inghts',
      //   link: '/user/channels/whatsapp/insights',
      // },
      {
        id: 2,
        name: "Message Templates",
        label: "messageTemplates",
        link: "/user/channels/whatsapp/message-templates",
      },
      {
        id: 3,
        name: "Phone Numbers",
        label: "phoneNumbers",
        link: "/user/channels/whatsapp/phonenumbers",
      },
    ],
  },
];

export const userConnectOption = [
  { id: 1, name: "Sync", icon: ICONS?.LoopIcon },
  { id: 2, name: "Configuration", icon: ICONS?.ToolIcon },
];
export const whatsappAccount = [
  {
    id: 1,
    name: "Whatsapp Account 1",
    nameID: "Whatsapp Account ID: 1234513245651423",
  },
];

export const numberStatus = [
  {
    id: 1,
    status: "Active",
  },
  {
    id: 2,
    status: "Being Reviewed",
  },
  {
    id: 3,
    status: "Pending",
  },
  {
    id: 4,
    status: "Offline",
  },
  {
    id: 5,
    status: "Warned",
  },
  {
    id: 6,
    status: "Banned",
  },
  {
    id: 7,
    status: "Connected",
  },
  {
    id: 8,
    status: "Unavailable",
  },
  {
    id: 9,
    status: "Message Limit Reached",
  },
  {
    id: 10,
    status: "Pending",
  },
];

export const TemplateLeftSideList = [
  {
    id: 1,
    name: "Display Name",
  },
  {
    id: 2,
    name: "Status",
  },
  {
    id: 3,
    name: "Quality Rating",
  },
  {
    id: 4,
    name: "Messaging Limit",
  },
  {
    id: 5,
    name: "Country",
  },
];

export const MessageHeaderList = [
  {
    id: 1,
    name: "Status",
  },
  {
    id: 2,
    name: "Category",
  },
  {
    id: 3,
    name: "Language",
  },
  {
    id: 4,
    name: " Active Broadcast",
  },
  {
    id: 5,
    name: "Total Runs",
  },
  {
    id: 6,
    name: "Message Sent",
  },
  {
    id: 7,
    name: "Delivered",
  },
  {
    id: 8,
    name: "Message Read",
  },
];

export const channelNameAction = [
  {
    id: 1,
    name: "edit",
    icon: ICONS?.edit,
    status: ["DRAFT", "APPROVED", "IN_REVIEW", "META_FAILED", "PENDING", 10, 0],
    // Active_Runs: 0,
  },
  {
    id: 2,
    name: "preview",
    icon: ICONS?.campaignEye,
    status: ["DRAFT", "APPROVED", "IN_REVIEW", "META_FAILED", "PENDING", 10, 0],
  },
  {
    id: 3,
    name: "delete",
    status: ["DRAFT", "REJECTED", "META_FAILED", 0],
  },
];

export const MessageTemplate = [
  {
    id: 1,
    deal: "exclusive_dinner_deal",
    Category: "Marketing",
    Language: "English",
    Active_Runs: "10",
    TotalRuns: "25",
    MessageSent: "0",
    MessageDelivered: "0",
    MessageRead: "0",
  },
  {
    id: 2,
    deal: "exclusive_dinner_deal",
    Category: "Marketing",
    Language: "English",
    Active_Runs: "0",
    TotalRuns: "30",
    MessageSent: "0",
    MessageDelivered: "0",
    MessageRead: "0",
  },
  {
    id: 3,
    deal: "exclusive_dinner_deal",
    Category: "Utility",
    Language: "English",
    Active_Runs: "0",
    TotalRuns: "0",
    MessageSent: "0",
    MessageDelivered: "0",
    MessageRead: "0",
  },
  {
    id: 4,
    deal: "exclusive_dinner_deal",
    Category: "Utility",
    Language: "English",
    Active_Runs: "0",
    TotalRuns: "0",
    MessageSent: "0",
    MessageDelivered: "0",
    MessageRead: "0",
  },
  {
    id: 5,
    deal: "exclusive_dinner_deal",
    Category: "Utility",
    Language: "English",
    Active_Runs: "0",
    TotalRuns: "0",
    MessageSent: "0",
    MessageDelivered: "0",
    MessageRead: "0",
  },
];

export const Status = [
  {
    id: 1,
    status: "Active-Quality pending",
  },
  {
    id: 2,
    status: "Active-Quality pending",
  },
  {
    id: 3,
    status: "In-review",
  },
  {
    id: 4,
    status: "Draft",
  },
  {
    id: 5,
    status: "Rejected",
  },
];

export const ADD_VARIABLE_OPTIONS = [
  {
    id: 1,
    value: "{{first-name}}",
    label: "first-name",
  },
  {
    id: 2,
    value: "{{last-name}}",
    label: "last-name",
  },
  {
    id: 3,
    value: "{{shop-name}}",
    label: "shop-name",
  },
  {
    id: 4,
    value: "{{email-id}}",
    label: "email-id",
  },
  {
    id: 5,
    value: "{{mobile-number}}",
    label: "mobile-number",
  },
  {
    id: 6,
    value: "{{custom-variable}}",
    label: "custom-variable",
  },
];

export const languageMap = {
  en: "English",
  en_US: "English",
  ta: "Tamil",
};
