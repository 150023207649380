import React, { useEffect } from "react";
import "../style/auth.css";

import { useAspSelector, useAspDispatch } from "../../../test/jest-redux-hooks";
import { setStepper } from "../../../reduxToolkit/authSlice";

import Stepper1 from "../components/Stepper1";
import Stepper2 from "../components/Stepper2";
import Stepper3 from "../components/Stepper3";

function Stepper() {
  const dispatch = useAspDispatch();
  const { stepper } = useAspSelector((state) => state.authentication);

  
  useEffect(() => {
    dispatch(
      setStepper({
        stepper1: 0,
      })
    );
  }, []);
  return (
    <>
      {/* {stepper?.stepper1 === 0 && <Stepper1 />} */}
      {stepper?.stepper1 === 1 && <Stepper2 />}
      {stepper?.stepper1 === 2 && <Stepper3 />}
    </>
  );
}

export default Stepper;
