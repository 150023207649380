import {
  contactsHeaderWithId,
  contactFilters,
  crmContactHeaders,
} from "../modules/crm/constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";

const initialState = {
  columns: [],
  crmColumn: crmContactHeaders,
  singleContactView: {},
  tagList: [],
  dynamicColumns:
    JSON.parse(localStorage.getItem("dynamicClos")) || contactsHeaderWithId,
  selectedContactSource: "",
  // modelContactSources: contactFilters,
  files: [],
  // filters: JSON.parse(localStorage.getItem("filters")) || [],
  filters: [],
  // this is need to filled by an api
  uploadedFields: [
    {
      id: 1,
      label: "first_name",
      selected: false,
      selected_by: "",
    },
    {
      id: 2,
      label: "last_name",
      selected: false,
      selected_by: "",
    },
    {
      id: 3,
      label: "email",
      selected: false,
      selected_by: "",
    },
    {
      id: 4,
      label: "phone_number",
      selected: false,
      selected_by: "",
    },
    {
      id: 5,
      label: "website",
      selected: false,
      selected_by: "",
    },
    {
      id: 6,
      label: "others",
      selected: false,
      selected_by: "",
    },
  ],
  mappingFields: {
    first_name: "",
    last_name: "",
    email_ID: "",
    mobile_number: "",
    location: "",
    tags: "",
    country: "",
    alternative_mobile: "",
    work_email_ID: "",
    whatsApp_opt_out: "",
    email_opt_out: "",
    SMS_opt_out: "",
  },
  singleContactUploadDetails: {
    contact: {
      first_name: "",
      last_name: "",
      // phone: { country_code: "", number: "" },
      phone_number: "",
      phone_number_code: "91",
      email: "",
      alternative_mobile: [{ phone_number: "", phone_number_code: "91" }],
      contact_origin: "",
      // life_cycle_stage: "",
      opted_out: [],
      whatsApp_opt_out: false,
      email_opt_out: false,
      SMS_opt_out: false,
    },
    address: {
      address: "",
      country: "",
      state: "",
      city: "",
      "zip_code / postal_code": "",
    },
  },
  tags: [
    { id: 1, label: "travel", color: "blue" },
    { id: 2, label: "location", color: "orange" },
    { id: 3, label: "Vlog-YoutubeReels", color: "red" },
    { id: 4, label: "Interested", color: "violet" },
    { id: 8, label: "Flight", color: "#265073" },
    { id: 5, label: "entertainment-entertainment", color: "black" },
    { id: 6, label: "education", color: "#E88D67" },
    { id: 7, label: "schools", color: "#41B06E" },
  ],

  singleContactDetails: {
    first_name: "",
    last_name: "",
    phone_number: "",
    alt_phone: "",
    wk_email: "",
    email: "",
    age: "",
    gender: "",
    profession: "",
    contact_src: "",
    lc_stage: "",
    created_at: "",
    last_contacted_at: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    selected_tags: [],
    country: "",
    opt_wap: false,
    opt_email: true,
    opt_sms: true,
    prof_fb: "",
    prof_ig: "",
    prof_x: "",
    prof_linkedin: "",
    prof_tiktok: "",
  },

  overviewDetails: null,
};

export const crmSlice = createSlice({
  name: "CRM",
  initialState,
  reducers: {
    setColumns: (state, action) => {
      state.columns = action.payload;
    },

    handleDynamicContactSelect: (state, action) => {
      const { id } = action.payload;

      const updatedCols = state.dynamicColumns.map((item) => {
        if (
          item.id === id &&
          !["name", "phone", "email"].includes(item.label)
        ) {
          return { ...item, selected: !item.selected };
        }
        return item;
      });

      if (updatedCols.filter((item) => item.selected).length >= 5)
        state.dynamicColumns = updatedCols;
    },
    // handleContactModelSelection: (state, action) => {
    //   const { id } = action.payload;
    //   state.modelContactSources = state.modelContactSources.map((item) => {
    //     if (item.id === id) {
    //       state.selectedContactSource = item.label;
    //       return { ...item, selected: true };
    //     }
    //     return { ...item, selected: false };
    //   });
    // },

    setFiles: (state, action) => {
      state.files.push(action.payload.file);
    },

    handelDeleteFile: (state, action) => {
      const { id } = action.payload;
      state.files = state.files.filter((item) => item.id !== id);
    },

    handleUploadedFieldsSelection: (state, action) => {
      const { id, selected_by, prev_selected_by } = action.payload;
      state.uploadedFields = state.uploadedFields.map((item) => {
        if (item.selected_by === selected_by) {
          state.mappingFields[selected_by] = "";
          return { ...item, selected: false, selected_by: "" };
        }
        if (item.id == id) {
          state.mappingFields[selected_by] = item.label;
          return { ...item, selected: true, selected_by };
        }
        return item;
      });
    },

    handleSingleColsAction: (state, action) => {
      const { key, value, field, optOutValue } = action.payload;

      state.singleContactUploadDetails[field][key] = value;
      if (
        key === "whatsApp_opt_out" ||
        key === "email_opt_out" ||
        key === "SMS_opt_out"
      ) {
        if (value) {
          if (
            !state.singleContactUploadDetails[field].opted_out?.includes(
              optOutValue
            )
          ) {
            state.singleContactUploadDetails[field].opted_out?.push(
              optOutValue
            );
          }
        } else {
          state.singleContactUploadDetails[field].opted_out =
            state.singleContactUploadDetails[field].opted_out?.filter(
              (item) => item !== optOutValue
            );
        }
      }
    },
    setFilters: (state, action) => {
      const { key, value, filter, operator } = action.payload;
      const item = { id: uuid(), [key]: filter, value };

      if (state.filters.length >= 1) {
        state.filters[state.filters.length - 1]["operator"] = operator;
      }
      state.filters.push(item);
    },

    updateFilterOperator: (state, action) => {
      const { id, operator } = action.payload;
      state.filters = state.filters.map((item) => {
        if (item.id === id) {
          return { ...item, operator };
        }
        return item;
      });
    },

    handleDeleteFilter: (state, action) => {
      const {
        id,
        clear_filter = false,
        remove_label = false,
        label_id,
      } = action.payload;
      if (clear_filter) {
        state.filters = [];
        return;
      }

      if (remove_label) {
        state.filters = state.filters.map((filter) => {
          if (filter.id === id && filter.value.fieldValue.length >= 2) {
            return {
              ...filter,
              value: {
                ...filter.value,
                fieldValue: filter.value.fieldValue.filter(
                  (item) => item.id !== label_id
                ),
              },
            };
          }
          return filter;
        });
        return;
      }

      state.filters = state.filters.filter((item) => item.id !== id);

      if (state.filters.length >= 1) {
        delete state.filters[state.filters.length - 1].operator;
      }
    },
    updateSingleContactDetails: (state, action) => {
      const { access_name, value } = action.payload;
      state.singleContactDetails[access_name] = value;
    },

    updateSingleContactView: (state, action) => {
      const { data } = action.payload;
      const phoneNumber = data?.person_contacts?.filter(
        (c) => c.contact_type === "Mobile phone number"
      );

      const alternative_mobile = data?.person_contacts?.filter(
        (c) => c.contact_type === "Mobile phone number" && c.priority === 2
      );
      const email = data?.person_contacts?.filter(
        (c) => c.contact_type === "Email address" && c.priority === 1
      );
      state.overviewDetails = data;
      state.singleContactDetails = {
        ...state.singleContactDetails,
        first_name: data?.first_name ? data?.first_name : "",
        last_name: data?.last_name ? data?.last_name : "",
        phone_number:
          phoneNumber?.[0]?.["contact_type"] === "Mobile phone number"
            ? phoneNumber?.[0]?.phone_number
            : "",
        alt_phone:
          alternative_mobile?.[0]?.["contact_type"] === "Mobile phone number" &&
          alternative_mobile?.[0]?.["priority"] === 2
            ? alternative_mobile?.[0]?.phone_number
            : "hii",
        wk_email: data?.last_name ? data?.last_name : "",
        email:
          email?.[0]?.["contact_type"] === "Email address"
            ? email?.[0]?.email
            : "",
        age: data?.age ? data?.age : "",
        gender: data?.gender ? data?.gender : "",
        profession: data?.profession ? data?.profession : "",
        contact_src: data?.contact_source ? data?.contact_source : "",
        // lc_stage: data?.last_name ? data?.last_name : "",
        created_at: data?.created_at ? data?.created_at : "",
        last_contacted_at: data?.created_at !== null ? "" : data?.created_at,
        street: data?.address ? data?.address : "",
        city: data?.city ? data?.city : "",
        state: data?.state ? data?.state : "",
        zip: data?.zip ? data?.zip : "",
        selected_tags: [],
        country: data?.country ? data?.country : "",
        opt_wap: false,
        opt_email: true,
        opt_sms: true,
        prof_fb: data?.prof_fb ? data?.prof_fb : "",
        prof_ig: data?.prof_ig ? data?.prof_ig : "",
        prof_x: data?.prof_x ? data?.prof_x : "",
        prof_linkedin: data?.prof_linkedin ? data?.prof_linkedin : "",
        prof_tiktok: data?.prof_tiktok ? data?.prof_tiktok : "",
      };
    },

    setTagList: (state, action) => {
      state.tagList = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setColumns,
  handleDynamicContactSelect,
  // handleContactModelSelection,
  setFiles,
  handelDeleteFile,
  setFilters,
  clearFilters,
  handleDeleteFilter,
  updateFilterOperator,
  handleUploadedFieldsSelection,
  handleSingleColsAction,
  updateSingleContactDetails,
  updateSingleContactView,
  setTagList,
  setOrigins,
} = crmSlice.actions;

export default crmSlice.reducer;
