import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

// assets
import { ICONS } from "../../../../assets/icons";
import { images } from "../../../../assets/images";

// api
import {
  UpdateTemplate,
  carouselApprovedGet,
  carouselCardUpdate,
  carouselDiscard,
  carouselSaveDraft,
  carouselUpdate,
  checkTemplateExits,
  createAndUpdateTemplate,
} from "../../api/Api";

// utils
import {
  checkToAddOptout,
  filterValidValuesFromButton,
  getCTAButtons,
} from "components/commonComponents/templates/Buttons/utils";

// helper
import { capitalizeFirstLetter } from "helper/capitalizeFirstLetter";
import { getLanguageCode, getLanguageName } from "helper/setLanguageCode";
import { isValidHttpsUrl } from "helper/checkUrls";
import { removeDoubleStyles, getDataFromTemplate } from "helper/templates";
import { cn } from "../../../../helper/cn";
import { checkMultipleNewLines } from "../../../inbox/helper";
import {
  bodyTextLimit,
  checkVariablesAreValid,
  getReplacedVariablesCount,
  getVariablesCount,
  replaceCustomVariableCount,
} from "./index";
import { convertMinutesIntoSecond } from "../../../../helper/timeConversion";

// constants
import {
  CATEGORIES_OPTIONS,
  copyCode,
  LANGUAGE_OPTIONS,
  oneTabAutoFill,
  zeroTabAutoFill,
} from "../../constant";
import { FEATURE_FLAG_AUTHENTICATION_TEMPLATE } from "../../constant/featureFlags";

// hooks
import useToggle from "hooks/useToggle";

// reducer
import {
  INITIAL_STATE,
  templateReducer,
} from "hooks/templates/templateReducers";
import {
  resetTemplateDetails,
  update,
} from "../../../../reduxToolkit/templateSlice";
import {
  useAspDispatch,
  useAspSelector,
} from "../../../../test/jest-redux-hooks";
import { updateToggleToast } from "../../../../reduxToolkit/appSlice";

// components
import Loader from "components/commonComponents/Loader/Index";
import Select from "components/commonComponents/select/index";
import Alert from "components/commonComponents/modals/Alert";
import TemplatePreview from "../../../../components/commonComponents/templates/TemplatePreview";
import AuthenticationTemplate from "./AuthenticationTemplate";
import TemplateBody from "./TemplateBody";
import TemplateButtons from "./TemplateButtons";
import TemplateFooter from "./TemplateFooter";
import TemplateHeader from "./TemplateHeader";
import TemplateName from "./TemplateName";
import CarouselTemplate from "./CarouselTemplate";
import { Modal, Popover } from "antd";
import NewAlert from "../../../../components/commonComponents/modal/alert/NewAlert";

export default function TemplateDetails() {
  const navigate = useNavigate();
  const { template } = useParams();

  const dispatch = useAspDispatch();
  const templateDetails = useAspSelector((state) => state?.Template);
  const [footerText, setFooterText] = useState("");
  const [discardAlertOpen, setDiscardAlertOpen] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const channelsData = useAspSelector((state) => state?.Channel?.data);
  const [templateData, templateDispatch] = useReducer(
    templateReducer,
    INITIAL_STATE
  );
  const {
    id,
    header,
    headerVariable,
    body,
    bodyVariables,
    footer,
    buttons,
    buttonsOrder,
    ctaButtons,
    customButtons,
    authentication,
    carousel,
  } = templateData;

  const category = useCallback(
    () => templateDetails?.categoryType?.toUpperCase(),
    [templateDetails]
  );

  const { currentBrand, toggleToast } = useAspSelector((state) => state.app);
  const [showOnUnloadPopup, setshowOnUnloadPopup] = useToggle(false);
  const [templateName, setTemplateName] = useState(false);
  const [editTemplateName, setEditTemplateName] = useToggle(false);
  const [isTemplateNameChecking, setIsTemplateNameChecking] = useToggle(false);
  const [templateNameError, setTemplateNameError] = useToggle(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [loading, setLoading] = useToggle(false);
  const [isDiscard, setIsDiscard] = useToggle(false);
  const [isCategorychanging, setIsCategorychanging] = useToggle(false);
  // editor
  const [showEmojiPicker, setShowEmojiPicker] = useToggle(false);
  const checkOptOutButton = checkToAddOptout(buttons);

  const [isAddingCarouselCard, setIsAddingCarouselCard] = useToggle(false);

  const location = useLocation();

  // useEffect(() => {
  //   const handleBackClick = () => {
  //     const backPath = `/user/channels/whatsapp/edit-template/template-details`;
  //     navigate(backPath);
  //   };

  //   handleBackClick();
  // }, []);

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     if (
  //       location.pathname ===
  //       "/user/channels/whatsapp/edit-template/template-details"
  //     ) {
  //       event.preventDefault();
  //       event.returnValue = "";
  //       setDiscardAlertOpen(true);
  //     }
  //   };

  //   // Add event listener
  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   // Cleanup function to remove the event listener
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [location.pathname]);

  const ref = useRef(null);

  useEffect(() => {
    if (isAddingCarouselCard) {
      const timeout = setTimeout(async () => {
        setIsAddingCarouselCard(false);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [isAddingCarouselCard]);

  useEffect(() => {
    setTemplateName(templateDetails?.name);

    if (isCategorychanging) {
      const timeout = setTimeout(() => {
        setIsCategorychanging(false);
      }, [1000]);

      return () => clearTimeout(timeout);
    }
  }, [isCategorychanging]);

  useEffect(() => {
    if (!showOnUnloadPopup) return;
    else {
      const handleonBeforeUnload = (e) => {
        e.preventDefault();
        return (e.returnValue = "");
      };

      window.addEventListener("beforeunload", handleonBeforeUnload, {
        capture: true,
      });

      return () => {
        window.removeEventListener("beforeunload", handleonBeforeUnload, {
          capture: true,
        });
      };
    }
  }, [showOnUnloadPopup]);

  useEffect(() => {
    if (template === "create-template" && templateDetails?.language?.[0]) {
      const language = templateDetails?.language?.[0]
        ? Object?.keys(templateDetails?.language?.[0])
        : "";
      const res = LANGUAGE_OPTIONS?.filter((l) => l.value === language?.[0]);
      setSelectedLanguage(res?.[0]);
      // setSelectedLanguage({
      //   label: language?.[0],
      //   value: language?.[0],
      // });
      // if (res?.length > 0) setSelectedLanguage(res?.[0]);
      // else setSelectedLanguage(LANGUAGE_OPTIONS?.[0]);
    }

    if (
      template === "duplicate-template" &&
      templateDetails?.details?.name?.length > 0
    ) {
      setTemplateNameError(true);
    }

    if (["edit-template", "duplicate-template"]?.includes(template)) {
      const res = LANGUAGE_OPTIONS?.filter(
        (l) => l.value === getLanguageName(templateDetails?.details?.language)
      );
      if (res?.length > 0) setSelectedLanguage(res?.[0]);
      else setSelectedLanguage(LANGUAGE_OPTIONS?.[0]);
      // setSelectedLanguage({
      //   label: getLanguageName(templateDetails?.details?.language),
      //   value: getLanguageName(templateDetails?.details?.language),
      // });
    }

    const categorys = capitalizeFirstLetter(templateDetails?.category)
      ? {
          label: capitalizeFirstLetter(templateDetails?.category),
          value: templateDetails?.category,
        }
      : null;
    setSelectedCategory(categorys);
  }, []);

  useEffect(() => {
    setTemplateName(templateDetails?.name);
  }, [templateDetails]);

  useEffect(() => {
    let payload = {
      type: "update_template",
      data: null,
    };

    const template_ids =
      templateDetails?.continueDetails?.template?.id ||
      templateDetails?.continueDetails?.template_id ||
      templateDetails?.continueDetails?.id;

    if (
      ["edit-template", "duplicate-template"]?.includes(template) &&
      templateDetails?.details &&
      templateDetails?.categoryType !== "carousel"
    ) {
      payload["data"] = templateDetails?.details;
      templateDispatch(payload);
    } else if (
      ["create-template", "edit-template", "duplicate-template"]?.includes(
        template
      ) &&
      templateDetails?.continueDetails &&
      templateDetails?.categoryType === "carousel"
    ) {
      payload["data"] = templateDetails?.continueDetails;

      if (
        templateDetails?.categoryType === "carousel"
        // &&
        // (templateDetails?.continueDetails?.status === "PAM" ||
        //   templateDetails?.continueDetails?.status === "APPROVED" ||
        //   templateDetails?.continueDetails?.status === "TEMPLATE_LOCKED")
      ) {
        carouselApprovedGet(template_ids, currentBrand?.brand_id)
          .then((res) => {
            if (res?.status === 200) {
              payload["data"] = res?.data?.template;
              templateDispatch(payload);
            }
          })
          .catch((error) => {
            console.error("Error fetching carousel template:", error);
          });
      } else if (
        ["duplicate-template"]?.includes(template) &&
        templateDetails?.continueDetails &&
        templateDetails?.categoryType === "carousel"
      ) {
        payload["data"] = templateDetails?.continueDetails;

        if (templateDetails?.categoryType === "carousel") {
          carouselApprovedGet(template_ids, currentBrand?.brand_id)
            .then((res) => {
              if (res?.status === 200) {
                payload["data"] = res?.data?.template;
                templateDispatch(payload);
              }
            })
            .catch((error) => {
              console.error("Error fetching carousel template:", error);
            });
        }
      } else {
        templateDispatch(payload);
      }
    }
  }, [
    template,
    templateDetails,
    templateDispatch,
    currentBrand,
    carouselApprovedGet,
  ]);

  useEffect(() => {
    checkValueHasChanged();
  }, [
    header,
    headerVariable,
    body,
    bodyVariables,
    footer,
    buttons,
    buttonsOrder,
    ctaButtons,
    customButtons,
    authentication,
    carousel,
  ]);

  const checkName = async (name) => {
    if (name) {
      setIsTemplateNameChecking(true);
      setTemplateNameError(false);
      try {
        const res = await checkTemplateExits({
          brandId: currentBrand?.brand_id,
          name: name,
        });
        if (!res?.data?.exists) {
          dispatch(update({ key: "name", value: templateName }));
          setIsTemplateNameChecking(false);
          setEditTemplateName(false);
        } else {
          setIsTemplateNameChecking(false);
          setTemplateNameError(true);
        }
      } catch (e) {
        setIsTemplateNameChecking(false);
        console.warn(e);
      }
    } else {
      setTemplateNameError(true);
      setIsTemplateNameChecking(false);
    }
  };

  const checkValueHasChanged = () => {
    if (template === "create-template") {
      const data =
        JSON.stringify(INITIAL_STATE) === JSON.stringify(templateData);
      setshowOnUnloadPopup(!data);
    }
    if (
      ["edit-template", "duplicate-template"].includes(template) ||
      !["carousel", "authentication"]?.includes(templateDetails?.categoryType)
    ) {
      const datas = getDataFromTemplate(templateDetails?.details);
      const data = JSON.stringify(datas) === JSON.stringify(templateData);
      setshowOnUnloadPopup(!data);
    }
  };

  // handle buttons
  const updateButtons = ({ updatevalue, btnObj, key, value, id }) => {
    if (btnObj?.format === "OPT_OUT") {
      templateDispatch({
        type: "footer",
        text: "Not interested? Tap Stop promotions",
      });
      setFooterText("Not interested? Tap Stop promotions");
    }
    if (updatevalue === "remove") {
      const removed = buttons?.filter((b) => b.id === id);
      if (removed?.length > 0) {
        if (removed[0]?.format === "OPT_OUT") {
          templateDispatch({
            type: "footer",
            text: "",
          });
          setFooterText("");
        }
      }
    }
    if (!loading) {
      templateDispatch({
        type: "buttons",
        updateType: updatevalue,
        btnObj: btnObj
          ? { id: id, ...btnObj }
          : {
              id: id,
              key: key,
              value: value,
            },
      });
    }
  };

  //**  valiadtion for submit the template **//
  // submit actions
  const buttonValidatations = useCallback(
    (type) => {
      return ctaButtons?.filter((b) => {
        if (type === "URL")
          return (
            b?.text &&
            b?.url &&
            isValidHttpsUrl(b?.url) &&
            b?.text?.length <= 25 &&
            b?.url?.length <= 2000
          );
        if (type === "PHONE_NUMBER")
          return (
            b?.text &&
            b?.text.length <= 25 &&
            b?.phone_number &&
            b?.phone_number.slice(3).length <= 20
          );
        if (type === "COPY_CODE")
          return (
            b?.example &&
            b?.example?.[0] !== "" &&
            b?.example?.[0]?.length <= 15
          );
        if (format === "CUSTOM") return b?.text !== "" && b?.text?.length <= 25;
        if (format === "OPT-OUT")
          return b?.text !== "" && b?.text?.length <= 25;
      });
    },
    [ctaButtons]
  );

  const customButtonValidatations = useCallback(
    (format) => {
      return customButtons?.filter((b) => {
        if (b?.format === format) return b.text !== "" && b.text?.length < 25;
      });
    },
    [customButtons]
  );

  // check the specific type of buttons length in button array
  const chekButtonsLength = useCallback(
    (type) => buttons?.filter((b) => b.type === type),
    [buttons]
  );

  const checkOptoutisExistinCustom = useCallback(() => {
    let optoutFilter = [];
    customButtons?.map((b) => {
      if (b.format === "CUSTOM" && b.text === "Stop promotions")
        optoutFilter.push(b);
    });
    return optoutFilter?.length === 0 ? true : false;
  }, [customButtons]);

  // check is allow to submit the template
  const checkAllowToSubmit = useCallback(() => {
    // header validation
    const isHeader = header
      ? (header?.format === "TEXT" && header?.text !== "" && !headerVariable) ||
        (header?.format === "TEXT" &&
          header?.text !== "" &&
          headerVariable &&
          headerVariable?.value !== "") ||
        (["IMAGE", "VIDEO", "DOCUMENT"].includes(header?.format) &&
          header?.example?.header_handle &&
          header?.example?.header_handle?.[0] !== "")
      : true;

    // body validation
    const isBodyVariable = bodyVariables?.filter((v) => v.value === "");
    const checkLine = checkMultipleNewLines(body?.text);
    const newLineLength = checkLine?.length > 0 ? false : true;
    const bodyVariableCount = getVariablesCount(body?.text);

    const replacedCount = replaceCustomVariableCount(body?.text);
    const isVariablesAreInvalid = checkVariablesAreValid(replacedCount);

    const isBody =
      body?.text?.length > 0
        ? bodyVariableCount?.length !== 0 &&
          bodyVariableCount?.length <= bodyTextLimit &&
          !isVariablesAreInvalid
          ? bodyVariableCount?.length !== 0 &&
            bodyVariableCount?.length <= bodyTextLimit &&
            (bodyVariables?.length === 0 ? true : false)
            ? true
            : bodyVariableCount?.length !== 0 &&
              bodyVariableCount?.length <= bodyTextLimit &&
              (isBodyVariable?.length > 0 ? false : true)
            ? true
            : bodyVariableCount?.length !== 0 &&
              bodyVariableCount?.length <= bodyTextLimit &&
              newLineLength
            ? false
            : bodyVariableCount?.length !== 0 &&
              bodyVariableCount?.length <= bodyTextLimit
            ? true
            : false
          : false
        : false;

    // buttons validations
    const urlButtons =
      chekButtonsLength("URL")?.length > 0
        ? buttonValidatations("URL")?.length ===
          getCTAButtons(ctaButtons, "URL")?.length
          ? true
          : false
        : true;

    const phonenumberButtons =
      chekButtonsLength("PHONE_NUMBER")?.length > 0
        ? buttonValidatations("PHONE_NUMBER")?.length ===
          getCTAButtons(ctaButtons, "PHONE_NUMBER")?.length
          ? true
          : false
        : true;

    const copyButtons =
      chekButtonsLength("COPY_CODE")?.length > 0
        ? buttonValidatations("COPY_CODE")?.length ===
          getCTAButtons(ctaButtons, "COPY_CODE")?.length
          ? true
          : false
        : true;

    const isCustom =
      chekButtonsLength("QUICK_REPLY")?.length > 0
        ? customButtonValidatations("CUSTOM")?.length ===
          getCTAButtons(customButtons, "CUSTOM")?.length
          ? true
          : false
        : true;

    const isButtons =
      urlButtons && phonenumberButtons && copyButtons && isCustom
        ? true
        : false;

    const language = templateDetails?.language?.[0]
      ? Object?.keys(templateDetails?.language?.[0])
      : "";

    const res = LANGUAGE_OPTIONS?.filter(
      (l) =>
        l.label === selectedLanguage?.label &&
        l.value === selectedLanguage?.value
    );

    // authentication template
    const isExpiryTime = authentication?.content?.expiryTimeForCode
      ? authentication?.content?.expiryTime >= 1 &&
        authentication?.content?.expiryTime <= 90
      : true;

    const isAuthenticationButtons = authentication?.buttons?.filter(
      (b) => b.text === ""
    );

    // carousel template
    const isCrad = (key) =>
      carousel?.cards?.filter((c) => c[key] === "" || c[key] === null);
    const isCardHeader = isCrad("image");
    const isCardBody = isCrad("bodyText");
    const emptyButtons = [];
    carousel?.cards?.map((c) => {
      c.buttons.map((b) => {
        if (b?.type === "PHONE_NUMBER")
          if (b?.text === "") emptyButtons?.push(b);
          else if (b?.phone_number === "") emptyButtons?.push(b);
        if (b?.type === "URL")
          if (b?.text === "") emptyButtons?.push(b);
          else if (b?.url === "") emptyButtons?.push(b);
          else if (!isValidHttpsUrl(b.url)) emptyButtons?.push(b);
        if (b.text === "") emptyButtons?.push(b);
      });
    });
    const isCarouselVariables = carousel?.bodyVariables?.filter(
      (v) => v.value === ""
    );

    return isCategorychanging
      ? false
      : category() === "AUTHENTICATION"
      ? templateDetails?.name &&
        !templateNameError &&
        authentication?.body?.length > 0 &&
        isExpiryTime &&
        isAuthenticationButtons?.length === 0
      : category() === "CAROUSEL"
      ? templateDetails?.name &&
        !templateNameError &&
        isBody &&
        isCardHeader?.length === 0 &&
        isCardBody?.length === 0 &&
        carousel?.cards?.length > 1 &&
        emptyButtons?.length === 0 &&
        isCarouselVariables?.length === 0
      : templateDetails?.name &&
        !templateNameError &&
        isHeader &&
        isBody &&
        isButtons &&
        checkOptoutisExistinCustom() &&
        newLineLength &&
        res?.length > 0;
  }, [
    isCategorychanging,
    category(),
    templateNameError,
    templateName,
    header,
    body,
    footer,
    buttons,
    ctaButtons,
    customButtons,
    checkOptoutisExistinCustom(),
    bodyVariables,
    authentication,
    templateDetails,
    carousel,
  ]);
  //** validations ends here  **//

  // cancel the template
  const discard = () => {
    dispatch(resetTemplateDetails());
    templateDispatch({
      type: "reset",
    });
    navigate("/user/channels/whatsapp/message-templates");
  };

  const discardCarousel = () => {
    const payLoad = {
      brand_id: currentBrand?.brand_id,
    };

    carouselDiscard(
      templateDetails?.continueDetails?.template_id,
      payLoad
    ).then((res) => {
      if (res?.status === 200) {
        navigate("/user/channels/whatsapp/message-templates");
      }
    });
  };

  useEffect(() => {
    // Check if body.text is filled
    if (body?.text) {
      let component = [];
      let data = {};
      const languages = getLanguageCode(selectedLanguage?.value);

      if (bodyVariables?.length > 0) {
        component.push({
          type: "BODY",
          text: body.text,
          example: {
            body_text: [bodyVariables.map((b) => b.value)],
          },
        });
      } else {
        component.push({
          type: "BODY",
          text: removeDoubleStyles(body.text),
        });
      }

      data = {
        category: selectedCategory?.value,
        name: templateDetails?.name,
        language: languages,
        brand_id: currentBrand?.brand_id,
        components: [...component, { type: "CAROUSEL", cards: [] }],
        save_as_draft: true,
      };

      const template_ids =
        templateDetails?.continueDetails?.template?.id ||
        templateDetails?.continueDetails?.template_id ||
        templateDetails?.continueDetails?.id;

      const card_template_id =
        ["create-template", "edit-template", "duplicate-template"].includes(
          template
        ) && template_ids;
      // : templateDetails?.continueDetails?.template_id;

      const debounceTimeout = setTimeout(() => {
        if (templateDetails?.categoryType === "carousel") {
          carouselSaveDraft(card_template_id, {
            ...data,
          });
        }
      }, 500);
      return () => clearTimeout(debounceTimeout);
    }
  }, [
    body?.text,
    bodyVariables,
    selectedCategory,
    templateDetails,
    selectedLanguage,
    currentBrand,
  ]);

  //** make the api call for create and update the template *//
  const createAndUpdateTemplateData = async (draft = "false") => {
    setLoading(true);

    const languages = getLanguageCode(selectedLanguage?.value);

    let component = [];

    if (header) {
      component.push(header);
    }

    if (footer) {
      component.push(footer);
    }

    if (checkOptOutButton && footer && footer?.text > 0) {
      component.push(footer);
    }

    if (bodyVariables?.length > 0) {
      component.push({
        type: "BODY",
        text: body?.text,
        example: {
          body_text: [
            bodyVariables?.map((b) => {
              return b.value;
            }),
          ],
        },
      });
    } else {
      component.push({
        type: "BODY",
        text: removeDoubleStyles(body?.text),
      });
    }

    if (buttons?.length > 0) {
      const cta = filterValidValuesFromButton(ctaButtons);
      const custom = filterValidValuesFromButton(customButtons);
      component.push({
        type: "BUTTONS",
        buttons:
          buttonsOrder === "ctaButtons"
            ? [...cta, ...custom]
            : [...custom, ...cta],
      });
    }

    let data = {};

    if (category() === "AUTHENTICATION") {
      const autheticationButtons = authentication?.buttons?.map((b) => {
        const { id, label, placeholder, ...rest } = b;
        return rest;
      });

      data = {
        name: templateDetails?.name,
        category: selectedCategory?.value,
        language: languages,
        brand_id: currentBrand?.brand_id,
        save_as_draft: draft,
        components: [
          {
            type: "BODY",
            add_security_recommendation:
              authentication?.content?.securityRecommendation,
          },
          {
            type: "BUTTONS",
            buttons: autheticationButtons,
          },
        ],
      };

      if (authentication?.content?.expiryTimeForCode)
        data = {
          ...data,
          components: [
            ...data?.components,
            {
              type: "FOOTER",
              code_expiration_minutes: authentication?.content?.expiryTime,
            },
          ],
        };

      if (authentication?.messageValidity?.validity) {
        data["validity_period"] = convertMinutesIntoSecond(
          authentication?.messageValidity?.period
        );
      } else {
        const { validity_period, ...rest } = data;
        data = rest;
      }
    } else if (category() === "CAROUSEL") {
      const updatedCards = carousel.cards.map((c) => {
        const headers = {
          type: "HEADER",
          format: carousel.header,
          example: {
            header_handle: [c.image],
          },
        };

        const body = {
          type: "BODY",
          text: c.bodyText,
          example: {
            body_text: c.bodyVariables.map((v) => v.value),
          },
        };

        const buttons = c.buttons?.map((b) => {
          if (b.type === "URL")
            return { type: b.type, text: b.text, url: b.url };
          if (b.type === "PHONE_NUMBER")
            return {
              type: b.type,
              text: b.text,
              phone_number: `+91${b.phone_number}`,
            };
          if (b.type === "COPY_CODE")
            return { type: b.type, text: b.text, example: [b?.example?.[0]] };

          return { type: b.type, text: b.text };
        });

        return {
          components: [
            headers,
            body,
            {
              type: "BUTTONS",
              buttons: buttons,
            },
          ],
        };
      });

      data = {
        category: selectedCategory?.value,
        name: templateDetails?.name,
        language: languages,
        brand_id: currentBrand?.brand_id,
        components: [...component, { type: "CAROUSEL", cards: [] }],
        save_as_draft: draft,
      };
    } else {
      data = {
        category: selectedCategory?.value,
        name: templateDetails?.name,
        language: languages,
        brand_id: currentBrand?.brand_id,
        components: component,
        save_as_draft: draft,
      };
    }

    if (
      (["create-template", "duplicate-template"].includes(template) &&
        category() !== "CAROUSEL") ||
      (["edit-template"].includes(template) &&
        templateDetails?.details?.status === "DRAFT" &&
        category() !== "CAROUSEL")
    ) {
      await createAndUpdateTemplate(data)
        .then((res) => {
          setLoading(false);
          if (res?.status === 200) {
            dispatch(
              updateToggleToast([
                ...toggleToast,
                {
                  id: toggleToast?.length + 1,
                  content: draft
                    ? "Template saved as draft"
                    : "Template created successfully",
                  status: "Success",
                  duration: "",
                },
              ])
            );
            navigate("/user/channels/whatsapp");
          } else {
            dispatch(
              updateToggleToast([
                ...toggleToast,
                {
                  id: toggleToast?.length + 1,
                  content: "Template creation failed. Please try again!",
                  status: "Error",
                  duration: "",
                },
              ])
            );
          }
        })
        .catch((error) => {
          console.error("Error creating/updating template:", error);
        });
    }

    const template_ids =
      templateDetails?.continueDetails?.template?.id ||
      templateDetails?.continueDetails?.template_id ||
      templateDetails?.continueDetails?.id;

    const card_template_id =
      ["create-template", "edit-template", "duplicate-template"].includes(
        template
      ) && template_ids;
    console.log(card_template_id);
    if (category() === "CAROUSEL") {
      carouselSaveDraft(card_template_id, {
        ...data,
      })
        .then((res) => {
          setLoading(false);
          if (res?.status === 200) {
            dispatch(
              updateToggleToast([
                ...toggleToast,
                {
                  id: toggleToast?.length + 1,
                  content: "Template Updated successfully",
                  status: "Success",
                  duration: "",
                },
              ])
            );
            navigate("/user/channels/whatsapp");
          } else {
            dispatch(
              updateToggleToast([
                ...toggleToast,
                {
                  id: toggleToast?.length + 1,
                  content: "Template creation failed. Please try again!",
                  status: "Error",
                  duration: "",
                },
              ])
            );
          }
        })
        .catch((error) => {
          console.error("Error creating/updating template:", error);
        });
    }

    if (
      template === "edit-template" &&
      templateDetails?.details?.status !== "DRAFT" &&
      category() !== "CAROUSEL"
    ) {
      if (
        templateDetails?.details?.validity_period &&
        !authentication?.messageValidity?.validity
      ) {
        data["validity_period"] = -1;
      }

      UpdateTemplate({
        ...data,
        template_id: templateDetails?.details?.template_id,
      })
        .then((res) => {
          setLoading(false);
          if (res?.status === 200) {
            dispatch(
              updateToggleToast([
                ...toggleToast,
                {
                  id: toggleToast?.length + 1,
                  content: "Template Updated successfully",
                  status: "Success",
                  duration: "",
                },
              ])
            );
            navigate("/user/channels/whatsapp");
          } else {
            dispatch(
              updateToggleToast([
                ...toggleToast,
                {
                  id: toggleToast?.length + 1,
                  content: "Template creation failed. Please try again!",
                  status: "Error",
                  duration: "",
                },
              ])
            );
          }
        })
        .catch((error) => {
          console.error("Error creating/updating template:", error);
        });
    }
  };

  const getCardBodyVariables = useCallback(
    (bodyVariables) => {
      return bodyVariables?.map((v) => {
        const res = carousel?.bodyVariables?.filter(
          (b) => b?.title === v?.title
        );
        return res?.[0]?.value;
      });
    },
    [carousel]
  );

  const cardId = carousel?.cards?.slice(-1)?.[0]?.id;

  useEffect(() => {
    if (category() === "CAROUSEL" && carousel?.cards?.length > 0) {
      const cardToUpdate = carousel?.cards?.find(
        (c) => c?.id === selectedCard?.id
      );

      if (cardToUpdate) {
        const headers = {
          type: "HEADER",
          format: carousel?.header,
          example: {
            header_handle: [cardToUpdate?.image],
          },
        };

        const body = {
          type: "BODY",
          text: cardToUpdate?.bodyText,
        };

        if (cardToUpdate?.bodyVariables?.length > 0)
          body["example"] = {
            body_text: [getCardBodyVariables(cardToUpdate?.bodyVariables)],
          };

        const buttons = cardToUpdate?.buttons?.map((b) => {
          if (b?.type === "URL")
            return { type: b.type, text: b?.text, url: b?.url };
          if (b?.type === "PHONE_NUMBER")
            return {
              type: b?.type,
              text: b?.text,
              phone_number: b?.phone_number?.startsWith("+91+91")
                ? b?.phone_number.replace("+91+91", "+91")
                : b?.phone_number?.startsWith("+91")
                ? b?.phone_number
                : `+91${b?.phone_number}`,
            };
          if (b.type === "COPY_CODE")
            return { type: b?.type, text: b?.text, example: [b?.example?.[0]] };

          return { type: b?.type, text: b?.text };
        });

        const updatedCardData = {
          components: [
            headers,
            body,
            {
              type: "BUTTONS",
              buttons: buttons,
            },
          ],
        };

        const isFilled =
          cardToUpdate?.image?.length &&
          cardToUpdate?.bodyText &&
          cardToUpdate?.buttons?.some((b) => b?.text);

        const template_ids =
          templateDetails?.continueDetails?.template?.id ||
          templateDetails?.continueDetails?.template_id ||
          templateDetails?.continueDetails?.id;

        const card_template_id =
          ["create-template", "edit-template", "duplicate-template"].includes(
            template
          ) && template_ids;

        // const card_template_id = [
        //   "create-template",
        //   "duplicate-template",
        // ].includes(template)
        //   ? templateDetails?.continueDetails?.template?.id
        //   : templateDetails?.continueDetails?.template_id;

        if (isFilled) {
          const debounceTimeout = setTimeout(() => {
            carouselCardUpdate(card_template_id, cardToUpdate?.id, {
              name: cardToUpdate?.name,
              brand_id: currentBrand?.brand_id,
              components: updatedCardData.components,
            }).then((res) => {
              if (res?.status === 200) {
                // Handle successful response
              }
            });
          }, 500);

          return () => clearTimeout(debounceTimeout);
        }
      }
    }
  }, [carousel, category, currentBrand, templateDetails, cardId, selectedCard]);

  //** create and update template api call ends here *//

  //** AUTHENTICATION */
  const onAuthenticationChange = () => {};

  // styles
  const btnStyle =
    "w-auto px-[0.8vw] py-[0.5vw] font-md weight-semibold rounded-[0.37vw]";

  const handleDiscard = () => {
    setDiscardAlertOpen(true);
    // if (!discardAlertOpen) {
    //   setPopoverOpen(false);
    // }
  };

  return (
    <div
      className={`w-full max-h-[92vh] overflow-hidden ${
        loading ? "cursor-not-allowed" : "cursor-default"
      }`}
    >
      <div className="w-auto h-[3.7vw] bg-[var(--white)] m-[0.5vw] flex items-center justify-between px-[1vw] mb-0">
        <div className="flex items-center">
          <div className="w-full flex items-center gap-2.5 mr-4">
            <div
              className="w-full max-w-[16vw] h-full flex items-center gap-2.5 p-[0.5vw] border border-[var(--card-border)] rounded-[0.4vw]"
              ref={ref}
              onClick={() => {
                if (template !== "edit-template") setEditTemplateName(true);
              }}
            >
              <img
                src={ICONS?.TemplateName}
                alt="template-name icons"
                className="size-4"
              />
              <div
                className={cn(
                  "w-full font-default weight-semibold flex flex-1",
                  template === "edit-template"
                    ? "cursor-not-allowed"
                    : "cursor-pointer"
                )}
              >
                {template === "edit-template" ? (
                  <p className="w-full max-w-[12vw] truncate font-md weight-medium rounded-[0.4vw] outline-none cursor-not-allowed">
                    {templateDetails?.name}
                  </p>
                ) : editTemplateName ? (
                  <div className="flex-row align-center">
                    <input
                      type="text"
                      value={templateName}
                      className="w-auto"
                      placeholder="Enter template name..."
                      maxLength={512}
                      onChange={(e) => {
                        const { value } = e.target;
                        const modifiedValue = value?.replace(/ /g, "_");
                        const sanitizedValue = modifiedValue
                          .replace(/[^\w\s]/gi, "")
                          .toLowerCase();
                        setTemplateName(sanitizedValue);
                      }}
                    />
                    <span className="w-[3.5vw] pl-10 text-[0.68vw] font-[400] text-[--font-600]">
                      {templateName?.length} / 512
                    </span>
                  </div>
                ) : (
                  <p className="w-full max-w-[12vw] truncate font-md weight-medium rounded-[0.4vw] outline-none cursor-pointer">
                    {templateName ? (
                      templateName
                    ) : (
                      <span disabled className="text-[var(--font-400)]">
                        Enter template name...
                      </span>
                    )}
                  </p>
                )}
              </div>
            </div>
            <div>
              {isTemplateNameChecking ? (
                <Loader Width={20} height={20} />
              ) : editTemplateName ? (
                <img
                  src={ICONS?.greenTickVerticalSteps}
                  alt="confirm"
                  className={cn(
                    "w-[0.8vw] h-[0.8vw]",
                    templateName !== ""
                      ? "opacity-100 cursor-pointer"
                      : "cursor-not-allowed opacity-60"
                  )}
                  onClick={() => {
                    if (templateName !== "") checkName(templateName);
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="flex items-center gap-[1.5vw] border-l-[0.08vw] border-[var(--card-border)] pl-[1.5vw]">
            {template === "create-template" ||
            template === "edit-template" ||
            template === "duplicate-template" ? (
              category() !== "CAROUSEL" ? (
                <Select
                  rootClassName="w-[10vw] border-[var(--card-border)]"
                  placeholder="Select Category"
                  options={
                    FEATURE_FLAG_AUTHENTICATION_TEMPLATE
                      ? CATEGORIES_OPTIONS
                      : CATEGORIES_OPTIONS?.slice(0, 2)
                  }
                  value={selectedCategory}
                  allow={!loading}
                  position="bottom"
                  onChange={(value) => {
                    if (!loading) {
                      setIsCategorychanging(true);
                      if (template === "create-template")
                        templateDispatch({ type: "reset" });
                      dispatch(
                        update({ key: "category", value: value?.value })
                      );
                      setSelectedCategory(value);
                      if (value?.value === "UTILITY") {
                        const res = buttons?.filter(
                          (b) => b.format === "OPT_OUT"
                        );
                        if (res?.length > 0) {
                          updateButtons({
                            updatevalue: "remove",
                            btnObj: { id: res?.[0]?.id },
                          });
                        }
                      }
                    }
                  }}
                />
              ) : (
                <>
                  <span className="border-[0.08vw] border-[var(--card-border)] pl-10 pr-20 py-2.5 br-8 text-[0.78vw] w-[9vw]">
                    Marketing
                  </span>
                </>
              )
            ) : (
              <div className="w-[10vw] font-md weight-medium rounded-[0.4vw] p-[0.5vw] outline-none cursor-not-allowed border border-[var(--card-border)]">
                {templateDetails?.details?.category}
              </div>
            )}
          </div>
          <div className="w-full bg-[var(--white)] px-[0.7vw]">
            {template === "create-template" ? (
              <Select
                value={selectedLanguage}
                options={LANGUAGE_OPTIONS}
                placeholder={"Select language"}
                allow={!loading}
                rootClassName="w-[10vw] border-[var(--card-border)]"
                optionsClassName="!w-[10vw]"
                cardStyle="!max-h-[1.5vw]"
                position="bottom"
                onChange={(val) => {
                  if (!loading) setSelectedLanguage(val);
                }}
              />
            ) : (
              <div className="w-[10vw] font-md weight-medium border-[0.08vw] border-[var(--border-100)] rounded-[0.4vw] p-[0.5vw] outline-none cursor-not-allowed">
                {selectedLanguage?.label}
              </div>
            )}
          </div>
        </div>

        <div className="flex items-center gap-[0.5vw]">
          {category() !== "CAROUSEL" ? (
            <button
              className={`${btnStyle} text-[#616874] py-[0.4vw] hover:bg-[var(--BG-25)]`}
              onClick={() => {
                setIsDiscard(true);
              }}
            >
              Discard
            </button>
          ) : (
            <Popover
              trigger="click"
              open={popoverOpen}
              onOpenChange={(open) => setPopoverOpen(open)}
              placement="bottom"
              content={
                <div className="flex-column gap-5  rounded-lg pt-10 pb-10 pointer ">
                  {category() === "CAROUSEL" &&
                    templateDetails?.continueDetails?.status !== "APPROVED" &&
                    templateDetails?.continueDetails?.status !== "PAM" && (
                      <div
                        className="flex-row align-center gap-1  ml-10 mr-10 hover:bg-[var(--channelButton)] p-10 b-radius-6"
                        onClick={() => {
                          createAndUpdateTemplateData(true);
                          setIsDiscard(false);
                        }}
                      >
                        <img src={ICONS?.carouselDraft} alt="" />
                        <span className="text-[0.8vw] font-[500]">
                          Save As Draft
                        </span>
                      </div>
                    )}
                  {category() === "CAROUSEL" &&
                    (templateDetails?.continueDetails?.status === "APPROVED" ||
                      templateDetails?.continueDetails?.status ===
                        "TEMPLATE_LOCKED" ||
                      templateDetails?.continueDetails?.status === "PAM") && (
                      <div
                        className="flex-row align-center pl-[15px] pr-[15px] hover:bg-[var(--channelButton)] ml-10 mr-10 b-radius-6"
                        onClick={() => {
                          handleDiscard();
                          setPopoverOpen(false);
                        }}
                      >
                        <img src={ICONS?.RedDeleteIcon} alt="" />
                        <span className="pr-10 text-[red] text-[0.8vw] font-[500]">
                          Discard
                        </span>
                      </div>
                    )}
                </div>
              }
              arrow={false}
            >
              {/* {category() === "CAROUSEL" &&
                templateDetails?.continueDetails?.status !== "APPROVED" &&
                templateDetails?.continueDetails?.status !== "PAM" && ( */}
              <div className="flex-row align-center gap-2 pointer border-2 border-[#E8E8EA] rounded-lg px-2 mr-10">
                <img src={ICONS?.CarouselOptions} alt="CarouselOptions" />
                <span className="text-[#616874] text-[0.8vw] font-[500] py-[0.4vw]  ">
                  Options
                </span>
                <img
                  src={ICONS?.chevronDownCarousel}
                  alt="chevronDownCarousel"
                />
              </div>
              {/* )} */}
            </Popover>
          )}
          <button
            className={`${btnStyle} ${
              checkAllowToSubmit()
                ? "bg-[var(--primary)] text-[var(--white)] cursor-pointer"
                : "bg-[var(--lightGrey)] text-[var(--darkgrey)] cursor-not-allowed"
            }`}
            onClick={() => {
              if (checkAllowToSubmit()) {
                createAndUpdateTemplateData(false);
              }
            }}
            disabled={!checkAllowToSubmit()}
            data-testid="submit"
          >
            {loading ? (
              <div className="w-[3vw] h-[1.15vw] flex-row align-center justify-center">
                <Loader Width={20} Height={20} loaderBg="white" />
              </div>
            ) : (
              <div className="w-[3vw] h-[1.15vw]">Submit</div>
            )}
          </button>
        </div>
      </div>
      {templateNameError && (
        <div className="px-[1.5vw] font-sm weight-medium text-[var(--fontRed)] pt-[0.3vw]">
          This name already exists, please enter a different one.
        </div>
      )}
      {(["edit-template", "duplicate-template"].includes(template) &&
        templateName === "") ||
      (template === "create-template" && templateName === "") ? (
        <div className="px-[1.5vw] font-sm weight-medium text-[var(--fontRed)] pt-[0.3vw]">
          Template name can't be empty.
        </div>
      ) : (
        <></>
      )}
      {isCategorychanging ? (
        <div className="w-full h-auto p-[0.5vw]">
          <div className="bg-white flex items-center justify-center p-6 my-4 rounded">
            <Loader Width={30} Height={30} />
          </div>
        </div>
      ) : (
        <div className="w-full flex justify-center gap-[0.5vw] overflow-hidden p-[0.5vw]">
          <div className="w-[25vw] bg-[var(--white)]">
            <div className="w-full font-default weight-semibold text-[var(--contentText)] px-[1vw] py-[1vw]">
              Message Preview
            </div>
            <div className="max-w-[18.5vw] w-full flex-1 overflow-hidden px-2.5">
              <div className="h-[6vh] flex items-center gap-[0.5vw] bg-[#077C70] rounded-t-[1.5vw] px-[0.6vw]">
                <div className="size-[2.1vw] bg-[var(--white)] rounded-full flex items-center justify-center font-xs">
                  Logo
                </div>
                <div className="text-[var(--white)]">
                  <h3 className="font-md weight-bold capitalize leading-[1.2vw]">
                    {channelsData?.whatsapp_business_account_name}
                  </h3>
                </div>
              </div>
              <TemplatePreview
                category={category()}
                header={header}
                headerVariable={headerVariable}
                body={body}
                bodyVariables={bodyVariables}
                footer={footer}
                buttons={buttons}
                buttonsOrder={buttonsOrder}
                ctaButtons={ctaButtons}
                customButtons={customButtons}
                data={templateDetails?.details}
                className={"w-full rounded-none h-[55vh] max-[1600px]:h-[55vh]"}
                authentication={authentication}
                carousel={carousel}
                isAddingCarouselCard={isAddingCarouselCard}
                setIsAddingCarouselCard={setIsAddingCarouselCard}
              />
              <img
                src={images?.TemplatePreviewBottom}
                alt="send msg"
                className="w-[17.2vw]  rounded-b-[1.5vw]"
              />
            </div>
          </div>{" "}
          <div
            className={`w-full bg-[var(--white)] px-[0.7vw] py-[1vw] overflow-y-scroll ${
              templateNameError ? "h-[72.5vh]" : "h-[74.5vh]"
            }`}
          >
            {category() === "AUTHENTICATION" ? (
              <>
                <AuthenticationTemplate
                  data={authentication}
                  dispatch={templateDispatch}
                />
              </>
            ) : category() === "CAROUSEL" ? (
              <>
                <CarouselTemplate
                  id={id}
                  language={selectedLanguage?.value ?? ""}
                  body={body}
                  dispatch={templateDispatch}
                  headerVariable={headerVariable}
                  variables={bodyVariables}
                  allow={!loading}
                  showEmojiPicker={showEmojiPicker}
                  setShowEmojiPicker={setShowEmojiPicker}
                  data={carousel}
                  isAddingCarouselCard={isAddingCarouselCard}
                  setIsAddingCarouselCard={setIsAddingCarouselCard}
                  setSelectedCard={setSelectedCard}
                  selectedCard={selectedCard}
                />
              </>
            ) : (
              <>
                <div className="border-b-[0.08vw] border-[var(--card-border)] pb-[1.5vw]">
                  <TemplateHeader
                    header={header}
                    headerVariable={headerVariable}
                    dispatch={templateDispatch}
                    allow={!loading}
                  />
                </div>
                <div className="mt-[1.5vw] border-b-[0.08vw] border-[var(--card-border)] pb-[0.5vw]">
                  <TemplateBody
                    title="Body"
                    subTitle="Enter the text for your message in the language that you've selected."
                    type="default"
                    language={selectedLanguage?.value ?? ""}
                    body={body}
                    dispatch={templateDispatch}
                    headerVariable={headerVariable}
                    variables={bodyVariables}
                    allow={!loading}
                    showEmojiPicker={showEmojiPicker}
                    setShowEmojiPicker={setShowEmojiPicker}
                  />
                </div>
                <div className="mt-[1.5vw]">
                  <TemplateFooter
                    footer={footer}
                    dispatch={templateDispatch}
                    disabled={!checkOptOutButton}
                    allow={!loading}
                    text={footerText}
                    setText={setFooterText}
                  />
                </div>
                <div className="mt-[1.5vw] pt-[1.5vw] border-t-[0.08vw] border-[var(--card-border)]">
                  <TemplateButtons
                    buttons={buttons}
                    ctaButtons={ctaButtons}
                    customButtons={customButtons}
                    order={buttonsOrder}
                    updateButtons={updateButtons}
                    allow={!loading}
                  />
                </div>
              </>
            )}
          </div>
          <Alert
            content="What would you like to do with the template?"
            open={isDiscard}
            setOpen={setIsDiscard}
            yesContent={"Discard"}
            noButtonClassName="!font-md !weight-semibold"
            // noDisabled={
            //   !(
            //     template === 'edit-template' &&
            //     templateDetails?.details?.status === 'DRAFT' &&
            //     templateDetails?.name?.length > 0 &&
            //     body?.text
            //   ) ||
            //   (template !== 'edit-template' &&
            //     body?.text &&
            //     templateDetails?.name?.length > 0)
            // }
            noContent={
              template === "edit-template"
                ? templateDetails?.details?.status === "DRAFT"
                  ? "Save as Draft"
                  : null
                : "Save as Draft"
            }
            handleNo={() => {
              createAndUpdateTemplateData(true);
              setIsDiscard(false);
            }}
            handleYes={() => {
              discard();
            }}
          />
          {category() === "CAROUSEL" && (
            <Modal
              // open={isDuplicateModal}
              footer={null}
              open={discardAlertOpen}
              className={"asp-modal-popup-small"}
              closable={false}
              centered={true}
              wrapClassName={"bg-[#00000095]"}

              // onCancel={handleCancel}
            >
              <NewAlert
                type="new"
                open={discardAlertOpen}
                icon={ICONS?.discardCarousel}
                setOpen={setDiscardAlertOpen}
                content={
                  <div>
                    <p>
                      You can't bring back once it's discard. What would you
                      like to do?
                    </p>
                  </div>
                }
                title={"Discard Template?"}
                yesName="Continue Editing"
                noName="Yes Discard"
                noButtonClassName="!font-md !weight-semibold"
                handleNo={() => {
                  setDiscardAlertOpen(false);
                }}
                handleYes={() => {
                  discardCarousel();
                }}
              />
            </Modal>
          )}
        </div>
      )}
    </div>
  );
}
