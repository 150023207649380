import { ICONS } from "../../../assets/icons/index";

export const FilterList = [
  {
    id: 1,
    name: "STATUS",
    subFilter: [
      {
        id: 1,
        Filter_name: "Pending",
      },
      {
        id: 2,
        Filter_name: "Being Reviewed",
      },
      {
        id: 3,
        Filter_name: "Offline",
      },

      {
        id: 4,

        Filter_name: "Connected",
      },
      {
        id: 5,

        Filter_name: "Warned",
      },
      {
        id: 6,

        Filter_name: "Banned",
      },
      {
        id: 7,

        Filter_name: "Message Limit Reached",
      },
      {
        id: 8,

        Filter_name: "Unavailable",
      },
      {
        id: 9,

        Filter_name: "Transferred",
      },
      {
        id: 10,
        Filter_name: "Unverified",
      },
    ],
  },
];

export const WhatsappFilterList = [
  {
    id: 1,
    name: "STATUS",
    subFilter: [
      {
        id: 1,
        label: "Active",
        value: "APPROVED" || 10,
        status: "ACTIVE",
      },
      {
        id: 2,
        label: "Rejected",
        value: "REJECTED",
        status: "REJECTED",
      },
      {
        id: 3,
        label: "In Review",
        value: "IN_REVIEW",
        status: "PENDING",
      },
      {
        id: 4,
        label: "Draft",
        value: "DRAFT",
        status: "DRAFT",
      },
      {
        id: 5,
        label: "Paused",
        value: "PAUSED",
        status: "PAUSED",
      },
   
      // {
      //   id: 5,
      //   label: "Meta Failed",
      //   value: "META_FAILED",
      // },
    ],
  },
  {
    id: 2,
    name: "CATEGORY",
    subFilter: [
      {
        id: 5,
        label: "Marketing",
        value: "MARKETING",
        status: 2,
      },
      {
        id: 6,
        label: "Utility",
        value: "UTILITY",
        status: 3,
      },
      {
        id: 7,
        label: "Authentication",
        value: "AUTHENTICATION",
        status: 1,
      },
    ],
  },
];

export const CampaignFilterList = [
  {
    id: 1,
    name: "STATUS",
    subFilter: [
      {
        id: 1,
        Filter_name: "Scheduled",
        value: 10,
      },
      {
        id: 2,
        Filter_name: "Running",
        value: 11,
      },
      {
        id: 3,
        Filter_name: "Completed",
        value: 12,
      },
    ],
  },
  // {
  //   id: 2,
  //   name: "TYPE",
  //   subFilter: [
  //     {
  //       id: 5,
  //       Filter_name: "Acquisition",
  //     },
  //     {
  //       id: 6,

  //       Filter_name: "Awareness",
  //     },
  //     {
  //       id: 7,

  //       Filter_name: "Conversation",
  //     },
  //   ],
  // },
];

export const BroadCastFilterList = [
  {
    id: 1,
    name: "STATUS",
    subFilter: [
      {
        id: 1,
        Filter_name: "Running",
        value: 11,
      },
      {
        id: 2,
        Filter_name: "Scheduled",
        value: 10,
      },
      {
        id: 3,
        Filter_name: "Draft",
        value: 0,
      },
      {
        id: 4,
        Filter_name: "Completed",
        value: 12,
      },
    ],
  },
  // {
  //   id: 2,
  //   name: "TYPE",
  //   subFilter: [
  //     {
  //       id: 5,
  //       Filter_name: "Broadcast",
  //     },
  //     {
  //       id: 6,

  //       Filter_name: "Drip",
  //     },
  //     {
  //       id: 7,

  //       Filter_name: "Sequence",
  //     },
  //     {
  //       id: 8,

  //       Filter_name: "Trigger",
  //     },
  //   ],
  // },
];

export const ContactViewFilterList = [
  {
    id: 1,
    name: "STATUS",
    subFilter: [
      {
        id: 1,
        Filter_name: "Delivered",
        value: 4,
      },
      {
        id: 2,
        Filter_name: "Sent",
        value: 3,
      },
      {
        id: 3,
        Filter_name: "Opened",
        value: 5,
      },
      {
        id: 4,
        Filter_name: "Lead",
        value: 6,
      },
      {
        id: 5,
        Filter_name: "Bounced",
        value: -1,
      },
      {
        id: 6,
        Filter_name: "Sending",
        value: 2,
      },
    ],
  },
];
